import { useFiltersState, useRequest } from 'hooks';
import React from 'react';
import LogsSearchBarV2 from 'screens/Logs/LogsSearchBarV2/LogsSearchBarV2';
import { DateSelection } from 'types';

const LogsMetricsQueryBuilderSearchInner = ({
  date,
  filtersState,
  getFacetNamesRequest,
}: {
  date: DateSelection;
  filtersState: ReturnType<typeof useFiltersState>;
  getFacetNamesRequest: ReturnType<typeof useRequest>;
}) => {
  return (
    <LogsSearchBarV2
      date={date}
      filtersState={filtersState}
      getFacetNamesRequest={getFacetNamesRequest}
    />
  );
};

export default LogsMetricsQueryBuilderSearchInner;
