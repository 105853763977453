import { UplotExtended, TooltipDataReturnProps } from 'types/Timeseries';
import {
  canvasPadding,
  drawChevron,
  drawLineOnTimeseries,
  drawTooltipTimestamp,
  drawVertcalCursor,
  getTooltipPositionByLayoutType,
  themeColorDark,
  themeColorLight,
} from 'utils';
import { CursorStateProps } from 'types/CursorStateContext';

import { getCursorTimestamp, tooltipCursorData } from './tooltip-utils';

const defaultTimeseriesTooltip = ({
  bbox,
  seriesIdx,
  u,
}: {
  bbox: DOMRect;
  seriesIdx: number | null;
  u: UplotExtended;
}): TooltipDataReturnProps => {
  const devicePixelRatio = window.devicePixelRatio || 1;
  // clear canvas
  u.clearCanvasByContext(u.tooltipCtx);

  const padding = canvasPadding(u);
  const leftX = u.cursor.left * devicePixelRatio + padding.left;
  const bottomY = u.height * devicePixelRatio - padding.bottom;
  const pointIdx = u.posToIdx(u.cursor.left);
  const timestamp = getCursorTimestamp(u.data, pointIdx);

  const cursorState: CursorStateProps = {
    timestamp: { value: timestamp, type: 'timestamp' },
  };

  if (u.kfuseHook?.kfuseTooltip && Array.isArray(u.kfuseHook.kfuseTooltip)) {
    for (const fn of u.kfuseHook.kfuseTooltip) {
      const result = fn({ u, si: seriesIdx });
      if (result) return { cursorState, tooltip: result };
    }
  }
  const { darkModeEnabled } = u;

  // calculate color
  const bgColor = darkModeEnabled
    ? themeColorLight.light03
    : themeColorDark.dark03;

  // draw vertical cursor
  drawVertcalCursor({ ctx: u.tooltipCtx, leftX, bottomY, padding, bgColor });

  const CHEVRON_SIZE = 10 * devicePixelRatio;
  // draw chevron
  drawChevron({
    ctx: u.tooltipCtx,
    x: leftX,
    y: bottomY,
    size: CHEVRON_SIZE,
    bgColor,
  });

  // draw tooltip timestamp
  drawTooltipTimestamp({ u, bbox, timestamp, leftX });

  const tooltipData = tooltipCursorData({ bbox, pointIdx, seriesIdx, u });
  if (!tooltipData) return { cursorState, tooltip: undefined };

  // draw lines till the tooltip data point
  if (tooltipData) {
    const { pointX, pointY } = tooltipData.cursor;
    const { x, y } = tooltipData.tooltip;
    drawLineOnTimeseries({
      ctx: u.tooltipCtx,
      start: { x: pointX, y: pointY },
      end: { x, y },
      options: { color: bgColor, lineWidth: 2 },
    });
  }

  const positionByLayoutType = getTooltipPositionByLayoutType({
    bbox,
    nextPos: tooltipData?.tooltip,
    u,
  });

  if (!positionByLayoutType) return;
  return {
    cursorState,
    tooltip: { ...tooltipData.tooltip, ...positionByLayoutType },
  };
};

export default defaultTimeseriesTooltip;
