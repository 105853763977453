import { BasicLogsSheet, Skeleton } from 'components';
import { delimiter } from 'kfuse-constants';
import React, { useEffect, useMemo } from 'react';
import { DateSelection, KfPlatform } from 'types';
import useRequest from 'hooks/useRequest';
import metricsAnalytics from 'requests/metricsAnalytics';
import useSelectedFacetValuesByNameState from 'hooks/useSelectedFacetValuesByNameState';
import { buildPromQLFilterFromSelectedFacetValuesByName } from 'utils/buildPromQLFilterFromSelectedFacetValuesByName';

type Props = {
  date: DateSelection;
  kfPlatform: KfPlatform;
  service?: string;
  serviceHash: string;
  serviceLabels: Record<string, string>;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
};

const ServiceActiveLogs = ({
  date,
  kfPlatform,
  service,
  serviceHash,
  serviceLabels,
  selectedFacetValuesByNameState,
}: Props) => {
  const metricsAnalyticsRequest = useRequest(metricsAnalytics);

  useEffect(() => {
    const filters = buildPromQLFilterFromSelectedFacetValuesByName({
      selectedFacetValuesByName: {
        ...selectedFacetValuesByNameState.state,
        ...(serviceHash ? { service_hash: { [serviceHash]: 1 } } : {}),
      },
      customFilter: 'service_hash!="UNKNOWN"',
      spanTypeFilter: {
        filter: 'db',
        operator: '!=',
      },
    });
    metricsAnalyticsRequest.call({
      date: date,
      excludeNull: false,
      matcher: `edge_latency_count${filters}`,
      labels: ['service_hash'],
      groupBy: [
        kfPlatform === KfPlatform.Kubernetes ? 'pod_name' : 'container_id',
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const metricsAnalyticsResult = metricsAnalyticsRequest.result;

  const logsAttributes = useMemo(() => {
    const attributes = metricsAnalyticsResult?.map(
      (item: { groupVal: Record<string, string> }) => item.groupVal,
    );
    return attributes;
  }, [metricsAnalyticsResult]);

  const logsState = useMemo(() => {
    if (!logsAttributes || !logsAttributes.length) {
      return null;
    }

    const selectedFacetValuesWithOr = logsAttributes.reduce(
      (acc: Record<string, number>, attribute: { [s: string]: string }) => {
        if (!attribute) return acc;
        const [[key, value]] = Object.entries(attribute);
        if (value === 'null') {
          return acc;
        }
        return {
          ...acc,
          [`Cloud${delimiter}${key}${delimiter}string${delimiter}${value}`]: 1,
        };
      },
      {},
    );

    const selectedFacetsFromLabels = Object.entries(serviceLabels || {}).reduce(
      (acc, [key, value]) => {
        //Don't add kf_platform and cloud_account_id to selectedFacetValues
        if (
          !value ||
          !key ||
          key === 'kf_platform' ||
          key === 'cloud_account_id'
        ) {
          return acc;
        }
        return {
          ...acc,
          [`Cloud${delimiter}${key}${delimiter}string${delimiter}${value}`]: 1,
        };
      },
      {},
    );

    return {
      date,
      selectedFacetValues: {
        ...(kfPlatform === KfPlatform.Kubernetes
          ? selectedFacetsFromLabels
          : {}),
      },
      selectedFacetValuesWithOr,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logsAttributes]);

  const isAtleastOneAttributeHasContainerId = useMemo(() => {
    if (!logsAttributes) {
      return false;
    }
    return logsAttributes.some(
      (attribute: { container_id: string }) => attribute.container_id,
    );
  }, [logsAttributes]);

  const isAtleastOneAttributeHasPodName = useMemo(() => {
    if (!logsAttributes) {
      return false;
    }

    return logsAttributes.some(
      (attribute: { pod_name: string }) => attribute.pod_name,
    );
  }, [logsAttributes]);

  if (metricsAnalyticsRequest.isLoading) {
    return <Skeleton count={6} />;
  }

  if (!logsState) {
    return null;
  }

  if (
    kfPlatform === KfPlatform.Docker &&
    !isAtleastOneAttributeHasContainerId
  ) {
    return (
      <div className="placeholder">{`No Logs for ${
        service ? ` for ${service}` : ''
      }`}</div>
    );
  }

  if (
    kfPlatform === KfPlatform.Kubernetes &&
    !isAtleastOneAttributeHasPodName
  ) {
    return (
      <div className="placeholder">{`No Logs for ${
        service ? ` for ${service}` : ''
      }`}</div>
    );
  }

  return (
    <div className="trace-sidebar__active-span__logs">
      <BasicLogsSheet
        logsState={logsState}
        renderToolbarLeft={() => null}
        disableToolbar={true}
      />
    </div>
  );
};

export default ServiceActiveLogs;
