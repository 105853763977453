import { KfPlatform } from 'types/kfuse';
import { Property, RuntimeLanguage, TelemetrySdk } from '../types';

type SubTab = {
  label: string;
  property: Property;
};

type Tab = {
  label: string;
  property: Property;
  subTabs?: SubTab[];
};

const infraKubernetesSubTabs: SubTab[] = [
  { label: 'Pods', property: Property.infraPod },
  { label: 'Nodes', property: Property.infraNode },
  { label: 'Volumes', property: Property.infraVolume },
];

const infraDockerSubTabs: SubTab[] = [
  { label: 'Container', property: Property.infraDockerContainer },
  { label: 'Nodes', property: Property.infraDockerNode },
];

export const tabs: Tab[] = [
  { label: 'Span Names', property: Property.spanName },
  { label: 'Deployments', property: Property.version },
  { label: 'Errors', property: Property.errors },
  {
    label: 'Dependencies',
    property: '' as Property,
    subTabs: [
      { label: 'Upstream', property: Property.upstreamDependencies },
      { label: 'Downstream', property: Property.downstreamDependencies },
    ],
  },
  { label: 'Service Map', property: Property.serviceMap },
  { label: 'Traces', property: Property.traces },
  {
    label: 'Logs',
    property: Property.logs,
  },
  {
    label: 'Infra',
    property: '' as Property,
    subTabs: infraKubernetesSubTabs,
  },
  {
    label: 'Report',
    property: '' as Property,
    subTabs: [
      {
        label: 'Performance',
        property: Property.reportPerformance,
      },
      { label: 'SLA', property: Property.reportSLA },
    ],
  },
];

export const asmTabs = [
  { label: 'Service Map', property: Property.serviceMap },
  {
    label: 'Logs',
    property: Property.logs,
  },
  {
    label: 'Infra',
    property: '' as Property,
    subTabs: infraKubernetesSubTabs,
  },
];

const getRuntimeTab = ({
  language,
  sdk,
  isServiceFromDatabasesList,
}: {
  language: string;
  sdk: string;
  isServiceFromDatabasesList: boolean;
}) => {
  if (isServiceFromDatabasesList || !language) {
    return null;
  }

  if (sdk === TelemetrySdk.opentelemetry) {
    if (language === RuntimeLanguage.java) {
      return { label: 'Runtime', property: Property.infraRuntimeJava };
    }

    if (language === RuntimeLanguage.go) {
      return { label: 'Runtime', property: Property.infraRuntimeGo };
    }

    if (language === RuntimeLanguage.python) {
      return { label: 'Runtime', property: Property.infraRuntimePython };
    }

    if (language === RuntimeLanguage.nodejs) {
      return { label: 'Runtime', property: Property.infraRuntimeNodejs };
    }
  }

  if (sdk === TelemetrySdk.datadog) {
    if (language === RuntimeLanguage.java) {
      return { label: 'Runtime', property: Property.infraRuntimeDatadogJava };
    }

    if (language === RuntimeLanguage.go) {
      return { label: 'Runtime', property: Property.infraRuntimeDatadogGo };
    }

    if (
      language === RuntimeLanguage.nodejs ||
      language === RuntimeLanguage.javascript
    ) {
      return { label: 'Runtime', property: Property.infraRuntimeDatadogNodejs };
    }
  }
};

const TABS_TO_REMOVE_FOR_DB: {
  [key: string]: boolean;
} = {
  Deployments: true,
  Logs: true,
  Infra: true,
  Report: true,
};

const SUB_TABS_TO_REMOVE_FOR_DB: {
  [key: string]: boolean;
} = {
  Downstream: true,
};

// TODO: Khalid/Pavan Refactor this function to make it more readable
export const getAPMTabs = ({
  language,
  sdk,
  isServiceFromDatabasesList,
  kfPlatform,
}: {
  language: string;
  sdk: string;
  isServiceFromDatabasesList: boolean;
  kfPlatform: KfPlatform;
}) => {
  const tabsForService = isServiceFromDatabasesList
    ? tabs.filter((tab) => !TABS_TO_REMOVE_FOR_DB[tab.label])
    : tabs;
  const tabsWithSubTabs = tabsForService.map((tab) => {
    if (tab.subTabs) {
      return {
        ...tab,
        subTabs: isServiceFromDatabasesList
          ? tab.subTabs.filter(
              (subTab) => !SUB_TABS_TO_REMOVE_FOR_DB[subTab.label],
            )
          : tab.subTabs,
      };
    }
    return tab;
  });

  const flattenedSubTabsIfSubTabLengthIsOne = tabsWithSubTabs.map((tab) => {
    if (tab.subTabs && tab.subTabs.length === 1) {
      return {
        ...tab.subTabs[0],
        label: `${tab.subTabs[0].label} ${tab.label}`,
      };
    }
    return tab;
  });

  const runtimeTab = getRuntimeTab({
    language,
    sdk,
    isServiceFromDatabasesList,
  });

  const infraTabIndex = flattenedSubTabsIfSubTabLengthIsOne.findIndex(
    (tab) => tab.label === 'Infra',
  );
  if (language && runtimeTab) {
    flattenedSubTabsIfSubTabLengthIsOne.splice(
      infraTabIndex + 1,
      0,
      runtimeTab,
    );
  }

  let filteredTabsForService = flattenedSubTabsIfSubTabLengthIsOne;
  const isServiceOpentelemetry = sdk === TelemetrySdk.opentelemetry;
  const isServiceDatadog = sdk === TelemetrySdk.datadog;
  const isRuntimeSupported = isServiceOpentelemetry || isServiceDatadog;
  if (!isRuntimeSupported) {
    filteredTabsForService = filteredTabsForService.filter(
      (tab) => tab.label !== 'Runtime',
    );
  }

  if (infraTabIndex > -1 && filteredTabsForService.length > 0) {
    if (kfPlatform === KfPlatform.Docker) {
      filteredTabsForService[infraTabIndex].subTabs = infraDockerSubTabs;
    } else {
      filteredTabsForService[infraTabIndex].subTabs = infraKubernetesSubTabs;
    }
  }

  return filteredTabsForService;
};
