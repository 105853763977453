import {
  ConfirmationModal,
  Paginator,
  ProductTour,
  Table,
  TableSearch,
  TooltipTrigger,
  useColumnsState,
  useModalsContext,
  usePaginator,
  useTableSearch,
  useTableSort,
  useToaster,
} from 'components';
import dayjs from 'dayjs';
import { parseFiltersFromLogsState, useRequest } from 'hooks';
import React from 'react';
import { GrEdit, GrTrash } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { deleteWorkbook } from 'requests';
import LogsSavedViewsRenameModal from './LogsSavedViewsRenameModal';
import { LogsState } from 'types/logs-workbook';
import { defaultLogsQuery } from 'utils';

const logsStateParams = [
  {
    key: 'date',
    isNonEmpty: (date) => Object.keys(date).length,
  },
  {
    key: 'customColumns',
    isNonEmpty: (customColumns) => Object.keys(customColumns).length,
  },
];

type CellType = {
  row: {
    logsState: string;
    name: string;
  };
};

const columns = ({ openDeleteModal, openRenameModal }) => [
  {
    key: 'name',
    label: 'Name',
    renderCell: ({ row }: CellType) => {
      const logsState = row.logsState
        ? (JSON.parse(row.logsState) as LogsState)
        : {};

      const filters = parseFiltersFromLogsState(logsState);

      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set(
        'LogsMetricsQueries',
        JSON.stringify([{ ...defaultLogsQuery, filters }]),
      );

      logsStateParams.forEach(({ key, isNonEmpty }) => {
        const logStateParamValue = logsState[key];
        if (logStateParamValue && isNonEmpty(logStateParamValue)) {
          urlSearchParams.set(key, JSON.stringify(logStateParamValue));
        } else {
          urlSearchParams.delete(key);
        }
      });

      return (
        <Link className="link" to={`/logs?${urlSearchParams.toString()}`}>
          {row.name}
        </Link>
      );
    },
  },
  {
    key: 'createdAt',
    label: 'Created',
    renderCell: ({ value }) => dayjs(value).fromNow(),
  },
  {
    key: 'actions',
    label: 'Actions',
    renderCell: ({ row }: any) => (
      <div className="logs-saved-views__actions">
        <TooltipTrigger tooltip="Rename">
          <button onClick={openRenameModal(row)}>
            <GrEdit />
          </button>
        </TooltipTrigger>
        <TooltipTrigger tooltip="Delete">
          <button onClick={openDeleteModal(row)}>
            <GrTrash />
          </button>
        </TooltipTrigger>
      </div>
    ),
  },
];

type Props = {
  getWorkbooksRequest: ReturnType<typeof useRequest>;
};

const LogsSavedViews = ({ getWorkbooksRequest }: Props) => {
  const modals = useModalsContext();
  const toastmaster = useToaster();
  const openDeleteModal = (workbook) => () => {
    const onConfirm = () => {
      const onSuccess = () => {
        getWorkbooksRequest.call();
        modals.pop();
        toastmaster.addToast({
          status: 'success',
          text: `Successfully deleted '${workbook.name}'`,
        });
      };

      deleteWorkbook(workbook.id).then(onSuccess, () => {});
    };

    modals.push(
      <ConfirmationModal
        onCancel={() => modals.pop()}
        onConfirm={onConfirm}
        description={`Are you sure you want to delete '${workbook.name}'`}
        title={`Delete ${workbook.name}`}
      />,
    );
  };

  const openRenameModal = (workbook) => () => {
    modals.push(
      <LogsSavedViewsRenameModal
        getWorkbooksRequest={getWorkbooksRequest}
        workbook={workbook}
      />,
    );
  };

  const columnsState = useColumnsState({
    columns: columns({ openDeleteModal, openRenameModal }),
    key: 'logs-saved-views',
    initialState: {
      selectedColumns: {
        name: 1,
        createdAt: 1,
        updatedAt: 1,
        actions: 1,
      },
    },
  });

  const tableSearch = useTableSearch({
    rows: getWorkbooksRequest.result || [],
  });

  const tableSort = useTableSort({
    columns: columns({ openDeleteModal, openRenameModal }),
    rows: tableSearch.searchedRows,
  });

  const paginator = usePaginator({ rows: tableSort.sortedRows });
  return (
    <div className="logs-saved-views">
      <div className="logs-saved-views-header">Logs Saved Queries</div>
      <TableSearch
        className="dashboard__list__search mb-2"
        placeholder="Search saved queries"
        tableSearch={tableSearch}
        dataTestId="logs-saved-views-search"
      />
      <Table
        className="table--bordered table--bordered-cells table--padded"
        columns={columnsState.columns}
        rows={paginator.paginatedRows}
        externalTableSort={tableSort}
        isSortingEnabled
        dataTestId="logs-saved-views"
      />
      {<ProductTour />}
      <div className="table-footer">
        <Paginator paginator={paginator} />
      </div>
    </div>
  );
};

export default LogsSavedViews;
