import classnames from 'classnames';
import {
  useIsFirstRender,
  usePortalScroll,
  useRefContext,
  useRequest,
  useSubscriptionRequest,
} from 'hooks';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  FacetPickerAction,
  SelectedFacetRange,
  SelectedFacetValues,
  SubscriptionArgs,
} from 'types';
import Icon from '../base/Icon';
import FacetActions from './FacetActions';
import FacetPickerRange from './FacetPickerRange';
import FacetPickerResetButton from './FacetPickerResetButton';
import FacetPickerValues from './FacetPickerValues';
import useFacetPickerValuesState from './hooks/useFacetPickerValuesState';
import HorizontallyExpandOnHoverAccordion from './HorizontallyExpandOnHoverAccordion';

type Props = {
  actions?: FacetPickerAction[];
  changeFacetRange?: (value: number) => void;
  className?: string;
  clearFacet: () => void;
  disabled?: boolean;
  excludeFacetValue: (value: string) => void;
  getFacetValueCountsRequestOverride?: {
    call: () => Promise<Array<{ value: string; count: number }>>;
    isLoading: false;
    result: Array<{ value: string; count: number }>;
  };
  name: string;
  forceExpanded?: boolean;
  getSearchedValue?: (value: string) => string;
  label?: string;
  ignoreCount?: boolean;
  info?: string;
  isRadio?: boolean;
  isRangeFacet?: boolean;
  lastRefreshedAt: number;
  onExpansionChange?: (expanded: boolean) => void;
  renderAction?: (name: string) => ReactNode;
  renderName?: (
    name: string,
    nameRef: React.MutableRefObject<HTMLElement>,
  ) => ReactNode;
  renderPlaceholderText?: (name: string) => string;
  renderValue?: (value: string) => ReactNode;
  request?: (args: any) => Promise<any>;
  requestSubscription?: SubscriptionArgs;
  selectOnlyFacetValue: (value: string) => void;
  selectedFacetRange?: SelectedFacetRange;
  selectedFacetValues: SelectedFacetValues;
  toggleFacetValue: (value: string, allValues: Array<string>) => void;
  // Only passed in when inside a group
  parentScrollContainerRef?: React.MutableRefObject<HTMLElement>;
};

const FacetPicker = ({
  actions,
  changeFacetRange,
  clearFacet,
  disabled,
  excludeFacetValue,
  getFacetValueCountsRequestOverride,
  getSearchedValue,
  forceExpanded,
  ignoreCount,
  isRadio,
  isRangeFacet,
  info,
  lastRefreshedAt,
  name,
  onExpansionChange,
  renderAction,
  renderName,
  renderPlaceholderText,
  renderValue,
  request,
  requestSubscription,
  selectOnlyFacetValue,
  selectedFacetRange,
  selectedFacetValues,
  toggleFacetValue,
  parentScrollContainerRef,
  ...props
}: Props): ReactElement => {
  const { appRef, leftSidebarRef } = useRefContext();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const fixedContainerRef = useRef<HTMLDivElement | null>(null);
  const facetValuesSearchInputRef = useRef<HTMLInputElement | null>(null);

  const [expanded, setExpanded] = useState(forceExpanded);
  const [hovered, setHovered] = useState(false);

  const onWheel = usePortalScroll({
    fixedContainerRef: fixedContainerRef,
    relativeContainerRef: containerRef,
    fallbackContainerRef: leftSidebarRef,
    parentScrollContainerRef: parentScrollContainerRef || leftSidebarRef,
  });

  const getFacetValueCountsRequest = useRequest(request);
  const getFacetValuesSubscriptionRequest = useSubscriptionRequest(
    requestSubscription || {
      queryBuilder: () => '',
      merge: () => [],
      key: '',
      initialState: [],
    },
  );

  const getFacetValuesRequest = requestSubscription
    ? getFacetValuesSubscriptionRequest
    : getFacetValueCountsRequestOverride
      ? getFacetValueCountsRequestOverride
      : getFacetValueCountsRequest;

  const renderedName =
    renderName && typeof renderName === 'function'
      ? renderName(name, null)
      : name;

  const facetPickerValuesState = useFacetPickerValuesState({
    excludeFacetValue,
    getFacetValuesRequest,
    getSearchedValue,
    isRadio,
    lastRefreshedAt,
    name,
    renderedName,
    renderPlaceholderText,
    renderValue,
    selectOnlyFacetValue,
    selectedFacetValues,
    shouldDisableLoader: Boolean(requestSubscription),
    toggleFacetValue,
    isRangeFacet,
    expanded,
  });

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (onExpansionChange) {
      onExpansionChange(expanded);
    }

    return () => {
      if (onExpansionChange) {
        onExpansionChange(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return (
    <HorizontallyExpandOnHoverAccordion
      portalClassName={classnames({
        'facet-picker--disabled': disabled,
      })}
      renderedName={renderedName}
      info={info}
      appRef={appRef}
      containerRef={containerRef}
      fixedContainerRef={fixedContainerRef}
      leftSidebarRef={leftSidebarRef}
      onWheel={onWheel}
      hovered={hovered}
      setHovered={setHovered}
      isFocusCaptured={() => {
        return facetValuesSearchInputRef?.current === document.activeElement;
      }}
      renderTrigger={(facetNameRef?: React.MutableRefObject<HTMLElement>) => (
        <div className="flex w-full min-w-0 items-center justify-between">
          <div className="max-w-full overflow-hidden truncate">
            {renderName && typeof renderName === 'function'
              ? renderName(name, facetNameRef || null)
              : name}
          </div>
          <div className="flex">
            <div className="hidden group-hover:flex">
              <FacetActions
                renderAction={renderAction}
                requestSubscription={requestSubscription}
                request={request}
                actions={actions}
                name={name}
              />
            </div>
            {isRadio ? null : (
              <div
                className={classnames(
                  'mr-2 flex h-[24px] items-center justify-center rounded-sm',
                )}
              >
                {disabled ? (
                  <Icon icon="lock" size="xs" />
                ) : (
                  <FacetPickerResetButton
                    clearFacet={clearFacet}
                    name={renderedName}
                    selectedFacetRange={selectedFacetRange}
                    selectedFacetValues={selectedFacetValues}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
      expanded={expanded}
      onExpansionChange={(updatedExpanded: boolean) => {
        // Fetch facet values on Accordion open
        if (updatedExpanded) {
          if (isRangeFacet) {
            // getFacetValuesRequest.call();
          } else {
            // TODO(baibhav): Refactor values data fetching logic
            // facetPickerValuesState.fetchGetFacetValues();
          }
        }
        setExpanded(updatedExpanded);
      }}
      renderContent={({
        hoveredAccordionRef,
        scrollDivRef,
      }: {
        hoveredAccordionRef?: React.MutableRefObject<HTMLDivElement>;
        scrollDivRef: React.MutableRefObject<HTMLDivElement>;
      }) => {
        if (isRangeFacet) {
          return (
            <FacetPickerRange
              changeFacetRange={changeFacetRange}
              getFacetValuesRequest={getFacetValuesRequest}
              lastRefreshedAt={lastRefreshedAt}
              name={name}
              renderedName={renderedName}
              renderPlaceholderText={renderPlaceholderText}
              selectedFacetRange={selectedFacetRange}
            />
          );
        }
        return (
          <FacetPickerValues
            {...facetPickerValuesState}
            facetValuesSearchInputRef={facetValuesSearchInputRef}
            hoveredAccordionRef={hoveredAccordionRef}
            facetValuesScrollDivRef={scrollDivRef}
            setHovered={setHovered}
            onWheel={onWheel}
            clearFacet={clearFacet}
            ignoreCount={ignoreCount}
          />
        );
      }}
      triggerClassName={classnames(
        'group w-full cursor-pointer border-t-0 border-transparent pl-2 leading-[28px] hover:bg-interaction-secondary focus:bg-interaction-secondary active:bg-interaction-secondary-contrast',
        { 'mb-1': expanded },
      )}
    />
  );
};

export default FacetPicker;
