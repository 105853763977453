import { delimiter } from 'kfuse-constants';
import { getFacetKey } from 'utils';

import useDateState from '../useDateState';
import {
  useFiltersState,
  FilterType,
  SelectedFacetValueFilter,
} from '../useFiltersState';
import getFiltersByFacets from '../useFiltersState/getFiltersByFacets';
import getKeyExistsState from '../useFiltersState/getKeyExistsState';
import getSelectedFacetRangeByNameState from '../useFiltersState/getSelectedFacetRangeByNameState';
import getSelectedFacetValuesByNameState from '../useFiltersState/getSelectedFacetValuesByNameState';
import getTermsState from '../useFiltersState/getTermsState';

type Args = {
  filtersState: ReturnType<typeof useFiltersState>;
};

const getLogsStateFromFiltersState = ({ filtersState }: Args) => {
  const keyExistsState = getKeyExistsState({
    filtersState,
    filterType: FilterType.keyExists,
  });

  const filterByFacets = getFiltersByFacets({ filtersState });

  const filterOrExcludeByFingerprintState = getKeyExistsState({
    filtersState,
    filterType: FilterType.filterOrExcludeByFingerprint,
  });

  const searchTermsState = getTermsState({
    filtersState,
    filterType: FilterType.searchTerms,
  });

  const selectedFacetRangeByNameState =
    getSelectedFacetRangeByNameState(filtersState);

  const selectedFacetValuesByNameState =
    getSelectedFacetValuesByNameState(filtersState);

  const addFilterByFacet = ({ facetName, dataType, operator, value }) => {
    filtersState.setState((prevState) => [
      ...prevState,
      {
        type: FilterType.selectedFacetValue,
        value: {
          facet: getFacetKey({
            component: '',
            name: facetName,
            type: dataType,
          }),
          operator,
          values: {
            [value]: 1,
          },
        },
      } as SelectedFacetValueFilter,
    ]);
  };

  const applyDeselectedFacetValues = ({
    component,
    facetName,
    facetType,
    facetValuesToSelect,
  }) => {
    const name = getFacetKey({
      component,
      name: facetName,
      type: facetType,
    });

    const facetValues = Object.keys(facetValuesToSelect).reduce(
      (obj, facetCompositeKey) => {
        const parts = facetCompositeKey.split(delimiter);
        const value = parts[parts.length - 1];
        return {
          ...obj,
          [value]: facetValuesToSelect[facetCompositeKey],
        };
      },
      {},
    );

    selectedFacetValuesByNameState.setFacetValues({ facetValues, name });
  };

  const clear = () => {
    filtersState.setState([]);
  };

  const clearFilterOrExcludeByFingerprint = (fpHash: string) => {
    filterOrExcludeByFingerprintState.unsetKeyExists(fpHash);
  };

  const changeFacetRange = ({ facetKey, range }) => {
    selectedFacetRangeByNameState.changeFacetRange({ name: facetKey })(range);
  };

  const excludeFingerprint = (fpHash: string) => {
    filterOrExcludeByFingerprintState.setKeyExists(fpHash, 0);
  };

  const filterFingerpint = (fpHash: string) => {
    filterOrExcludeByFingerprintState.setKeyExists(fpHash, 1);
  };

  const filterOnlyFacetValueFromLogEventDetail = ({
    name,
    source,
    type,
    value,
    exclude = false,
  }) => {
    const facetKey = getFacetKey({
      component: source,
      name,
      type,
    });

    const facetValues = {
      [value]: exclude ? 0 : 1,
    };

    selectedFacetValuesByNameState.setFacetValues({
      name: facetKey,
      facetValues,
    });
  };

  const removeSearchTermByIndex = (index: number) => {
    searchTermsState.removeByIndex(index);
  };

  const resetFacet = (facet: FacetBase) => {
    selectedFacetValuesByNameState.clearFacet(getFacetKey(facet));
  };

  const resetFacetRange = (facet: FacetBase) => {
    selectedFacetRangeByNameState.clearFacet(getFacetKey(facet));
  };

  const toggleKeyExists = ({ component, name, type, isEnabled }) => {
    const facetKey = getFacetKey({
      component,
      name,
      type,
    });

    if (typeof isEnabled === 'number') {
      keyExistsState.setKeyExists(facetKey, isEnabled);
    } else {
      keyExistsState.toggleKeyExists(facetKey);
    }
  };

  const selectedFacetValues = (() => {
    const result: Record<string, 0 | 1> = {};
    Object.keys(selectedFacetValuesByNameState.state).forEach((facetKey) => {
      Object.keys(selectedFacetValuesByNameState.state[facetKey]).forEach(
        (value) => {
          const facetCompositeKey = `${facetKey}${delimiter}${value}`;
          result[facetCompositeKey] =
            selectedFacetValuesByNameState.state[facetKey][value];
        },
      );
    });

    return result;
  })();

  const toggleFacetValue = ({ isSelecting = null, name, value }) => {
    selectedFacetValuesByNameState.toggleFacetValue({
      isSelecting,
      name,
      value,
    });
  };

  return {
    addFilterByFacet,
    addSearchTerm: searchTermsState.add,
    applyDeselectedFacetValues,
    clear,
    clearFilterOrExcludeByFingerprint,
    changeFacetRange,
    excludeFingerprint,
    filterByFacets,
    filterFingerpint,
    filterOnlyFacetValueFromLogEventDetail,
    filterOrExcludeByFingerprint: filterOrExcludeByFingerprintState.state,
    filtersState,
    keyExists: keyExistsState.state,
    removeFilterByFacetByIndex: () => {},
    removeSearchTermByIndex,
    resetFacet,
    resetFacetRange,
    searchTerms: searchTermsState.state,
    selectedFacetRanges: selectedFacetRangeByNameState.state,
    selectedFacetValuesByNameState,
    selectedFacetValues,
    toggleFacetValue,
    toggleKeyExists,
  };
};

export default getLogsStateFromFiltersState;
