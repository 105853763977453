import { ErrorBoundary } from 'components';
import { useSelectedFacetValuesByNameState } from 'hooks';
import React, { ReactElement, useMemo } from 'react';
import { DateSelection, KfPlatform } from 'types';

import { DashboardByName } from '../../Kfuse';
import { Property } from '../types';

const serviceInfraASMDashboardNames: { [key: string]: string } = {
  [Property.infraPod]: 'ASM Pods (Outliers)',
  [Property.infraNode]: 'ASM Nodes (Outliers)',
  [Property.infraVolume]: 'ASM Volumes (Outliers and Forecast)',
};

const serviceInfraRuntimeDashboardNames: { [key: string]: string } = {
  [Property.infraRuntimeGo]: 'Go Runtime Metrics',
  [Property.infraRuntimeJava]: 'JVM Runtime Metrics',
  [Property.infraRuntimePython]: 'Python Runtime Metrics',
  [Property.infraRuntimeNodejs]: 'Nodejs Runtime Metrics',
  [Property.infraRuntimeDatadogGo]: 'Go Runtime Metrics Datadog',
  [Property.infraRuntimeDatadogJava]: 'JVM Runtime Metrics Datadog',
  [Property.infraRuntimeDatadogNodejs]: 'Nodejs Runtime Metrics Datadog',
};

const serviceInfraDockersDashboardNames: { [key: string]: string } = {
  [Property.infraDockerContainer]: 'Docker Containers (Outliers)',
  [Property.infraDockerNode]: 'Docker Nodes (Outliers)',
};

const serviceInfraDashboardNames: { [key: string]: string } = {
  ...serviceInfraASMDashboardNames,
  ...serviceInfraRuntimeDashboardNames,
  ...serviceInfraDockersDashboardNames,
};

const ServiceInfraTabs = ({
  asmMatcher,
  date,
  kfSource,
  kfPlatform,
  property,
  selectedFacetValuesByNameState,
}: {
  asmMatcher: string;
  date: DateSelection;
  kfSource?: string;
  kfPlatform: KfPlatform;
  property: Property;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
}): ReactElement => {
  const kubeFilters = useMemo(() => {
    const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
    const selectedFacetKeys = Object.keys(selectedFacetValuesByName);

    if (
      !selectedFacetKeys.includes('pod_name') &&
      kfPlatform === KfPlatform.Kubernetes
    ) {
      selectedFacetKeys.push('pod_name');
    }

    if (kfPlatform === KfPlatform.Docker) {
      selectedFacetKeys.push('container_id');
    }

    const asmMatcherSplit = asmMatcher.split(',');
    const podNameIndex = asmMatcherSplit.findIndex((item) =>
      item.trim().startsWith('pod_name='),
    );
    if (podNameIndex > -1) {
      asmMatcherSplit.splice(podNameIndex, 1);
    }

    const kfPlatformIndex = asmMatcherSplit.findIndex((item) =>
      item.trim().startsWith('kf_platform='),
    );
    if (kfPlatformIndex > -1) {
      asmMatcherSplit.splice(kfPlatformIndex, 1);
    }

    return {
      matchers: asmMatcherSplit.join(','),
      kf_source: kfSource,
      outlier_threshold: '0.5',
      groupby: selectedFacetKeys.join(', '),
    };
  }, [selectedFacetValuesByNameState.state, kfPlatform, asmMatcher, kfSource]);

  const folderName = useMemo(() => {
    if (Object.keys(serviceInfraASMDashboardNames).includes(property)) {
      return 'kfuse-asm';
    }

    if (Object.keys(serviceInfraDockersDashboardNames).includes(property)) {
      return 'kfuse-apm';
    }

    return 'kfuse-apm';
  }, [property]);

  return (
    <div className="min-h-[50vh]">
      {kubeFilters && (
        <ErrorBoundary errorMessage={<h1>Failed to load outliers charts</h1>}>
          <DashboardByName
            date={date}
            disableFilter={
              Property.infraNode === property ||
              Property.infraDockerNode === property
                ? false
                : true
            }
            disableDeletePanel={true}
            folderName={folderName}
            fetchLimitedTemplates={
              Property.infraNode === property ||
              Property.infraDockerNode === property
                ? [{ name: 'node_matchers', joinValues: true }]
                : []
            }
            hideHeader={true}
            name={serviceInfraDashboardNames[property]}
            shouldWriteToUrl={false}
            templateValues={kubeFilters}
          />
        </ErrorBoundary>
      )}
    </div>
  );
};

export default ServiceInfraTabs;
