import * as yup from 'yup';
import { yupValidator } from 'utils';
import { convertValueWithUnit } from 'utils';

import { AlertType } from '../types';

const alertThresholdConditionValidator = (unit: string) =>
  yup.object().shape({
    value: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === '' ? undefined : value,
      )
      .required('Threshold value is required.')
      .test('is-valid-unit', function (value) {
        const parsedValue = convertValueWithUnit(value, unit);
        if (typeof parsedValue === 'number') {
          return true;
        }
        return this.createError({ path: 'value', message: parsedValue });
      }),
  });

const alertThresholdAnomalyConditionValidator = yup.object().shape({
  value: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value,
    )
    .required('Threshold percentage is required.')
    .typeError('Threshold percentage must be numeric.')
    .min(1, 'Threshold percentage must be between 1 and 100.')
    .max(100, 'Threshold percentage must be between 1 and 100.'),
});

export const validateAlertThresholdValue = ({
  value,
  alertType,
  unit,
}: {
  value: string;
  alertType: AlertType;
  unit?: string;
}) => {
  let validator = undefined;
  if (alertType === AlertType.ANOMALY) {
    validator = alertThresholdAnomalyConditionValidator;
  } else {
    validator = alertThresholdConditionValidator(unit);
  }
  return yupValidator({ schema: validator, value: { value } });
};
