import { useThemeContext } from 'components';
import { useLayoutEffect, useRef, useState } from 'react';
import { useMountedState } from 'react-use';
import {
  ChartType,
  TimeseriesRenderProps,
  TooltipCoordsProps,
  UplotExtended,
} from 'types';
import { drawAnomalyBand } from 'utils/Timeseries';

import {
  addTooltipToDOM,
  getBarSeriesIdx,
  getIndexOfSeriesNearestToCursorValue,
  isCursorOutsideCanvas,
  handleTooltipCanvasForCursorState,
  updateTooltipCanvas,
} from '../utils';
import { UPlotConfig } from '../types';

const useTooltipPluginV2 = ({
  chartType,
  config,
  cursorState,
  onCursorStateChange,
}: {
  chartType: ChartType;
  config: UPlotConfig;
  cursorState: TimeseriesRenderProps['cursorState'];
  onCursorStateChange: TimeseriesRenderProps['onCursorStateChange'];
}): {
  tooltip: TooltipCoordsProps | null;
  isActive: boolean;
} => {
  const plotInstance = useRef<UplotExtended>();
  const { darkModeEnabled } = useThemeContext();

  const [tooltip, setTooltip] = useState<TooltipCoordsProps | null>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const isMounted = useMountedState();

  useLayoutEffect(() => {
    let bbox: DOMRect | undefined = undefined;

    const plotEnter = () => {
      if (!isMounted()) {
        return;
      }
      setIsActive(true);
      plotInstance.current?.root.classList.add('plot-active');
    };

    const plotLeave = () => {
      if (!isMounted()) {
        return;
      }
      setTooltip(null);
      setIsActive(false);
      plotInstance.current?.root.classList.remove('plot-active');
    };

    // cache uPlot plotting area bounding box
    config.addHook('syncRect', (u: uPlot, rect: DOMRect) => (bbox = rect));

    config.addHook('init', (u: UplotExtended) => {
      if (config.width && config.height) {
        const { tooltipDiv, tooltipCtx } = addTooltipToDOM(
          config.width,
          config.height,
        );
        u.root.childNodes[0].appendChild(tooltipDiv);
        u.tooltipCtx = tooltipCtx;
      }
      plotInstance.current = u;

      u.root.parentElement?.addEventListener('focus', () => {
        plotEnter();
      });
      u.over.addEventListener('mouseenter', () => {
        plotEnter();
      });

      u.root.parentElement?.addEventListener('blur', () => {
        plotLeave();
      });
      u.over.addEventListener('mouseleave', () => {
        plotLeave();
      });
    });

    // default series/datapoint idx retireval
    config.addHook('setCursor', (u: UplotExtended) => {
      if (!bbox || !isMounted()) {
        return;
      }

      if (isCursorOutsideCanvas(u.cursor, bbox)) {
        u.clearCanvasByContext(u.tooltipCtx);
        setTooltip(null);
        onCursorStateChange && onCursorStateChange(null);
        return;
      }

      const nearestSeriesFn =
        chartType === 'Line'
          ? getIndexOfSeriesNearestToCursorValue
          : getBarSeriesIdx;
      const nearestSeriesIdx = nearestSeriesFn(u);
      u.setSeries(nearestSeriesIdx, { focus: true });

      const tooltipData = u.getTooltipData({
        bbox,
        seriesIdx: nearestSeriesIdx,
        u,
      });
      drawAnomalyBand({ darkModeEnabled, u, seriesIndex: nearestSeriesIdx });

      if (!tooltipData) {
        setTooltip(null);
        onCursorStateChange && onCursorStateChange(null);
        return;
      }

      onCursorStateChange && onCursorStateChange(tooltipData.cursorState);
      if (tooltipData.tooltip) {
        setTooltip(tooltipData.tooltip);
      } else {
        setTooltip(null);
      }
    });

    // tooltip canvas sync with uPlot canvas
    updateTooltipCanvas(plotInstance.current, config.width, config.height);
    return () => {
      setTooltip(null);
      if (plotInstance.current) {
        plotInstance.current.over.removeEventListener('mouseleave', plotLeave);
        plotInstance.current.over.removeEventListener('mouseenter', plotEnter);
        plotInstance.current.root.parentElement?.removeEventListener(
          'focus',
          plotEnter,
        );
        plotInstance.current.root.parentElement?.removeEventListener(
          'blur',
          plotLeave,
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  useLayoutEffect(() => {
    if (!plotInstance.current || isActive) return;

    handleTooltipCanvasForCursorState({
      u: plotInstance.current,
      cursorState,
      darkModeEnabled,
    });
  }, [cursorState, darkModeEnabled, isActive]);
  return { tooltip, isActive };
};

export default useTooltipPluginV2;
