import { AutocompleteOption, useToaster } from 'components';
import { useRequest } from 'hooks';
import { useEffect, useState } from 'react';
import {
  getDatasources,
  getGrafanaDashboard,
  getGrafanaDashboardByUid,
  getGrafanaFolders,
  mutateGrafanaDashboard,
} from 'requests';
import { DashboardPanelProps, DateSelection } from 'types';
import { convertTimestampToCode } from 'utils';

import {
  dashboardPanelOptions,
  getNewDashboardJSONModel,
  findLargestBottomOfDashboard,
  timeseriesFieldConfig,
} from './utils';

const EXCLUDE_FOLDERS = ['kfuse-cp', 'kfuse-asm', 'kfuse-apm'];

const useDashboardExportState = ({
  annotations,
  closeModal,
  date,
  panel,
  drawStyle = 'line',
}: {
  annotations?: string;
  closeModal: () => void;
  date: DateSelection;
  panel: DashboardPanelProps;
  drawStyle?: string;
}) => {
  const { addToast } = useToaster();
  const [dashboardExportType, setDashboardExportType] = useState<string>('new');
  const [existingDashboards, setExistingDashboards] = useState<
    AutocompleteOption[]
  >([]);
  const [selectedDashboard, setSelectedDashboard] = useState<string>('');
  const [selectedFolder, setSelectedFolder] = useState<string>('');
  const [dashboardDetails, setDashboardDetails] = useState<{
    title: string;
    panelName: string;
  }>({ title: '', panelName: '' });

  const grafanaFoldersRequest = useRequest(
    () =>
      getGrafanaFolders().then((res) =>
        res.map((folder) => ({ label: folder.title, value: folder.uid })),
      ),
    true,
  );
  const grafanaDashboardRequest = useRequest(getGrafanaDashboard);
  const grafanaDatasourceRequest = useRequest(getDatasources);
  const grafanaDashboardMutateRequest = useRequest(mutateGrafanaDashboard);
  const grafanaDashboardByUidRequest = useRequest(getGrafanaDashboardByUid);

  const onExportDashboard = async () => {
    const datasource = await grafanaDatasourceRequest
      .call()
      .then((response: any) => {
        return response.find((datasource: any) => datasource.type === 'loki');
      });

    if (datasource) {
      const datasourceUid = datasource.uid;
      const newPanel = {
        ...panel,
        fieldConfig: timeseriesFieldConfig(drawStyle),
        datasource: { type: 'loki', uid: datasourceUid },
        gridPos: { x: 0, y: 0, w: 12, h: 8, i: '0' },
        title: dashboardDetails.panelName,
      };
      if (annotations) {
        newPanel.fieldConfig.defaults.custom.kAnnotations = annotations;
      }

      if (!panel.options) {
        newPanel.options = dashboardPanelOptions;
      }

      if (dashboardExportType === 'new') {
        const timeDiff = convertTimestampToCode(date);
        const jsonModel = getNewDashboardJSONModel({
          dashboardTitle: dashboardDetails.title,
          timeDiff,
        });
        jsonModel.panels.push(newPanel);
        mutateDashboard(jsonModel);
      } else {
        grafanaDashboardByUidRequest
          .call(selectedDashboard)
          .then((response: any) => {
            const dashboard = response.dashboard;
            const panels: DashboardPanelProps[] = dashboard.panels;
            newPanel.gridPos = findLargestBottomOfDashboard(panels);
            if (panels.length > 0) {
              newPanel.id = panels[panels.length - 1].id + 1;
            } else {
              newPanel.id = 1;
            }
            panels.push(newPanel);
            mutateDashboard(dashboard);
          });
      }
    }
  };

  const mutateDashboard = (jsonModel: any) => {
    grafanaDashboardMutateRequest
      .call({
        jsonModel,
        message: 'Exported from Logs Analytics',
        folderUid: selectedFolder,
      })
      .then((res: any) => {
        addToast({
          text: 'Dashboard exported successfully',
          status: 'success',
        });
        closeModal();
      })
      .catch((e: any) => {
        addToast({ text: 'Dashboard export failed', status: 'error' });
      });
  };

  useEffect(() => {
    grafanaDashboardRequest
      .call(`type=dash-db`)
      .then((responseDashboard: any) => {
        const dashboardList: AutocompleteOption[] = [];
        if (responseDashboard) {
          responseDashboard.forEach((dashboard: any) => {
            if (EXCLUDE_FOLDERS.includes(dashboard.folderTitle)) return;
            const dashboardName = dashboard.title;
            dashboardList.push({
              label: dashboard.folderTitle
                ? `${dashboard.folderTitle}/${dashboardName}`
                : `General/${dashboardName}`,
              value: dashboard.uid,
            });
          });
          setExistingDashboards(dashboardList);
        }
      });
    grafanaFoldersRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dashboardExportType,
    dashboardDetails,
    existingDashboards,
    grafanaFoldersRequest,
    grafanaDatasourceRequest,
    grafanaDashboardMutateRequest,
    grafanaDashboardByUidRequest,
    onExportDashboard,
    selectedDashboard,
    selectedFolder,
    setDashboardExportType,
    setDashboardDetails,
    setSelectedDashboard,
    setSelectedFolder,
  };
};

export default useDashboardExportState;
