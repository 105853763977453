import { RightSidebar, SidebarTraceError } from 'components';
import { useRequest, useTracesState } from 'hooks';
import React, { useEffect } from 'react';
import { traceErrors } from 'requests';
import { Service } from 'types';

type Props = {
  colorsByServiceHash: Record<string, string>;
  close: VoidFunction;
  serviceByHash: Record<string, Service>;
  traceErrorId: string;
  tracesState: ReturnType<typeof useTracesState>;
};

const ApmErrorsTableSidebar = ({
  colorsByServiceHash,
  close,
  serviceByHash,
  traceErrorId,
  tracesState,
}: Props) => {
  const getTraceError = async (args) => {
    return traceErrors({
      traceErrorId: args.traceErrorId,
      tracesState: args.tracesState,
    }).then((result) => (result.length ? result[0] : Promise.reject(null)));
  };

  const getTraceErrorRequest = useRequest(getTraceError);
  const traceError = getTraceErrorRequest.result || null;

  useEffect(() => {
    getTraceErrorRequest.call({ traceErrorId, tracesState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RightSidebar
      className="apm-errors__group-sidebar"
      close={close}
      title={`Error: ${traceErrorId}`}
    >
      <div>
        {traceError ? (
          <SidebarTraceError
            colorsByServiceHash={colorsByServiceHash}
            serviceByHash={serviceByHash}
            traceError={traceError}
            tracesState={tracesState}
          />
        ) : null}
      </div>
    </RightSidebar>
  );
};

export default ApmErrorsTableSidebar;
