import { SelectedFacetValuesByName } from 'types';
import { escapeLinesTabsAndQuotes, getIsDeselecting } from 'utils';

const getFilter = ({ name, value }: { name: string; value: string }) => {
  const nameKey = 'key';
  const nameString = name;

  return `{ ${nameKey}: "${nameString}", value:"${escapeLinesTabsAndQuotes(
    value,
  )}"}`;
};

const buildSelectedFacetValuesByNameFilter = (
  selectedFacetValuesByName: SelectedFacetValuesByName,
) => {
  let result = '';

  const names = Object.keys(selectedFacetValuesByName);

  names.forEach((name) => {
    const selectedFacetValues = selectedFacetValuesByName[name];
    const isDeselecting = getIsDeselecting(selectedFacetValues);
    const keys = Object.keys(selectedFacetValues);

    if (keys.length > 1) {
      result += `{ ${isDeselecting ? 'and' : 'or'}: [`;
    }

    keys.forEach((value) => {
      result += '{';
      result += 'attributeFilter';
      result += ': {';
      result += isDeselecting ? 'neq' : 'eq';
      result += ': ';

      result += getFilter({ name, value });
      result += '}';
      result += '}';
    });

    if (keys.length > 1) {
      result += `]}`;
    }
  });

  return result;
};

export const buildRumFilter = ({
  selectedFacetValuesByName = {},
  idSearch = '',
}: {
  selectedFacetValuesByName?: SelectedFacetValuesByName;
  idSearch?: string;
}): string => {
  let result = '{ and: [';
  result += buildSelectedFacetValuesByNameFilter(selectedFacetValuesByName);

  if (idSearch) {
    result += `{attributeFilter: { eq: {key: "view.id", value: "${idSearch}"}}}`;
  }
  result += '] }';

  return result;
};

export const VIEW_LABELS = [
  'type',
  'view.loading_time',
  'view.loading_type',
  'view.time_spent',
  'view.first_contentful_paint',
  'view.largest_contentful_paint',
  'view.largest_contentful_paint_target_selector',
  'view.first_input_delay',
  'view.first_input_time',
  'view.first_input_target_selector',
  'view.interaction_to_next_paint',
  'view.interaction_to_next_paint_time',
  'view.interaction_to_next_paint_target_selector',
  'view.cumulative_layout_shift',
  'view.cumulative_layout_shift_time',
  'view.cumulative_layout_shift_target_selector',
  'view.dom_complete',
  'view.dom_content_loaded',
  'view.dom_interactive',
  'view.load_event',
  'view.first_byte',
  'view.custom_timings',
  'view.is_active',
  'view.is_slow_rendered',
  'view.action.count',
  'view.error.count',
  'view.crash.count',
  'view.long_task.count',
  'view.frozen_frame.count',
  'view.resource.count',
  'view.frustration.count',
  'view.in_foreground_periods',
  'view.memory_average',
  'view.memory_max',
  'view.cpu_ticks_count',
  'view.cpu_ticks_per_second',
  'view.refresh_rate_average',
  'view.refresh_rate_min',
  'view.flutter_build_time',
  'view.flutter_raster_time',
  'view.js_refresh_rate',
  'session.is_active',
  'session.sampled_for_replay',
  'feature_flags',
  'privacy.replay_level',
  '_dd.document_version',
  '_dd.page_states',
  '_dd.replay_stats.records_count',
  '_dd.replay_stats.segments_count',
  '_dd.replay_stats.segments_total_raw_size',
  '_dd.configuration.start_session_replay_recording_manually',
  'display.scroll.max_depth',
  'display.scroll.max_depth_scroll_top',
  'display.scroll.max_scroll_height',
  'display.scroll.max_scroll_height_time',
  'date',
  'application.id',
  'service',
  'version',
  'build_version',
  'build_id',
  'session.id',
  'session.type',
  'session.has_replay',
  'source',
  'view.id',
  'view.referrer',
  'view.url',
  'view.name',
  'usr.id',
  'usr.name',
  'usr.email',
  'connectivity.status',
  'connectivity.interfaces',
  'connectivity.effective_type',
  'connectivity.cellular.technology',
  'connectivity.cellular.carrier_name',
  'display.viewport.width',
  'display.viewport.height',
  'synthetics.test_id',
  'synthetics.result_id',
  'synthetics.injected',
  'ci_test.test_execution_id',
  'os.name',
  'os.version',
  'os.build',
  'os.version_major',
  'device.type',
  'device.name',
  'device.model',
  'device.brand',
  'device.architecture',
  '_dd.format_version',
  '_dd.session.plan',
  '_dd.session.session_precondition',
  '_dd.configuration.session_sample_rate',
  '_dd.configuration.session_replay_sample_rate',
  '_dd.browser_sdk_version',
  'context',
  'container.view.id',
  'container.source',
];

export const ACTION_LABELS = [
  'type',
  'action.type',
  'action.id',
  'action.loading_time',
  'action.target.name',
  'action.frustration.type',
  'action.error.count',
  'action.crash.count',
  'action.long_task.count',
  'action.resource.count',
  'view.in_foreground',
  '_dd.action.position.x',
  '_dd.action.position.y',
  '_dd.action.target.selector',
  '_dd.action.target.width',
  '_dd.action.target.height',
  'date',
  'application.id',
  'service',
  'version',
  'build_version',
  'build_id',
  'session.id',
  'session.type',
  'session.has_replay',
  'source',
  'view.id',
  'view.referrer',
  'view.url',
  'view.name',
  'usr.id',
  'usr.name',
  'usr.email',
  'connectivity.status',
  'connectivity.interfaces',
  'connectivity.effective_type',
  'connectivity.cellular.technology',
  'connectivity.cellular.carrier_name',
  'display.viewport.width',
  'display.viewport.height',
  'synthetics.test_id',
  'synthetics.result_id',
  'synthetics.injected',
  'ci_test.test_execution_id',
  'os.name',
  'os.version',
  'os.build',
  'os.version_major',
  'device.type',
  'device.name',
  'device.model',
  'device.brand',
  'device.architecture',
  '_dd.format_version',
  '_dd.session.plan',
  '_dd.session.session_precondition',
  '_dd.configuration.session_sample_rate',
  '_dd.configuration.session_replay_sample_rate',
  '_dd.browser_sdk_version',
  'context',
  'container.view.id',
  'container.source',
];
