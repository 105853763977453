import { DashboardPanelTableTransformProps } from 'types/Dashboard';

import mergeSeriesData from './mergeSeriesData';
import organizeColumn from './organizeColumn';
import formatTableTimestamp from './formatTableTimestamp';
import overrideColumnsData from './overrideColumnsData';
import {
  hostmapTransformer,
  pieChartTransformer,
  topListTransformer,
} from '../visualTransformer';

import { DataFrame, TableTransformerData } from '../types';

const tableTransformer = ({
  dataFrames,
  transformations,
}: {
  dataFrames: DataFrame[];
  transformations: DashboardPanelTableTransformProps[];
}) => {
  const byFieldColumn = transformations.find((d) => d.id === 'seriesToColumns');
  return transformations.reduce((acc, transformation) => {
    if (transformation.id && transformation.func) {
      return transformation.func(acc);
    }

    if (transformation.id === 'merge') {
      return mergeSeriesData({ dataFrames: acc, columns: [] });
    }

    if (transformation.id === 'organize') {
      return organizeColumn({
        data: acc,
        options: transformation.options,
        byField: byFieldColumn?.options.byField,
      });
    }

    if (transformation.id === 'formatTime') {
      return formatTableTimestamp({
        data: acc,
        options: transformation.options,
      });
    }

    if (transformation.id === 'override') {
      return overrideColumnsData({
        data: acc,
        overrides: transformation.options,
      });
    }

    if (transformation.id === 'toPieChart') {
      return pieChartTransformer(acc as unknown as TableTransformerData);
    }

    if (transformation.id === 'toTopList') {
      return topListTransformer({
        data: acc as unknown as TableTransformerData,
        options: transformation.options,
      });
    }

    if (transformation.id === 'toHostmap') {
      return hostmapTransformer(acc as unknown as TableTransformerData);
    }
    return acc;
  }, dataFrames);
};

export default tableTransformer;
