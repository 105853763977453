import { LogsMetricsQueryBuilder } from 'components';
import { useLogsMetricsQueryState } from 'hooks';
import React, { ReactElement } from 'react';
import { Plus } from 'react-feather';
import { DateSelection } from 'types';

const newArray = [];
const newObj = {};

const AlertsCreateLogsQueryBuilder = ({
  date,
  logsMetricsQueryState,
}: {
  date: DateSelection;
  logsMetricsQueryState: ReturnType<typeof useLogsMetricsQueryState>;
}): ReactElement => {
  return (
    <div>
      <LogsMetricsQueryBuilder
        allowMultipleQueries={true}
        date={date}
        logsMetricsQueryState={logsMetricsQueryState}
        minStep="15s"
        logsState={{
          date,
          filterByFacets: newArray,
          searchTerms: newArray,
          selectedFacetValues: newObj,
        }}
      />
      <button
        className="button button--blue"
        onClick={() => logsMetricsQueryState.addQuery(false)}
      >
        <Plus size={16} /> Add Query
      </button>
      <button
        className="button button--blue"
        onClick={() => logsMetricsQueryState.addFormula(false)}
      >
        <Plus size={16} /> Add Formula
      </button>
    </div>
  );
};

export default AlertsCreateLogsQueryBuilder;
