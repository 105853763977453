import dayjs from 'dayjs';
import { FacetName, FacetNameList, FilterProps, LogsState } from 'types';
import { onPromiseError } from 'utils';
import query from './query';
import { buildQuery, formatFacetNames } from './utils';

type Args = FilterProps & {
  logsState: LogsState;
};

const getFacetNames = async ({ logsState }: Args): Promise<FacetName[]> => {
  const { date } = logsState;
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const logQuery = buildQuery(logsState);

  return query<FacetNameList, 'getFacetNames'>(`
    {
      getFacetNames(
        durationSecs: ${durationSecs}
        limit: 100000,
        logQuery: ${logQuery},
        timestamp: "${endTime.format()}",
      ) {
        cursor,
        facetNames {
          name,
          source,
          type,
          fpHash
        }
      }
    }
  `)
    .then((data) => data.getFacetNames?.facetNames || [], onPromiseError)
    .then(formatFacetNames);
};

export default getFacetNames;
