import { fetchJson } from 'requests';

const getArchivesList = async (): Promise<{ name: string }[]> => {
  const result = (await fetchJson('/hydration/query/list-archives')) as {
    archiveList: string[];
  };

  return (
    result?.archiveList.map((name, idx) => ({ name, serialNumber: idx + 1 })) ||
    []
  );
};

export default getArchivesList;
