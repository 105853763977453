import { Button } from 'components';
import { useSearches, useSearchFormulas } from 'hooks';
import React, { useCallback, useEffect } from 'react';
import { Plus } from 'react-feather';
import { DashboardPanelType, TracesTab } from 'types';
import { ALLOW_ONLY_ONE_ACTIVE_SEARCH } from '../utils';

type Props = {
  searches: ReturnType<typeof useSearches>;
  searchesFormulas?: ReturnType<typeof useSearchFormulas>;
  tracesTab: TracesTab;
};

const TracesSearchQueryButtons = ({
  searches,
  searchesFormulas,
  tracesTab,
}: Props) => {
  const addNewSearch = useCallback(() => {
    if (
      ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
        tracesTab as unknown as DashboardPanelType,
      )
    ) {
      searchesFormulas.deactivateAll();
      searches[0].addNewSearch(true);
    } else {
      searches[0].addNewSearch(false);
    }
  }, [searches, searchesFormulas, tracesTab]);

  const addFormula = useCallback(() => {
    if (
      ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
        tracesTab as unknown as DashboardPanelType,
      )
    ) {
      searches[0].deactivateAll();
      searchesFormulas.addNewFormula(true);
    } else {
      searchesFormulas.addNewFormula(false);
    }
  }, [searches, searchesFormulas, tracesTab]);

  useEffect(() => {
    if (
      ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
        tracesTab as unknown as DashboardPanelType,
      )
    ) {
      const countOfFormulas = searchesFormulas?.formulas?.filter(
        (f) => f.state.isActive,
      )?.length;
      if (countOfFormulas > 0) {
        searches?.[0]?.deactivateAll();
        searchesFormulas?.formulas?.[0]?.activateOnlySingleFormula();
        return;
      }
      const countOfSearches = searches.filter((s) => s.state.isActive).length;
      if (countOfSearches > 1) {
        searches?.[0]?.selectOnlySingeQuery();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracesTab]);

  return (
    <div>
      <Button
        variant="default"
        size="sm"
        className="mt-2"
        onClick={addNewSearch}
      >
        <Plus size={16} /> Add query
      </Button>
      <Button
        variant="default"
        size="sm"
        className="ml-1 mt-2"
        onClick={addFormula}
      >
        <Plus size={16} /> Add Formula
      </Button>
    </div>
  );
};

export default TracesSearchQueryButtons;
