import classnames from 'classnames';
import {
  ApmErrorsGroupTable,
  DateControls,
  LeftSidebar,
  Loader,
  TraceErrorChartGrid,
  useLeftSidebarState,
} from 'components';
import { Datepicker } from 'composite';
import { useColorsByServiceName, useRequest } from 'hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  errorLabelNames,
  errorLabelValues,
  getServices,
  traceErrors,
} from 'requests';
import { ApmErrorsTab, DateSelection, TraceError, TracesTab } from 'types';
import { getColorsByServiceHash } from 'utils';
import ApmErrorsTable from './ApmErrorsTable';
import ApmErrorsVisualize from './ApmErrorsVisualize';
import TracesSearch from 'screens/Traces/TracesSearch';
import TracesSidebar from 'screens/Traces/TracesSidebar';
import { useApmErrorsPageStateContext } from 'context/PageStateProvider';

const ApmErrors = () => {
  const pageNumRef = useRef<number>(null);
  const { tab } = useParams();
  const [errorsResult, setErrorsResult] = useState<TraceError[]>([]);
  const leftSidebarState = useLeftSidebarState('apmErrors');
  const getServicesRequest = useRequest(getServices);
  const traceErrorsRequest = useRequest(traceErrors);
  const traceLabelNamesRequest = useRequest(errorLabelNames);

  const {
    dependenciesForWriteStateToUrl,
    searches,
    tracesState,
    writeStateToUrl,
  } = useApmErrorsPageStateContext();

  const search = searches[0];
  const { dateState } = tracesState;
  const [date, setDate] = dateState;
  const colorsByServiceName = useColorsByServiceName(date);

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependenciesForWriteStateToUrl]);

  useEffect(() => {
    getServicesRequest.call({ date });
    traceLabelNamesRequest.call({ date });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const fetchTraceErrors = () => {
    const pageNum = pageNumRef.current || 1;
    traceErrorsRequest.call({ pageNum, tracesState }).then((nextResult) => {
      if (nextResult && nextResult.length) {
        pageNumRef.current = pageNum + 1;
        setErrorsResult((prevRes) => [...prevRes, ...nextResult]);
      }
    });
  };

  useEffect(() => {
    pageNumRef.current = null;
    setErrorsResult([]);
    fetchTraceErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracesState.state]);

  const isTraceErrorsRequestLoading = useMemo(() => {
    return tab === ApmErrorsTab.allErrors && traceErrorsRequest.isLoading;
  }, [traceErrorsRequest.isLoading, tab]);

  const serviceByHash = useMemo(
    () =>
      (getServicesRequest.result || []).reduce(
        (obj, service) => ({ ...obj, [service.hash]: service }),
        {},
      ),
    [getServicesRequest.result],
  );

  const colorsByServiceHash = useMemo(
    () => getColorsByServiceHash(getServicesRequest.result || []),
    [getServicesRequest.result],
  );

  return (
    <div
      className={classnames({
        'apm-errors': true,
        traces: true,
      })}
    >
      <LeftSidebar leftSidebarState={leftSidebarState}>
        <TracesSidebar
          colorsByServiceHash={colorsByServiceHash}
          colorsByServiceName={colorsByServiceName}
          getLabelValues={errorLabelValues}
          hideDuration
          serviceByHash={serviceByHash}
          traceLabelNamesRequest={traceLabelNamesRequest}
          tracesState={tracesState}
        />
      </LeftSidebar>
      <div className="traces__main overflow-hidden">
        <div className="traces__header">
          <div className="traces__header__left">
            <TracesSearch
              colorsByServiceHash={colorsByServiceHash}
              isErrorScreen={true}
              leftSidebarState={leftSidebarState}
              placeholder="Search for any tag on trace errors"
              search={search}
              searches={searches}
              serviceByHash={serviceByHash}
              traceLabelNamesRequest={traceLabelNamesRequest}
              tracesState={tracesState}
              tracesTab={TracesTab.list}
            />
            <ApmErrorsVisualize />
          </div>
          <div className="traces__header__right">
            <div className="traces__header__date-controls">
              <div className="traces__header__date-controls__item">
                <Datepicker
                  onChange={setDate as (dateSelection: DateSelection) => void}
                  value={date as DateSelection}
                />
              </div>
              <div className="traces__header__date-controls__item">
                <DateControls date={date} setDate={setDate} />
              </div>
            </div>
          </div>
        </div>
        <TraceErrorChartGrid search={search} tracesState={tracesState} />
        <Loader
          className="traces__table"
          isLoading={isTraceErrorsRequestLoading}
        >
          <div className="traces__table" data-testid="traces-table">
            {tab === ApmErrorsTab.errorGroups ? (
              <ApmErrorsGroupTable
                colorsByServiceHash={colorsByServiceHash}
                tracesState={tracesState}
                serviceByHash={serviceByHash}
              />
            ) : (
              <ApmErrorsTable
                className="table--padded table--bordered-cells"
                colorsByServiceHash={colorsByServiceHash}
                serviceByHash={serviceByHash}
                onScrollEnd={fetchTraceErrors}
                rows={errorsResult}
                tracesState={tracesState}
              />
            )}
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default ApmErrors;
