import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  EdgeTypes,
  MiniMap,
  NodeTypes,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import getFlowLayout from './getFlowLayout';
import getLayoutWithState from './getLayoutWithState';
import ServiceMapEdge from './ServiceMapEdge';
import ServiceMapMiniMapNode from './ServiceMapMiniMapNode';
import ServiceMapNode from './ServiceMapNode';
import ServiceMapToolbar from './ServiceMapToolbar';
import { ServiceMapStateContextProvider } from './ServiceMapStateContext';
import {
  CustomBEFilterType,
  LegendProps,
  Orientation,
  ServiceMapType,
} from './types';
import useServiceMapState from './useServiceMapState';
import CursorTooltip from '../CursorTooltip';
import ShiftToZoomHelperText from '../ShiftToZoomHelperText';
import SizeObserver from '../SizeObserver';
import ServiceMapNodeSizeLegend from './ServiceMapNodeSizeLegend';

const defaultEdgeOptions = {};

const nodeTypes: NodeTypes = {
  custom: ServiceMapNode,
};

const edgeTypes: EdgeTypes = {
  custom: ServiceMapEdge,
};

const proOptions = {
  account: 'paid-pro',
  hideAttribution: true,
};

type Props = {
  areBEFiltersSelected?: boolean;
  customFilters: any;
  customBEFilters?: CustomBEFilterType[];
  handleClearBEFilters?: () => void;
  initialNodes: { id: string; searchValue?: string } & Record<string, any>[];
  initialEdges: { id: string } & Record<string, any>[];
  legendProps?: LegendProps;
  orientation: Orientation;
  renderEdgeTooltip: (edge: any) => ReactNode;
  renderNodeTooltip: (edge: any) => ReactNode;
};

const ServiceMap = ({
  areBEFiltersSelected,
  customFilters = [],
  customBEFilters = [],
  initialEdges,
  initialNodes,
  legendProps,
  orientation,
  renderEdgeTooltip,
  renderNodeTooltip,
  handleClearBEFilters,
}: Props) => {
  const serviceMapState = useServiceMapState({
    orientation,
    renderNodeTooltip,
  });
  const { hideExternalAndUnknown, hoveredEdgeId, showMiniMap } =
    serviceMapState.state;

  const reactFlow = useReactFlow();

  const [activeServiceMapType] = useState(ServiceMapType.flow);

  const { edges: flowEdges, nodes: flowNodes } = useMemo(
    () =>
      getFlowLayout({
        customFilters,
        edges: initialEdges,
        nodes: initialNodes,
        serviceMapState,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customFilters, initialEdges, initialNodes, serviceMapState.state],
  );

  const resetZoom = () => {
    const maxDiameterNode = initialNodes.reduce((acc, node) => {
      if (node.data?.nodeDiameter > acc?.data?.nodeDiameter) {
        return node;
      }
      return acc;
    }, null);

    reactFlow.fitView({
      duration: 400,
      minZoom: 0.6,
      maxZoom: 1,
      nodes: maxDiameterNode ? [{ id: maxDiameterNode.id }] : undefined,
    });
  };

  useEffect(() => {
    serviceMapState.clear();
    setTimeout(resetZoom, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialEdges, initialNodes]);

  const { edges, nodes } = useMemo(
    () =>
      getLayoutWithState({
        edges: flowEdges,
        nodes: flowNodes,
        serviceMapState,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeServiceMapType, flowEdges, flowNodes, serviceMapState.state],
  );

  const hoveredEdge = edges.find((edge) => edge.id === hoveredEdgeId);

  const initialNodesWithoutExternalAndUnknown = initialNodes.filter(
    (node) =>
      node.id !== 'EXTERNAL' &&
      node.id !== 'UNKNOWN' &&
      node.id !== 'UNKNOWN-client' &&
      node.id !== 'UNKNOWN-server',
  );

  const totalNodeCount = hideExternalAndUnknown
    ? initialNodesWithoutExternalAndUnknown.length
    : initialNodes.length;

  const isShowingAllNodes = nodes.length === totalNodeCount;

  const clearHandler = () => {
    serviceMapState.clear();
    handleClearBEFilters?.();
    setTimeout(resetZoom, 500);
  };

  return (
    <ServiceMapStateContextProvider serviceMapState={serviceMapState}>
      <div className="service-map">
        <ServiceMapToolbar
          customFilters={customFilters}
          customBEFilters={customBEFilters}
          initialNodes={initialNodes}
          reactFlow={reactFlow}
          resetZoom={resetZoom}
          serviceMapState={serviceMapState}
        />
        <SizeObserver className="service-map__body">
          {({ height, width }) => (
            <div
              className="service-map__inner relative"
              onMouseLeave={serviceMapState.onEdgeMouseLeave}
              style={{ height: `${height}px`, width: `${width}px` }}
            >
              <ReactFlow
                defaultEdgeOptions={defaultEdgeOptions}
                edges={edges}
                fitView
                fitViewOptions={{ padding: 42 }}
                // newly added edges get these options automatically
                minZoom={-Infinity}
                maxZoom={Infinity}
                edgeTypes={edgeTypes}
                nodeTypes={nodeTypes}
                nodes={nodes}
                proOptions={proOptions}
                zoomActivationKeyCode="Shift"
                zoomOnScroll={false}
              >
                {showMiniMap ? (
                  <MiniMap
                    nodeColor={(node) => node.data.color || '#3d8bc9'}
                    nodeStrokeWidth={6}
                    nodeComponent={ServiceMapMiniMapNode}
                    zoomable
                    pannable
                  />
                ) : null}
              </ReactFlow>
              <div className="service-map__helper-text">
                <ShiftToZoomHelperText />
              </div>
              <div className="service-map__node-count">
                <div
                  className="service-map__node-count__left"
                  data-testid="service-map_count"
                >
                  {`Showing ${
                    isShowingAllNodes
                      ? `all ${totalNodeCount}`
                      : `${nodes.length} of ${totalNodeCount}`
                  } nodes`}
                </div>
                {!isShowingAllNodes || !areBEFiltersSelected ? (
                  <div className="service-map__node-count__right">
                    <span
                      className="service-map__node-count__link link"
                      onClick={clearHandler}
                    >
                      Reset Filters to default
                    </span>
                  </div>
                ) : null}
              </div>
              {Boolean(legendProps) && (
                <ServiceMapNodeSizeLegend legendProps={legendProps} />
              )}
            </div>
          )}
        </SizeObserver>
        {hoveredEdge && renderEdgeTooltip ? (
          <CursorTooltip>{renderEdgeTooltip(hoveredEdge)}</CursorTooltip>
        ) : null}
      </div>
    </ServiceMapStateContextProvider>
  );
};

export default ServiceMap;
