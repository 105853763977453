import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import UserManagementEditInfo from './UserManagementEditInfo';
import UserManagementEditOrg from './UserManagementEditOrg';
import useUserManagementState from './useUserManagementState';

const UserManagementEdit = () => {
  const { userId } = useParams();
  const userManagementState = useUserManagementState();
  const { userByIdRequest } = userManagementState;

  useEffect(() => {
    if (!userId) return;
    userByIdRequest.call({ userId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div className="mx-auto max-w-[1200px] py-4">
      <div className="rounded-sm border px-4 pb-4 pt-3">
        {userByIdRequest.isLoading ? (
          <div className="admin__row-muted flex min-h-[200px] items-center justify-center">
            <div className="spinner"></div>
          </div>
        ) : (
          <UserManagementEditInfo userManagementState={userManagementState} />
        )}
        <UserManagementEditOrg userManagementState={userManagementState} />
      </div>
    </div>
  );
};

export default UserManagementEdit;
