import {
  CloudLabels,
  CoreLabels,
  KubernetesLabels,
  delimiter,
} from 'kfuse-constants';
import {
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
} from 'types/LogsMetricsQueryBuilder';
import { buildLogql, buildLogqlFormula } from 'utils/LogqlBuilder';
import { getRollupToSecond } from 'utils/rollup';

import { alertParseConditionValue } from '../../AlertsCreateCondition';

export const parseAlertsLogsQueryAndConditions = (
  data: any,
  annotations: any,
) => {
  const promqls: string[] = [];
  const condition = alertParseConditionValue(data);
  const [queryModel] = data;
  const { model, relativeTimeRange } = queryModel;
  promqls.push(model.expr);

  if (annotations) {
    const parsedExtraData = JSON.parse(annotations.extraData || '{}');
    if (parsedExtraData?.queryType === 'formula') {
      condition.queryKey = 'Formula (1)';
    } else {
      condition.queryKey = 'Query (a)';
    }
  }

  return { condition, promqls, relativeTimeRange };
};

export const getLabelWithDelimiter = (): string[] => {
  const labels = [...CoreLabels, ...KubernetesLabels, ...CloudLabels];
  const newlabels: string[] = [];
  labels.map(({ component, name, type }) => {
    newlabels.push(`${component}${delimiter}${name}${delimiter}${type}`);
  });
  return newlabels;
};

export const getSelectedLogQueryLogql = (
  formulas: LogsMetricForumlaProps[],
  queries: LogsMetricQueryProps[],
  selectedQuery: string,
): { logql: string; query: LogsMetricQueryProps } | string => {
  if (selectedQuery.includes('Query')) {
    const queryKeyParsed = selectedQuery.split('(')[1].split(')')[0];
    const query = queries.find((q) => q.queryKey === queryKeyParsed);
    if (!query) {
      return 'Selected query does not exist';
    }

    if (query.showInput) {
      return { logql: query.logql, query };
    }
    if (query) {
      let step = '1m';
      if (query.step) {
        const stepSecond = getRollupToSecond(query.step);
        if (stepSecond < 15) {
          step = '15s';
        } else {
          step = query.step;
        }
      }
      const builtLogql = buildLogql({ queries: [query], step })[0];
      return { logql: builtLogql, query };
    }
  }

  if (selectedQuery.includes('Formula')) {
    const formulaKeyParsed = selectedQuery.split('(')[1].split(')')[0];
    const formula = formulas.find((f) => f.queryKey === formulaKeyParsed);
    if (!formula) {
      return 'Selected formula does not exist';
    }

    if (formula) {
      const builtFormula = buildLogqlFormula({
        queries,
        formulas: [formula],
        step: '1m',
      })[0];
      return { logql: builtFormula, query: queries[0] };
    }
  }

  return null;
};

export const confirmSelectedLogsAlertQueryKey = (
  formulaCount: number,
  queryCount: number,
  selectedQuery: string,
): string => {
  const isSelectingFormula = selectedQuery.includes('Formula');

  if (formulaCount > 0 && !isSelectingFormula) {
    return `You have explored formulas, are you sure you want to create an alert with a ${selectedQuery}?`;
  }

  if (queryCount > 1 && !isSelectingFormula) {
    return `You have multiple explored queries, are you sure you want to create an alert with a ${selectedQuery}?`;
  }

  return '';
};
