import {
  DashboardPanelProps,
  DashboardPanelType,
  DashboardTemplateValueProps,
  DateSelection,
  LogsMetricsAPIRequestMultiProps,
} from 'types';
import {
  DataFrame,
  getLogQLWithMetaToLoad,
  getRollupByVisualization,
  logsDataTransformer,
  timeseriesDataTransformer,
  logqlDashboardDataTransformer,
  sortAndLimitTimeseries,
} from 'utils';

import { getActivePromqlQuery } from './widget-utils';
import { replaceLogQLVariables } from './common-utils';
import { getQueryBuilderTransformedLogQl } from '../DashboardEdit/utils';

const MILISECONDS_IN_A_SECOND = 1000;

export const getLogqlWithQuery = ({
  panel,
  templateValuesWithRepeated,
  panelDate,
  defaultChartTypes,
}: {
  panel: DashboardPanelProps;
  templateValuesWithRepeated: DashboardTemplateValueProps;
  panelDate: DateSelection;
  defaultChartTypes: string[];
}): {
  logqlWithMeta?: LogsMetricsAPIRequestMultiProps['logqlWithMeta'];
  logQlQueries?: LogsMetricsAPIRequestMultiProps['logQlQueries'];
  mainTransformer?: LogsMetricsAPIRequestMultiProps['mainTransformer'];
} => {
  const defaultStep =
    getRollupByVisualization(panelDate, defaultChartTypes[0]) *
    MILISECONDS_IN_A_SECOND;
  const query = getQueryBuilderTransformedLogQl(panel);
  if (
    query &&
    query.queries.length > 0 &&
    panel.type === DashboardPanelType.TIMESERIES
  ) {
    const logQLWithMetaToLoad = getLogQLWithMetaToLoad({
      queries: query.queries,
      formulas: query.formulas,
      date: panelDate,
      dataFormat: panel.type,
      formulaOnly: false,
      instant: false,
    });

    const logQLWithMetaToLoadTransformer = logQLWithMetaToLoad.map((logQL) => {
      const transformer = logsDataTransformer();
      transformer.pop(); // remove the last transformer
      if (logQL.limit) {
        transformer.splice(2, 0, {
          id: 'sortAndLimitTimeseries',
          func: (dataFrame: DataFrame) =>
            sortAndLimitTimeseries(dataFrame, logQL.limit),
        });
      }
      return { ...logQL, transformer };
    });
    const mainTransformer = [
      {
        id: 'logqlDashboardDataTransformer',
        func: logqlDashboardDataTransformer,
      },
      {
        id: 'timeseriesDataTransformer',
        func: timeseriesDataTransformer,
      },
    ];

    return { logqlWithMeta: logQLWithMetaToLoadTransformer, mainTransformer };
  }

  const transformedLogQls = getActivePromqlQuery(panel.targets).map(
    (target) => ({
      logQL: replaceLogQLVariables(target.expr, templateValuesWithRepeated),
      queryKey: `query_${target.refId.toLowerCase()}`,
      step: defaultStep,
    }),
  );

  return { logQlQueries: transformedLogQls };
};
