import {
  useTableSearch,
  useTableSort,
  usePaginator,
  Table,
  TableSearch,
  Paginator,
  TooltipTrigger,
  useToaster,
} from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useMemo } from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { RequestResult } from 'types/Request';

import GroupManagementMembersPermissions from './GroupManagementMembersPermissions';
import GroupManagementMembersAdd from './GroupManagementMembersAdd';
import { removeUserFromGroup } from './requests';
import { GroupMemberProps } from './types';

const columnsMembers = ({
  groupMembersRequest,
  onRemoveMember,
}: {
  groupMembersRequest: RequestResult<GroupMemberProps[], any>;
  onRemoveMember: (member: GroupMemberProps) => void;
}) => [
  { key: 'email', label: 'Email' },
  {
    key: 'permission',
    label: 'Permission',
    renderCell: ({ row }: { row: GroupMemberProps }) => {
      return (
        <GroupManagementMembersPermissions
          member={row}
          groupMembersRequest={groupMembersRequest}
        />
      );
    },
  },
  {
    label: '',
    key: 'actions',
    renderCell: ({ row }: { row: GroupMemberProps }) => {
      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': '41px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider">
              <div className="alerts__contacts__table__actions">
                <div
                  className="table__cell__actions__item--red"
                  onClick={() => onRemoveMember(row)}
                >
                  <TooltipTrigger tooltip="Remove">
                    <CloseIcon
                      className="alerts__contacts__table__actions__icon--delete"
                      size={18}
                    />
                  </TooltipTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

const emptyArr: GroupMemberProps[] = [];

const GroupManagementMembers = ({
  groupMembersRequest,
}: {
  groupMembersRequest: RequestResult<GroupMemberProps[], any>;
}): ReactElement => {
  const { addToast } = useToaster();
  const { groupId } = useParams();
  const removeGroupMemberRequest = useRequest(removeUserFromGroup);

  const onRemoveMember = (member: GroupMemberProps) => {
    if (!groupId) return;
    removeGroupMemberRequest
      .call({ groupId, userId: member.id })
      .then((res) => {
        if (res?.message) {
          addToast({ text: 'User removed from group', status: 'success' });
        }
        groupMembersRequest.call({ groupId });
      });
  };

  const columns = useMemo(() => {
    return columnsMembers({ groupMembersRequest, onRemoveMember });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupMembersRequest]);

  const groupMembers = groupMembersRequest.result || emptyArr;
  const tableSearch = useTableSearch({ rows: groupMembers });
  const tableSort = useTableSort({ columns, rows: tableSearch.searchedRows });
  const paginator = usePaginator({
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  return (
    <div>
      <GroupManagementMembersAdd groupMembersRequest={groupMembersRequest} />
      {groupMembersRequest.isLoading || removeGroupMemberRequest.isLoading ? (
        <div className="admin__row-muted flex min-h-[200px] items-center justify-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <TableSearch
            className="dashboard__list__search"
            placeholder="Search Members..."
            tableSearch={tableSearch}
            dataTestId="dashboard-list-search"
          />
          <Table
            className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
            columns={columns}
            dataTestId="alerts-list-table"
            externalTableSort={tableSort}
            isSortingEnabled
            rows={paginator.paginatedRows}
          />
          <div className="table-footer">
            <Paginator paginator={paginator} />
          </div>
        </>
      )}
    </div>
  );
};

export default GroupManagementMembers;
