import { fetchJson } from 'requests';

const jobProgress = async (jobId: string): Promise<Record<string, string>> => {
  const result = await fetchJson('/hydration/query/job-progress', {
    method: 'POST',
    body: JSON.stringify({ jobId }),
  });

  return result as unknown as Record<string, string>;
};

export default jobProgress;
