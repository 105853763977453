import {
  rumqlDataTransformerInstant,
  rumqlDataTransformer,
} from './rangeTransformer/rumqlDataTransformer';
import { labelTransformer } from './labelTransformer';
import traceTimeseriesDataTransformer from './traces/traceTimeseriesDataTransformer';

const rumDataTransformer = (isInstant?: boolean) => {
  if (isInstant) {
    return [
      {
        id: 'rumToDataFrame',
        func: rumqlDataTransformerInstant,
      },
    ];
  }

  return [
    {
      id: 'rumToDataFrame',
      func: rumqlDataTransformer,
    },
    {
      id: 'transformToDisplayLabel',
      func: labelTransformer,
    },
    {
      id: 'transformToTimeseries',
      func: traceTimeseriesDataTransformer,
    },
  ];
};

export default rumDataTransformer;
