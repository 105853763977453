import {
  AutocompleteV2,
  Button,
  Input,
  Textarea,
  useToaster,
} from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { getGrafanaFolders } from 'requests';
import { useDashboardState } from './hooks';

const DashboardDetailsModal = ({
  close,
  dashboardState,
}: {
  close: VoidFunction;
  dashboardState: ReturnType<typeof useDashboardState>;
}): ReactElement => {
  const grafanaFoldersRequest = useRequest(
    () =>
      getGrafanaFolders().then((res) =>
        res.map((folder) => ({ label: folder.title, value: folder.uid })),
      ),
    true,
  );
  const { addToast } = useToaster();
  const { dashboardDetails, dashboardMeta, setDashboardDetails } =
    dashboardState;
  const [state, setState] = useState({
    title: dashboardDetails.title,
    description: dashboardDetails.description,
    folderUid: dashboardMeta.folderUid,
  });

  const updateDashboardDetails = () => {
    const { title, description } = state;
    if (!title) {
      addToast({ text: 'Title is required', status: 'error' });
      return;
    }
    setDashboardDetails((prevState) => ({
      ...prevState,
      title,
      description,
      folderUid: state.folderUid,
      isEdited: true,
    }));
    close();
  };

  useEffect(() => {
    grafanaFoldersRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-[600px] p-2">
      <div className="flex p-2 justify-between items-center pr-4">
        <div className="dashboard__details-modal__header__title">
          Dashboard Details
        </div>
        <div className="cursor-pointer">
          <Button variant="icon-outline">
            <X onClick={close} />
          </Button>
        </div>
      </div>
      <div className="dashboard__details-modal__body">
        <div>
          <label>Title</label>
          <Input
            onChange={(val) => setState({ ...state, title: val })}
            placeholder="Enter dashboard title"
            type="text"
            value={state.title}
            size="4"
            variant="default"
          />
        </div>
        <div>
          <label>Description</label>
          <Textarea
            className="dashboard__details-modal__body__description"
            onChange={(val) => setState({ ...state, description: val })}
            placeholder="Describe the dashboard"
            type="text"
            value={state.description}
          />
        </div>
        <div className="pt-2">
          <label>Folder</label>
          <AutocompleteV2
            options={grafanaFoldersRequest.result || []}
            onChange={(val: string) => setState({ ...state, folderUid: val })}
            placeholder="Select folder"
            isLoading={grafanaFoldersRequest.isLoading}
            value={state.folderUid}
          />
        </div>
      </div>
      <div className="dashboard__details-modal__footer">
        <Button variant="outline" size="sm" onClick={close}>
          Cancel
        </Button>
        <Button variant="default" size="sm" onClick={updateDashboardDetails}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default DashboardDetailsModal;
