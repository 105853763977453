import { useMemo } from 'react';
import useDateState from '../useDateState';
import useLogsAnalyticsChart from 'screens/Logs/LogsAnalytics/hooks/useLogsAnalyticsChart';
import getLogsStateFromFiltersState from './getLogsStateFromFiltersState';

type Args = {
  dateState: ReturnType<typeof useDateState>;
  isAnalyticsView: boolean;
  logsAnalytics: ReturnType<typeof useLogsAnalyticsChart>;
};

const useLogsState = ({ dateState, isAnalyticsView, logsAnalytics }: Args) => {
  const { logsMetricsQueryState } = logsAnalytics;
  const { queries, syncSelectedQuery } = logsMetricsQueryState;

  const [date, setDate] = dateState;

  const setDateZoomed = (nextDatePatch) => {
    setDate((prevDate) => ({
      ...prevDate,
      ...nextDatePatch,
    }));
  };

  const common = {
    date,
    dateState,
    removeFilterByFacetByIndex: () => {},
    setDate,
    setDateZoomed,
  };

  const activeQueryIndex = useMemo(() => {
    return isAnalyticsView ? syncSelectedQuery.queryIndex || 0 : 0;
  }, [isAnalyticsView, syncSelectedQuery.queryIndex]);

  const activeSidebarLogsState = useMemo(
    () => {
      const activeQuery = queries[activeQueryIndex];
      return {
        ...getLogsStateFromFiltersState({
          filtersState: activeQuery.filtersState,
        }),
        ...common,
        filtersState: activeQuery.filtersState,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeQueryIndex,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dateState[0],
      queries,
      syncSelectedQuery,
    ],
  );

  const { filtersState } = queries[0];
  const logsState = useMemo(
    () => {
      return {
        ...getLogsStateFromFiltersState({ filtersState }),
        ...common,
        filtersState,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateState[0], filtersState],
  );

  return {
    activeQueryIndex,
    activeSidebarLogsState,
    logsState,
  };

  // return {
  //   dateState,
  //   filterOrExcludeByFingerprint,
  //   filtersState,
  //   keyExistsState,
  //   searchTermsState,
  //   selectedFacetRangeByNameState,
  //   selectedFacetValuesByNameState,
  //   state,
  // };
};

export default useLogsState;
