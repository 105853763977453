import {
  ChipWithLabel,
  IconWithLabel,
  PercentageBar,
  Tab,
  Tabs,
  useTabs,
} from 'components';
import { useRequest, useTracesState } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { describeSpan } from 'requests';
import { ChartGridKeysState, Span, SpanMetrics, TraceMetrics } from 'types';
import { formatDurationNs } from 'utils';
import TraceSidebarActiveSpanErrors from './TraceSidebarActiveSpanErrors';
import TraceSidebarActiveSpanJson from './TraceSidebarActiveSpanJson';
import TraceSidebarActiveSpanLogs from './TraceSidebarActiveSpanLogs';
import TraceSidebarActiveSpanMetrics from './TraceSidebarActiveSpanMetrics';
import TraceSidebarLatencyTooltip from './TraceSidebarLatencyTooltip';
import { LogsAttribute } from './types';
import { getSpanErrors } from './utils';

type Props = {
  applyFilterOnTracesPage?: boolean;
  chartGridKeysState: ChartGridKeysState;
  colorsByServiceName: { [key: string]: string };
  fetchSpanMetrics: (span: Span) => Promise<SpanMetrics>;
  isRootSpan?: boolean;
  span: Span;
  spanMetrics?: SpanMetrics;
  spans: Span[];
  traceMetrics: TraceMetrics;
  tracesState: ReturnType<typeof useTracesState>;
};

const shouldMetricsTabBeEnabled = (span: Span) => {
  const spanKind = span?.attributes?.span_kind;

  return spanKind === 'SPAN_KIND_CONSUMER' || spanKind === 'SPAN_KIND_SERVER';
};

const TraceSidebarActiveSpan = ({
  applyFilterOnTracesPage,
  chartGridKeysState,
  colorsByServiceName,
  fetchSpanMetrics,
  isRootSpan,
  span,
  spanMetrics,
  spans,
  traceMetrics,
  tracesState,
}: Props) => {
  const describeSpanRequest = useRequest(describeSpan);
  const describedSpan = describeSpanRequest.result;
  const errors = getSpanErrors(describedSpan || span);
  const [logsAttribute, setLogsAttribute] = useState<LogsAttribute>({
    key: 'traceId',
    value: span.traceId,
  });
  const tabs = useTabs();
  const spanIdExecTimeNs: { [key: string]: number } =
    traceMetrics.spanIdExecTimeNs;
  const durationInNs = span.durationNs || 0;
  const totalDurationInNs = Object.values(spanIdExecTimeNs).reduce(
    (sum, duration) => sum + duration,
    0,
  );

  const rawPercent = (durationInNs / totalDurationInNs) * 100;
  const percent = rawPercent.toFixed(0) === '100' ? 100 : rawPercent.toFixed(1);

  const isMetricsTabEnabled = useMemo(() => {
    return shouldMetricsTabBeEnabled(span);
  }, [span]);

  useEffect(() => {
    describeSpanRequest.call({
      spanId: span.spanId,
      endTimeNs: span.endTimeNs,
      traceId: span.traceId,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="trace-sidebar__active-span">
      <div className="trace-sidebar__active-span__header">
        <div className="trace-sidebar__active-span__header__left text--h3">
          <ChipWithLabel
            color={colorsByServiceName[span.service.name]}
            label={
              <span>
                <span>{span.service.name}</span>
                <span className="trace-sidebar__active-span__header__carat">
                  {'>'}
                </span>
                <span>{span.name}</span>
              </span>
            }
          />
        </div>
        <div className="trace-sidebar__active-span__header__right">
          <div className="trace-sidebar__active-span__header__time">
            <div className="trace-sidebar__active-span__header__time__top">
              <IconWithLabel
                icon={<AiOutlineClockCircle size={14} />}
                label={formatDurationNs(durationInNs, 1, 2)}
              />
              <div className="trace-sidebar__active-span__header__time__percent">
                {isNaN(percent) ? null : `${percent}% of total exec time`}
              </div>
            </div>
            <div className="trace-sidebar__active-span__header__time__bottom">
              <PercentageBar percent={Number(percent)} />
            </div>
          </div>
          <TraceSidebarLatencyTooltip
            fetchSpanMetrics={fetchSpanMetrics}
            key={span.spanId}
            label="span"
            shouldNotFetch={isRootSpan}
            span={span}
            spanMetrics={spanMetrics}
          />
        </div>
      </div>
      <div className="trace-sidebar__active-span__body">
        <Tabs className="tabs--underline" tabs={tabs}>
          <Tab label="Info">
            <TraceSidebarActiveSpanJson
              applyFilterOnTracesPage={applyFilterOnTracesPage}
              setLogsAttribute={setLogsAttribute}
              span={describedSpan || span}
              tabs={tabs}
              tracesState={tracesState}
            />
          </Tab>
          {isMetricsTabEnabled && (
            <Tab label={'Metrics'}>
              <TraceSidebarActiveSpanMetrics
                chartGridKeysState={chartGridKeysState}
                colorsByServiceName={colorsByServiceName}
                key={span.spanId}
                span={describedSpan || span}
                spans={spans}
                traceMetrics={traceMetrics}
                tracesState={tracesState}
              />
            </Tab>
          )}
          <Tab label="Logs">
            <TraceSidebarActiveSpanLogs
              logsAttribute={logsAttribute}
              span={span}
              setLogsAttribute={setLogsAttribute}
            />
          </Tab>
          {errors.length ? (
            <Tab label="Stacktrace">
              <TraceSidebarActiveSpanErrors errors={errors} />
            </Tab>
          ) : null}
        </Tabs>
      </div>
    </div>
  );
};

export default TraceSidebarActiveSpan;
