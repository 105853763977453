import { Loader, Table } from 'components';
import { tracesTableColumns } from 'kfuse-constants';
import { useColorsByServiceName, useRequest } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { getServices, traces } from 'requests';
import { DashboardPanelComponentProps, Trace } from 'types';
import getColorsByServiceHash from 'utils/getColorsByServiceHash';
import { getColorsByServiceName } from 'utils/index';

const DashboardPanelTraces = ({
  dashboardState,
  panel,
}: DashboardPanelComponentProps) => {
  const getServicesRequest = useRequest(getServices);
  const { date } = dashboardState;
  const tracesRequest = useRequest(traces);

  const rows: Trace[] = tracesRequest.result || [];

  useEffect(() => {
    if (panel?.targets?.length) {
      const { expr } = panel.targets[0];

      const initialTracesState = { date, ...JSON.parse(expr) };
      tracesRequest.call({ date, ...initialTracesState });
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, panel]);

  useEffect(() => {
    getServicesRequest.call({ date });
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const colorsByServiceName = useMemo(
    () => getColorsByServiceName(getServicesRequest.result || []),
    [getServicesRequest.result],
  );

  if (panel?.targets?.length) {
    return (
      <Loader
        className="dashboard-panel__traces"
        isLoading={tracesRequest.isLoading}
      >
        <Table
          className="table--padded table--bordered-cells"
          columns={tracesTableColumns({
            colorsByServiceName,
          }).filter((column) => column.key !== 'span.endpoint')}
          isSortingEnabled
          rows={rows}
        />
      </Loader>
    );
  }

  return null;
};

export default DashboardPanelTraces;
