import { Loader, RightSidebar } from 'components';
import React, { ReactElement, useMemo } from 'react';

import { RuleProps } from '../../NewAlerts/types';
import AlertsDetailsWrapper from '../../NewAlerts/AlertsDetails/AlertsDetailsWrapper';
import useRequest from 'hooks/useRequest';
import { getAsmAutoAlert } from 'requests';
import useDebouncedEffect from 'use-debounced-effect';

const ServiceAlertsDetailsSidebar = ({
  asmMatcher,
  close,
  kfSource,
  rule,
  title,
}: {
  asmMatcher: string;
  close: () => void;
  kfSource?: string;
  rule: RuleProps;
  title: string;
}): ReactElement => {
  const getAsmAutoAlertRequest = useRequest(getAsmAutoAlert);

  const asmData = getAsmAutoAlertRequest.result;

  useDebouncedEffect(
    () => {
      if (rule.isASMAlert) {
        getAsmAutoAlertRequest.call({ matcher: asmMatcher, kfSource });
      }
    },
    {
      timeout: 100,
      ignoreInitialCall: false,
    },
    [asmMatcher, kfSource, rule],
  );

  const showASMLoader = useMemo(() => {
    if (!rule.isASMAlert) {
      return false;
    }
    return getAsmAutoAlertRequest.calledAtLeastOnce
      ? getAsmAutoAlertRequest.isLoading
      : true;
  }, [
    rule,
    getAsmAutoAlertRequest.isLoading,
    getAsmAutoAlertRequest.calledAtLeastOnce,
  ]);
  return (
    <RightSidebar
      className="service__alerts__details__sidebar slo__right-sidebar"
      close={close}
      closeOnOutsideClick={(e: MouseEvent) => {
        const target = e.target as HTMLElement;
        if (!target?.className?.includes('select__panel__option')) {
          close();
        }
        return;
      }}
      title={title}
    >
      {showASMLoader ? (
        <Loader isLoading />
      ) : (
        <div className="slo__right-sidebar__main">
          <AlertsDetailsWrapper
            enableAnalysis={true}
            fileNameForAlertEvents={asmData?.folder}
            isDetailedCompact={true}
            kfSource={kfSource}
            hideTitle
            rulePrev={rule}
          />
        </div>
      )}
    </RightSidebar>
  );
};

export default ServiceAlertsDetailsSidebar;
