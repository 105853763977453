import {
  convertSecondToReadable,
  getRollupByVisualizationByMinLimit,
  getRollupToSecond,
} from 'utils';
import { DateSelection } from 'types/DateSelection';

import {
  ConditionProps,
  ConditionGrafana,
} from '../AlertsCreateCondition/types';
import { AlertQuery, AlertsEvaluateProps, RuleType } from '../types';
import {
  getAlertPromqlExprQuery,
  getAlertReducerForQuery,
  getAlertMathForQuery,
} from './alertCreateJsonHelper';

export const getCreateAlertQueries = ({
  condition,
  promqlQuery,
  datasourceUid,
  datasourceType,
  evaluate,
  interval,
  ruleType,
}: {
  condition: ConditionProps;
  promqlQuery: string;
  datasourceUid: string;
  datasourceType: 'loki' | 'prometheus';
  evaluate: AlertsEvaluateProps;
  interval?: string;
  ruleType: RuleType;
}): AlertQuery[] => {
  const queryCondition: ConditionGrafana[] = [
    {
      type: 'query',
      reducer: { params: [], type: condition.when },
      operator: { type: 'and' },
      query: { params: [] },
      evaluator: { params: [Number(condition.value)], type: condition.of },
    },
  ];

  const queryA = getAlertPromqlExprQuery({
    promqlQuery,
    datasourceUid,
    datasourceType,
    evaluate,
    interval,
  });

  const queryB = getAlertReducerForQuery({
    conditions: queryCondition,
    expression: 'A',
    reducer: condition.when,
    refId: 'B',
  });

  const mathExpression = `$B ${getConditionToOperator(condition.of)} ${
    condition.value
  }`;
  const queryC = getAlertMathForQuery({
    conditions: queryCondition,
    expression: mathExpression,
    refId: 'C',
  });

  return [queryA, queryB, queryC];
};

export const getCreateAlertQueriesForAnomaly = ({
  condition,
  promqlQuery,
  datasourceUid,
  datasourceType,
  evaluate,
}: {
  condition: ConditionProps;
  promqlQuery: string;
  datasourceUid: string;
  datasourceType: 'loki' | 'prometheus';
  evaluate: AlertsEvaluateProps;
}): AlertQuery[] => {
  const queryCondition: ConditionGrafana[] = [
    {
      type: 'query',
      reducer: { params: [], type: condition.when },
      operator: { type: 'and' },
      query: { params: [] },
      evaluator: { params: [Number(condition.value)], type: condition.of },
    },
  ];

  const queryA = getAlertPromqlExprQuery({
    promqlQuery,
    datasourceUid,
    datasourceType,
    evaluate,
  });

  const queryB = getAlertReducerForQuery({
    conditions: queryCondition,
    expression: 'A',
    reducer: 'sum',
    refId: 'B',
  });

  const queryC = getAlertReducerForQuery({
    conditions: queryCondition,
    expression: 'A',
    reducer: 'count',
    refId: 'C',
  });

  const mathExpression = `$B/$C * 100 > ${condition.value}`;
  const queryD = getAlertMathForQuery({
    conditions: queryCondition,
    expression: mathExpression,
    refId: 'D',
  });

  return [queryA, queryB, queryC, queryD];
};

const getConditionToOperator = (of: ConditionProps['of']): string => {
  switch (of) {
    case 'gt':
      return '>';
    case 'lt':
      return '<';
    case 'eq':
      return '==';
    case 'neq':
      return '!=';
    case 'gte':
      return '>=';
    case 'lte':
      return '<=';
    default:
      return '=';
  }
};

export const getAlertWindowChartPercentage = (
  forWindow: string,
  date: DateSelection,
): {
  percent: number;
  window: string;
} => {
  const endTimeUnix = getRollupToSecond(forWindow);
  const dateRange = { startTimeUnix: 0, endTimeUnix };
  const step = getRollupByVisualizationByMinLimit(dateRange, 'line', '15s');

  const duration = date.endTimeUnix - date.startTimeUnix;
  const percent = (step / duration) * 100;
  const readable = convertSecondToReadable(step);
  return { percent, window: readable };
};

export const checkPromqlIncludeDivision = (promql: string): boolean => {
  // This regex looks for a division symbol that is not followed by the end of the string
  // and is not inside double quotes.
  const regex = /\/(?!$)(?=(?:[^"]*"[^"]*")*[^"]*$)/;
  const divisions = promql.match(regex);

  // Check if division is not in the last position
  const isNotLast = divisions && promql[promql.length - 1] !== '/';

  // If divisions are found and it's not in the last position, return the match; otherwise, return null
  return divisions && isNotLast;
};
