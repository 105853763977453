import dayjs from 'dayjs';
import { FacetBase, LogsState, SubscriptionArgs } from 'types';
import {
  buildQuery,
  formatFacetName,
  mergeValueCountSubscription,
} from './utils';

type Args = {
  facet: FacetBase;
  logsState: LogsState;
};

const getLogFacetValuesCountsSubscription = ({
  facet,
  logsState,
}: Args): SubscriptionArgs => {
  const { date } = logsState;
  const startTimeUnix = date.zoomedStartTimeUnix || date.startTimeUnix;
  const endTimeUnix = date.zoomedEndTimeUnix || date.endTimeUnix;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const logQuery = buildQuery(logsState);

  return {
    queryBuilder: () => `
      subscription {
      getFacetValueCountsStream(
        durationSecs: ${durationSecs}
        facetName: "${formatFacetName(facet.component, facet.name, facet.type)}"
        ${logQuery !== '{}' ? `logQuery: ${logQuery},` : ''}
        limit: 500,
        timestamp: "${endTime.format()}",
      ) {
        count
        floatValue
        value
      }
    }
  `,
    key: 'getFacetValueCountsStream',
    initialState: [],
    merge: mergeValueCountSubscription(facet.type),
  };
};

export default getLogFacetValuesCountsSubscription;
