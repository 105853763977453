import { Input, PopoverTriggerV2, TooltipTrigger } from 'components';
import { default as React, ReactElement, useMemo, useState } from 'react';
import { Badge } from '../base';

type Props = {
  badgeList: string[];
  renderPopoverOnBadgeClick: (badge: string, close: () => void) => ReactElement;
};

export const BadgeGroupPanel = ({
  badgeList,
  renderPopoverOnBadgeClick,
}: Props): ReactElement => {
  const [search, setSearch] = useState('');
  const [popOverOpen, setPopOverOpen] = useState(false);

  const filteredBadgeList = useMemo(() => {
    return badgeList.filter((badge) =>
      badge.toLowerCase().includes(search.toLowerCase()),
    );
  }, [badgeList, search]);

  return (
    <div className="max-h-[42vh] w-[42vw] overflow-y-auto overflow-x-hidden">
      <div className="flex flex-col gap-2">
        <Input
          placeholder={`Filter all ${badgeList.length} tags`}
          type="text"
          size="4"
          variant="default"
          value={search}
          onChange={(val) => setSearch(val)}
        />
        <div className="flex flex-col">
          {filteredBadgeList.map((badge) => (
            <PopoverTriggerV2
              forceOpen={popOverOpen}
              onClose={() => setPopOverOpen(false)}
              popover={({ close }) => renderPopoverOnBadgeClick(badge, close)}
              key={badge}
            >
              <TooltipTrigger tooltip={badge}>
                <Badge
                  className="chip--blue w-fit mb-1 mr-1 inline-block break-words rounded-sm px-2 font-robotoMono text-xs font-medium text-text-secondary"
                  key={badge}
                  variant="outline"
                >
                  {badge}
                </Badge>
              </TooltipTrigger>
            </PopoverTriggerV2>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BadgeGroupPanel;
