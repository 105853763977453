import { DataFrame, intersectLabels } from 'utils';
import { getUsedQueryKeysFromFormula } from 'utils/FormulaValidator/validateTraceFormulaExpression';

export const getUsedQueriesLabelForFormula = ({
  formulaExpression,
  chartData,
}: {
  formulaExpression: string;
  chartData: Array<DataFrame>;
}): { [key: string]: boolean } | null => {
  const usedQueryKeys = getUsedQueryKeysFromFormula(formulaExpression);
  const labelBitmapForQuery: { [query: string]: { [key: string]: boolean } } =
    {};

  const chartDataMap = chartData.reduce(
    (acc, data) => {
      acc[data.meta.refId] = data;
      return acc;
    },
    {} as { [key: string]: DataFrame },
  );

  if (usedQueryKeys.length && chartDataMap[usedQueryKeys[0]]) {
    if (chartDataMap[usedQueryKeys[0]]?.meta?.labels?.[0] === '*') {
      return null;
    }
  }
  usedQueryKeys.forEach((key) => {
    const labelBitmap: { [key: string]: boolean } = {};

    const queryId = key;

    const queryData = chartDataMap[queryId];
    if (!queryData) {
      return;
    }

    if (queryData.data.series) {
      queryData.data.series.map((s) => {
        labelBitmap[s.label] = true;
      });
    }

    labelBitmapForQuery[queryId] = labelBitmap;
  });

  const labelBitmap = intersectLabels(...Object.values(labelBitmapForQuery));

  return labelBitmap;
};
