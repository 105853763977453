import { useToaster } from 'components';
import { useRequest } from 'hooks';
import { createGrafanaAlertsRule, getGrafanaAlertsRuleByGroup } from 'requests';

import { AlertRulerByGroup, AlertRuleGroup, DeleteRuleProps } from '../types';
import { groupByGroupBulkDeleteAlertsRule } from '../utils';

const useAlertsDelete = () => {
  const { addToast } = useToaster();
  const createGrafanaAlertsRuleRequest = useRequest(createGrafanaAlertsRule);

  const getAlertByFolder = (
    folderName: string,
    groupFile?: string,
  ): Promise<AlertRulerByGroup | AlertRuleGroup> => {
    return new Promise((resolve, reject) => {
      getGrafanaAlertsRuleByGroup(folderName, groupFile)
        .then((res: AlertRulerByGroup) => {
          if (!res) {
            reject();
            return;
          }
          resolve(res);
        })
        .catch(reject);
    });
  };

  const deteleAlertCall = (ruleData: DeleteRuleProps) => {
    return new Promise((resolve, reject) => {
      getAlertByFolder(ruleData.folderName, ruleData.groupName)
        .then((res: AlertRuleGroup) => {
          const payload = { ...res };
          payload.rules = payload.rules.filter(
            (rule) => rule.grafana_alert.uid !== ruleData.uid,
          );

          createGrafanaAlertsRuleRequest
            .call(ruleData.folderName, payload)
            .then((resCreate: { message: string }) => {
              if (resCreate.message.indexOf('successfully') > -1) {
                addToast({
                  text: 'Alerts rule deleted successfully',
                  status: 'success',
                });
              }
              resolve(true);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };

  const deleteAlertsRule = (ruleData: DeleteRuleProps) => {
    return deteleAlertCall(ruleData).catch(() => {
      addToast({ text: 'Error deleting alerts rule', status: 'error' });
    });
  };

  const bulkDeleteAlertsRuleCall = ({
    deleteRulesForGroup,
    folderName,
    rulesForGroup,
  }: {
    deleteRulesForGroup: DeleteRuleProps[];
    folderName: string;
    rulesForGroup: AlertRuleGroup;
  }) => {
    return new Promise((resolve, reject) => {
      const payload = { ...rulesForGroup };
      payload.rules = payload.rules.filter((rule) => {
        const toDelete = deleteRulesForGroup.find(
          (deleteRule) => deleteRule.uid === rule.grafana_alert.uid,
        );
        return !toDelete;
      });
      createGrafanaAlertsRuleRequest
        .call(folderName, payload)
        .then(resolve)
        .catch(reject);
    });
  };

  const bulkDeleteAlertsRuleGroup = ({
    deleteRulesByFolder,
    folderName,
    ruleByFolder,
  }: {
    deleteRulesByFolder: DeleteRuleProps[];
    folderName: string;
    ruleByFolder: AlertRuleGroup[];
  }) => {
    const groupedDeleteRulesByGroup =
      groupByGroupBulkDeleteAlertsRule(deleteRulesByFolder);

    return Promise.all(
      Object.entries(groupedDeleteRulesByGroup).map(
        ([groupName, deleteRulesForGroup]) => {
          const rulesForGroup = ruleByFolder.find(
            (rule) => rule.name === groupName,
          );
          return bulkDeleteAlertsRuleCall({
            folderName,
            rulesForGroup,
            deleteRulesForGroup,
          });
        },
      ),
    );
  };

  const bulkDeleteAlertsRule = (groupedDeleteRules: {
    [key: string]: DeleteRuleProps[];
  }) => {
    return Promise.all(
      Object.entries(groupedDeleteRules).map(([folderName, deleteRules]) => {
        return getAlertByFolder(folderName).then((res: AlertRulerByGroup) => {
          const ruleByFolder = res[folderName];
          return bulkDeleteAlertsRuleGroup({
            folderName,
            ruleByFolder,
            deleteRulesByFolder: deleteRules,
          })
            .then(() => {
              addToast({
                text: `${groupedDeleteRules[folderName].length} alerts rule deleted successfully from folder ${folderName}`,
                status: 'success',
              });
            })
            .catch(() => {
              addToast({
                text: `Error deleting alerts rule from folder ${folderName}`,
                status: 'error',
              });
            });
        });
      }),
    );
  };

  return { deleteAlertsRule, bulkDeleteAlertsRule };
};

export default useAlertsDelete;
