import { ChartGridV2 } from 'components';
import dayjs from 'dayjs';
import colors from 'kfuse-constants/colors';
import React, { useMemo } from 'react';
import { DateSelection } from 'types';
import { multipleQueryRangeWithLabels, transformSLOPromql } from 'utils';

const getRows = ({
  date,
  queryTemplatesForSLOType,
  sloData,
}: {
  date: DateSelection;
}) => {
  const errorBudgetQuery = queryTemplatesForSLOType.sloBudgetRemaining;
  const errorBudgetQueryPromQl = transformSLOPromql({
    promql: `100 * (${errorBudgetQuery})`,
    slo: sloData,
  });

  const currentBurnRateQuery = queryTemplatesForSLOType.sloCurrentBurnRate;
  const currentBurnRateQueryPromQl = `${currentBurnRateQuery}{slo_id="${sloData.id}"}`;

  const chartRows = [
    [
      {
        charts: [
          {
            key: 'Error Budget Burndown',
            chartType: 'line',
            colorMap: { 'Error Budget Burndown': colors.red },
            label: 'Error Budget Burndown',
            legendTableColumns: ['Error Budget Burndown'],
            libraryType: 'uplot',
            onSelection: () => {},
            query: multipleQueryRangeWithLabels(
              [() => errorBudgetQueryPromQl],
              [['Error Budget Burndown']],
            ),
            useCeilInAdjustingTime: true,
            tooltipProps: { layoutType: 'drawer' },
          },
        ],
      },
      {
        charts: [
          {
            key: 'Burn Rate',
            chartType: 'line',
            colorMap: { 'Burn Rate': colors.yellow },
            label: 'Burn Rate',
            legendTableColumns: ['Burn Rate'],
            libraryType: 'uplot',
            onSelection: () => {},
            query: multipleQueryRangeWithLabels(
              [() => currentBurnRateQueryPromQl],
              [['Burn Rate']],
            ),
            useCeilInAdjustingTime: true,
            scaleYRange: { min: 0, max: 1 },
            tooltipProps: { layoutType: 'drawer' },
          },
        ],
      },
    ],
  ];

  return chartRows;
};

type Props = {
  queryTemplatesForSLOType: any;
  sloData: { sloId: string; sloName: string; sloType: string };
};

const SLOChartGrid = ({ queryTemplatesForSLOType, sloData }: Props) => {
  const date = useMemo(() => {
    const dayUnix = dayjs().unix();
    const oneDay = 24 * 60 * 60;
    const last30Days = oneDay * 30;
    return { startTimeUnix: dayUnix - last30Days, endTimeUnix: dayUnix };
  }, []);

  const rows = useMemo(
    () =>
      getRows({
        date,
        queryTemplatesForSLOType,
        sloData,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return <ChartGridV2.ChartGrid date={date} rows={rows} />;
};

export default SLOChartGrid;
