import { useLeftSidebarState } from 'components';
import { useFeatureFlag } from 'configcat-react';
import {
  useDateState,
  useRequest,
  useSelectedFacetValuesByNameState,
} from 'hooks';
import React, { useMemo } from 'react';
import { getServices } from 'requests';
import { DateSelection, Service } from 'types';
import { getColorsByServiceHash } from 'utils';
import useDebouncedEffect from 'use-debounced-effect';
import ServicesMainServices from './ServicesMainServices';

type Props = {
  dateState: ReturnType<typeof useDateState>;
  isAsmChecked: boolean;
  leftSidebarState: ReturnType<typeof useLeftSidebarState>;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
};

const SECONDS_IN_5_MINUTES = 60 * 5;

const subtract5MinutesFromStartTimeUnix = (
  date: DateSelection,
): DateSelection => {
  return {
    ...date,
    startTimeUnix: date.startTimeUnix - SECONDS_IN_5_MINUTES,
  };
};

const ServicesMainServicesContainer = ({
  dateState,
  isAsmChecked,
  leftSidebarState,
  selectedFacetValuesByNameState,
}: Props) => {
  const getServicesRequest = useRequest(getServices);
  const isReady = Boolean(getServicesRequest.result);

  const serviceByHash = useMemo(
    () =>
      (getServicesRequest.result || []).reduce(
        (obj, service) => ({ ...obj, [service.hash]: service }),
        {},
      ),
    [getServicesRequest.result],
  );

  const colorsByServiceHash = useMemo(
    () => getColorsByServiceHash(getServicesRequest.result || []),
    [getServicesRequest.result],
  );

  const [date] = dateState;
  const selectedFacetValuesByName = useMemo(
    () => ({
      ...selectedFacetValuesByNameState.state,
    }),
    [selectedFacetValuesByNameState.state],
  );

  useDebouncedEffect(
    () => {
      getServicesRequest.call({
        date: subtract5MinutesFromStartTimeUnix(date),
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    { timeout: 400, ignoreInitialCall: false },
    [date, selectedFacetValuesByNameState.state],
  );

  const { value: isLatencyBoundedToMinMax, loading: latencyConfigLoading } =
    useFeatureFlag('isLatencyBoundedToMinMax', false);

  return isReady && !latencyConfigLoading ? (
    <ServicesMainServices
      colorsByServiceHash={colorsByServiceHash}
      dateState={dateState}
      isAsmChecked={isAsmChecked}
      isLatencyBoundedToMinMax={isLatencyBoundedToMinMax}
      leftSidebarState={leftSidebarState}
      selectedFacetValuesByName={selectedFacetValuesByName}
      selectedFacetValuesByNameState={selectedFacetValuesByNameState}
      serviceByHash={serviceByHash}
    />
  ) : null;
};

export default ServicesMainServicesContainer;
