import {
  DownloadPopover,
  Loader,
  Table,
  TableHeader,
  useColumnsState,
  useModalsContext,
  useTableOptions,
  useTableBESort,
} from 'components';
import React from 'react';
import { DownloadType } from 'types';
import useRumTableRequest from './useRumTableRequest';
import {
  RumActionColumnKey,
  RumTableColumnKey,
  RumViewColumnKey,
} from './contants';
import {
  rumActionTableColumns,
  rumViewTableColumns,
} from './rumEventTableColumns';
import useRumState from './hooks/useRumState';
import { RUMEvent, RumEventType } from './types';

type Props = {
  setActiveRumEvent: (rumEvent: RUMEvent) => void;
  rumState: ReturnType<typeof useRumState>;
};

const RumTable = ({ setActiveRumEvent, rumState }: Props) => {
  const modals = useModalsContext();

  const {
    dateState,
    eventType,
    idSearch,
    rumTableSortState,
    selectedFacetValuesByNameState,
  } = rumState;

  const [date] = dateState;
  const rumEventsRequest = useRumTableRequest({
    date,
    eventType,
    idSearch,
    rumTableSortState,
    selectedFacetValuesByNameState,
  });

  const onRowClick = ({ row }: { row: RUMEvent }) => {
    setActiveRumEvent(row);
  };
  const viewColumns = rumViewTableColumns();
  const actionColumns = rumActionTableColumns();

  const viewsColumnsState = useColumnsState({
    columns: viewColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        [RumViewColumnKey.date]: 1,
        [RumViewColumnKey.urlPath]: 1,
        [RumViewColumnKey.loadingType]: 1,
        [RumViewColumnKey.largestContentfulPaint]: 1,
        [RumViewColumnKey.country]: 1,
        [RumViewColumnKey.environment]: 1,
        [RumViewColumnKey.service]: 1,
        [RumViewColumnKey.browserName]: 1,
      },
    },
    key: 'rum-view-table',
  });

  const actionsColumnsState = useColumnsState({
    columns: actionColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        [RumActionColumnKey.date]: 1,
        [RumActionColumnKey.applicationId]: 1,
        [RumActionColumnKey.viewId]: 1,
        [RumActionColumnKey.viewName]: 1,
        [RumActionColumnKey.actionId]: 1,
        [RumActionColumnKey.actionType]: 1,
        [RumActionColumnKey.actionLoadingTime]: 1,
        [RumActionColumnKey.actionFrustrationType]: 1,
      },
    },
    key: 'rum-action-table',
  });

  const columnsState =
    eventType === RumEventType.VIEW ? viewsColumnsState : actionsColumnsState;

  const columns = eventType === RumEventType.VIEW ? viewColumns : actionColumns;
  const rows = rumEventsRequest.result || [];

  const tableOptions = useTableOptions();
  const tableSort = useTableBESort({
    columns,
    initialKey: RumTableColumnKey.date,
    rows: rows,
    onSortChange: ({ sortBy, sortOrder }) => {
      rumTableSortState?.sortBy({ sortBy, sortOrder });
    },
  });

  const openRumDownloadModal = (downloadType: string) => {
    //yet to implement
  };

  return (
    <>
      <TableHeader
        columnsState={columnsState}
        tableOptions={tableOptions}
        tableOptionsClassName="rum-events__toolbar overflow-auto"
      >
        <div className="flex">
          <div className="ml-3">
            <DownloadPopover
              downloadTypes={[DownloadType.csv, DownloadType.json]}
              openModal={openRumDownloadModal}
            />
          </div>
        </div>
      </TableHeader>
      <Loader
        className="rum-events__table overflow-auto"
        isLoading={rumEventsRequest.isLoading}
        dataTestId="rum-events-table"
      >
        <Table
          className="table--padded table--bordered-cells font-robotoMono"
          columns={columnsState.renderedColumns}
          externalTableSort={tableSort}
          isResizingEnabled
          isSortingEnabled
          onRowClick={onRowClick}
          onScrollEnd={rumEventsRequest.onScrollEnd}
          rows={tableSort.sortedRows}
          tableKey="rum-events-table"
        />
      </Loader>
    </>
  );
};

export default RumTable;
