import dayjs from 'dayjs';
import { DateSelection, TimeSeries } from 'types';
import {
  DataFrameMeta,
  DataTransformerConfig,
  getAdjustedStartAndEndTimeUnix,
  onPromiseError,
  transformLogQL,
} from 'utils';

import query from './query';

type Args = {
  date: DateSelection;
  instant?: boolean;
  logQL: string;
  meta: DataFrameMeta;
  transformer: DataTransformerConfig[];
  width?: number;
};

const getLogMetricsTimeSeriesLogQLV2 = <T>({
  date,
  instant,
  meta,
  logQL,
  transformer,
  width,
}: Args): Promise<T> => {
  const { startTimeUnix, endTimeUnix } = date;
  const stepMs = meta.step * 1000;
  const adjusted = getAdjustedStartAndEndTimeUnix({
    date,
    step: `${stepMs}ms`,
  });
  const endTime = dayjs.unix(instant ? endTimeUnix : adjusted.endTimeUnix);

  const durationSecs = endTimeUnix - startTimeUnix;
  const durationMs = durationSecs * 1000;

  const logQLSanitized = transformLogQL(logQL, date, width);
  meta.executedDate = adjusted;
  meta.executedQueryStr = logQLSanitized;
  return query<TimeSeries[], 'getLogMetricsTimeSeries'>(`
    {
      getLogMetricsTimeSeries(
        durationMs: ${durationMs},
        ${logQL ? `logQL: "${logQLSanitized}",` : ''}
        stepMs: ${instant ? durationMs : stepMs}
        timestamp: "${endTime.format()}"
      ) {
        points {
          ts
          value
        }
        tags
      }
    }
  `).then((data) => {
    const initialData = { datasets: data.getLogMetricsTimeSeries, meta };
    const transformed = transformer.reduce(
      (prevData, item) => item.func(prevData),
      initialData,
    );
    return transformed as unknown as T;
  }, onPromiseError);
};

export default getLogMetricsTimeSeriesLogQLV2;
