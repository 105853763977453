import { RumEventType } from '../types';
import { ACTION_LABELS, VIEW_LABELS } from './utils';

type Args = {
  eventType: RumEventType;
};

const getLabels = (type: RumEventType): string[] => {
  switch (type) {
    case 'VIEW':
      return VIEW_LABELS;
    case 'ACTION':
      return ACTION_LABELS;

    default:
      return [];
  }
};

const rumLabels = async ({ eventType }: Args): Promise<string[]> => {
  return Promise.resolve(getLabels(eventType));
};

export default rumLabels;
