import { FacetPicker, ScrollViewChanning } from 'components';
import React, { ReactElement, ReactNode, useMemo, useRef } from 'react';
import {
  FacetBase,
  FacetName,
  FacetPickerAction,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { getFacetKey, getIsLogRangeFacet } from 'utils';

import { useLogsFacetSearch, useQueryScheduler } from './hooks';

type Props = {
  component: string;
  getActions: (facet: FacetBase) => FacetPickerAction[];
  handlersByName: (facet: FacetBase) => {
    clearFacet: VoidFunction;
    changeFacetRange: VoidFunction;
    excludeFacetValue: (value: string) => void;
    selectOnlyFacetValue: (value: string) => void;
    toggleFacetValue: (value: string, values: any[]) => void;
    toggleKeyExists: () => void;
  };
  keyExists: { [key: string]: number };
  logsFacetSearch: ReturnType<typeof useLogsFacetSearch>;
  queryScheduler: ReturnType<typeof useQueryScheduler>;
  renderNameHandler: (facet: FacetBase) => () => ReactNode;
  renderPlaceholderText: (facetKey: string) => string;
  requestByFacet: (
    facet: FacetBase,
  ) => Promise<{ count: number; value: string }[]>;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  selectedFacetRanges: SelectedFacetRangeByName;
  shouldFilterFacets?: boolean;
  filteredFacets: Array<FacetName>;
};

const LogsFacetGroupExpandedFacets = ({
  component,
  getActions,
  handlersByName,
  keyExists,
  logsFacetSearch,
  queryScheduler,
  renderNameHandler,
  renderPlaceholderText,
  requestByFacet,
  selectedFacetValuesByName,
  selectedFacetRanges,
  shouldFilterFacets,
  filteredFacets,
}: Props): ReactElement => {
  const { facets, onScrollToBottomFacets, facetSearch } = logsFacetSearch;
  const scrollContainerRef = useRef<HTMLDivElement>();

  return (
    <ScrollViewChanning
      className="virtualized-facet-container pb-1 mb-1 shadow-facetGroupExpandedBottom"
      onScrollToBottom={onScrollToBottomFacets}
      scrollContainerRef={scrollContainerRef}
    >
      {filteredFacets.length ? (
        filteredFacets.map((facet) => {
          const facetKey = getFacetKey(facet);
          return (
            <FacetPicker
              actions={getActions(facet)}
              className="facet-picker--nested"
              isRangeFacet={getIsLogRangeFacet(facet.type)}
              key={facetKey}
              keyExists={keyExists}
              label={facet.name}
              lastRefreshedAt={queryScheduler.secondQueryCompletedAt}
              name={facetKey}
              renderName={renderNameHandler(facet)}
              renderPlaceholderText={renderPlaceholderText}
              selectedFacetValues={selectedFacetValuesByName[facetKey] || {}}
              selectedFacetRange={selectedFacetRanges[facetKey] || null}
              {...handlersByName(facet)}
              {...requestByFacet(facet)}
              parentScrollContainerRef={scrollContainerRef}
            />
          );
        })
      ) : (
        <div className="logs__facet-group__facets__placeholder">{`No facets for ${component}`}</div>
      )}
    </ScrollViewChanning>
  );
};

export default LogsFacetGroupExpandedFacets;
