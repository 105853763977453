import { useToaster } from 'components';
import { useRequest } from 'hooks';
import { useEffect, useMemo } from 'react';
import {
  getAsmAutoAlert,
  getGrafanaAlertsRulesByFolder,
  mutateAsmAutoAlert,
} from 'requests';
import { DateSelection } from 'types';
import { formatGrafanaAlertsRules } from 'screens/NewAlerts/utils';

import { RuleProps } from '../../NewAlerts/types';

type AsmResponseProps = {
  enabled: boolean;
  folder: string;
  group_name: string;
};

interface UseAdvanceFunctionAlertStateReturn {
  asmServiceAlertsRules: {
    ok: RuleProps[];
    alerting: RuleProps[];
  };
  enableDisableSmartAlert: () => void;
  isEnabled: boolean | undefined;
  getAsmAutoAlertRequest: ReturnType<typeof useRequest>;
  isLoading: boolean;
}

const useAdvanceFunctionAlertState = ({
  asmMatcher,
  date,
  kfSource,
  serviceName,
}: {
  asmMatcher: string;
  date: DateSelection;
  kfSource: string;
  serviceName: string;
}): UseAdvanceFunctionAlertStateReturn => {
  const { addToast } = useToaster();
  const getAsmAutoAlertRequest = useRequest(getAsmAutoAlert);
  const mutateAsmAutoAlertRequest = useRequest(mutateAsmAutoAlert);
  const requestGrafanaAlertsRules = useRequest((args) =>
    getGrafanaAlertsRulesByFolder(args).then((data) =>
      formatGrafanaAlertsRules(data, []),
    ),
  );

  const isEnabled = useMemo(() => {
    return getAsmAutoAlertRequest.result?.enabled;
  }, [getAsmAutoAlertRequest.result]);

  const enableDisableSmartAlert = () => {
    if (getAsmAutoAlertRequest.isLoading) {
      addToast({
        text: `Please wait for the request to finish.`,
        status: 'error',
      });
      return;
    }
    mutateAsmAutoAlertRequest
      .call({
        matcher: asmMatcher,
        kfSource,
        method: isEnabled ? 'DELETE' : 'POST',
      })
      .then((res: AsmResponseProps) => {
        if (!res.enabled && !isEnabled) {
          addToast({ text: `Failed to enable smart alert`, status: 'error' });
          return;
        }

        getAsmAutoAlertRequest.call({ matcher: asmMatcher, kfSource });
        requestGrafanaAlertsRules.call(res.folder);
      })
      .catch((err) => {
        addToast({
          text: `Failed to ${isEnabled ? 'disable' : 'enable'} smart alert.`,
          status: 'error',
        });
      });
  };

  const asmServiceAlertsRules = useMemo(() => {
    const asmData = getAsmAutoAlertRequest.result as AsmResponseProps;
    if (!asmData || !requestGrafanaAlertsRules.result) {
      return { ok: [], alerting: [] };
    }

    const { folder, group_name } = asmData;
    if (!asmData.enabled || !folder || !group_name) {
      return { ok: [], alerting: [] };
    }

    const rules = requestGrafanaAlertsRules.result;
    const rulesBitmap: { ok: RuleProps[]; alerting: RuleProps[] } = {
      ok: [],
      alerting: [],
    };
    rules.forEach((rule) => {
      if (rule.groupFile !== folder || rule.group !== group_name) return;

      if (rule.status === 'alerting') {
        rulesBitmap.alerting.push(rule);
      } else {
        rulesBitmap.ok.push(rule);
      }
    });

    return rulesBitmap;
  }, [getAsmAutoAlertRequest.result, requestGrafanaAlertsRules.result]);

  useEffect(() => {
    getAsmAutoAlertRequest
      .call({ matcher: asmMatcher, kfSource })
      .then((res) => {
        requestGrafanaAlertsRules.call(res.folder);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asmMatcher, serviceName, kfSource]);

  return {
    asmServiceAlertsRules,
    enableDisableSmartAlert,
    isEnabled,
    getAsmAutoAlertRequest,
    isLoading:
      getAsmAutoAlertRequest.isLoading ||
      requestGrafanaAlertsRules.isLoading ||
      mutateAsmAutoAlertRequest.isLoading,
  };
};

export default useAdvanceFunctionAlertState;
