import {
  ChartToolbar,
  Loader,
  TimeseriesRenderer,
  TooltipTrigger,
  useCursorContextState,
  useThemeContext,
} from 'components';
import React, { ReactElement, useMemo } from 'react';
import { MdLegendToggle } from 'react-icons/md';
import { DateSelection, LegendTypes } from 'types';
import { combineRangeQueryData } from 'utils';
import uPlot from 'uplot';

import { useAlertsAnomalyDataLoader } from '../hooks';
import { AlertAnomalyQueryItem, AnomalyConditionProps } from '../types';
import { drawAlertChartWindow } from './utils';
import { getAlertWindowChartPercentage } from '../utils';
import { ANOMALY_OPTION_UNSELECTED_ERROR_MESSAGE } from '../constants/alertsConstants';

const AlertChartRightToolbar = ({
  isActive,
  onClick,
}: {
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <div className="new-metrics__chart__right-toolbar__icon" onClick={onClick}>
      <TooltipTrigger tooltip={`${isActive ? 'Hide' : 'Show'} Legends`}>
        <MdLegendToggle />
      </TooltipTrigger>
    </div>
  );
};

const defaultChartTypes = ['Line'];

const AlertsChartAnomalySplit = ({
  anomalyDataState,
  anomalyCondition,
  baseWidth,
  date,
  forWindow,
  queryItem,
  unit = 'number',
}: {
  anomalyDataState: ReturnType<typeof useAlertsAnomalyDataLoader>;
  anomalyCondition: AnomalyConditionProps;
  baseWidth: number;
  date: DateSelection;
  forWindow?: string;
  queryItem: AlertAnomalyQueryItem;
  unit?: string;
}): ReactElement => {
  const { cursorState, setCursorState } = useCursorContextState();
  const { evaluationData, evalLegendToggle, histLegendToggle, historicalData } =
    anomalyDataState;
  const { darkModeEnabled } = useThemeContext();

  const evalCombinedData = useMemo(() => {
    return combineRangeQueryData({
      formulas: [],
      queries: [{ queryKey: queryItem.refId, isActive: true }],
      queryData: evaluationData,
      darkModeEnabled,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationData, forWindow]);

  const histCombinedData = useMemo(() => {
    return combineRangeQueryData({
      formulas: [],
      queries: [{ queryKey: queryItem.refId, isActive: true }],
      queryData: historicalData,
      darkModeEnabled,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historicalData]);

  const anomalyChartWindowHooks = useMemo(() => {
    if (!forWindow) return [];
    const percentWindow = getAlertWindowChartPercentage(forWindow, date);
    return [
      {
        hook: (u: uPlot) => {
          drawAlertChartWindow({
            darkModeEnabled,
            percent: percentWindow.percent,
            u,
            window: percentWindow.window,
          });
        },
        type: 'draw',
      },
    ];
  }, [forWindow, date, darkModeEnabled]);

  return (
    <div className="alerts__chart__forecast">
      <Loader isLoading={histCombinedData.isLoading}>
        <TimeseriesRenderer
          chartData={histCombinedData || { data: [], series: [] }}
          chartTypes={defaultChartTypes}
          cursorState={cursorState}
          date={date || null}
          isLoading={false}
          chartKey="alerts-chart"
          legend={{
            legendType: histLegendToggle.value
              ? LegendTypes.COMPACT_ONE_LINE
              : 'none',
            legendHeight: 120,
          }}
          onCursorStateChange={setCursorState}
          renderToolbar={({ activeChart, setActiveChart }) => (
            <ChartToolbar
              activeChart={activeChart}
              chartTypes={['Line']}
              toolbar={{
                rightToolbar: (
                  <AlertChartRightToolbar
                    isActive={histLegendToggle.value}
                    onClick={() => histLegendToggle.toggle()}
                  />
                ),
                leftToolbar: <div className="text--h3">Historical View</div>,
              }}
              setActiveChart={setActiveChart}
            />
          )}
          size={{ height: 280, width: baseWidth / 2 - 32 }}
          tooltipType="compact"
          unit={unit}
        />
      </Loader>
      {anomalyCondition?.anomalyAlgorithm ? (
        <Loader isLoading={evalCombinedData.isLoading}>
          <TimeseriesRenderer
            bands={evalCombinedData.bands}
            chartData={evalCombinedData}
            chartTypes={defaultChartTypes}
            cursorState={cursorState}
            date={date || null}
            isLoading={false}
            hooks={anomalyChartWindowHooks}
            legend={{
              legendType: evalLegendToggle.value
                ? LegendTypes.COMPACT_ONE_LINE
                : 'none',
              legendHeight: 120,
            }}
            onCursorStateChange={setCursorState}
            size={{ height: 280, width: baseWidth / 2 - 32 }}
            renderToolbar={({ activeChart, setActiveChart }) => (
              <ChartToolbar
                activeChart={activeChart}
                chartTypes={['Line']}
                toolbar={{
                  rightToolbar: (
                    <AlertChartRightToolbar
                      isActive={evalLegendToggle.value}
                      onClick={() => evalLegendToggle.toggle()}
                    />
                  ),
                  leftToolbar: <div className="text--h3">Evaluation View</div>,
                }}
                setActiveChart={setActiveChart}
              />
            )}
            tooltipType="compact"
            unit={unit}
          />
        </Loader>
      ) : (
        <div className="text--h4 flex px-4">
          {ANOMALY_OPTION_UNSELECTED_ERROR_MESSAGE}
        </div>
      )}
    </div>
  );
};

export default AlertsChartAnomalySplit;
