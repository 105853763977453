import {
  LanguageIconWithLabel,
  ServiceWithLabels,
  Table,
  TableOptionsPopover,
  useColumnsState,
  useTableOptions,
} from 'components';
import { toNumber } from 'lodash';
import dayjs from 'dayjs';
import { useTracesState, useUrlState } from 'hooks';
import { timeFormats } from 'kfuse-constants';
import React from 'react';
import { Service, TraceError } from 'types';
import ApmErrorsTableSidebar from './ApmErrorsTableSidebar';
import { NANO_TO_MILLI_SECONDS } from 'utils';

type RenderCellProps = {
  row: TraceError;
};

const columns = (colorsByServiceHash: Record<string, string>) => [
  { key: 'id', label: 'ID' },
  {
    key: 'errorGroupingKey',
    label: 'Error grouping key',
  },
  {
    key: 'errorType',
    label: 'Error type',
  },
  {
    key: 'language',
    label: 'Language',
    renderCell: ({ value }: { value: string }) => (
      <LanguageIconWithLabel label={value} language={value} />
    ),
    value: ({ row }: { row: TraceError }) =>
      row.attributes['telemetry_sdk_language'],
  },
  { key: 'method', label: 'Method' },
  { key: 'parentId', label: 'Parent ID' },
  {
    key: 'service_name',
    label: 'Service',
    renderCell: ({ row }) => {
      const { service } = row;
      return service ? (
        <ServiceWithLabels
          color={colorsByServiceHash[service.hash]}
          distinctLabels={service.distinctLabels || {}}
          labels={service.labels}
          name={service.name}
        />
      ) : null;
    },
  },
  {
    key: 'serviceVersion',
    label: 'Service version',
    value: ({ row }: RenderCellProps) => row?.attributes?.['service_version'],
  },
  {
    key: 'timestampNs',
    label: 'Timestamp',
    renderCell: ({ value }: { value: string }) => {
      return dayjs(toNumber(value) / NANO_TO_MILLI_SECONDS).format(
        timeFormats.dateAtTime,
      );
    },
  },
  { key: 'traceId', label: 'Trace ID' },
];

type Props = {
  className?: string;
  colorsByServiceHash: Record<string, string>;
  onScrollEnd: () => void;
  rows: TraceError[];
  serviceByHash: Record<string, Service>;
  tracesState: ReturnType<typeof useTracesState>;
};

const ApmErrorsTable = ({
  className,
  colorsByServiceHash,
  onScrollEnd,
  rows,
  serviceByHash,
  tracesState,
}: Props) => {
  const columnsState = useColumnsState({
    columns: columns(colorsByServiceHash),
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        errorType: 1,
        language: 1,
        method: 1,
        service: 1,
        serviceVersion: 1,
        timestampNs: 1,
      },
    },
    key: 'apm-errors-table',
  });

  const tableOptions = useTableOptions();
  const [traceErrorId, setErrorTraceId] = useUrlState('traceId', null);

  const close = () => {
    setErrorTraceId(null);
  };

  const onRowClick = ({ row }) => {
    setErrorTraceId(row.id);
  };

  return (
    <>
      <div className="table-toolbar">
        <TableOptionsPopover
          columnsState={columnsState}
          shouldHideLinesToShow
          tableOptions={tableOptions}
        />
      </div>
      <Table
        className={className}
        columns={columnsState.renderedColumns}
        onRowClick={onRowClick}
        onScrollEnd={onScrollEnd}
        rows={rows}
      />
      {traceErrorId ? (
        <ApmErrorsTableSidebar
          colorsByServiceHash={colorsByServiceHash}
          close={close}
          serviceByHash={serviceByHash}
          traceErrorId={traceErrorId}
          tracesState={tracesState}
        />
      ) : null}
    </>
  );
};

export default ApmErrorsTable;
