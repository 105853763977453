import { escapePeriod, getIsComponentLabel } from 'utils';

const formatFacetName = (
  component: string,
  facetName: string,
  type: string,
): string => {
  if (getIsComponentLabel(component)) {
    return facetName;
  }

  return `@${escapePeriod(component)}${type ? `:${type}` : ''}.${escapePeriod(
    facetName,
  )}`;
};

export default formatFacetName;
