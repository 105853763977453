import classnames from 'classnames';
import { Checkbox, Radio } from 'components';
import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import { SelectedFacetValues, ValueCount } from 'types';
import {
  convertNumberToReadableUnit,
  getIsDeselecting,
  facetValueToggleUtil,
  FacetValueActionType,
  FilterType,
  exhaustiveSwitchError,
} from 'utils';
import { has, size, noop, mapKeys, last } from 'lodash';
import delimiter from 'kfuse-constants/delimiter';

type Props = {
  count: number;
  isRadio?: boolean;
  ignoreCount?: boolean;
  renderValue: (value: string) => ReactNode;
  selectedFacetValues: SelectedFacetValues;
  selectOnlyFacetValue: (value: string) => void;
  toggleFacetValue: (value: string, values: any[]) => void;
  value: string;
  clearFacet: () => void;
  allValues: ValueCount[];
};

const FacetPickerValuesItem = ({
  count,
  isRadio,
  ignoreCount,
  renderValue,
  selectedFacetValues: _selectedFacetValues,
  selectOnlyFacetValue,
  toggleFacetValue,
  value,
  clearFacet,
  allValues,
}: Props): ReactElement => {
  // Parse facetValue in case selectedFacetValues keys are in the form of facetName:!:facetValue
  const selectedFacetValues = mapKeys(_selectedFacetValues, (v, k) =>
    last(k.split(delimiter)),
  );

  const [isCheckboxHovered, setIsCheckboxHovered] = useState(false);
  const [isCheckboxLabelHovered, setIsCheckboxLabelHovered] = useState(false);
  const filterType = facetValueToggleUtil.getFilterType(selectedFacetValues);
  const isFacetValueAlreadyIncludedInFilters = has(selectedFacetValues, value);
  const hasExactlyOneUnfilteredValue =
    size(selectedFacetValues) === allValues.length - 1;
  const allValuesListHasExactlyOneItem = allValues.length === 1;

  const determineAction = (
    element: 'checkbox' | 'label',
  ): FacetValueActionType => {
    if (isRadio) return FacetValueActionType.SELECT;
    if (allValuesListHasExactlyOneItem)
      return element === 'label'
        ? isFacetValueAlreadyIncludedInFilters
          ? FacetValueActionType.TOGGLE
          : FacetValueActionType.ONLY
        : FacetValueActionType.TOGGLE;

    switch (filterType) {
      case FilterType.EMPTY:
        return element === 'label'
          ? FacetValueActionType.ONLY
          : FacetValueActionType.TOGGLE;
      case FilterType.EQ:
        if (
          size(selectedFacetValues) === 1 &&
          isFacetValueAlreadyIncludedInFilters
        ) {
          return element === 'label'
            ? FacetValueActionType.ALL
            : FacetValueActionType.TOGGLE;
        }
        return element === 'label'
          ? FacetValueActionType.ONLY
          : FacetValueActionType.TOGGLE;
      case FilterType.NEQ:
        if (
          hasExactlyOneUnfilteredValue &&
          !isFacetValueAlreadyIncludedInFilters
        ) {
          return element === 'label'
            ? FacetValueActionType.ALL
            : FacetValueActionType.TOGGLE;
        }
        return element === 'label'
          ? FacetValueActionType.ONLY
          : FacetValueActionType.TOGGLE;
      default:
        throw exhaustiveSwitchError(filterType);
    }
  };

  const textToShowOnCheckboxHover = determineAction('checkbox');
  const textToShowOnLabelHover = determineAction('label');

  const textToShowOnHover = (() => {
    if (isCheckboxHovered) {
      return textToShowOnCheckboxHover;
    }
    if (isCheckboxLabelHovered) {
      return textToShowOnLabelHover;
    }
    return null;
  })();

  const onLabelClick = (() => {
    switch (textToShowOnLabelHover) {
      case FacetValueActionType.ALL:
        return () => clearFacet();
      case FacetValueActionType.ONLY:
        return () => selectOnlyFacetValue(value);
      case FacetValueActionType.SELECT:
        return () => selectOnlyFacetValue(value);
      case FacetValueActionType.TOGGLE:
        return () => toggleFacetValue(value, allValues);
      default:
        throw exhaustiveSwitchError(textToShowOnLabelHover);
    }
  })();

  const onChange = () => {
    if (isRadio) {
      selectOnlyFacetValue(value);
    } else {
      toggleFacetValue(value, allValues);
    }
  };

  const renderedValue =
    renderValue && typeof renderValue === 'function'
      ? renderValue(value)
      : value;

  return (
    <div className="flex items-center" data-testid="facet-picker-values-item">
      <div
        className="flex h-[26px] cursor-pointer items-center px-0.5 py-1 hover:bg-interaction-secondary"
        onMouseEnter={() => {
          setIsCheckboxHovered(true);
        }}
        onMouseLeave={() => {
          setIsCheckboxHovered(false);
        }}
      >
        {isRadio ? (
          <Radio
            variant="compact"
            value={value}
            checked={Boolean(selectedFacetValues[value])}
            onChange={onChange}
            onClick={onChange}
          />
        ) : (
          <Checkbox
            variant="compact"
            onChange={onChange}
            value={(() => {
              switch (filterType) {
                case FilterType.EMPTY:
                  return true;
                case FilterType.EQ:
                  return has(selectedFacetValues, value);
                case FilterType.NEQ:
                  return !has(selectedFacetValues, value);
                default:
                  throw exhaustiveSwitchError(filterType);
              }
            })()}
          />
        )}
      </div>
      <div
        // TODO: maybe use min-width: 0 technique instead of calc(100% - px)
        className={classnames(
          'flex h-[26px] w-[calc(100%-20px)] cursor-pointer items-center justify-between gap-x-2 pb-1.5 pl-[3px] pr-3 pt-1 hover:bg-interaction-secondary focus-visible:bg-interaction-secondary',
          { 'bg-interaction-secondary': isCheckboxHovered },
        )}
        role="button"
        tabIndex={0}
        onClick={() => {
          onLabelClick();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onLabelClick();
          }
        }}
        onMouseEnter={() => {
          setIsCheckboxLabelHovered(true);
        }}
        onMouseLeave={() => {
          setIsCheckboxLabelHovered(false);
        }}
      >
        <span
          className="max-w-full overflow-hidden truncate"
          data-testid="facet-picker-label"
        >
          {renderedValue}
        </span>
        <div className="flex items-center">
          {textToShowOnHover && (
            <span className="mr-6 whitespace-nowrap text-2xs text-text-secondary" data-testid="only-all-toggle">
              {textToShowOnHover}
            </span>
          )}

          {!ignoreCount && (
            <div
              className="facet-picker__values__item__count text-2xs text-text-secondary"
              data-testid="facet-picker-count"
            >
              {count ? convertNumberToReadableUnit(count) : '-'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FacetPickerValuesItem;
