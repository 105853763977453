import { useThemeContext } from 'components';
import { Switch } from 'components/shadcn';
import React from 'react';

const convertOffsetToTimezone = (offset: number) => {
  const hour = Math.abs(offset / 60)
    .toString()
    .split('.')[0];
  const minute = Math.abs(offset % 60);
  const isPositive = offset > 0;

  return `UTC${isPositive ? '-' : '+'}${hour}:${minute}`;
};

const joinFirstChar = (str: string) => {
  const splitStr = str.split(' ');
  let result = '';
  for (let i = 0; i < splitStr.length; i++) {
    result += splitStr[i].charAt(0);
  }
  return result;
};

const getTimezoneAndOffset = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const timezoneOffsetText = convertOffsetToTimezone(offset);

  const dateString = date.toString();
  const timezone = dateString.split('(')[1].split(')')[0];
  const timezoneAbbr = joinFirstChar(timezone);
  return { timezoneOffsetText, timezoneAbbr };
};

const CalendarPickerTimezone = () => {
  const { utcTimeEnabled, toggleUtcTime } = useThemeContext();
  const { timezoneOffsetText, timezoneAbbr } = getTimezoneAndOffset();

  return (
    <div className="calendar-picker__modal__timezone flex justify-between">
      <div className="flex items-center">
        <div className="text-sm font-medium">
          {utcTimeEnabled ? 'UTC Time' : `Browser Time`}
        </div>
        {!utcTimeEnabled && (
          <div className="pl-2 text-sm text-text-secondary">{timezoneAbbr}</div>
        )}
      </div>
      <div className="flex items-center">
        {!utcTimeEnabled && (
          <div className="mr-4 rounded-sm border bg-secondary px-1">
            {timezoneOffsetText}
          </div>
        )}
        <div className="flex items-center pr-2">
          <div className="text-sm font-medium">
            {utcTimeEnabled ? 'Disable' : 'Enable'} UTC
          </div>
          <div className="ml-1.5 pt-[3px]">
            <Switch
              size="3"
              checked={utcTimeEnabled}
              onCheckedChange={toggleUtcTime}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarPickerTimezone;
