import classnames from 'classnames';
import { DateWarningAlert, LeftSidebar, useLeftSidebarState } from 'components';
import { useFeatureFlag } from 'configcat-react';
import { useServicesPageStateContext } from 'context';
import { useRequest, useToggle } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { getServices, traceLabelValuesV2 } from 'requests';
import { Service as ServiceType } from 'types';
import { getIsAsmChecked } from 'utils';
import ServiceContainer from '../Service/ServiceContainer';
import ServicesMainServicesContainer from './ServicesMainServicesContainer';
import ServicesSidebar from './ServicesSidebar';
import { ServicesTab } from './utils';

type Props = {
  isAsm?: boolean;
  tab?: string;
  isInServiceDetails?: boolean;
};

const Services = ({ isAsm = false, tab, isInServiceDetails }: Props) => {
  const { value: showService7Day } = useFeatureFlag('showservice7day', false);
  const leftSidebarState = useLeftSidebarState('services');
  const { serviceNameAndHash } = useParams();
  const serviceNameAndHashParts = (serviceNameAndHash || '').split('_');
  const serviceHash =
    serviceNameAndHashParts[serviceNameAndHashParts.length - 1];
  const showSidebarToggle = useToggle(true);
  const location = useLocation();

  const {
    apmDateState,
    dependenciesForWriteStateToUrl,
    selectedFacetValuesByNameState,
    chartGridKeysState,
    writeStateToUrl,
  } = useServicesPageStateContext();

  const telemetrySdkNameRequest = useRequest(traceLabelValuesV2);
  // TODO: This is so hack.
  useEffect(() => {
    if (isAsm) {
      selectedFacetValuesByNameState.selectOnlyFacetValue({
        name: 'kf_source',
        value: 'knight',
      });
    } else {
      if (location.pathname === '/apm/services') {
        selectedFacetValuesByNameState.selectOnlyFacetValue({
          name: 'kf_source',
          value: 'apm',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAsm]);

  const isAsmChecked = useMemo(
    () => getIsAsmChecked(selectedFacetValuesByNameState.state),
    [selectedFacetValuesByNameState.state],
  );

  const dateState = apmDateState;
  const [date] = dateState;

  useEffect(() => {
    telemetrySdkNameRequest.call({
      date,
      name: 'telemetry_sdk_name',
      selectedFacetValuesByName: selectedFacetValuesByNameState.state,
      serviceHash,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, selectedFacetValuesByNameState.state, serviceHash]);

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependenciesForWriteStateToUrl]);

  const [params] = useSearchParams();
  const isServiceFromDatabasesList = useMemo(() => {
    const isDatabase = params?.get('activeTab') === ServicesTab.db;
    return isDatabase;
  }, [params]);

  const serviceTab = useMemo(() => {
    if (isServiceFromDatabasesList) {
      return ServicesTab.db;
    }
    if (tab) {
      return tab;
    }
    return null;
  }, [tab, isServiceFromDatabasesList]);

  const getServicesRequest = useRequest(getServices);
  const serviceByHash: Record<string, ServiceType> = useMemo(
    () =>
      (getServicesRequest.result || []).reduce(
        (obj, service) => ({ ...obj, [service.hash]: service }),
        {},
      ),
    [getServicesRequest.result],
  );

  const service = serviceByHash[serviceHash];

  return (
    <div
      className={classnames({
        services: true,
        'services--disable-7-day': !showService7Day,
      })}
    >
      <LeftSidebar leftSidebarState={leftSidebarState}>
        <ServicesSidebar
          date={date}
          isAsmChecked={isAsmChecked}
          selectedFacetValuesByNameState={selectedFacetValuesByNameState}
          serviceHash={serviceHash}
          serviceDistinctLabels={service?.distinctLabels || {}}
          showSidebarToggle={showSidebarToggle}
          serviceTab={serviceTab}
          telemetrySdkName={
            telemetrySdkNameRequest.result?.[0]?.groupVal?.telemetry_sdk_name
          }
          isInServiceDetails={isInServiceDetails}
        />
      </LeftSidebar>
      <div className="services__main">
        <DateWarningAlert className="services__banner" date={date} />
        {serviceHash ? (
          <ServiceContainer
            apmDateState={apmDateState}
            chartGridKeysState={chartGridKeysState}
            isAsmChecked={isAsmChecked}
            getServicesRequest={getServicesRequest}
            key={serviceHash}
            leftSidebarState={leftSidebarState}
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            serviceHash={serviceHash}
            service={service}
            serviceByHash={serviceByHash}
            telemetrySdkName={
              telemetrySdkNameRequest.result?.[0]?.groupVal?.telemetry_sdk_name
            }
          />
        ) : (
          <ServicesMainServicesContainer
            dateState={dateState}
            isAsmChecked={isAsmChecked}
            leftSidebarState={leftSidebarState}
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
          />
        )}
      </div>
    </div>
  );
};

export default Services;
