import classNames from 'classnames';
import { Input, PopoverTriggerV2 } from 'components';
import { useMetricsQueryStateV2 } from 'hooks';
import { debounce } from 'lodash';
import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import MetricsQueryBuilderMetricPanel from './MetricsQueryBuilderMetricPanel';

const MetricsQueryBuilderMetric = ({
  editableMetrics,
  metric,
  queryIndex,
  metricsQueryState,
}: {
  editableMetrics: boolean;
  metric: string;
  queryIndex: number;
  metricsQueryState: ReturnType<typeof useMetricsQueryStateV2>;
}): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchMetric, setSearchMetric] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const {
    loadLabelList,
    getMetricsListRequest,
    labelsList,
    metricsList,
    openMetricUsingTags,
    updateQuery,
  } = metricsQueryState;

  const metricsListOptions = useMemo(() => {
    if (searchMetric) {
      return metricsList.filter(
        (metric) => metric.label.indexOf(searchMetric) > -1,
      );
    }
    return metricsList;
  }, [metricsList, searchMetric]);

  const onHover = useCallback(
    (metricName: string) => {
      loadLabelList([], metricName, -1);
    },
    [loadLabelList],
  );

  const onTagClick = (metricName: string, tagName: string) => {
    openMetricUsingTags(queryIndex, metricName, tagName);
  };

  const inputValue = useMemo(() => {
    return isFocused ? searchMetric : metric;
  }, [isFocused, metric, searchMetric]);

  return (
    <>
      <PopoverTriggerV2
        shouldUseClickOnOutsideClick={false}
        className="w-full"
        contentClassName="p-0"
        popover={({ close }) => {
          return (
            <MetricsQueryBuilderMetricPanel
              isLoading={getMetricsListRequest.isLoading}
              labelsList={labelsList}
              metricsList={metricsListOptions}
              onHover={onHover}
              onClick={(metricName: string) => {
                updateQuery(queryIndex, 'metric', metricName);
                close();
              }}
              onTagClick={(metricName: string, tagName: string) => {
                onTagClick(metricName, tagName);
                close();
              }}
            />
          );
        }}
        onOpen={() => {
          setTimeout(() => inputRef.current?.focus(), 100);
        }}
      >
        <Input
          className={classNames({
            'input--naked metrics__query-builder__query-item__metric': true,
            'input--disabled': !editableMetrics,
          })}
          disabled={!editableMetrics}
          onBlur={() => {
            setIsFocused(false);
          }}
          onFocus={() => setIsFocused(true)}
          onChange={(val) => setSearchMetric(val)}
          placeholder="Select a metric"
          ref={inputRef}
          type="text"
          value={inputValue}
          onEnter={() => {}}
          size="5"
        />
      </PopoverTriggerV2>
    </>
  );
};

export default MetricsQueryBuilderMetric;
