import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateSelection, LogsMetricQueryProps } from 'types';
import {
  defaultLogsQuery,
  extractPathNameFromURL,
  extractParamsFromURL,
  parseUrlParamByKey,
} from 'utils';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { useToggle } from '..';

const useLogsPageState = () => {
  const navigate = useNavigate();
  const parsedQueries = parseUrlParamByKey('LogsMetricsQueries');

  const dateState = useState<DateSelection>(
    parseUrlParamByKey('logsDate') || getDateFromRange('now-5m', 'now'),
  );

  const prettifyJsonToggle = useToggle();

  const queriesState = useState<LogsMetricQueryProps[]>(
    parsedQueries || [defaultLogsQuery],
  );

  const dependenciesForWriteStateToUrl = useMemo(
    () => [dateState[0], queriesState[0]],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateState[0], queriesState[0]],
  );

  // Should be called on state change when on the Traces page
  const writeStateToUrl = () => {
    const params = extractParamsFromURL();
    params.set('logsDate', JSON.stringify(dateState[0]));
    params.set('LogsMetricsQueries', JSON.stringify(queriesState[0]));
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    dependenciesForWriteStateToUrl,
    writeStateToUrl,
    dateState,
    prettifyJsonToggle,
    queriesState,
  };
};

export default useLogsPageState;
