import {
  useAlertsPageState,
  useApmErrorsPageState,
  useDashboardListPageState,
  useEventsPageState,
  useHydrationPageState,
  useKubernetesPageState,
  useLogsPageState,
  useMetricsCardinalityPageState,
  useMetricsExplorerPageState,
  useMetricsSummaryPageState,
  useServicesPageState,
  useTracesPageState,
} from 'hooks';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { DateSelection } from 'types';
import { parseUrlParamByKey } from 'utils';

type PageState = {
  // dependency array for url change
  dependenciesForWriteStateToUrl: any[];

  // function to write to url
  writeStateToUrl?: VoidFunction;
} & Record<string, unknown>;

const PageStateContext = createContext<Record<string, PageState>>({});

export const useApmErrorsPageStateContext = () =>
  useContext(PageStateContext).apmErrorsPageState as ReturnType<
    typeof useApmErrorsPageState
  >;

export const useServicesPageStateContext = () =>
  useContext(PageStateContext).servicesPageState as ReturnType<
    typeof useServicesPageState
  >;

export const useTracesPageStateContext = () =>
  useContext(PageStateContext).tracesPageState as ReturnType<
    typeof useTracesPageState
  >;

export const useRumPageStateContext = () =>
  useContext(PageStateContext).rumPageState as ReturnType<
    typeof useRumPageState
  >;

export const useEventsPageStateContext = () =>
  useContext(PageStateContext).eventsPageState as ReturnType<
    typeof useEventsPageState
  >;

export const useDashboardListPageStateContext = () =>
  useContext(PageStateContext).dashboardListPageState as ReturnType<
    typeof useDashboardListPageState
  >;

export const useAlertsPageStateContext = () =>
  useContext(PageStateContext).alertsPageState as ReturnType<
    typeof useAlertsPageState
  >;

export const useLogsPageStateContext = () =>
  useContext(PageStateContext).logsPageState as ReturnType<
    typeof useLogsPageState
  >;

export const useMetricsCardinalityPageStateContext = () =>
  useContext(PageStateContext).metricsCardinalityPageState as ReturnType<
    typeof useMetricsCardinalityPageState
  >;

export const useMetricsExplorerPageStateContext = () =>
  useContext(PageStateContext).metricsExplorerPageState as ReturnType<
    typeof useMetricsExplorerPageState
  >;

export const useMetricsSummaryPageStateContext = () =>
  useContext(PageStateContext).metricsSummaryPageState as ReturnType<
    typeof useMetricsSummaryPageState
  >;

export const useHydrationPageStateContext = () =>
  useContext(PageStateContext).hydrationPageState as ReturnType<
    typeof useHydrationPageState
  >;

export const useKubernetesPageStateContext = () =>
  useContext(PageStateContext).kubernetesPageState as ReturnType<
    typeof useKubernetesPageState
  >;

const getInitialApmDateState = (parsedParamValue: DateSelection | null) => {
  if (parsedParamValue && typeof parsedParamValue === 'object') {
    const { endTimeUnix, endLabel, startTimeUnix, startLabel } =
      parsedParamValue;
    if (endTimeUnix && startTimeUnix) {
      return {
        startLabel,
        endLabel,
        endTimeUnix,
        startTimeUnix,
      };
    }
  }

  return null;
};

export const PageStateProvider = ({ children }: { children: ReactNode }) => {
  const parsedParmValue = parseUrlParamByKey('apmDate');
  const parsedInitialApmDateState = getInitialApmDateState(parsedParmValue);

  const apmDateState = useState<DateSelection>(
    parsedInitialApmDateState || getDateFromRange('now-5m', 'now'),
  );

  const rumDateState = useState<DateSelection>(
    parseUrlParamByKey('rumDate') || getDateFromRange('now-5m', 'now'),
  );

  const metricDateState = useState<DateSelection>(
    parseUrlParamByKey('metricDate') || getDateFromRange('now-1h', 'now'),
  );

  const chartGridKeysState = useState<Record<string, string>>({});

  const apmErrorsPageState = useApmErrorsPageState({
    apmDateState,
  });

  const eventsPageState = useEventsPageState();
  const dashboardListPageState = useDashboardListPageState();
  const alertsPageState = useAlertsPageState();
  const logsPageState = useLogsPageState();
  const metricsCardinalityPageState = useMetricsCardinalityPageState();
  const metricsExplorerPageState = useMetricsExplorerPageState({
    metricDateState,
  });
  const metricsSummaryPageState = useMetricsSummaryPageState({
    metricDateState,
  });

  const servicesPageState = useServicesPageState({
    apmDateState,
    chartGridKeysState,
  });
  const tracesPageState = useTracesPageState({
    apmDateState,
    chartGridKeysState,
  });
  const rumPageState = useRumPageState({
    rumDateState,
  });

  const kubernetesPageState = useKubernetesPageState();
  const hydrationPageState = useHydrationPageState();

  return (
    <PageStateContext.Provider
      value={{
        eventsPageState,
        servicesPageState,
        tracesPageState,
        dashboardListPageState,
        alertsPageState,
        logsPageState,
        metricsCardinalityPageState,
        metricsExplorerPageState,
        metricsSummaryPageState,
        rumPageState,
        kubernetesPageState,
        hydrationPageState,
        apmErrorsPageState,
      }}
    >
      {children}
    </PageStateContext.Provider>
  );
};
