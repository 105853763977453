import { useToggle } from 'hooks';
import { useRef, useState } from 'react';
import { promqlQueryRangeV3 } from 'requests';
import { DashboardPanelType, DateSelection, QueryDataProps } from 'types';
import {
  anomalyDataTransformer,
  getRollupByVisualization,
  metricsDataTransformer,
} from 'utils';

import { AlertAnomalyQueryItem } from '../types';

const useAlertsAnomalyDataLoader = ({ date }: { date: DateSelection }) => {
  const userAction = useRef({ initLoaded: false });
  const [evaluationData, setEvaluationData] = useState<QueryDataProps>({});
  const [historicalData, setHistoricalData] = useState<QueryDataProps>({});
  const [innerQueryData, setInnerQueryData] = useState<QueryDataProps>({});

  const histLegendToggle = useToggle(true);
  const evalLegendToggle = useToggle(true);

  const handleLoadingHistorical = (
    queryType: AlertAnomalyQueryItem['queryType'],
    refId: string,
    isLoading: boolean,
  ) => {
    const queryId = `${queryType}_${refId}`;
    setHistoricalData({
      [queryId]: { isLoading, range: undefined, meta: undefined },
    });
  };

  const handleLoadingEvaluation = (
    queryType: AlertAnomalyQueryItem['queryType'],
    refId: string,
    isLoading: boolean,
  ) => {
    const queryId = `${queryType}_${refId}`;
    setEvaluationData({
      [queryId]: { isLoading, range: undefined, meta: undefined },
    });
  };

  const loadAnomalyData = async ({
    queryItem,
    promqls,
    innerData,
  }: {
    promqls: string[];
    queryItem: AlertAnomalyQueryItem;
    innerData: QueryDataProps;
  }) => {
    const { refId, queryType } = queryItem;
    if (!promqls || !Array.isArray(promqls)) return;

    handleLoadingEvaluation(queryType, refId, true);
    const baseTransformer = metricsDataTransformer();
    const isSplitRequired = promqls.length === 1;
    if (isSplitRequired) {
      baseTransformer.pop();
      baseTransformer.push({
        id: 'anomalyBandTransformer',
        func: anomalyDataTransformer,
      });
    }

    const meta = {
      refId: queryItem.refId,
      step: queryItem.step,
      type: DashboardPanelType.TIMESERIES,
      metricName: queryItem.metricName,
    };

    const datasets = await Promise.all(
      promqls.map((p) => {
        return promqlQueryRangeV3({
          date,
          promqlQuery: p,
          meta,
          addEncoding: false,
          transformer: baseTransformer,
        });
      }),
    );

    const flattenedData = isSplitRequired ? datasets.flat() : datasets;

    const queryId = `${queryType}_${refId}_anomaly`;
    const queryKeys = ['_upper', '_lower', ''];
    const newQueryDataAnomaly: QueryDataProps = {};
    newQueryDataAnomaly[`${queryId}${queryKeys[0]}`] = {
      isLoading: false,
      range: flattenedData[0],
      meta,
    };

    newQueryDataAnomaly[`${queryId}${queryKeys[1]}`] = {
      isLoading: false,
      range: flattenedData[1],
      meta,
    };

    newQueryDataAnomaly[`${queryId}${queryKeys[2]}`] =
      innerData[`${queryType}_${refId}`];

    setEvaluationData(newQueryDataAnomaly);
  };

  const loadInnerQueryData = async (
    queryItem: AlertAnomalyQueryItem,
  ): Promise<QueryDataProps> => {
    const { promql, refId, queryType } = queryItem;
    if (!promql) return;
    return new Promise(async (resolve, reject) => {
      handleLoadingEvaluation(queryType, refId, true);
      const baseTransformer = metricsDataTransformer();
      const meta = {
        refId,
        step: queryItem.step,
        type: DashboardPanelType.TIMESERIES,
        metricName: queryItem.metricName,
      };
      const innerQueryData: QueryDataProps['k']['range'] =
        await promqlQueryRangeV3({
          date,
          promqlQuery: promql,
          meta,
          addEncoding: false,
          transformer: baseTransformer,
        }).catch(() => {
          handleLoadingEvaluation(queryType, refId, false);
          reject();
        });

      if (!innerQueryData) {
        handleLoadingEvaluation(queryType, refId, false);
        reject();
        return;
      }

      const queryId = `${queryType}_${refId}`;
      resolve({
        [queryId]: { isLoading: false, range: innerQueryData, meta },
      });
    });
  };

  const loadHistoricalData = async (
    queryItem: AlertAnomalyQueryItem,
  ): Promise<number> => {
    return new Promise(async (resolve, reject) => {
      const { promql, refId, queryType } = queryItem;
      if (!promql) return;
      handleLoadingHistorical(queryType, refId, true);
      // date multiple four times to get the historical data
      const { startTimeUnix, endTimeUnix } = date;
      const duration = endTimeUnix - startTimeUnix;
      const newStartTime = startTimeUnix - duration * 4;
      const baseTransformer = metricsDataTransformer();
      const historicalData: QueryDataProps['k']['range'] =
        await promqlQueryRangeV3({
          date: { startTimeUnix: newStartTime, endTimeUnix },
          promqlQuery: promql,
          meta: {
            refId: queryItem.refId,
            step: getRollupByVisualization({
              startTimeUnix: newStartTime,
              endTimeUnix,
            }),
            type: DashboardPanelType.TIMESERIES,
            metricName: queryItem.metricName,
          },
          addEncoding: false,
          transformer: baseTransformer,
        }).catch(() => handleLoadingHistorical(queryType, refId, false));

      if (!historicalData) {
        handleLoadingHistorical(queryType, refId, false);
        reject();
        return;
      }

      const queryId = `${queryType}_${refId}`;
      setHistoricalData({
        [queryId]: { isLoading: false, range: historicalData, meta: undefined },
      });
      const seriesLength = historicalData.series.length;
      resolve(seriesLength);
    });
  };

  return {
    evaluationData,
    evalLegendToggle,
    innerQueryData,
    handleLoadingEvaluation,
    histLegendToggle,
    historicalData,
    loadInnerQueryData,
    loadHistoricalData,
    loadAnomalyData,
    setEvaluationData,
    setInnerQueryData,
    setHistoricalData,
    userAction,
  };
};

export default useAlertsAnomalyDataLoader;
