import { ChipWithLabel, ServiceWithLabels, TableColumnType } from 'components';
import { serviceTableKpis, ServiceTableKpiKeys } from 'kfuse-constants';
import React from 'react';
import { Link } from 'react-router-dom';
import { DateSelection, Service } from 'types';
import { KpisByServiceName } from './types';
import { ServicesTab, getBoundedValueForLatency } from './utils';

type Row = { serviceHash: string; serviceName: string };

type RenderCellProps = {
  row: Row;
  rows: Row[];
  value: any;
};

const getRenderCell = (kpi) => (args) => {
  if (args.value === undefined || isNaN(args.value)) {
    if (kpi.key === ServiceTableKpiKeys.errorRate) {
      return `${0}%`;
    }

    return '-';
  }

  return kpi.renderCell(args);
};

type Args = {
  activeTab?: ServicesTab;
  colorsByServiceHash: { [key: string]: string };
  date: DateSelection;
  isLatencyBoundedToMinMax: boolean;
  isLoadingState: Record<keyof KpisByServiceName, number>;
  kpisByServiceName: KpisByServiceName;
  search: string;
  serviceByHash: Record<string, Service>;
};

const getColumns = ({
  activeTab,
  colorsByServiceHash,
  isLatencyBoundedToMinMax,
  isLoadingState,
  kpisByServiceName,
  serviceByHash = {},
}: Args) => [
  {
    key: 'name',
    label: 'Name',
    renderCell: ({ row }: RenderCellProps) => {
      const { serviceHash, serviceName } = row;
      const service = serviceByHash[serviceHash];
      if (!service) {
        return <div className="flex">{serviceName}</div>;
      }

      const distinctLabels = service.distinctLabels || {};
      const serviceLabels = service.labels || {};

      const urlSearchParams = new URLSearchParams();
      if (activeTab === ServicesTab.db) {
        urlSearchParams.set('activeTab', ServicesTab.db);
      }

      return (
        <div className="flex">
          <ChipWithLabel
            color={colorsByServiceHash[serviceHash]}
            label={
              <Link
                className="link text--weight-medium services__table__link"
                to={`/apm/services/${serviceHash}?${urlSearchParams.toString()}`}
              >
                <ServiceWithLabels
                  name={serviceName}
                  distinctLabels={distinctLabels}
                  labels={serviceLabels}
                />
              </Link>
            }
          />
        </div>
      );
    },
    value: ({ row }) => {
      const service = serviceByHash[row.serviceHash];
      if (service) {
        const serviceLabelsString = Object.keys(service.labels)
          .sort()
          .map((key) => service.labels[key])
          .join(':');

        return `${service.name}:${serviceLabelsString}`;
      }

      return row.serviceHash;
    },
  },
  ...serviceTableKpis.map((kpi) => ({
    type: TableColumnType.NUMBER,
    key: kpi.key,
    label: kpi.label,
    renderCell: isLoadingState[kpi.key]
      ? () => <div className="spinner" />
      : getRenderCell(kpi),
    value: ({ row }) => {
      if (kpisByServiceName[row.serviceHash]) {
        const value = kpisByServiceName[row.serviceHash][kpi.key] || 0;
        return isLatencyBoundedToMinMax
          ? getBoundedValueForLatency({
              kpiKey: kpi.key,
              maxValue:
                kpisByServiceName[row.serviceHash][
                  ServiceTableKpiKeys.maxLatency
                ],
              minValue:
                kpisByServiceName[row.serviceHash][
                  ServiceTableKpiKeys.minLatency
                ],
              value,
            })
          : value;
      }

      return 0;
    },
  })),
];

export default getColumns;
