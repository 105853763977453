import { Loader, SearchInput, Table } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { convertNumberToReadableUnit } from 'utils/formatNumber';

import MetricsSummaryTagsValues from './MetricsSummaryTagsValues';
import useMetricsSummary from './useMetricsSummary';

const columns = (isLoading: boolean) => [
  { label: 'Tag Key', key: 'label' },
  {
    label: 'Count',
    key: 'count',
    renderCell: ({ row }: { row: { count: number } }) =>
      isLoading ? (
        <div className="spinner" />
      ) : (
        convertNumberToReadableUnit(row.count)
      ),
  },
];

const MetricsSummaryTags = ({
  metricSummaryState,
}: {
  metricSummaryState: ReturnType<typeof useMetricsSummary>;
}): ReactElement => {
  const {
    loadMetricsLabelValues,
    metricLabels,
    metricLabelValues,
    metricsLabelValueCardinalityRequest,
    promqlLabelValuesRequest,
    selectedMetric,
    selectedMetricLabel,
    seriesCountByMetricName,
    setSelectedMetricLabel,
    setTagSearch,
    tagSearch,
  } = metricSummaryState;

  const filteredTags = useMemo(() => {
    if (!selectedMetric) return;
    let tags: Array<{ label: string; count: number }> = [];
    if (metricLabels[selectedMetric]) {
      tags = [...metricLabels[selectedMetric]];
    }

    if (tagSearch) {
      tags = tags.filter((tag) => tag.label.includes(tagSearch));
    }

    if (tags.length > 0 && tags[tags.length - 1].label === 'labels') {
      tags.pop();
    }
    return tags;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagSearch, selectedMetric, metricLabels, metricLabelValues]);

  const filteredTagsValues = useMemo(() => {
    if (!selectedMetric || !selectedMetricLabel) return;

    let tagValues: string[] = [];
    if (metricLabelValues[selectedMetric]?.[selectedMetricLabel]) {
      tagValues = metricLabelValues[selectedMetric][selectedMetricLabel];
    }

    if (tagSearch) {
      tagValues = tagValues.filter(
        (tagValue) =>
          selectedMetricLabel.includes(tagSearch) ||
          tagValue.includes(tagSearch),
      );
    }
    return tagValues;
  }, [metricLabelValues, selectedMetricLabel, selectedMetric, tagSearch]);

  const activeRowId = useMemo(() => {
    if (!selectedMetric || !selectedMetricLabel) return;
    return filteredTags.findIndex((tag) => tag.label === selectedMetricLabel);
  }, [filteredTags, selectedMetric, selectedMetricLabel]);

  return (
    <>
      <div className="metrics-summary__body__details__tags">
        <div className="flex">
          <div className="metrics-summary__body__subtitle">
            {metricLabels[selectedMetric]
              ? `${convertNumberToReadableUnit(
                  metricLabels[selectedMetric].length,
                )} Unique`
              : null}{' '}
            Tags
          </div>
          {seriesCountByMetricName[selectedMetric] && (
            <div className="metrics-summary__body__subtitle">
              {convertNumberToReadableUnit(
                seriesCountByMetricName[selectedMetric],
              )}{' '}
              Series Cardinality
            </div>
          )}
        </div>
        <div>
          <SearchInput
            placeholder="Search tags..."
            onChange={(e) => setTagSearch(e)}
            value={tagSearch}
            type="text"
            containerClassName="mt-2 mb-2"
            size="2"
          />
        </div>
        <div className="metrics-summary__body__details__tags-table">
          <div className="metrics-summary__body__details__tags-table__table">
            <Table
              activeRowId={activeRowId}
              className="table--bordered table--bordered-cells metrics-summary__tags-table__table"
              columns={columns(metricsLabelValueCardinalityRequest.isLoading)}
              rows={filteredTags}
              rowClassName="metrics-summary__tags-table__table__row"
              onRowClick={({ row }) => {
                setSelectedMetricLabel(row.label);
                loadMetricsLabelValues(selectedMetric, row.label);
              }}
            />
          </div>
          {filteredTags && filteredTags.length && (
            <div className="metrics-summary__body__details__tags-table__values">
              <div className="metrics-summary__body__subtitle">Tag Values</div>
              <Loader
                className="metrics-summary__body__details__tags-table__values__items"
                isLoading={promqlLabelValuesRequest.isLoading}
              >
                {filteredTagsValues ? (
                  <MetricsSummaryTagsValues
                    metricName={selectedMetric}
                    tagName={selectedMetricLabel}
                    values={filteredTagsValues}
                  />
                ) : (
                  <div className="metrics-summary__body__details__tags-table__values__empty">
                    Please select a tag to view its values
                  </div>
                )}
              </Loader>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MetricsSummaryTags;
