import classNames from 'classnames';
import {
  AutocompleteOption,
  AutocompleteV2,
  Input,
  Loader,
  MultiselectV2,
} from 'components';
import React, { ReactElement, useCallback, useEffect } from 'react';
import {
  DashboardTemplateProps,
  DashboardTemplateValueProps,
} from 'types/Dashboard';

import DashboardLink from './DashboardLink';
import { useDashboardState, useDashboardTemplateState } from './hooks';
import { getReloadPanelsForTemplating } from './utils';

const getTemplateValuesForRepeat = ({
  template,
  templateIndex,
  templateOptions,
  templateValues,
}: {
  template: DashboardTemplateProps;
  templateIndex: number;
  templateOptions: AutocompleteOption[][];
  templateValues: DashboardTemplateValueProps;
}) => {
  const filterValues = templateValues[template.name];
  if (!filterValues) return [];

  if (template.multi) {
    if (filterValues.length === 1 && filterValues[0] === template.allValue) {
      return templateOptions[templateIndex]
        .filter((option) => option.value !== template.allValue && option.value)
        .map((option) => option.value);
    } else {
      return (filterValues as string[]) || [];
    }
  } else {
    if (filterValues === template.allValue) {
      return templateOptions[templateIndex]
        .filter((option) => option.value !== template.allValue && option.value)
        .map((option) => option.value);
    }
  }

  return ([filterValues] as string[]) || [];
};

const DashboardFilter = ({
  dashboardState,
  dashboardTemplateState,
  showReloadButton = false,
}: {
  dashboardState: ReturnType<typeof useDashboardState>;
  dashboardTemplateState: ReturnType<typeof useDashboardTemplateState>;
  showReloadButton?: boolean;
}): ReactElement => {
  const {
    handleTemplateRepeatedRows,
    panels,
    reloadPanels,
    setReloadPanels,
    userActionRef,
  } = dashboardState;
  const {
    isLoading,
    onTemplateChangeReload,
    setTemplateValues,
    templating,
    templateOptions,
    templateValues,
    setTemplating,
  } = dashboardTemplateState;

  const onTemplateFilterChange = useCallback(
    ({
      name,
      templateIndex,
      values,
    }: {
      name: string;
      templateIndex: number;
      values: string | string[];
    }) => {
      const newTemplateValues = { ...templateValues };
      const template = templating[templateIndex];

      if (typeof values === 'string') {
        newTemplateValues[name] = values;
      }

      if (Array.isArray(values)) {
        if (template.includeAll) {
          const lastValue = values[values.length - 1];

          const checkAllSelected = lastValue === template.allValue;
          if (checkAllSelected || values.length === 0) {
            newTemplateValues[name] = [template.allValue];
          } else {
            newTemplateValues[name] = values.filter(
              (value) => value !== template.allValue,
            );
          }
        } else {
          newTemplateValues[name] = values;
        }
      }

      const isRepeatedRowExist = panels.find((p) => p.repeat === template.name);
      if (isRepeatedRowExist) {
        const repeatValues = getTemplateValuesForRepeat({
          template,
          templateIndex,
          templateOptions,
          templateValues: newTemplateValues,
        });
        handleTemplateRepeatedRows(template, repeatValues);
      }

      const newReloadPanels = getReloadPanelsForTemplating(panels, name, {});
      setReloadPanels((prev) => ({ ...prev, ...newReloadPanels }));
      setTemplateValues(newTemplateValues);

      onTemplateChangeReload(name, newTemplateValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [panels, reloadPanels, templateValues],
  );

  const onTemplateFilterChangeTextinput = useCallback(
    ({
      name,
      templateIndex,
      value,
    }: {
      name: string;
      templateIndex: number;
      value: string;
    }) => {
      setTemplating((prev) => {
        const newTemplating = [...prev];
        newTemplating[templateIndex].current.text = value;
        return newTemplating;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onTemplateFilterChange],
  );

  const fireRepeatRowOnFilterOptionLoad = useCallback(() => {
    if (!templateOptions || templateOptions.length === 0) return;
    const repeatedTemplateBitmap: { [key: string]: string } = {};
    panels.forEach((p) => {
      if (p.repeat) {
        repeatedTemplateBitmap[p.repeat] = p.repeat;
      }
    });

    const usedRepeatedTemplate = Object.keys(repeatedTemplateBitmap);

    if (usedRepeatedTemplate.length === 0) return;
    usedRepeatedTemplate.forEach((templateName) => {
      const templateIndex = templating.findIndex(
        (t) => t.name === templateName,
      );
      if (templateIndex === -1) return;
      const template = templating[templateIndex];
      const repeatValues = getTemplateValuesForRepeat({
        template,
        templateIndex,
        templateOptions,
        templateValues,
      });
      handleTemplateRepeatedRows(template, repeatValues);
    });
  }, [
    handleTemplateRepeatedRows,
    panels,
    templateOptions,
    templateValues,
    templating,
  ]);

  useEffect(() => {
    fireRepeatRowOnFilterOptionLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateOptions]);

  useEffect(() => {
    if (!userActionRef.current.repeatedRowInitiated && panels.length > 0) {
      userActionRef.current.repeatedRowInitiated = true;
      fireRepeatRowOnFilterOptionLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panels]);

  return (
    <Loader className="w-full" isLoading={isLoading}>
      <div className="mr-2 flex flex-row justify-between">
        <div
          className={classNames({
            dashboard__filter: true,
            'dashboard__filter--loading': isLoading,
          })}
        >
          {templateOptions &&
            templateOptions.map(
              (labelValues: AutocompleteOption[], index: number) => {
                if (!templating[index]) {
                  return null;
                }
                const { label, hide, multi, name, type } = templating[index];
                if (hide === 2) return null; // 2 is the value for hide
                return (
                  <div key={index}>
                    <label>{label || name}</label>
                    {multi && (
                      <MultiselectV2
                        className="autocomplete__fixed-height-28"
                        components={{ ClearIndicator: null }}
                        onChange={(values: string[]) =>
                          onTemplateFilterChange({
                            name,
                            templateIndex: index,
                            values,
                          })
                        }
                        options={labelValues}
                        placeholder={label || name}
                        value={templateValues[name] || []}
                      />
                    )}
                    {multi === false && (
                      <AutocompleteV2
                        className="autocomplete__fixed-height-28"
                        components={{ ClearIndicator: null }}
                        onChange={(values: string) =>
                          onTemplateFilterChange({
                            name,
                            templateIndex: index,
                            values,
                          })
                        }
                        options={labelValues}
                        placeholder={label || name}
                        value={templateValues[name] || ''}
                      />
                    )}
                    {type === 'textbox' && (
                      <Input
                        className="dashboard__filter__textbox"
                        onChange={(val) =>
                          onTemplateFilterChangeTextinput({
                            name,
                            templateIndex: index,
                            value: val,
                          })
                        }
                        onBlur={() =>
                          onTemplateFilterChange({
                            name,
                            templateIndex: index,
                            values: templating[index].current.text,
                          })
                        }
                        placeholder={label || name}
                        type="text"
                        value={
                          templating[index].current.text ||
                          templateValues[name] ||
                          ''
                        }
                      />
                    )}
                  </div>
                );
              },
            )}
        </div>
        <DashboardLink
          dashboardState={dashboardState}
          dashboardTemplateState={dashboardTemplateState}
        />
      </div>
    </Loader>
  );
};

export default DashboardFilter;
