import uPlot, { Series } from 'uplot';
import { themeColorDark, themeColorLight } from 'utils/colors';
import { clearCanvasByContext, drawXaxisLine, isDevEnv } from 'utils';
import { UplotChartStyles, UplotExtended } from 'types';

import { UPlotConfig, ChartConfigProps } from '../types';
import {
  defaultTimeseriesTooltip,
  formatYAxis,
  getChartScaleConfig,
  getChartXAxisConfig,
  getChartYAxisWidth,
  getLineSeries,
  getNoDataSeries,
  getRawDataBySeriesIdx,
  getStackedOpts,
  getUplotChartBar,
  jsonInDomForTest,
} from '../utils';

const getBaseConfig = ({
  darkModeEnabled,
  unit,
}: {
  darkModeEnabled: boolean;
  unit: string;
}) => {
  const { dark05, dark12 } = themeColorDark;
  const { light03, light11 } = themeColorLight;
  const config: UPlotConfig = {
    width: 1000,
    height: 340,
    axes: [
      {},
      {
        size: 60,
        stroke: darkModeEnabled ? dark12 : light11,
        grid: {
          stroke: darkModeEnabled ? dark05 : light03,
          width: 0.8,
        },
        values: (u, vals, space) => formatYAxis(vals, unit),
      },
    ],
    cursor: {
      focus: { prox: 5 },
      drag: { x: true, y: false },
      x: false,
      y: false,
    },
    series: [],
    legend: { show: false },
    hooks: {},
  };
  config.addHook = (type, hook) => {
    if (!config.hooks[type]) {
      config.hooks[type] = [];
    }

    config.hooks[type]?.push(hook as any);
  };

  return config;
};

export const getConfig = ({
  bands = [],
  unit,
  chartStyles,
  darkModeEnabled,
  data,
  hooks,
  kfuseHook,
  layoutType,
  maxValue,
  minValue,
  plugins = [],
  series,
  size: { width: chartWidth, height: chartHeight },
  type,
  utcTimeEnabled,
}: ChartConfigProps): UPlotConfig => {
  const {
    fillOpacity,
    lineWidth,
    pointSize,
    showPoints,
    scaleDistribution,
    scaleYRange,
  } = chartStyles;
  const config = getBaseConfig({ darkModeEnabled, unit });
  const newConfig = {
    ...config,
    bands,
    plugins,
    height: chartHeight,
    width: chartWidth,
  };

  newConfig.addHook('draw', (u: uPlot) =>
    drawXaxisLine({ u, darkModeEnabled }),
  );

  if (type !== 'Stacked Bar') {
    newConfig.axes[1].size = getChartYAxisWidth({
      maxValue,
      minValue,
      unit,
      chartHeight,
      type,
      scaleDistribution,
    });
  }

  newConfig.scales = {
    ...getChartScaleConfig(scaleDistribution, type),
  };

  newConfig.axes[0] = {
    ...newConfig.axes[0],
    ...getChartXAxisConfig({ darkModeEnabled, utcTimeEnabled }),
  };

  if (scaleYRange) {
    newConfig.scales = {
      ...newConfig.scales,
      auto: false,
      y: { range: [scaleYRange.min, scaleYRange.max] },
    };
  }

  if (isDevEnv) {
    newConfig.addHook('draw', (u: uPlot) => jsonInDomForTest(u, type));
  }

  // set the config on the uPlot instance
  newConfig.addHook('init', (u: UplotExtended) => {
    u.darkModeEnabled = darkModeEnabled;
    u.layoutType = layoutType;
    u.chartType = type;
    u.getRawData = () => data;
    u.getRawDataBySeriesIdx = (seriesIndex: number, pointIndex: number) =>
      getRawDataBySeriesIdx(u, seriesIndex, pointIndex);
    u.scaleDistribution = scaleDistribution;
    u.utcTimeEnabled = utcTimeEnabled;
    u.clearCanvasByContext = clearCanvasByContext;
    u.getTooltipData = defaultTimeseriesTooltip;
    u.kfuseHook = kfuseHook;
  });

  if (hooks) {
    hooks.forEach(({ type, hook }) => {
      newConfig.addHook(type, hook);
    });
  }

  if (type === 'Line') {
    const newSeries = getLineSeries(
      series,
      fillOpacity,
      pointSize,
      showPoints,
      lineWidth,
    );
    newConfig.series = [...getNoDataSeries(series.length), ...newSeries];
    return newConfig;
  }

  const isScaledToPercent = scaleDistribution?.type === 'percent';
  if (type === 'Stacked Bar') {
    if (!data[0]?.length) {
      newConfig.series = [...getNoDataSeries(series.length)];
      return newConfig;
    }
    const {
      cursor,
      hooks,
      series: optsSeries,
    } = getStackedOpts(series, data, isScaledToPercent);
    const bars60_100 = getUplotChartBar(newConfig, data);
    const newSeries: Series[] = [];
    optsSeries?.forEach((s) => {
      newSeries.push({
        ...s,
        ...{ fill: s.stroke, paths: bars60_100 },
      });
    });

    newConfig.addHook('init', hooks?.init[0]);
    newConfig.cursor = {
      ...newConfig.cursor,
      ...cursor,
      ...{ focus: { prox: 10 } },
      points: { show: false },
    };

    const stackedMax = maxValue * newSeries.length;
    newConfig.axes[1].size = getChartYAxisWidth({
      maxValue: stackedMax,
      minValue,
      unit,
      chartHeight,
      type,
      scaleDistribution,
    });

    newConfig.series = [...getNoDataSeries(series.length), ...newSeries];
    return newConfig;
  }

  if (type === 'Area') {
    if (series.length === 0) {
      newConfig.series = [...getNoDataSeries(series.length)];
      return newConfig;
    }
    const newSeries: Series[] = [];
    const {
      cursor,
      hooks,
      series: optsSeries,
    } = getStackedOpts(series, data, isScaledToPercent);
    optsSeries?.forEach((s) => {
      newSeries.push({ ...s, ...{ fill: s.stroke } });
    });

    newConfig.addHook('init', hooks?.init[0]);
    newConfig.cursor = {
      ...newConfig.cursor,
      ...cursor,
      ...{ focus: { prox: 10 } },
      points: { show: false },
    };

    // reset the y axis size
    const stackedMax = maxValue * newSeries.length;
    newConfig.axes[1].size = getChartYAxisWidth({
      maxValue: stackedMax,
      minValue,
      unit,
      chartHeight,
      type,
      scaleDistribution,
    });

    newConfig.series = [...getNoDataSeries(series.length), ...newSeries];
    return newConfig;
  }
};

export const toggleLogScale = (
  config: UPlotConfig,
  scaleDistribution: UplotChartStyles['scaleDistribution'],
): UPlotConfig => {
  const newConfig = { ...config };
  if (scaleDistribution?.type === 'log') {
    newConfig.scales = {
      x: { time: true, auto: true },
      y: { distr: 3, auto: true, log: scaleDistribution.log || 2 },
    };
  } else {
    newConfig.scales = {};
  }

  return newConfig;
};
