import {
  Button,
  Loader,
  Paginator,
  Table,
  TableSearch,
  useModalsContext,
  usePaginator,
  useTableSearch,
  useTableSort,
} from 'components';
import { useRequest } from 'hooks';
import React, { useEffect } from 'react';

import LogsHydrationArchivesHydrate from './LogsHydrationArchivesHydrate';
import { getArchivesList } from './requests';
import { useNavigate } from 'react-router-dom';

const archivesColumns = (onHydrate: (archiveName: string) => void) => [
  { key: 'serialNumber', label: 'Id' },
  { key: 'name', label: 'Name' },
  {
    key: 'actions',
    label: '',
    renderCell: ({ row }: { row: { name: string } }) => {
      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': '80px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider table__row__actions__slider--no-color">
              <div className="alerts__contacts__table__actions">
                <div className="mr-1.5 flex items-center py-1">
                  <Button
                    variant="default"
                    size="sm"
                    onClick={() => onHydrate(row.name)}
                  >
                    Hydrate
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

const emptyArr = [];
const LogsHydrationArchives = () => {
  const getArchivesListRequest = useRequest(getArchivesList);
  const modal = useModalsContext();
  const navigate = useNavigate();

  useEffect(() => {
    getArchivesListRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHydrate = (archiveName: string) => {
    modal.push(
      <LogsHydrationArchivesHydrate
        archiveName={archiveName}
        close={() => modal.pop()}
        onSuccess={() => {
          navigate('/logs/hydration/jobs');
        }}
      />,
    );
  };

  const columns = archivesColumns(onHydrate);
  const archives = getArchivesListRequest.result || emptyArr;
  const tableSearch = useTableSearch({ rows: archives });
  const tableSort = useTableSort({ columns, rows: tableSearch.searchedRows });
  const paginator = usePaginator({
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  return (
    <div className="pt-4">
      <TableSearch
        className="dashboard__list__search"
        placeholder="Search Archives..."
        tableSearch={tableSearch}
        dataTestId="dashboard-list-search"
      />
      <Loader isLoading={getArchivesListRequest.isLoading}>
        <Table
          columns={columns}
          rows={paginator.paginatedRows}
          className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
          dataTestId="alerts-list-table"
          externalTableSort={tableSort}
          isSortingEnabled
        />
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
      </Loader>
    </div>
  );
};

export default LogsHydrationArchives;
