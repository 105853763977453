import dayjs from 'dayjs';
import { useTracesState } from 'hooks';
import { TraceErrorGroup } from 'types';
import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';
import { buildTracesFilter } from './utils';

const parseError = (error: string) => {
  const parsedError = JSON.parse(error);
  return parsedError;
};

type Args = {
  isServiceFromDatabasesList?: boolean;
  serviceHash?: string;
  tracesState: ReturnType<typeof useTracesState>;
};

const traceErrorGroups = async ({
  isServiceFromDatabasesList,
  serviceHash,
  tracesState,
}: Args): Promise<TraceErrorGroup[]> => {
  const {
    dateState,
    facetRegexState,
    keyExistsState,
    selectedFacetValuesByNameState,
    selectedFacetRangeByNameState,
  } = tracesState;

  const [date] = dateState;
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const facetRegex = facetRegexState.state;
  const keyExists = keyExistsState.state;
  const selectedFacetValuesByName = { ...selectedFacetValuesByNameState.state };
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;

  if (serviceHash) {
    if (isServiceFromDatabasesList) {
      selectedFacetValuesByName['kf_database_service_hash'] = {
        [serviceHash]: 1,
      };
    } else {
      selectedFacetValuesByName['service_hash'] = {
        [serviceHash]: 1,
      };
    }
  }

  return queryTraceService<TraceErrorGroup[], 'traceErrorGroups'>(`
    {
      traceErrorGroups (
        limit: 200,
        durationSecs: ${durationSecs},
        filter: ${buildTracesFilter({
          facetRegex,
          keyExists,
          selectedFacetRangeByName,
          selectedFacetValuesByName,
        })}
        timestamp: "${endTime.format()}",
      ) {
        errorGroupingKey
        errorType
        firstOccurrenceNs
        language
        lastOccurrenceNs
        message
        numOccurrences
      }
    }
  `).then((data) => data.traceErrorGroups || [], onPromiseError);
};

export default traceErrorGroups;
