import classnames from 'classnames';
import {
  FacetGroup,
  FacetPicker,
  IconWithLabel,
  LanguageIconWithLabel,
} from 'components';
import {
  useRequest,
  useSelectedFacetValuesByNameState,
  useToggle,
} from 'hooks';
import { groupByFacetName, iconsBySpanType } from 'kfuse-constants';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apmSeriesV2, traceLabelValuesV2 } from 'requests';
import { DateSelection, SelectedFacetValuesByName } from 'types';
import { getIsAsmChecked } from 'utils';
import helpText from '../../helpText';
import { getSpanTypeFilter, ServicesTab } from './utils';

const getUngroupedFacetNames = (isAsmChecked: boolean) =>
  isAsmChecked
    ? [
        { name: 'protocol', forceExpanded: true, renderName: () => 'Protocol' },
        { name: 'error', forceExpanded: false, renderName: () => 'Error' },
      ]
    : [
        {
          name: 'span_type',
          forceExpanded: true,
          renderName: () => 'Span Type',
        },
        {
          name: 'telemetry_sdk_language',
          forceExpanded: true,
          renderName: () => 'Language',
        },
        {
          name: 'service_version',
          forceExpanded: false,
          renderName: () => 'Version',
        },
      ];

const groupedFacetNames = [
  'display_container_name',
  'image_name',
  'image_tag',
  'short_image',
  'kube_app_component',
  'kube_app_instance',
  'kube_app_managed_by',
  'kube_app_name',
  'kube_app_part_of',
  'kube_app_version',
  'kube_cluster_name',
  'kube_container_name',
  'kube_cronjob',
  'kube_daemon_set',
  'kube_deployment',
  'kube_job',
  'kube_namespace',
  'kube_node_role',
  'kube_ownerref_kind',
  'kube_ownerref_name',
  'kube_replica_set',
  'kube_replication_controller',
  'kube_service',
  'kube_stateful_set',
  'oshift_deployment',
  'oshift_deployment_config',
  'persistentvolumeclaim',
  'pod_name',
  'pod_phase',
  'pod_uid',
  'container_id',

  'availability_zone',
  'instance_type',
  'project',
  'region',

  'host',
];

type BitmapType = {
  [key: string]: any;
  span_name: number;
  __name__: number;
};

const ignoredFacetNamesBitmap: BitmapType = {
  '': 1,
  span_name: 1,
  __name__: 1,
};

const getUngroupedFacetNamesBitmap = (isAsmChecked: boolean) =>
  getUngroupedFacetNames(isAsmChecked).reduce(
    (obj, facetName) => ({ ...obj, [facetName.name]: 1 }),
    {} as { [key: string]: 1 },
  );

const groupFacetNames =
  (selectedFacetNames: SelectedFacetValuesByName) =>
  (seriesResult: string[]) => {
    const isAsmChecked = getIsAsmChecked(selectedFacetNames);
    const ungroupedFacetNamesBitmap =
      getUngroupedFacetNamesBitmap(isAsmChecked);
    const groupedFacetNamesByGroup: {
      [group: string]: { [name: string]: 1 };
    } = {};
    const customFacetNamesBitmap: { [key: string]: 1 } = {};

    [...seriesResult, ...Object.keys(selectedFacetNames)]
      .filter((name) => !ignoredFacetNamesBitmap[name])
      .forEach((name) => {
        if (!ungroupedFacetNamesBitmap[name]) {
          if (groupByFacetName[name]) {
            const group = groupByFacetName[name];
            if (!groupedFacetNamesByGroup[group]) {
              groupedFacetNamesByGroup[group] = {};
            }

            groupedFacetNamesByGroup[group][name] = 1;
          } else {
            customFacetNamesBitmap[name] = 1;
          }
        }
      });

    return {
      customFacetNamesBitmap,
      groupedFacetNamesByGroup,
    };
  };

const getUsedFacetNamesBitmap = (isAsmChecked: boolean) =>
  [
    ...getUngroupedFacetNames(isAsmChecked).map((facetName) => facetName.name),
    ...groupedFacetNames,
  ].reduce((obj, name) => ({ ...obj, [name]: 1 }), {
    kf_source: 1,
    le: 1,
    service_name: 1,
  } as { [key: string]: 1 });

const customGroupByFacetName: { [key: string]: string } = {
  request_headers_Accept: 'http',
  request_headers_Host: 'http',
  query_type: 'pg',
  table_name: 'pg',
};

const getFacetsByGroup = (facetNames: string[], isAsmChecked: boolean) => {
  const result: { [key: string]: string[] } = {};
  const ungroupedFacets: string[] = [];
  facetNames.forEach((facetName) => {
    const group = customGroupByFacetName[facetName];
    if (group && isAsmChecked) {
      if (!result[group]) {
        result[group] = [];
      }

      result[group].push(facetName);
    } else {
      ungroupedFacets.push(facetName);
    }
  });

  return {
    result,
    ungroupedFacets,
  };
};

type Props = {
  date: DateSelection;
  isAsmChecked: boolean;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  serviceHash: string;
  serviceDistinctLabels?: Record<string, string>;
  showSidebarToggle: ReturnType<typeof useToggle>;
  telemetrySdkName: string;
  serviceTab?: string;
  isInServiceDetails?: boolean;
};

const ServicesSidebar = ({
  date,
  isAsmChecked,
  selectedFacetValuesByNameState,
  serviceHash,
  serviceDistinctLabels,
  showSidebarToggle,
  telemetrySdkName,
  serviceTab,
  isInServiceDetails,
}: Props) => {
  const navigate = useNavigate();
  const apmSeriesRequest = useRequest((args) =>
    apmSeriesV2(args).then(
      groupFacetNames(selectedFacetValuesByNameState.state),
    ),
  );

  const ungroupedFacetNames = getUngroupedFacetNames(isAsmChecked);
  const usedFacetNamesBitmap = useMemo(
    () => getUsedFacetNamesBitmap(isAsmChecked),
    [isAsmChecked],
  );

  const [lastRefreshedAt, setLastRefreshedAt] = useState(
    new Date().valueOf() / 1000,
  );

  const clearFacetHandler = (name: string) => () => {
    selectedFacetValuesByNameState.clearFacet(name);
  };

  const handlersByName = (name: string) => {
    if (name === 'kf_source') {
      const selected = selectedFacetValuesByNameState.state['kf_source']
        ? Object.keys(selectedFacetValuesByNameState.state['kf_source'])[0]
        : null;
      const onKfSourceSelection = (value: string) => {
        if (value !== selected) {
          if (value === 'knight') {
            navigate('/advanced-service-monitoring');
          } else {
            navigate('/apm/services');
          }
        }
      };
      return {
        excludeFacetValue: () => {},
        selectOnlyFacetValue: onKfSourceSelection,
        toggleFacetValue: onKfSourceSelection,
      };
    }

    return {
      excludeFacetValue: (value: string) => {
        selectedFacetValuesByNameState.excludeFacetValue({ name, value });
      },
      selectOnlyFacetValue: (value: string) => {
        selectedFacetValuesByNameState.selectOnlyFacetValue({ name, value });
      },
      toggleFacetValue: (value: string, allValues: Array<string>) => {
        selectedFacetValuesByNameState.toggleFacetPickerValueCheckbox({
          facetName: name,
          facetValueToToggle: value,
          allFacetValues: allValues,
        });
      },
    };
  };

  const renderValue = (name: string) => (value: string) => {
    if (name === 'span_type') {
      const icon = iconsBySpanType[value] || null;
      return <IconWithLabel icon={icon} label={value} />;
    }

    if (name === 'telemetry_sdk_language') {
      return <LanguageIconWithLabel label={value} language={value} />;
    }
    return value;
  };

  useEffect(() => {
    apmSeriesRequest
      .call({
        date,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByNameState.state,
          ...(serviceTab &&
            serviceTab === ServicesTab.db && {
              span_type: {},
            }),
        },
        spanTypeFilter: getSpanTypeFilter(serviceTab as ServicesTab),
        serviceHash,
      })
      .then(() => {
        setLastRefreshedAt(new Date().valueOf() / 1000);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, selectedFacetValuesByNameState.state, serviceHash, serviceTab]);

  const groupedFacetNamesByGroup =
    apmSeriesRequest.result?.groupedFacetNamesByGroup || {};

  type Params = {
    result: {
      [key: string]: string[];
    };
    ungroupedFacets: string[];
  };

  const facetsByGroup: Params = useMemo(() => {
    const customFacetNamesBitmap =
      apmSeriesRequest.result?.customFacetNamesBitmap || {};

    const customFacetNames = Object.keys(customFacetNamesBitmap)
      .filter(
        (name: string) =>
          typeof name === 'string' &&
          !name.startsWith('client_') &&
          !usedFacetNamesBitmap[name],
      )
      .sort();

    return getFacetsByGroup(customFacetNames, isAsmChecked);
  }, [apmSeriesRequest.result, isAsmChecked, usedFacetNamesBitmap]);

  const selectedFacetValuesByNameForTab = useMemo(() => {
    const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
    if (serviceTab && serviceTab === ServicesTab.db) {
      const clientSelectedFacetValuesByName: SelectedFacetValuesByName = {
        ...selectedFacetValuesByName,
        span_type: {},
      };
      return clientSelectedFacetValuesByName;
    }
    return selectedFacetValuesByName;
  }, [selectedFacetValuesByNameState.state, serviceTab]);

  const requestByLabelName = (name: string) => () =>
    traceLabelValuesV2({
      date,
      name,
      selectedFacetValuesByName: selectedFacetValuesByNameForTab,
      serviceHash,
      instant: true,
      filterUnknownServiceHash: true,
      spanTypeFilter: getSpanTypeFilter(serviceTab as ServicesTab),
    }).then((result) => {
      const resultToReturn = result.map(({ aggrVal, groupVal }) => {
        const value = groupVal[name];
        return {
          count: aggrVal.service_hash,
          value: value || '',
        };
      });
      return resultToReturn;
    });
  // new Promise((resolve) => {
  //   if (serviceNameByValueByName[name]) {
  //     resolve(
  //       Object.keys(serviceNameByValueByName[name]).map((value) => ({
  //         count: getCount(name, value),
  //         value: value,
  //       })),
  //     );
  //   }
  //
  //   resolve([]);
  // });

  const requestForKfSource = () => {
    const selectedFacetValuesByName = {
      ...selectedFacetValuesByNameState.state,
    };
    delete selectedFacetValuesByName['kf_source'];
    return traceLabelValuesV2({
      date,
      filterUnknownServiceHash: true,
      name: 'kf_source',
      selectedFacetValuesByName: selectedFacetValuesByNameForTab,
      serviceHash,
      spanTypeFilter: getSpanTypeFilter(serviceTab as ServicesTab),
    }).then((result) =>
      result.map(({ aggrVal, groupVal }) => {
        const value = groupVal['kf_source'];
        return {
          count: aggrVal.service_hash,
          value: value || '',
        };
      }),
    );
  };

  const renderHelp = (s: string) => {
    if (helpText.hasOwnProperty(s)) {
      return helpText[s];
    }

    return null;
  };

  const shouldExpandByDefault = (facetName: string): boolean =>
    Boolean(serviceDistinctLabels[facetName]) ||
    Boolean(selectedFacetValuesByNameState.state[facetName]);

  const renderFacet = (name: string): React.JSX.Element => {
    return (
      <FacetPicker
        clearFacet={clearFacetHandler(name)}
        disabled={
          Boolean(serviceDistinctLabels[name]) || name === 'kf_platform'
        }
        forceExpanded={shouldExpandByDefault(name)}
        key={name}
        lastRefreshedAt={lastRefreshedAt}
        name={name}
        info={renderHelp(name)}
        renderValue={renderValue(name)}
        request={requestByLabelName(name)}
        selectedFacetValues={selectedFacetValuesByNameState.state[name] || {}}
        {...handlersByName(name)}
      />
    );
  };

  const hideUngroupedFacets = useMemo(
    () => serviceTab === ServicesTab.db,
    [serviceTab],
  );

  return (
    <>
      <FacetPicker
        className={classnames({
          'services__sidebar__telemtry-type-facet-picker': true,
          'services__sidebar__telemtry-type-facet-picker--service-selected':
            serviceHash,
          'services__sidebar__telemtry-type-facet-picker--apm-checked':
            !isAsmChecked,
          'services__sidebar__telemtry-type-facet-picker--knight-checked':
            isAsmChecked,
        })}
        clearFacet={clearFacetHandler('kf_source')}
        forceExpanded
        isRadio
        lastRefreshedAt={lastRefreshedAt}
        name="kf_source"
        info={renderHelp('kf_source')}
        renderName={() => 'Telemetry Type'}
        renderValue={(value) =>
          value === 'apm'
            ? 'Distributed Tracing'
            : 'Advanced Service Monitoring'
        }
        request={requestForKfSource}
        selectedFacetRange={null}
        selectedFacetValues={
          selectedFacetValuesByNameState.state.kf_source || {}
        }
        {...handlersByName('kf_source')}
      />
      {hideUngroupedFacets
        ? null
        : ungroupedFacetNames.map(({ forceExpanded, name, renderName }) => (
            <FacetPicker
              clearFacet={clearFacetHandler(name)}
              disabled={Boolean(serviceDistinctLabels[name])}
              key={name}
              forceExpanded={forceExpanded}
              lastRefreshedAt={lastRefreshedAt}
              name={name}
              info={renderHelp(name)}
              renderName={renderName}
              renderValue={renderValue(name)}
              request={requestByLabelName(name)}
              selectedFacetValues={
                selectedFacetValuesByNameState.state[name] || {}
              }
              {...handlersByName(name)}
            />
          ))}
      <div className="left-sidebar__divider" />
      {Object.keys(groupedFacetNamesByGroup)
        .sort()
        .map((group, idx) => (
          <FacetGroup
            group={group}
            key={group}
            isLastListItem={
              idx === Object.keys(groupedFacetNamesByGroup).length - 1
            }
            forceExpanded={Object.keys(groupedFacetNamesByGroup[group]).some(
              shouldExpandByDefault,
            )}
          >
            {Object.keys(groupedFacetNamesByGroup[group])
              .sort()
              .map(renderFacet)}
          </FacetGroup>
        ))}
      <div className="left-sidebar__divider" />
      {Object.keys(facetsByGroup.result).map((group, idx) => (
        <FacetGroup
          group={group}
          key={group}
          isLastListItem={idx === Object.keys(facetsByGroup.result).length - 1}
          forceExpanded={facetsByGroup.result[group].some(
            shouldExpandByDefault,
          )}
        >
          {facetsByGroup.result[group].sort().map(renderFacet)}
        </FacetGroup>
      ))}
      <FacetGroup
        group="custom"
        isLastListItem={true}
        forceExpanded={facetsByGroup.ungroupedFacets.some(
          shouldExpandByDefault,
        )}
      >
        {facetsByGroup.ungroupedFacets
          .filter((facetName) => {
            const isServiceDetailsAndNotOpentelemetry =
              telemetrySdkName !== 'opentelemetry' && isInServiceDetails;

            const isTelemetrySdkNameFacet =
              facetName === 'telemetry_sdk_name'
                ? isServiceDetailsAndNotOpentelemetry
                : false;

            const shouldDisplayFacet =
              facetName !== 'service_hash' &&
              !isTelemetrySdkNameFacet &&
              !facetName.includes('error');

            return shouldDisplayFacet;
          })
          .map(renderFacet)}
      </FacetGroup>
    </>
  );
};

export default ServicesSidebar;
