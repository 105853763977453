import { SearchState } from 'hooks/useSearch';
import { SpanFilter } from 'types/SpanFilter';
import { ArithmeticParser, transformJSONToGraphql } from 'utils';
import buildTracesFilter from './buildTracesFilter';

const unQuoteOperation = [
  'Addition',
  'Subtraction',
  'Multiplication',
  'Division',
];
const buildAggregateTimeSeriesFormula = (
  expression: string,
  filter: string,
  queries: Array<SearchState>,
  spanFilter: SpanFilter,
) => {
  const queryBitmap: { [key: string]: any } = {};
  queries.forEach((query) => {
    const { searchBarState } = query;
    let filterPerQuery = null;
    if (searchBarState) {
      const {
        keyExists,
        facetRegex,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        selectedHcFacetValuesByName,
        traceIdSearch,
      } = searchBarState;
      filterPerQuery = buildTracesFilter({
        facetRegex,
        keyExists,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        selectedHcFacetValuesByName,
        spanFilter,
        traceIdSearch,
      });
    }
    const { measure, operation } = query;
    const shouldUseCount = operation === 'distinctcount' && !measure;
    queryBitmap[query.queryKey] = {
      aggregation: shouldUseCount ? 'count' : operation,
      aggregationField: measure ? measure : '*',
      filter: filterPerQuery ? filterPerQuery : filter,
    };
  });
  try {
    const parser = new ArithmeticParser();
    const formula = parser.parse(expression);
    const result = buildNestedFormula(formula, queryBitmap);
    const graphql = transformJSONToGraphql(result, unQuoteOperation, [
      'filter',
    ]);
    return graphql;
  } catch (e) {
    return new Error(e.message);
  }
};

const buildNestedFormula = (formula: any, queryBitmap: any) => {
  const { left, name, op, right, type, value } = formula;
  let result: any = {};

  if (type === 'BinaryExpr' || type === 'AddSubExpr') {
    result['binaryExpr'] = {
      op,
      leftExpr: buildNestedFormula(left, queryBitmap),
      rightExpr: buildNestedFormula(right, queryBitmap),
    };
  } else if (type === 'Variable') {
    result = { aggregationExpr: queryBitmap[name] };
  } else if (type === 'NumberLiteral') {
    result = { scalarExpr: value };
  }

  return result;
};

export default buildAggregateTimeSeriesFormula;
