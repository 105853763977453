import { Separator } from 'components/shadcn';
import { colorsByAlertState } from 'kfuse-constants';
import React, { ReactElement, useState } from 'react';

import { ConditionProps } from '../AlertsCreateCondition';
import { parseAlertsQueryAndConditions } from '../AlertsCreateMetrics/utils';
import AlertsDetailsPropertiesInfo from './AlertsDetailsPropertiesInfo';
import AlertsDetailsPropertiesExpression from './AlertsDetailsPropertiesExpression';
import { RuleProps } from '../types';

const AlertsDetailsPropertiesCompact = ({
  condition,
  hideTitle,
  properties,
  status,
}: {
  condition: ConditionProps;
  hideTitle?: boolean;
  properties: RuleProps;
  status: string;
}): ReactElement => {
  const [rule, setRule] = useState<RuleProps>(properties);
  const parsedMetadata = parseAlertsQueryAndConditions({
    data: rule.ruleData,
    annotations: rule.annotations,
  });

  return (
    <div>
      {!hideTitle && (
        <div className="alerts__details__properties__header">
          <div className="text-lg font-semibold">{rule.name}</div>
        </div>
      )}
      <div className="alerts__details__properties__summary box-shadow">
        <div className="alerts__details__subheader">
          <div className="text-base font-semibold">Properties</div>
          <div
            className="chip alerts__list__status-chip"
            style={{ backgroundColor: colorsByAlertState[status] }}
          >
            {status}
          </div>
        </div>
        <div className="flex pt-2">
          <AlertsDetailsPropertiesInfo
            condition={condition}
            parsedMetadata={parsedMetadata}
            rule={rule}
          />
          <Separator orientation="vertical" className="h-auto" />
          <AlertsDetailsPropertiesExpression
            hideExpression={true}
            parsedMetadata={parsedMetadata}
            rule={rule}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertsDetailsPropertiesCompact;
