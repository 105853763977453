import dayjs from 'dayjs';
import { DateSelection, SelectedFacetValuesByName, TimeSeries } from 'types';
import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { buildRumFilter } from './utils';
import { RumEventType } from '../types';

type Args = {
  aggregation: string;
  aggregationField: string;
  date: DateSelection;
  groupBys?: string[];
  eventType: RumEventType;
  idSearch?: string;
  instant?: boolean;
  rollUpSeconds: number;
  selectedFacetValuesByName: SelectedFacetValuesByName;
};

type Point = {
  ts: number;
  value: number;
};

const convertAggregateTimeSeries = ({
  aggregates,
}: {
  aggregates: number[];
}) => ({
  dimensions: {},
  points: aggregates.map((value, index) => ({
    ts: index,
    value,
  })),
});

const aggregateRumTable = async ({
  aggregation,
  aggregationField,
  date,
  groupBys = [],
  eventType,
  idSearch,
  instant = false,
  rollUpSeconds,
  selectedFacetValuesByName,
}: Args): Promise<TimeSeries[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  return queryRumService<TimeSeries[], 'aggregateRumTimeSeries'>(`
  query {
    aggregateTable(
        eventType: ${eventType},
        timestamp: "${endTime.format()}",
        durationSecs: ${durationSecs},
        aggregates: [
          {
            field: "${aggregationField}",
            aggregation: "${aggregation}",
          }
        ]
       filter: ${buildRumFilter({ idSearch, selectedFacetValuesByName })},
        groupBy: []
      ) {
        dimensions
        aggregates
      }
  }
  `).then(
    (data) => (data?.aggregateTable || []).map(convertAggregateTimeSeries),
    onPromiseError,
  );
};

export default aggregateRumTable;
