import React, { useEffect } from 'react';
import { DateSelection } from 'types';
import {
  DateControls,
  LeftSidebar,
  ProductTour,
  useLeftSidebarState,
} from 'components';
import Datepicker from 'composite/Datepicker';
import classnames from 'classnames';
import { useRequest, useSearchFormulas, useUrlState } from 'hooks';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import useDebouncedEffect from 'use-debounced-effect';
import RumChartGrid from './RumChartGrid';
import RumEventDrawer from './RumEventDrawer';
import RumSearch from './RumSearch/RumSearch';
import RumSidebar from './RumSidebar';
import RumTable from './RumTable';
import RumTimeseries from './RumAnalytics/RumTimeseries';
import EventTypeFilter from './EventTypeFilter';
import rumLabels from './requests/rumLabels';
import { RUMEvent, RumTab } from './types';

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
  rumTab: RumTab;
};

const Rum = ({ rumPageState, rumTab }: Props) => {
  const [activeRumEvent, setActiveRumEvent] = useUrlState<RUMEvent>(
    'activeRumEvent',
    null,
  );

  const {
    dependenciesForWriteStateToUrl,
    searches,
    rumState,
    writeStateToUrl,
  } = rumPageState;

  const queries = searches.map((search) => search.state);
  const searchesFormulas = useSearchFormulas(queries);

  const search = searches[0];

  const leftSidebarState = useLeftSidebarState('rum');
  const rumLabelNamesRequest = useRequest(rumLabels);

  const { dateState, eventType } = rumState;

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  const [date, setDate] = dateState;

  useDebouncedEffect(
    () => {
      rumLabelNamesRequest.call({ eventType });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      timeout: 50,
      ignoreInitialCall: false,
    },
    [eventType],
  );

  const handleRumEventDrawerClose = () => {
    setActiveRumEvent(null);
  };

  return (
    <div
      className={classnames({
        rum: true,
        'relative flex flex-row w-full overflow-hidden': true,
      })}
    >
      <LeftSidebar leftSidebarState={leftSidebarState}>
        <RumSidebar
          rumLabelNamesRequest={rumLabelNamesRequest}
          rumState={rumState}
        />
      </LeftSidebar>

      <div className="rum__main  flex flex-col relative overflow-auto">
        <div className="rum__header flex flex-row items-start justify-end">
          <div className="flex flex-col flex-grow overflow-hidden">
            <RumSearch
              leftSidebarState={leftSidebarState}
              placeholder="Search"
              searches={searches}
              searchesFormulas={searchesFormulas}
              rumLabelNamesRequest={rumLabelNamesRequest}
              rumState={rumState}
              rumTab={rumTab}
            />
          </div>
          <div className="rum__header__right">
            <div className="flex flex-row items-center justify-end">
              <Datepicker onChange={setDate} value={date as DateSelection} />
              <DateControls date={date} setDate={setDate} />
            </div>
            <div className="flex flex-row justify-end mt-3">
              <EventTypeFilter rumState={rumState} />
            </div>
          </div>
        </div>
        {rumTab === RumTab.list ? <RumChartGrid rumState={rumState} /> : null}
        <div className="rum__main flex flex-col relative overflow-auto">
          {rumTab === RumTab.list ? (
            <RumTable
              setActiveRumEvent={setActiveRumEvent}
              rumState={rumState}
            />
          ) : null}
          {rumTab !== RumTab.list ? (
            <RumTimeseries
              searches={searches}
              rumState={rumState}
              rumTab={rumTab}
            />
          ) : null}
        </div>
      </div>
      {activeRumEvent ? (
        <RumEventDrawer
          activeRumEvent={activeRumEvent}
          close={handleRumEventDrawerClose}
          eventType={eventType}
        />
      ) : null}
      {<ProductTour />}
    </div>
  );
};

export default Rum;
