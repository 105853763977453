import { CursorStateProvider } from 'components';
import React, { ReactElement, useEffect } from 'react';
import { AnomalyAlgorithmType, DateSelection, QueryDataProps } from 'types';

import AlertsChartAnomalySplit from './AlertsChartAnomalySplit';
import { getAnomalyAlertsLoadPromQL } from '../AlertsCreateMetrics/utils';
import { useAlertsAnomalyDataLoader } from '../hooks';
import { AlertAnomalyQueryItem } from '../types';
import { AnomalyConditionProps } from '../AlertsCreateCondition';

const evaluationPromql = (
  anomalyCondition: AnomalyConditionProps,
  promql: string,
): boolean => {
  const promqls = getAnomalyAlertsLoadPromQL({
    anomalyCondition,
    query: promql,
  });

  if (!promqls || promqls.length === 0) return false;
  return true;
};

const AlertsChartAnomalyPromql = ({
  anomalyCondition,
  baseWidth,
  date,
  forWindow,
  isEditing = false,
  queryItem,
  unit = 'number',
}: {
  anomalyCondition: AnomalyConditionProps;
  baseWidth: number;
  date: DateSelection;
  isEditing?: boolean;
  forWindow?: string;
  queryItem: AlertAnomalyQueryItem;
  unit?: string;
}): ReactElement => {
  const { anomalyAlgorithm } = anomalyCondition;

  const anomalyDataState = useAlertsAnomalyDataLoader({ date });
  const {
    handleLoadingEvaluation,
    innerQueryData,
    loadAnomalyData,
    loadInnerQueryData,
    loadHistoricalData,
    setInnerQueryData,
    userAction,
  } = anomalyDataState;

  const loadAnomalyAlertChartData = (innerData: QueryDataProps) => {
    if (!anomalyAlgorithm) return;
    const promqls = getAnomalyAlertsLoadPromQL({
      anomalyCondition,
      query: queryItem.promql,
    });

    if (
      anomalyAlgorithm === AnomalyAlgorithmType.AGILE ||
      anomalyAlgorithm === AnomalyAlgorithmType.ROBUST ||
      anomalyAlgorithm === AnomalyAlgorithmType.AGILE_ROBUST
    ) {
      loadAnomalyData({ queryItem, promqls, innerData });
    }

    if (anomalyAlgorithm === AnomalyAlgorithmType.BASIC) {
      const basicPromql = promqls.slice(0, -1);
      loadAnomalyData({ queryItem, promqls: basicPromql, innerData });
    }
  };

  useEffect(() => {
    if (!userAction.current.initLoaded) return;
    loadAnomalyAlertChartData(innerQueryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anomalyCondition]);

  useEffect(() => {
    if (!queryItem.promql) return;
    const { promql, refId, queryType } = queryItem;
    const isEvaluate = evaluationPromql(anomalyCondition, promql);
    loadHistoricalData(queryItem);

    handleLoadingEvaluation(queryType, refId, true);
    loadInnerQueryData(queryItem)
      .then((innerData: QueryDataProps) => {
        userAction.current.initLoaded = true;
        setInnerQueryData(innerData);
        if (!isEvaluate) {
          handleLoadingEvaluation(queryType, refId, false);
          return;
        }
        loadAnomalyAlertChartData(innerData);
      })
      .catch(() => {
        handleLoadingEvaluation(queryType, refId, false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, queryItem.promql]);

  return (
    <CursorStateProvider>
      <AlertsChartAnomalySplit
        anomalyDataState={anomalyDataState}
        anomalyCondition={anomalyCondition}
        baseWidth={baseWidth}
        date={date}
        forWindow={forWindow}
        queryItem={queryItem}
        unit={unit}
      />
    </CursorStateProvider>
  );
};

export default AlertsChartAnomalyPromql;
