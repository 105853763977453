import classnames from 'classnames';
import { usePortalContext } from 'components';
import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import exhaustiveSwitchError from 'utils/exhaustiveSwitchError';
import * as Shadcn from '../shadcn';

type Props = {
  asChild?: boolean;
  children: ReactNode;
  className?: string;
  contentClassName?: string;
  collisionBoundaryRef?: React.MutableRefObject<HTMLElement>;
  delayDuration?: number;
  skipDelayDuration?: number;
  tooltip: ReactNode;
  variant?: 'default' | 'compact';
} & DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const TooltipTrigger = ({
  asChild,
  children,
  className,
  contentClassName,
  collisionBoundaryRef,
  delayDuration,
  onClick,
  skipDelayDuration,
  tooltip,
  variant = 'default',
  ...props
}: Props): React.ReactElement => {
  const portalContext = usePortalContext();
  const renderedTooltip =
    typeof tooltip === 'function' ? tooltip({ close, state: {} }) : tooltip;

  if (!renderedTooltip) {
    return (
      <div className={classnames({ [className]: className })}>{children}</div>
    );
  }

  switch (variant) {
    case 'default':
      return (
        <Shadcn.TooltipProvider
          delayDuration={delayDuration}
          skipDelayDuration={skipDelayDuration}
        >
          <Shadcn.Tooltip>
            <Shadcn.TooltipTrigger
              className={classnames('h-full tooltip-trigger', className)}
              onClick={onClick}
              {...props}
            >
              {children}
            </Shadcn.TooltipTrigger>
            <Shadcn.TooltipPortal container={portalContext.portalRef.current}>
              <Shadcn.TooltipContent
                variant={variant}
                className={classnames(
                  'tooltip-trigger__content',
                  contentClassName,
                )}
              >
                {renderedTooltip}
              </Shadcn.TooltipContent>
            </Shadcn.TooltipPortal>
          </Shadcn.Tooltip>
        </Shadcn.TooltipProvider>
      );
    case 'compact':
      return (
        <Shadcn.TooltipProvider
          delayDuration={delayDuration}
          skipDelayDuration={skipDelayDuration}
        >
          <Shadcn.Tooltip>
            <Shadcn.TooltipTrigger
              onClick={onClick}
              className={className}
              asChild={asChild}
            >
              <div className="flex items-center justify-center">{children}</div>
            </Shadcn.TooltipTrigger>
            <Shadcn.TooltipPortal container={portalContext.portalRef.current}>
              <Shadcn.TooltipContent
                className="tooltip-trigger__content"
                variant={variant}
                sideOffset={8}
                collisionBoundary={collisionBoundaryRef?.current}
              >
                <Shadcn.TooltipArrow
                  style={{ fill: 'var(--ui-background-shade)' }}
                />
                {renderedTooltip}
              </Shadcn.TooltipContent>
            </Shadcn.TooltipPortal>
          </Shadcn.Tooltip>
        </Shadcn.TooltipProvider>
      );
    default:
      throw exhaustiveSwitchError(variant);
  }
};

export default TooltipTrigger;
