import { useEffect, useRef } from 'react';
import { defaultLogsTableColumnsBitmap } from '../constants';
import { useDebouncedDeepCompareEffect } from 'hooks';

const useQuerySchedulerEffect = ({
  cb,
  logsState,
  queryScheduler,
  sort,
  tab,
  tabFetchedRef,
}) => {
  const hasBeenFetched = (tab: string) => {
    return Boolean(tabFetchedRef?.current[tab]);
  };
  const setTabFetched = (tab: string) => {
    tabFetchedRef.current[tab] = new Date().valueOf();
  };

  const clearTabFetched = () => {
    tabFetchedRef.current = {};
  };

  const firstLoadRef = useRef<boolean>(false);
  const {
    date,
    filterByFacets,
    filterOrExcludeByFingerprint,
    keyExists,
    searchTerms,
    selectedFacetRanges,
    selectedFacetValues,
  } = logsState;

  useDebouncedDeepCompareEffect(
    () => {
      if (firstLoadRef.current) {
        queryScheduler.setStartTime();
        const callId = queryScheduler.callIdRef.current;
        cb().finally(() => {
          clearTabFetched();

          if (callId === queryScheduler.callIdRef.current) {
            queryScheduler.setFirstQueryCompletedAt({
              zoomHasBeenUpdated: false,
            })();
          }
        });
      }
    },
    400,
    [
      date.startTimeUnix,
      date.endTimeUnix,
      date.forceRefreshedAt,
      filterByFacets,
      filterOrExcludeByFingerprint,
      keyExists,
      searchTerms,
      selectedFacetRanges,
      selectedFacetValues,
    ],
  );

  useEffect(() => {
    if (firstLoadRef.current) {
      const callId = queryScheduler.callIdRef.current;
      cb().finally(() => {
        if (callId === queryScheduler.callIdRef.current) {
          queryScheduler.setFirstQueryCompletedAt({
            zoomHasBeenUpdated: true,
          })();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.zoomedStartTimeUnix, date.zoomedEndTimeUnix]);

  useEffect(() => {
    if (firstLoadRef.current && defaultLogsTableColumnsBitmap[sort.sortBy]) {
      cb();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  useEffect(() => {
    firstLoadRef.current = true;
    if (!hasBeenFetched(tab)) {
      const allowQueriesToContinue = Boolean(queryScheduler.endTimeMs);
      queryScheduler.setStartTime();
      const callId = queryScheduler.callIdRef.current;
      cb().finally(() => {
        if (callId === queryScheduler.callIdRef.current) {
          setTabFetched(tab);
          queryScheduler.setFirstQueryCompletedAt({
            allowQueriesToContinue,
            zoomHasBeenUpdated: false,
          })();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    clearTabFetched,
  };
};

export default useQuerySchedulerEffect;
