import { ChartGridV2 } from 'components';
import { Filter, useSearch } from 'hooks';
import React, { useMemo } from 'react';
import { DateSelection, SelectedFacetValuesByName, TimeSeries } from 'types';
import {
  DataFrameMeta,
  convertNumberToReadableUnit,
  formatAggregateTimeSeriesData,
  getRollupByVisualization,
} from 'utils';
import aggregateRumTimeSeries from './requests/aggregateRumTimeSeries';
import useRumState from './hooks/useRumState';
import { RumEventType } from './types';

type GetRowArgs = {
  eventType: RumEventType;
  filters: Filter[];
  idSearch: string;
  search: ReturnType<typeof useSearch>;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  setDate: (date: DateSelection) => void;
};

const getRows = ({
  eventType,
  filters,
  idSearch,
  selectedFacetValuesByName,
  search,
  setDate,
}: GetRowArgs) => {
  const onSelection = (startTimeUnix: number, endTimeUnix: number) => {
    setDate({ startTimeUnix, endTimeUnix });
  };

  const { rollUpInSeconds } = search.state;

  const rumQueries = {
    isActive: true,
    type: 'query',
    queryKey: 'query_a',
    query: {
      ...search.state,
      searchBarState: {
        filters,
      },
    },
  };

  return [
    [
      {
        charts: [
          {
            key: 'requests',
            chartType: 'bar',
            colorMap: { count: '#26BBF0' },
            label: 'Count',
            renderTotalCount: (totalCount: number) =>
              convertNumberToReadableUnit(totalCount),
            totalKey: 'count',
            onSelection,
            //TODO: move this in Next PR with RUM Analytics
            traceQueries: [rumQueries],
            query: ({ date }: { date: DateSelection }) =>
              aggregateRumTimeSeries({
                aggregation: 'count',
                aggregationField: '*',
                date,
                eventType,
                rollUpSeconds:
                  rollUpInSeconds || getRollupByVisualization(date, 'bar'),
                idSearch,
                selectedFacetValuesByName,
              }).then((data: TimeSeries[]) => {
                const points = data?.[0]?.points || [];
                return formatAggregateTimeSeriesData({
                  date,
                  points: points.map((point) => ({
                    Value: point.value,
                    BucketStart: Math.floor(point.ts / 1000),
                    GroupVal: {},
                  })),
                  step:
                    rollUpInSeconds || getRollupByVisualization(date, 'bar'),
                  fillMissingTimestamps: true,
                });
              }),
          },
        ],
      },
    ],
  ];
};

type Props = {
  rumState: ReturnType<typeof useRumState>;
};

const RumChartGrid = ({ rumState }: Props) => {
  const {
    dateState,
    eventType,
    filtersState,
    idSearch,
    selectedFacetValuesByNameState,
  } = rumState;
  const [date, setDate] = dateState;

  const search = useSearch();
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;

  const rows = useMemo(
    () =>
      getRows({
        eventType,
        filters: filtersState.state,
        idSearch,
        search,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        setDate,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, eventType, idSearch, search.state, selectedFacetValuesByName],
  );

  return (
    <div className="rum__chart-grid" data-testid="rum_chart_grid">
      <ChartGridV2.ChartGrid date={date} rows={rows} />
    </div>
  );
};

export default RumChartGrid;
