import { useToaster } from 'components';
import { RESERVED_CONTACT_POINTS } from 'kfuse-constants';
import { useRequest } from 'hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getGrafanaAlertManager, mutateGrafanaContactPoints } from 'requests';

import {
  getContactPointSettings,
  validateContactRequiredFields,
} from '../utils';
import {
  ContactNotifierType,
  ReceiverConfigProps,
  ReceiverManagerConfigProps,
  ReceiverProps,
} from '../types';

const useAlertsContactsCreate = (onClose: (name?: string) => void) => {
  const { addToast } = useToaster();
  const navigate = useNavigate();
  const grafanaAlertManagerRequest = useRequest(getGrafanaAlertManager);
  const mutateContactPointsRequest = useRequest(mutateGrafanaContactPoints);
  const [error, setError] = useState<{ name?: string }>({});

  const getContactPointsCreatePayload = ({
    contactPointName,
    getNotifierData,
    selectedNotifierData,
    selectedNotifierTypes,
  }: {
    contactPointName: string;
    getNotifierData: (notifierType: string) => ContactNotifierType;
    selectedNotifierData: Array<{ [key: string]: string }>;
    selectedNotifierTypes: string[];
  }): ReceiverProps => {
    const contactPoints: any = [];
    selectedNotifierTypes.forEach((notifierType, index) => {
      const { options } = getNotifierData(notifierType);
      const selectedData = selectedNotifierData[index];
      const { settings, secureSettings } = getContactPointSettings(
        options,
        selectedData,
      );

      contactPoints.push({
        settings,
        secureSettings,
        type: notifierType,
        name: contactPointName,
        disableResolveMessage: false,
      });
    });

    const prepData = {
      name: contactPointName,
      grafana_managed_receiver_configs: contactPoints,
    };
    return prepData;
  };

  const getRouteMatchers = (contactPointName: string): any => {
    return {
      continue: true,
      group_by: [],
      mute_time_intervals: [],
      receiver: contactPointName,
      object_matchers: [[contactPointName, '=', 'true']],
      routes: [],
    };
  };

  const validateContactPoint = ({
    contactPointName,
    dependencyRelation,
    getNotifierData,
    secureFieldsArray,
    selectedNotifierTypes,
    selectedNotifierData,
  }: {
    contactPointName: string;
    dependencyRelation: Record<string, string[]>;
    getNotifierData: (notifierType: string) => ContactNotifierType;
    secureFieldsArray: Array<Record<string, boolean>>;
    selectedNotifierTypes: string[];
    selectedNotifierData: Array<{ [key: string]: string }>;
  }) => {
    if (!contactPointName) {
      setError({ name: 'Contact point name is required' });
      return false;
    }

    const regex = /^[a-zA-Z0-9_-]+$/;
    if (!regex.test(contactPointName)) {
      setError({
        name: 'Contact point name must be alphanumeric, underscore or hyphen',
      });
      return false;
    }

    if (RESERVED_CONTACT_POINTS.includes(contactPointName)) {
      setError({
        name: 'Contact point name is reserved',
      });
      return false;
    }

    const checkRequiredFields = validateContactRequiredFields({
      dependencyRelation,
      getNotifierData,
      secureFieldsArray,
      selectedNotifierData,
      selectedNotifierTypes,
    });

    if (!checkRequiredFields) {
      addToast({ text: 'Required fields are missing', status: 'error' });
      return false;
    }

    return true;
  };

  const mutateContactPoint = ({
    contactPointName,
    dependencyRelation,
    getNotifierData,
    secureFieldsArray,
    selectedNotifierTypes,
    selectedNotifierData,
    type,
  }: {
    contactPointName: string;
    dependencyRelation: Record<string, string[]>;
    getNotifierData: (notifierType: string) => ContactNotifierType;
    secureFieldsArray: Array<Record<string, boolean>>;
    selectedNotifierTypes: string[];
    selectedNotifierData: Array<{ [key: string]: string }>;
    type: 'create' | 'update';
  }) => {
    const data = {
      contactPointName,
      dependencyRelation,
      getNotifierData,
      secureFieldsArray,
      selectedNotifierTypes,
      selectedNotifierData,
    };

    if (!validateContactPoint(data)) {
      return;
    }

    grafanaAlertManagerRequest
      .call()
      .then((result: ReceiverManagerConfigProps) => {
        const alertManager = result.alertmanager_config;
        let successMessage = `Contact point ${contactPointName} was successfully created`;
        const createPayload = getContactPointsCreatePayload(data);
        if (type === 'create') {
          const isContactPointExists = alertManager.receivers.some(
            (receiver: ReceiverProps) => receiver.name === contactPointName,
          );
          if (isContactPointExists) {
            setError({ name: 'Contact point already exists' });
            addToast({ text: 'Contact point already exists', status: 'error' });
            return;
          }
          const routesPayload = getRouteMatchers(contactPointName);
          alertManager.receivers.push(createPayload);
          if (!alertManager.route.routes) {
            alertManager.route.routes = [];
          }
          alertManager.route.routes.push(routesPayload);
        } else {
          const contactPointIndex = alertManager.receivers.findIndex(
            (receiver: ReceiverProps) => receiver.name === contactPointName,
          );
          const contactPoints: ReceiverProps =
            alertManager.receivers[contactPointIndex];

          contactPoints.grafana_managed_receiver_configs.forEach(
            (point: ReceiverConfigProps, index: number) => {
              createPayload.grafana_managed_receiver_configs[index].uid =
                point.uid;
            },
          );

          alertManager.receivers[contactPointIndex] = createPayload;
          successMessage = `Contact point ${name} was successfully updated`;
        }

        mutateContactPointsRequest
          .call({ alertmanager_config: alertManager })
          .then(() => {
            addToast({ text: successMessage, status: 'success' });
            onClose && onClose(contactPointName);
            navigate(`/alerts/contacts`);
          });
      });
  };

  return {
    error,
    grafanaAlertManagerRequest,
    mutateContactPoint,
    mutateContactPointsRequest,
    setError,
  };
};

export default useAlertsContactsCreate;
