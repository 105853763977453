import dayjs from 'dayjs';
import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { RumEventType } from '../types';

type Args = {
  eventTimeUnix: number;
  eventId: string;
  eventType: RumEventType;
};

const rawEvent = async ({
  eventTimeUnix,
  eventId,
  eventType,
}: Args): Promise<JSON> => {
  const eventTime = dayjs(eventTimeUnix);

  return queryRumService<JSON, 'rawEvent'>(`
  query {
    rawEvent(
      eventType: ${eventType},
      timestamp: "${eventTime.toISOString()}"
      eventId: "${eventId}"
    )
  }
  `).then((data) => data?.rawEvent || null, onPromiseError);
};

export default rawEvent;
