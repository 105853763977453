import { AutocompleteV2, DateControls } from 'components';
import { Datepicker } from 'composite';
import { logsTopBottomCountOptions } from 'kfuse-constants/logsAnalytics';
import React, { ReactElement } from 'react';
import { components } from 'react-select';

import TracesCardinalityLabel from './TracesCardinalityLabel';
import TracesCardinalityTable from './TracesCardinalityTable';
import useTracesCardinality from './useTracesCardinality';

const TracesCardinality = (): ReactElement => {
  const tracesCardinality = useTracesCardinality();
  const {
    date,
    onDateChange,
    tracesCardinalityQuery,
    updateTracesCardinalityQuery,
  } = tracesCardinality;
  const { limit, type } = tracesCardinalityQuery;

  return (
    <div className="metrics-cardinality">
      <div className="flex--justify-content-between flex ">
        <div className="new-metrics__header__title">
          Trace Attributes Cardinality
        </div>
        <div className="new-metrics__header__date-picker">
          <Datepicker
            className="logs__search__datepicker"
            hasStartedLiveTail={false}
            onChange={onDateChange}
            startLiveTail={null}
            quickRangeOptions={[
              { label: 'Last 5 minutes', from: 'now-5m', to: 'now' },
            ]}
            value={date}
          />
          <DateControls date={date} setDate={onDateChange} />
        </div>
      </div>
      <div
        className="search__grouper search__button-group"
        data-testid="search-grouper"
      >
        <div className="button-group">
          <div className="button-group__item button-group__item--label">
            Show Attributes
          </div>
          <div
            className="button-group__item button-group__item--value"
            data-testid="attributes-dropdown"
          >
            <AutocompleteV2
              className="autocomplete-container--no-border autocomplete__fixed-height-30"
              components={{
                SingleValue: ({ ...prop }) => {
                  const propLabel = prop.selectProps.value.label;
                  return (
                    <components.SingleValue {...prop}>
                      of {propLabel}
                    </components.SingleValue>
                  );
                },
              }}
              isSearchable={false}
              options={[
                { label: 'Low Cardinality', value: 'Lc' },
                { label: 'High Cardinality', value: 'Hc' },
              ]}
              onChange={(value: string) =>
                updateTracesCardinalityQuery('type', value)
              }
              value={type}
            />
          </div>
        </div>
        <div className="search__button-group__divider">
          <div />
        </div>
        <TracesCardinalityLabel tracesCardinality={tracesCardinality} />
        <div className="button-group">
          <div className="button-group__item button-group__item--label">
            limit to
          </div>
          <div
            className="button-group__item button-group__item--value"
            data-testid="direction-attribute"
          >
            <AutocompleteV2
              className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
              components={{ DropdownIndicator: null }}
              isSearchable={false}
              onChange={(val) =>
                updateTracesCardinalityQuery('limit', {
                  ...limit,
                  direction: val,
                })
              }
              options={[
                { label: 'top', value: 'topk' },
                { label: 'bottom', value: 'bottomk' },
              ]}
              value={limit.direction}
            />
          </div>
          <div
            className="button-group__item button-group__item--value"
            data-testid="size-attributes"
          >
            <AutocompleteV2
              className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
              components={{ DropdownIndicator: null }}
              isSearchable={false}
              onChange={(val) =>
                updateTracesCardinalityQuery('limit', { ...limit, count: val })
              }
              options={[
                ...logsTopBottomCountOptions,
                { label: 'All', value: 'all' },
              ]}
              value={limit.count}
            />
          </div>
        </div>
      </div>
      <TracesCardinalityTable tracesCardinality={tracesCardinality} />
    </div>
  );
};

export default TracesCardinality;
