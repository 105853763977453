import { SearchTag } from 'components';
import { BitmapFilter, Operator } from 'hooks';
import React from 'react';

type Args = {
  filter: BitmapFilter;
};

const getFilterOrExcludeFingerprintTag = ({ filter }: Args): SearchTag[] => {
  const { facet, operator } = filter.value;
  const operatorString = operator === Operator.equal ? '=' : '!=';

  const label = (
    <>
      <span className="traces-search__input__trigger__tag__name">
        fingerpint
      </span>
      <span>{operatorString}</span>
      <span className="traces-search__input__trigger__tag__value">
        {`"${facet}"`}
      </span>
    </>
  );

  return {
    label,
    text: `fingerprint${operatorString}"${facet}"`,
  };
};

export default getFilterOrExcludeFingerprintTag;
