import {
  AnomalyConditionProps,
  ConditionProps,
} from '../AlertsCreateCondition';
import { validateAlertThresholdValue } from './conditionValidator';
import customAnnotationsValidator from './customAnnotationsValidator';
import { AlertType, AlertsCreateDetailsProps } from '../types';
import { validateAlertDetails } from './alertsDetailsValidator';
/**
 * Order of threshold alert to be validated.
 * 1. Selected query exist
 * 2. Promql is valid and has metric selected
 * 3. Threshold value are correct
 * 4. Validate details of alert like name, folder
 * 5. Validate runbook must be a URL
 * 6. Custom annotations are not used from reserve
 */
export const apmAlertValidatorForCreate = ({
  alertsDetails,
  alertType,
  anomalyCondition,
  condition,
}: {
  alertsDetails: AlertsCreateDetailsProps;
  alertType: AlertType;
  anomalyCondition: AnomalyConditionProps;
  condition: ConditionProps;
}): string | { [key: string]: string } => {
  const { value } = condition;

  // 3. Threshold value are correct
  const validateThreshold = validateAlertThresholdValue({ value, alertType });
  if (Object.keys(validateThreshold).length) return validateThreshold;

  if (alertType === AlertType.ANOMALY) {
    const { anomalyAlgorithm } = anomalyCondition;
    if (!anomalyAlgorithm) {
      return 'Please select anomaly algorithm';
    }
  }

  // This will validate 4 and 5
  const validateDetails = validateAlertDetails(alertsDetails);
  if (Object.keys(validateDetails).length) return validateDetails;

  // This will validate 6
  const validateCustomAnnotations = customAnnotationsValidator(
    alertsDetails.customAnnotations,
  );
  if (validateCustomAnnotations) return validateCustomAnnotations;

  return {};
};

export default apmAlertValidatorForCreate;
