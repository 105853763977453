import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum';
import {
  Modals,
  ModalsContextProvider,
  Popover,
  PopoverContextProvider,
  Toaster,
  PortalProvider,
  PortalContainer,
} from 'components';
import { GlobalFilterProvider, PageStateProvider } from 'context';
import { useFeatureFlag } from 'configcat-react';
import { RefContext, useAuth, useRequest } from 'hooks';
import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import { HashRouter } from 'react-router-dom';
import { getWorkbooks } from 'requests';
import AppRouter from './AppRouter';
import Header from './Header';

import { initSentry } from 'utils';

type Props = {
  auth: ReturnType<typeof useAuth>;
};

const App = ({ auth }: Props): ReactElement => {
  const appRef = useRef<HTMLDivElement>(null);
  const leftSidebarRef = useRef<HTMLDivElement>(null);
  const bodyDivRef = useRef<HTMLDivElement>(null);

  const { value: datadogrumValue, loading: datadogrumValueLoading } =
    useFeatureFlag('datadogrum', false);
  const { value: datadogrumProxyValue, loading: datadogrumProxyValueLoading } =
    useFeatureFlag('datadogrumproxy', false);
  const {
    value: useSelfHostedSentryDsn,
    loading: useSelfHostedSentryDsnLoading,
  } = useFeatureFlag('useSelfHostedSentryDsn', false);
  const {
    value: datadogrumProxyEndpointValue,
    loading: datadogrumProxyEndpointValueLoading,
  } = useFeatureFlag('datadogrumproxyendpoint', '');

  const getWorkbooksRequest = useRequest(getWorkbooks);

  useEffect(() => {
    getWorkbooksRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      datadogrumValueLoading ||
      datadogrumProxyValueLoading ||
      datadogrumProxyEndpointValueLoading
    ) {
      return;
    }

    if (datadogrumValue) {
      const hostname = window.location.hostname;
      const rumConfig = {
        applicationId: '9422072c-7ded-4459-b2a8-db31aed3d010',
        clientToken: 'pub823cc3742d96566a8834f43a9117e968',
        env: hostname,
        site: 'us5.datadoghq.com',
        service: 'kf-frontend',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input' as DefaultPrivacyLevel,
      };

      if (datadogrumProxyValue && datadogrumProxyEndpointValue) {
        rumConfig.proxy = datadogrumProxyEndpointValue;
      }

      datadogRum.init(rumConfig);

      datadogRum.setUser({
        id: auth.user && (auth.user.user || auth.user.email),
        email: auth.user && auth.user.email,
      });

      datadogRum.startSessionReplayRecording();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    datadogrumValueLoading,
    datadogrumProxyValueLoading,
    datadogrumProxyEndpointValueLoading,
  ]);

  useEffect(() => {
    if (!useSelfHostedSentryDsnLoading) {
      initSentry({
        dsn: useSelfHostedSentryDsn
          ? 'https://62bfbfd50ed8a3e598645f272699c9ac@ui.observe.kloudfuse.io/2'
          : 'https://4b72d6579f354d95a526bd662e7bb68f@o1287559.ingest.sentry.io/6502653',
      });
    }
  }, [useSelfHostedSentryDsn, useSelfHostedSentryDsnLoading]);

  const refContextValue = useMemo(
    () => ({
      appRef,
      leftSidebarRef,
      bodyDivRef,
    }),
    [],
  );

  return (
    <HashRouter>
      <PageStateProvider>
        <PortalProvider>
          <ModalsContextProvider>
            <PopoverContextProvider>
              <RefContext.Provider value={refContextValue}>
                <GlobalFilterProvider>
                  <div
                    className="app flex flex-col font-noto"
                    id="app"
                    ref={appRef}
                  >
                    <Header
                      auth={auth}
                      workbooks={getWorkbooksRequest.result || []}
                    />
                    <div className="body grow" ref={bodyDivRef}>
                      <AppRouter
                        auth={auth}
                        getWorkbooksRequest={getWorkbooksRequest}
                      />
                    </div>
                    <Modals />
                    <Popover />
                    <Toaster />
                    <PortalContainer />
                  </div>
                </GlobalFilterProvider>
              </RefContext.Provider>
            </PopoverContextProvider>
          </ModalsContextProvider>
        </PortalProvider>
      </PageStateProvider>
    </HashRouter>
  );
};

export default App;
