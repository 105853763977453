import { useRequest } from 'hooks';
import React, { useEffect } from 'react';
import { promqlMetadata } from 'requests';

const MetricsQueryBuilderActiveMetricSummary = ({
  activeMetric,
}: {
  activeMetric: string;
}) => {
  const promqlMetadataRequest = useRequest(promqlMetadata);

  const metadata = promqlMetadataRequest?.result?.[activeMetric] || [];
  const { type, unit, help } = metadata?.[0] || {};

  useEffect(() => {
    promqlMetadataRequest.call(activeMetric);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMetric]);

  return (
    <div className="metrics-summary__body__details__metadata__metadata m-2">
      <div className="metrics-summary__body__subtitle">{activeMetric}</div>
      {promqlMetadataRequest.result && !promqlMetadataRequest.isLoading && (
        <>
          {type && (
            <div>
              Metric Type: <span className="chip">{type}</span>
            </div>
          )}
          {unit && (
            <div>
              Unit: <span className="chip">{unit}</span>
            </div>
          )}
          {help && <div className="mt-1">Description: {help}</div>}
        </>
      )}
    </div>
  );
};

export default MetricsQueryBuilderActiveMetricSummary;
