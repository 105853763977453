import camelcaseKeys from 'camelcase-keys';
import classnames from 'classnames';
import {
  DateControls,
  DateWarningAlert,
  LeftSidebar,
  SelectV2,
  SpanFilters,
  TraceSidebar,
  useLeftSidebarState,
  ProductTour,
} from 'components';
import { Datepicker } from 'composite';
import { useFeatureFlag } from 'configcat-react';
import {
  useColorsByServiceName,
  useLiveTail,
  useRequest,
  useSearchFormulas,
  useTracesPageState,
  useUrlState,
} from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { getServices, traceLabelNamesBase } from 'requests';
import {
  DateSelection,
  SpanFilter,
  Trace,
  TracesTab,
  VisualizeAs,
} from 'types';
import { getColorsByServiceName, getColorsByServiceHash } from 'utils';
import TracesChartGrid from './TracesChartGrid';
import TracesSearch from './TracesSearch';
import TracesSidebar from './TracesSidebar';
import TracesTable from './TracesTable';
import TracesTimeseries from './TracesTimeseries';
import { areFiltersEqual } from './utils';

type Props = {
  tracesPageState: ReturnType<typeof useTracesPageState>;
  tracesTab: TracesTab;
};

const Traces = ({ tracesPageState, tracesTab }: Props) => {
  const activeTraceState = useUrlState<Trace>('activeTrace', null);
  const {
    chartGridKeysState,
    dependenciesForWriteStateToUrl,
    searches,
    searchesFormulas,
    tracesState,
    writeStateToUrl,
  } = tracesPageState;
  const [activeTrace, setActiveTrace] = activeTraceState;

  // TODO(baibhav): URL should update to "live-tail=true" like Logs
  const liveTail = useLiveTail(
    '/trace/livetail?traceFilter=%7B%7D',
    camelcaseKeys,
  );
  const { value: showService7Day } = useFeatureFlag('showservice7day', false);
  const isLiveTailEnabled = liveTail?.isEnabled;

  const search = searches[0];

  const getServicesRequest = useRequest(getServices);
  const leftSidebarState = useLeftSidebarState('traces');
  const traceLabelNamesRequest = useRequest(traceLabelNamesBase);

  const { dateState, spanFilters } = tracesState;

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  const [date, setDate] = dateState;

  const { spanFilter, setSpanFilter } = spanFilters;

  const close = () => {
    setActiveTrace(null);
  };

  useEffect(() => {
    getServicesRequest.call({ date });
    traceLabelNamesRequest.call({ date });
    liveTail.stopLiveTail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    return () => {
      liveTail.closeSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serviceByHash = useMemo(
    () =>
      (getServicesRequest.result || []).reduce(
        (obj, service) => ({ ...obj, [service.hash]: service }),
        {},
      ),
    [getServicesRequest.result],
  );

  const colorsByServiceHash = useMemo(
    () => getColorsByServiceHash(getServicesRequest.result || []),
    [getServicesRequest.result],
  );

  const colorsByServiceName = useMemo(
    () => getColorsByServiceName(getServicesRequest.result || []),
    [getServicesRequest.result],
  );

  useEffect(() => {
    const selectedIndex = tracesState.selectedQueryIndexString || '0';
    const indexNum = Number(selectedIndex);
    if (!searches[indexNum]) {
      return;
    }
    if (searches[indexNum].searchBarState) {
      const filterOfSearch = searches[indexNum].searchBarState['filters'];
      const traceIdSearchOfSearch =
        searches[indexNum].searchBarState['traceIdSearch'];

      const areFiltersSame = areFiltersEqual({
        filters1: filterOfSearch,
        filters2: tracesState.filtersState.state,
      });

      if (
        !areFiltersSame ||
        traceIdSearchOfSearch !== tracesState.traceIdSearch
      ) {
        const parsedFilters = JSON.parse(JSON.stringify(filterOfSearch));

        tracesState.filtersState.setState(parsedFilters);
        tracesState.setTraceIdSearch(traceIdSearchOfSearch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracesState.selectedQueryIndexString]);

  const showUseFacetsOptionOnSideBar = useMemo(() => {
    return tracesTab !== TracesTab.list && searches.length > 1;
  }, [tracesTab, searches]);

  const facetsOfOptions = useMemo(() => {
    return searches.map((query, index) => {
      const value = query.queryKey;
      return {
        label: `Query ${value}`,
        value: String(index),
      };
    });
  }, [searches]);

  return (
    <div
      className={classnames({
        traces: true,
        'traces--disable-7-day': !showService7Day,
      })}
    >
      {!isLiveTailEnabled && (
        <LeftSidebar leftSidebarState={leftSidebarState}>
          <TracesSidebar
            colorsByServiceHash={colorsByServiceHash}
            colorsByServiceName={colorsByServiceName}
            facetsOfOptions={facetsOfOptions}
            showUseFacetsOptionOnSideBar={showUseFacetsOptionOnSideBar}
            serviceByHash={serviceByHash}
            traceLabelNamesRequest={traceLabelNamesRequest}
            tracesState={tracesState}
          />
        </LeftSidebar>
      )}
      <div className="traces__main overflow-auto">
        {!isLiveTailEnabled ? (
          <DateWarningAlert className="traces__banner" date={date} />
        ) : null}
        <div className="traces__header">
          <div className="traces__header__left">
            <TracesSearch
              colorsByServiceHash={colorsByServiceHash}
              leftSidebarState={leftSidebarState}
              placeholder="Search for any tag on ingested spans"
              searches={searches}
              searchesFormulas={searchesFormulas}
              serviceByHash={serviceByHash}
              traceLabelNamesRequest={traceLabelNamesRequest}
              tracesState={tracesState}
              tracesTab={tracesTab}
              isLiveTailEnabled={isLiveTailEnabled}
            />
          </div>
          <div className="traces__header__right">
            <div className="traces__header__date-controls">
              <div className="traces__header__date-controls__item">
                <Datepicker
                  hasStartedLiveTail={liveTail.isEnabled}
                  onChange={setDate}
                  startLiveTail={liveTail.startLiveTailIfNeeded}
                  value={date as DateSelection}
                />
              </div>
              <div className="traces__header__date-controls__item">
                <DateControls
                  date={date}
                  liveTail={liveTail}
                  setDate={setDate}
                />
              </div>
            </div>
            <div className="traces__header__filters">
              {search.visualizeAs === VisualizeAs.flowMap ? (
                <SelectV2.Select
                  className={classnames({
                    'select--disabled': true,
                  })}
                  onChange={setSpanFilter}
                  options={Object.values(SpanFilter).map((spanFilterLabel) => ({
                    label: spanFilterLabel,
                    value: spanFilterLabel,
                  }))}
                  right
                  value={
                    tracesTab === TracesTab.serviceMap
                      ? SpanFilter.traceRootSpans
                      : spanFilter
                  }
                />
              ) : (
                <SpanFilters spanFilters={spanFilters} />
              )}
            </div>
          </div>
        </div>
        {tracesTab === TracesTab.list ? (
          <TracesChartGrid tracesState={tracesState} />
        ) : null}
        <div className="traces__main overflow-auto">
          {tracesTab === TracesTab.list ? (
            <TracesTable
              colorsByServiceName={colorsByServiceName}
              colorsByServiceHash={colorsByServiceHash}
              liveTail={liveTail}
              setActiveTrace={setActiveTrace}
              tracesState={tracesState}
            />
          ) : null}
          {tracesTab !== TracesTab.list ? (
            <TracesTimeseries
              searches={searches}
              searchesFormulas={searchesFormulas}
              serviceByHash={serviceByHash}
              tracesState={tracesState}
              tracesTab={tracesTab}
              isLiveTailEnabled={isLiveTailEnabled}
            />
          ) : null}
        </div>
      </div>
      {activeTrace ? (
        <TraceSidebar
          chartGridKeysState={chartGridKeysState}
          close={close}
          colorsByServiceHash={colorsByServiceHash}
          colorsByServiceName={colorsByServiceName}
          key={activeTrace.span.spanId}
          trace={activeTrace}
          tracesState={tracesState}
        />
      ) : null}
      {<ProductTour />}
    </div>
  );
};

export default Traces;
