import { AutocompleteV2, InputWithValidatorV3 } from 'components';
import React, { ReactElement, Dispatch, SetStateAction } from 'react';

import { HydrateFormProps } from './types';
import { retentionUnitOptions } from './utils';
const LogsHydrationArchivesHydrateRetention = ({
  hydrateForm,
  setHydrateForm,
}: {
  hydrateForm: HydrateFormProps;
  setHydrateForm: Dispatch<SetStateAction<HydrateFormProps>>;
}): ReactElement => {
  return (
    <div>
      <div className="flex  gap-2">
        <div>
          <label>Retention</label>
          <InputWithValidatorV3
            onChange={(value: string) =>
              setHydrateForm({
                ...hydrateForm,
                retentionDurationValue: parseInt(value),
              })
            }
            placeholder="Retention Period"
            size="5"
            type="number"
            value={hydrateForm.retentionDurationValue.toString()}
            variant="default"
          />
        </div>
        <div>
          <label>Unit</label>
          <AutocompleteV2
            className="autocomplete__fixed-height-30"
            menuPlacement="top"
            onChange={(value: string) =>
              setHydrateForm({ ...hydrateForm, retentionDurationUnit: value })
            }
            options={retentionUnitOptions}
            value={hydrateForm.retentionDurationUnit}
          />
        </div>
      </div>
    </div>
  );
};

export default LogsHydrationArchivesHydrateRetention;
