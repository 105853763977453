import {
  Json,
  JsonType,
  PopoverPosition,
  PopoverTriggerV2,
  useTabs,
} from 'components';
import { useTracesState } from 'hooks';
import React from 'react';
import { Span } from 'types';
import TraceSidebarActiveSpanJsonLogsSearchButton from './TraceSidebarActiveSpanJsonLogsSearchButton';
import TraceSidebarActiveSpanJsonValue from './TraceSidebarActiveSpanJsonValue';
import { FacetPickerValuesItemPopoverPanel } from '../FacetPicker';
import { LogsAttribute } from './types';
import { useNavigate } from 'react-router-dom';

type Props = {
  applyFilterOnTracesPage?: boolean;
  setLogsAttribute: (logsAttribute: LogsAttribute) => void;
  span: Span;
  tabs: ReturnType<typeof useTabs>;
  tracesState: ReturnType<typeof useTracesState>;
};

type Args = {
  label: string;
  path: string;
  type: JsonType;
  value: any;
};

const ignoreShowExistsBitmap: { [key: string]: number } = {
  rootSpan: 1,
  parentSpanId: 1,
  spanId: 1,
  trace_id: 1,
};

const getParsedHcLabel = (path: string) => {
  if (path.indexOf('span.hc_attributes.') === 0) {
    const [_, label] = path.split('span.hc_attributes.');
    return `$.hc_attributes.${
      label.indexOf('[') > -1 ? label : `['${label}']`
    }`;
  }

  return `$.${path.replace(/^span\./, '')}`;
};

const TraceSidebarActiveSpanJson = ({
  applyFilterOnTracesPage,
  setLogsAttribute,
  span,
  tabs,
  tracesState,
}: Props) => {
  const {
    keyExistsState,
    selectedFacetValuesByNameState,
    selectedHcFacetValuesByNameState,
  } = tracesState;
  const navigate = useNavigate();

  const excludeFacetValueHandler =
    ({ label, selectedFacetValuesByNameState }) =>
    (value: string) => {
      if (applyFilterOnTracesPage) {
        const urlSearchParams = new URLSearchParams();
        const selectedFacetValuesByName = {
          [label]: { [value]: 0 },
          service_hash: { [span.service?.hash]: 1 },
        };
        urlSearchParams.set(
          'selectedFacetValuesByName',
          JSON.stringify(selectedFacetValuesByName),
        );

        const { startTimeUnix, endTimeUnix } =
          tracesState?.dateState?.[0] || {};

        urlSearchParams.set(
          'date',
          JSON.stringify({ startTimeUnix, endTimeUnix }),
        );
        navigate(`/apm/traces/list?${urlSearchParams.toString()}`);
      } else {
        selectedFacetValuesByNameState.excludeFacetValue({
          name: label,
          value,
        });
      }
    };

  const selectOnlyFacetValueHandler =
    ({ label, selectedFacetValuesByNameState }) =>
    (value: string) => {
      if (applyFilterOnTracesPage) {
        const urlSearchParams = new URLSearchParams();
        const selectedFacetValuesByName = {
          [label]: { [value]: 1 },
          service_hash: { [span.service?.hash]: 1 },
        };
        urlSearchParams.set(
          'selectedFacetValuesByName',
          JSON.stringify(selectedFacetValuesByName),
        );

        const { startTimeUnix, endTimeUnix } =
          tracesState?.dateState?.[0] || {};

        urlSearchParams.set(
          'date',
          JSON.stringify({ startTimeUnix, endTimeUnix }),
        );
        navigate(`/apm/traces/list?${urlSearchParams.toString()}`);
      } else {
        selectedFacetValuesByNameState.selectOnlyFacetValue({
          name: label,
          value,
        });
      }
    };

  const showKeyExistsHandler =
    ({ label, path }) =>
    () => {
      const key = path.indexOf('span.') === 0 ? getParsedHcLabel(path) : label;
      if (applyFilterOnTracesPage) {
        const keyExists = {
          [key]: 1,
        };
        const urlSearchParams = new URLSearchParams();
        const selectedFacetValuesByName = {
          service_hash: { [span.service?.hash]: 1 },
        };
        urlSearchParams.set(
          'selectedFacetValuesByName',
          JSON.stringify(selectedFacetValuesByName),
        );
        urlSearchParams.set('keyExists', JSON.stringify(keyExists));

        const { startTimeUnix, endTimeUnix } =
          tracesState?.dateState?.[0] || {};

        urlSearchParams.set(
          'date',
          JSON.stringify({ startTimeUnix, endTimeUnix }),
        );
        navigate(`/apm/traces/list?${urlSearchParams.toString()}`);
      } else {
        keyExistsState.setKeyExists(key);
      }
    };

  return (
    <div className="trace-sidebar__active-span__attributes">
      <Json
        data={span}
        options={{
          renderValue: ({ label, path, type, value }: Args) => {
            const isHcAttribute = path.indexOf('span.') === 0;
            const parsedLabel = isHcAttribute ? getParsedHcLabel(path) : label;
            return label.indexOf('stack') > -1 ? (
              <div className="json__value">
                <div className={`text--${type}`}>{String(value)}</div>
              </div>
            ) : (
              <PopoverTriggerV2
                className="json__value"
                popover={({ close }) => (
                  <FacetPickerValuesItemPopoverPanel
                    close={close}
                    excludeFacetValue={excludeFacetValueHandler({
                      label: parsedLabel,
                      selectedFacetValuesByNameState: isHcAttribute
                        ? selectedHcFacetValuesByNameState
                        : selectedFacetValuesByNameState,
                    })}
                    label={`${label}:${value}`}
                    selectOnlyFacetValue={selectOnlyFacetValueHandler({
                      label: parsedLabel,
                      selectedFacetValuesByNameState: isHcAttribute
                        ? selectedHcFacetValuesByNameState
                        : selectedFacetValuesByNameState,
                    })}
                    showKeyExists={
                      ignoreShowExistsBitmap[label]
                        ? null
                        : showKeyExistsHandler({ label, path })
                    }
                    showKeyExistsLabel={label}
                    value={String(value)}
                  >
                    <TraceSidebarActiveSpanJsonLogsSearchButton
                      label={label}
                      setLogsAttribute={setLogsAttribute}
                      tabs={tabs}
                      value={value}
                    />
                  </FacetPickerValuesItemPopoverPanel>
                )}
                position={
                  path === 'statusCode' || path === 'startTimeNs'
                    ? PopoverPosition.TOP_LEFT
                    : PopoverPosition.BOTTOM_LEFT
                }
              >
                <TraceSidebarActiveSpanJsonValue
                  label={label}
                  type={type}
                  value={value}
                />
              </PopoverTriggerV2>
            );
          },
        }}
      />
    </div>
  );
};

export default TraceSidebarActiveSpanJson;
