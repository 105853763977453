import { useRequest } from 'hooks';
import React, { ReactElement, useEffect } from 'react';
import { promqlQueryRange } from 'requests';
import { DateSelection } from 'types';

import { AlertsChart } from '../AlertsChart';
import {
  OutlierConditionProps,
  MetricChangeConditionProps,
  ConditionProps,
} from '../AlertsCreateCondition';
import {
  buildChangeAlertPromql,
  buildOutlierAlertPromql,
} from '../AlertsCreateMetrics/utils';

const AlertsChartPromql = ({
  changeCondition,
  condition,
  date,
  promql,
  outlierCondition,
  unit = 'number',
}: {
  changeCondition: MetricChangeConditionProps;
  condition: ConditionProps;
  date: DateSelection;
  promql: string;
  outlierCondition: OutlierConditionProps;
  unit?: string;
}): ReactElement => {
  const promqlQueryRangeRequest = useRequest(promqlQueryRange);
  const loadEvaluationGraph = async (promql: string) => {
    const encodedPromqls = [encodeURIComponent(promql)];
    promqlQueryRangeRequest.call({
      date,
      metricNames: [''],
      promqlQueries: encodedPromqls,
      type: 'timeseries',
    });
  };
  useEffect(() => {
    if (!changeCondition) return;
    const changePromql = buildChangeAlertPromql(changeCondition, promql);
    loadEvaluationGraph(changePromql);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCondition, date, promql]);

  useEffect(() => {
    if (!outlierCondition) return;
    const outlierPromql = buildOutlierAlertPromql(
      promql,
      outlierCondition,
      'load',
    );
    loadEvaluationGraph(outlierPromql);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlierCondition, date, promql]);

  return (
    <AlertsChart
      conditionOperator={condition.of}
      conditionValue={Number(condition.value)}
      date={date}
      isLoading={promqlQueryRangeRequest.isLoading}
      queryData={promqlQueryRangeRequest.result}
      unit={unit}
    />
  );
};

export default AlertsChartPromql;
