import { SizeObserver, CursorStateProvider } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  getDatasources,
  getLogMetricsTimeSeriesLogQL,
  promqlQueryRange,
} from 'requests';
import { DashboardPanelType, DateSelection } from 'types';
import { getRollupByVisualization } from 'utils';

import {
  AlertsChart,
  AlertsChartAnomalyPromql,
  AlertsChartPromql,
  AlertsChartForecast,
} from '../AlertsChart';
import { apmChartUnit } from '../AlertsCreateAPM/utils';
import {
  AlertType,
  AlertsMetricsParsedProps,
  RuleProps,
  RuleType,
} from '../types';

const AlertsDetailsChart = ({
  alertParsedMetadata,
  date,
  rule,
}: {
  alertParsedMetadata: AlertsMetricsParsedProps;
  date: DateSelection;
  rule: RuleProps;
}): ReactElement => {
  const { condition, formulas, promqls, queries } = alertParsedMetadata;
  const [datasource, setDatasource] = useState(null);
  const getDatasourcesRequest = useRequest(getDatasources);
  const promqlQueryRangeRequest = useRequest(promqlQueryRange);
  const logMetricsTimeSeriesLogQLRequest = useRequest(
    getLogMetricsTimeSeriesLogQL,
  );
  const extraData = JSON.parse(rule?.annotations?.extraData || '{}');

  const callLoadEvaluationGraph = async (newDatasource: string) => {
    if (newDatasource === 'prometheus') {
      const encodedPromqls = promqls.map((promql) =>
        encodeURIComponent(promql),
      );
      promqlQueryRangeRequest.call({
        date,
        metricNames: [''],
        promqlQueries: encodedPromqls,
        type: 'timeseries',
      });
    } else if (newDatasource === 'loki') {
      logMetricsTimeSeriesLogQLRequest.call({
        date,
        logQlQuery: { logQL: promqls[0] },
        width: document.body.clientWidth - 140,
      });
    }
  };

  const loadEvaluationGraph = async () => {
    if (getDatasourcesRequest.isLoading) return;
    if (datasource && datasource.type) {
      callLoadEvaluationGraph(datasource.type);
      return;
    }

    const result = await getDatasourcesRequest.call();
    const datasourceReq = result.find(
      (data) =>
        data.uid === rule.datasourceUid || data.name === rule.datasourceUid,
    );
    setDatasource(datasourceReq);

    if (datasourceReq) {
      callLoadEvaluationGraph(datasourceReq.type);
    }
  };

  useEffect(() => {
    if (
      rule.annotations.alertType === AlertType.FORECAST ||
      rule.annotations.alertType === AlertType.ANOMALY ||
      rule.annotations.alertType === AlertType.CHANGE ||
      rule.annotations.alertType === AlertType.OUTLIERS
    ) {
      return;
    }
    loadEvaluationGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promqls, condition, date]);

  const dataRequest =
    datasource?.type === 'loki'
      ? logMetricsTimeSeriesLogQLRequest
      : promqlQueryRangeRequest;

  if (rule.annotations?.alertType === AlertType.ANOMALY) {
    return (
      <SizeObserver>
        {({ width: baseWidth }) => (
          <AlertsChartAnomalyPromql
            anomalyCondition={alertParsedMetadata.anomalyCondition}
            baseWidth={baseWidth}
            date={date}
            forWindow={rule.evaluate?.for}
            queryItem={{
              promql: alertParsedMetadata.promqls[0],
              refId: 'a',
              type: DashboardPanelType.TIMESERIES,
              queryType: 'query',
              step: getRollupByVisualization(date),
              metricName: '{*}',
            }}
            unit={
              rule.annotations.ruleType === RuleType.APM
                ? apmChartUnit[extraData?.apmTriggerType]
                : 'number'
            }
          />
        )}
      </SizeObserver>
    );
  }

  if (rule.annotations.alertType === AlertType.FORECAST) {
    return (
      <SizeObserver>
        {({ width: baseWidth }) => (
          <CursorStateProvider>
            <AlertsChartForecast
              baseWidth={baseWidth}
              condition={alertParsedMetadata.condition}
              date={date}
              forecastCondition={alertParsedMetadata.forecastCondition}
              formulas={formulas}
              queries={queries}
              queryKey={condition.queryKey}
            />
          </CursorStateProvider>
        )}
      </SizeObserver>
    );
  }

  if (
    rule.annotations.alertType === AlertType.CHANGE ||
    rule.annotations.alertType === AlertType.OUTLIERS
  ) {
    return (
      <AlertsChartPromql
        changeCondition={alertParsedMetadata.changeCondition}
        condition={condition}
        date={date}
        outlierCondition={alertParsedMetadata.outlierCondition}
        promql={alertParsedMetadata.promqls[0]}
        unit="number"
      />
    );
  }

  return (
    <AlertsChart
      conditionOperator={condition.of}
      conditionValue={Number(condition.value)}
      date={date}
      isLoading={dataRequest.isLoading || getDatasourcesRequest.isLoading}
      queryData={dataRequest.result}
      unit={
        rule.annotations.ruleType === RuleType.APM
          ? apmChartUnit[extraData?.apmTriggerType]
          : 'number'
      }
    />
  );
};

export default AlertsDetailsChart;
