import { InputWithValidatorV3, Button } from 'components';
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { MdDelete as DeleteIcon } from 'react-icons/md';

import { HydrateFormProps } from './types';

const LogsHydrationArchivesHydrateTags = ({
  hydrateForm,
  setHydrateForm,
}: {
  hydrateForm: HydrateFormProps;
  setHydrateForm: Dispatch<SetStateAction<HydrateFormProps>>;
}): ReactElement => {
  const setTagByKey = (key: string, idx: number, value: string) => {
    setHydrateForm({
      ...hydrateForm,
      addTags: hydrateForm.addTags.map((tag, i) =>
        i === idx ? { ...tag, [key]: value } : tag,
      ),
    });
  };

  return (
    <div className="">
      {hydrateForm.addTags.map((tag, idx) => (
        <div key={idx} className="flex items-center gap-1">
          <div>
            <label>Key</label>
            <div>
              <InputWithValidatorV3
                size="4"
                type="text"
                variant="default"
                onChange={(e) => setTagByKey('key', idx, e)}
                placeholder="Key"
                value={tag.key}
              />
            </div>
          </div>
          <div>
            <label>Value</label>
            <div>
              <InputWithValidatorV3
                size="4"
                type="text"
                variant="default"
                onChange={(e) => setTagByKey('value', idx, e)}
                placeholder="Value"
                value={tag.value}
              />
            </div>
          </div>
          <div className="self-end">
            <Button
              variant="ghost-destructive"
              size="sm"
              className="h-[30px] border"
              onClick={() => {
                if (hydrateForm.addTags.length === 1) {
                  return;
                }
                setHydrateForm({
                  ...hydrateForm,
                  addTags: hydrateForm.addTags.filter((_, i) => i !== idx),
                });
              }}
            >
              <DeleteIcon size={16} />
            </Button>
          </div>
        </div>
      ))}
      <Button
        variant="default"
        className="mt-2"
        size="xs"
        onClick={() => {
          setHydrateForm({
            ...hydrateForm,
            addTags: [...hydrateForm.addTags, { key: '', value: '' }],
          });
        }}
      >
        Add Tag
      </Button>
    </div>
  );
};

export default LogsHydrationArchivesHydrateTags;
