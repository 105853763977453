const formatFpPatternFromLogLineParts = (logLineParts) => {
  let pIdx = 0;
  let vIdx = 0;
  let result = '';

  logLineParts.forEach((logLinePart) => {
    const { partType, tokenPart } = logLinePart;
    if (partType === 'token') {
      result += tokenPart.content;
    } else if (partType === 'param') {
      result += '<p_' + pIdx + '>';
      pIdx++;
    } else if (partType === 'facet') {
      result += '<v_' + vIdx + '>';
      vIdx++;
    }
  });

  return result;
};

export default formatFpPatternFromLogLineParts;
