import { DateSelection, SelectedFacetValuesByName } from 'types';
import {
  buildPromQLFilterFromSelectedFacetValuesByName,
  onPromiseError,
} from 'utils';
import fetchJson from './fetchJson';

interface Item {
  aggrVal: AggrVal;
  groupVal: GroupVal;
  bucketStart: number;
}

export interface AggrVal {
  service_hash: number;
}

export interface GroupVal {
  span_type: string;
  [key: string]: string;
}

type Props = {
  date: DateSelection;
  filterUnknownServiceHash?: boolean;
  name: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceHash: string;
  spanTypeFilter?: { filter: string; operator: string };
  instant?: boolean;
};

const traceLabelValuesV2 = ({
  date,
  filterUnknownServiceHash,
  name,
  selectedFacetValuesByName,
  serviceHash,
  spanTypeFilter,
  instant = true,
}: Props): Promise<Item[]> => {
  const { endTimeUnix, startTimeUnix } = date;
  const duration = endTimeUnix - startTimeUnix;
  const filters = buildPromQLFilterFromSelectedFacetValuesByName({
    selectedFacetValuesByName: {
      ...selectedFacetValuesByName,
      ...(serviceHash ? { service_hash: { [serviceHash]: 1 } } : {}),
    },
    customFilter: filterUnknownServiceHash
      ? 'service_hash!="UNKNOWN"'
      : undefined,
    spanTypeFilter,
  });

  const filtersEncoded = filters?.replaceAll(/\+/g, '%2B');
  return fetchJson(
    `/api/v1/analytics?match[]=edge_latency_count${filtersEncoded}&aggregation=distinctcount&duration=${
      instant ? 0 : duration
    }&timestamp=${endTimeUnix}&aggregationField=service_hash&groupBy=${name}&rollUpSeconds=${duration}`,
  ).then(
    (result) =>
      (result || []).filter((item) => item.groupVal[name] !== 'UNKNOWN'),
    onPromiseError,
  );
};

export default traceLabelValuesV2;
