import { delimiter } from 'kfuse-constants';
import { FacetBase } from 'types';

export const getFacetKey = (facet: FacetBase): string => {
  const { component, name, type } = facet;
  return `${component}${delimiter}${name}${delimiter}${type}`;
};

export const parseFacetKey = (facetKey: string): FacetBase => {
  const [component, name, type] = facetKey.split(delimiter);
  return {
    component,
    name,
    type,
  };
};
