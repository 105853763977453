import { Loader, RangeSlider } from 'components';
import { useRequest } from 'hooks';
import React, { useEffect } from 'react';
import { SelectedFacetRange } from 'types';

type Props = {
  changeFacetRange: (range: SelectedFacetRange) => void;
  getFacetValuesRequest: ReturnType<typeof useRequest>;
  lastRefreshedAt: Date;
  name: string;
  renderedName?: string;
  renderPlaceholderText: (name: string) => string;
  selectedFacetRange: SelectedFacetRange;
};

const FacetPickerRange = ({
  changeFacetRange,
  getFacetValuesRequest,
  lastRefreshedAt,
  name,
  renderedName,
  renderPlaceholderText,
  selectedFacetRange,
}: Props) => {
  if (getFacetValuesRequest.result?.found === false) {
    return (
      <div className="facet-picker__values">
        <div className="facet-picker__values__placeholder">
          {renderPlaceholderText && typeof renderPlaceholderText === 'function'
            ? renderPlaceholderText(name)
            : `No facet values for ${renderedName}`}
        </div>
      </div>
    );
  }

  const max = Math.max(
    getFacetValuesRequest.result?.max || 1,
    selectedFacetRange?.upper || 1,
  );

  const min = Math.min(
    getFacetValuesRequest.result?.min || 0,
    selectedFacetRange?.lower || max,
  );

  const value = selectedFacetRange || { lower: min, upper: max };

  const onChange = (facetRange: { lower: number; upper: number }) => {
    changeFacetRange({
      ...facetRange,
      isLowerInclusive: true,
      isUpperInclusive: true,
    });
  };

  return (
    <Loader
      className="facet-picker__range"
      isLoading={getFacetValuesRequest.isLoading}
    >
      {getFacetValuesRequest.result ? (
        <RangeSlider onChange={onChange} min={min} max={max} value={value} />
      ) : null}
    </Loader>
  );
};

export default FacetPickerRange;
