import { useRequest } from 'hooks';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { getFacetNamesByCursor } from 'requests';
import { FacetName } from 'types/generated';

import { HIDE_AUTOGENERATED_PREFIXS } from '../constants';
import useLogsWorkbooksState from './useLogsWorkbooksState';

type Props = {
  component: string;
  hardcodedFacets?: FacetName[];
  hideAutogeneratedFacets?: boolean;
  logsState: ReturnType<typeof useLogsWorkbooksState>['currentLogsState'];
};

const filterByHideAutogeneratedPrefixs = (facetNames: FacetName[]) => {
  return facetNames.filter((facet) => {
    return !HIDE_AUTOGENERATED_PREFIXS.some(
      (prefix) =>
        typeof facet?.name === 'string' && facet.name.startsWith(prefix),
    );
  });
};

const useLogsFacetSearch = ({
  component,
  hardcodedFacets,
  hideAutogeneratedFacets,
  logsState,
}: Props) => {
  const initialFacets = hardcodedFacets || [];
  const [facets, setFacets] = useState(initialFacets);
  const [facetSearch, setFacetSearch] = useState('');

  const getFacetNamesByCursorRequest = useRequest(getFacetNamesByCursor);

  const loadFacetOnSearchChange = (search: string) => {
    if (hardcodedFacets) {
      setFacets(hardcodedFacets);
      return;
    }
    setFacetSearch(search);
    getFacetNamesByCursorRequest
      .call({
        date: logsState.date,
        limit: '200',
        logsState,
        prefix: search,
        source: component,
      })
      .then((data) => {
        if (hideAutogeneratedFacets) {
          const facetNames = filterByHideAutogeneratedPrefixs(data.facetNames);
          setFacets(facetNames);
          return;
        }

        setFacets(data.facetNames);
      });
  };

  const onScrollToBottomFacets = () => {
    if (hardcodedFacets) return;

    if (!getFacetNamesByCursorRequest.result?.cursor) return;
    getFacetNamesByCursorRequest
      .call({
        cursor: getFacetNamesByCursorRequest.result?.cursor,
        date: logsState.date,
        limit: '200',
        logsState,
        source: component,
      })
      .then((data) => {
        if (hideAutogeneratedFacets) {
          const facetNames = filterByHideAutogeneratedPrefixs(data.facetNames);
          setFacets((prev) => [...prev, ...facetNames]);
          return;
        }

        setFacets((prev) => [...prev, ...data.facetNames]);
      });
  };

  const onFacetSearchChange = useMemo(
    () => debounce(loadFacetOnSearchChange, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    facets,
    facetSearch,
    isFacetSearchLoading: getFacetNamesByCursorRequest.isLoading,
    loadFacetOnSearchChange,
    onScrollToBottomFacets,
    onFacetSearchChange,
    setFacetSearch,
  };
};

export default useLogsFacetSearch;
