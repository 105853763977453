import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useRequest } from 'hooks';
import { getGrafanaAlertsRulesByFolder, getGrafanaFolders } from 'requests';
import { formatGrafanaAlertsRules } from 'screens/NewAlerts/utils';
import { DateSelection } from 'types';

import ServiceAdvanceFunctions from './ServiceAdvanceFunctions';
import ServiceAlertsDetailsSidebar from './ServiceAlertsDetailsSidebar';
import ServiceAlertsStatusAPM from './ServiceAlertsStatusAPM';

const ServiceAlertsStatus = ({
  asmMatcher,
  date,
  kfSource,
  serviceName,
  serviceDistinctLabels,
  serviceHash,
  serviceLabels,
}: {
  asmMatcher: string;
  date: DateSelection;
  kfSource?: string;
  serviceName: string;
  serviceDistinctLabels: Record<string, string>;
  serviceHash: string;
  serviceLabels: Record<string, string>;
}): ReactElement => {
  const [activeSmartAlert, setActiveSmartAlert] = useState(null);
  const requestGrafanaAlertsRules = useRequest((args) =>
    getGrafanaAlertsRulesByFolder(args).then((data) =>
      formatGrafanaAlertsRules(data, []),
    ),
  );
  const ALERT_FOLDER_NAME = `${serviceName}_${serviceHash}`;

  const grafanaFoldersRequest = useRequest(getGrafanaFolders);

  const getGraphanaAlertsRules = async () => {
    let folderList = [];
    if (grafanaFoldersRequest.result) {
      folderList = grafanaFoldersRequest.result;
    } else {
      folderList = await grafanaFoldersRequest.call();
    }
    const folder = folderList.find(
      (item: any) => item.title === ALERT_FOLDER_NAME,
    );

    if (folder) {
      requestGrafanaAlertsRules.call(ALERT_FOLDER_NAME);
    }
  };

  useEffect(() => {
    getGraphanaAlertsRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const isDockerService = useMemo(() => {
    return serviceDistinctLabels?.['kf_platform'] === 'docker';
  }, [serviceDistinctLabels]);

  const isKFSourceKnight = useMemo(() => {
    return kfSource === 'knight';
  }, [kfSource]);

  return (
    <div className="flex">
      {/*Temporarily hiding SLO feature
        https://github.com/kloudfuse/ui/issues/2360
      <ServiceSLOInfo
        isServiceUnique={isServiceUnique}
        kfSource={kfSource}
        serviceName={serviceName}
      /> */}
      {!isDockerService && (
        <ServiceAdvanceFunctions
          asmMatcher={asmMatcher}
          date={date}
          kfSource={kfSource}
          serviceName={serviceName}
          setActiveSmartAlert={setActiveSmartAlert}
          requestGrafanaAlertsRules={requestGrafanaAlertsRules}
          getGraphanaAlertsRules={getGraphanaAlertsRules}
        />
      )}
      {!isKFSourceKnight && (
        <ServiceAlertsStatusAPM
          serviceName={serviceName}
          serviceDistinctLabels={serviceDistinctLabels}
          serviceHash={serviceHash}
          serviceLabels={serviceLabels}
          setActiveSmartAlert={setActiveSmartAlert}
          requestGrafanaAlertsRules={requestGrafanaAlertsRules}
        />
      )}
      {activeSmartAlert && (
        <ServiceAlertsDetailsSidebar
          asmMatcher={asmMatcher}
          close={() => setActiveSmartAlert(null)}
          rule={activeSmartAlert}
          title={`${activeSmartAlert.name}`}
          kfSource={kfSource}
        />
      )}
    </div>
  );
};

export default ServiceAlertsStatus;
