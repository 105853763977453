import { InputWithValidatorV3 } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useState } from 'react';
import { XCircle } from 'react-feather';
import { createGrafanaFolder, getGrafanaFolders } from 'requests';

const CreateNewFolderModal = ({
  closeModal,
  updateFolder,
  editFolderName,
  editFolderUid,
}: {
  closeModal: () => void;
  updateFolder: (folderName: string) => void;
  editFolderName?: string;
  editFolderUid?: string;
}): ReactElement => {
  const [folderName, setFolderName] = useState(editFolderName || '');
  const createFolderRequest = useRequest(createGrafanaFolder);
  const getGrafanaFoldersRequest = useRequest(getGrafanaFolders);

  return (
    <div className="alerts__create-new-folder">
      <div className="alerts__create-new-folder__container">
        {createFolderRequest.isLoading || getGrafanaFoldersRequest.isLoading ? (
          <div className="flex h-[160px] w-full items-center justify-center">
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            <div className="alerts__create-new-folder__container__header">
              <div>{editFolderName ? 'Update' : 'Create'} Folder</div>
              <XCircle onClick={closeModal} />
            </div>
            <div className="flex flex-col">
              <div className="pb-0.5">Folder name</div>
              <InputWithValidatorV3
                size="4"
                variant="default"
                onChange={(val) => setFolderName(val)}
                placeholder="Enter Folder name"
                type="text"
                value={folderName}
              />
            </div>
            <div className="alerts__create-new-folder__container__action">
              <button
                className="button button--blue"
                onClick={async () => {
                  if (!folderName) return;

                  let folder = undefined;
                  if (editFolderUid) {
                    folder = await getGrafanaFoldersRequest.call(editFolderUid);
                  }

                  createFolderRequest
                    .call({
                      folderName,
                      uid: editFolderUid,
                      version: folder?.version,
                    })
                    .then(() => updateFolder(folderName));
                }}
              >
                {editFolderName ? 'Update Folder' : 'Create new Folder'}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateNewFolderModal;
