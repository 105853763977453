import dayjs from 'dayjs';
import { DateSelection, SelectedFacetValuesByName, ValueCount } from 'types';
import {
  filterListWithSelectedSidebar,
  convertSecondToReadable,
  getRollupToSecond,
  mergeTwoSidebarCountsValues,
} from 'utils';

import { ReservedAnnotationKeys } from './alertsCreateUtils';
import {
  AlertType,
  KeyPairProps,
  MutedAlertProps,
  RuleProps,
  RuleType,
} from '../types';

export const AlertsCore = [
  { name: 'Status', label: 'state', forceExpanded: true },
];

export const AlertsFacet = [
  { name: 'status', label: 'State', forceExpanded: false },
  { name: 'mute', label: 'Mute', forceExpanded: false },
  { name: 'type', label: 'Type', forceExpanded: false },
  { name: 'contactpoints', label: 'Contacts', forceExpanded: false },
  { name: 'tags', label: 'Tags', forceExpanded: false },
  { name: 'folder', label: 'Folder', forceExpanded: false },
];

export const AlertsStatus = [
  { label: 'Alerting', value: 'alerting' },
  { label: 'OK', value: 'ok' },
  { label: 'No Data', value: 'no_data' },
  { label: 'Pending', value: 'pending' },
  { label: 'Paused', value: 'paused' },
];

export const alertTypeSearch: { [key: string]: string } = {
  threshold: 'Threshold Alert',
  anomaly: 'Anomaly Alert',
  change: 'Change Alert',
  forecast: 'Forecast Alert',
  outliers: 'Outlier Alert',
};

export const ruleAlertType: { [key: string]: string } = {
  logs: 'Logs',
  metrics: 'Metrics',
  apm: 'APM',
  slo: 'SLO',
};

const getNonContactPointLabels = (
  labels: { [key: string]: string },
  contactPoints: string[],
): {
  tags: { [key: string]: string };
  contactPointLabels: string[];
} => {
  if (!labels) return { tags: {}, contactPointLabels: [] };

  const tags: { [key: string]: string } = {};
  const contactPointLabels: string[] = [];
  Object.keys(labels).forEach((key) => {
    if (contactPoints.includes(key)) {
      contactPointLabels.push(key);
    } else {
      tags[key] = labels[key];
    }
  });

  return { tags, contactPointLabels };
};

/**
 * Add fail safe annotations to the rule
 * @param rule
 * @returns
 */
const addFailSafeAnnotations = (rule: any): any => {
  const datasourceType = getDatasourceType(rule);
  if (datasourceType === 'loki') {
    rule.annotations = {
      ...(rule.annotations || {}),
      ruleType: RuleType.LOGS,
    };
  }

  if (
    !['loki', 'prometheus'].includes(datasourceType) &&
    rule.annotations?.ruleType !== RuleType.SLO
  ) {
    rule.annotations = {
      ...(rule.annotations || {}),
      ruleType: datasourceType,
    };
  }

  if (!rule.annotations) {
    if (datasourceType === 'loki') {
      rule.annotations = { ruleType: RuleType.LOGS };
    }
    if (datasourceType === 'prometheus') {
      rule.annotations = {
        ruleType: RuleType.METRICS,
        alertType: AlertType.THRESHOLD,
      };
    }
  }

  if (!rule.annotations?.ruleType) {
    rule.annotations.ruleType =
      datasourceType === 'loki' ? RuleType.LOGS : RuleType.METRICS;
  }

  if (
    rule.annotations?.ruleType === RuleType.METRICS &&
    !rule.annotations?.alertType
  ) {
    rule.annotations.alertType = AlertType.THRESHOLD;
  }
};

const getDatasourceType = (rule: any): string => {
  const queryRow = rule.grafana_alert?.data[0];
  if (!queryRow) return undefined;
  const datasourceType = queryRow?.model?.datasource?.type;

  if (datasourceType) {
    return datasourceType;
  }

  if (rule.annotations) {
    if (rule.annotations.ruleType === RuleType.LOGS) {
      return 'loki';
    }
    if (rule.annotations.ruleType === RuleType.METRICS) {
      return 'prometheus';
    }
  }
  return undefined;
};

const getRuleRow = (health: string, folder: string, rule: any): RuleProps => {
  const queryRow = rule.data[0];
  return {
    datasourceUid: queryRow.datasourceUid,
    group: rule.rule_group,
    groupFile: folder,
    name: rule.title,
    ruleData: rule.data,
    status: health,
    uid: rule.uid,
    updated: rule.updated,
    executionError: rule.exec_err_state,
    noData: rule.no_data_state,
  };
};

export const formatGrafanaAlertsRules = (
  data: { [key: string]: any },
  contactPoints: string[],
): RuleProps[] => {
  const statusMapped: { [key: string]: string } = {
    firing: 'alerting',
    inactive: 'ok',
  };

  const alerts: RuleProps[] = [];
  const folders = Object.keys(data);
  folders.map((folder) => {
    const folderData = data[folder];
    folderData.map((group: any) => {
      group.rules.map((rule: any) => {
        addFailSafeAnnotations(rule);
        const grafana_alert = rule.grafana_alert;
        let health = grafana_alert.state.toLowerCase();
        health = statusMapped[health] ? statusMapped[health] : health;

        const { tags: ruleTags, contactPointLabels } = getNonContactPointLabels(
          rule.labels,
          contactPoints,
        );

        alerts.push({
          ...getRuleRow(health, folder, grafana_alert),
          ...{
            annotations: rule.annotations,
            evaluate: {
              every: group.interval,
              for: rule.annotations?.forWindow || rule.for,
            },
            folder,
            labels: rule.labels,
            contactPointLabels,
            type: rule.annotations?.ruleType || RuleType.METRICS,
            contactpoints: contactPointLabels,
            tags: Object.keys(ruleTags).map((key) => `${key}:${ruleTags[key]}`),
          },
        });
      });
    });
  });

  return alerts;
};

const getCountForSidebar = (
  rules: RuleProps[],
): {
  tags: { [key: string]: number };
  status: { [key: string]: number };
  contactpoints: { [key: string]: number };
  type: { [key: string]: number };
  mute: { [key: string]: number };
  folder: { [key: string]: number };
} => {
  const tags: { [key: string]: number } = {};
  const status: { [key: string]: number } = {};
  const contactpoints: { [key: string]: number } = {};
  const type: { [key: string]: number } = {};
  const mute: { [key: string]: number } = {};
  const folder: { [key: string]: number } = {};

  rules.forEach((alert, idx) => {
    alert.tags.forEach((tag) => {
      if (tags[tag] === undefined) {
        tags[tag] = 1;
      } else {
        tags[tag] += 1;
      }
    });

    if (status[alert.status] === undefined) {
      status[alert.status] = 1;
    } else {
      status[alert.status] += 1;
    }

    if (alert.contactPointLabels) {
      alert.contactPointLabels.forEach((contact) => {
        if (contactpoints[contact] === undefined) {
          contactpoints[contact] = 1;
        } else {
          contactpoints[contact] += 1;
        }
      });
    }

    if (type[alert.type] === undefined) {
      type[alert.type] = 1;
    } else {
      type[alert.type] += 1;
    }

    if (alert.mute === undefined) {
      alert.mute = 'Unmuted';
    }

    if (mute[alert.mute] === undefined) {
      mute[alert.mute] = 1;
    } else {
      mute[alert.mute] += 1;
    }

    if (folder[alert.folder] === undefined) {
      folder[alert.folder] = 1;
    } else {
      folder[alert.folder] += 1;
    }
  });

  return { tags, status, contactpoints, type, mute, folder };
};

export const filterAlertsRules = (
  rules: RuleProps[],
  selectedFacetValuesByName: SelectedFacetValuesByName,
): {
  formattedRules: RuleProps[];
  contactpoints: ValueCount[];
  folder: ValueCount[];
  mute: ValueCount[];
  status: ValueCount[];
  tags: ValueCount[];
  type: ValueCount[];
} => {
  const filtered = filterListWithSelectedSidebar(
    rules,
    selectedFacetValuesByName,
  );

  const countFromRules = getCountForSidebar(rules);
  const countFromFiltered = getCountForSidebar(filtered);
  const mergedCount = mergeTwoSidebarCountsValues(
    countFromRules,
    countFromFiltered,
  );

  const { tags, status, contactpoints, type, mute, folder } = mergedCount;

  // sort by status order alerting, pending, ok
  filtered.sort((a, b) => {
    const statusOrder = ['alerting', 'pending', 'ok'];
    return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
  });

  return {
    formattedRules: filtered,
    status: Object.keys(status).map((key) => ({
      value: key,
      count: status[key],
    })),
    tags: Object.keys(tags).map((key) => ({
      value: key,
      count: tags[key],
    })),
    mute: Object.keys(mute).map((key) => ({
      value: key,
      count: mute[key],
    })),
    contactpoints: Object.keys(contactpoints).map((key) => ({
      value: key,
      count: contactpoints[key],
    })),
    type: Object.keys(type).map((key) => ({
      value: key,
      count: type[key],
    })),
    folder: Object.keys(folder).map((key) => ({
      value: key,
      count: folder[key],
    })),
  };
};

export const getDateForQuery = (range: {
  from: number;
  to: number;
}): DateSelection => {
  const endTimeUnix = dayjs().subtract(range.to, 'seconds').unix();
  const startTimeUnix = dayjs().subtract(range.from, 'seconds').unix();

  return { endTimeUnix, startTimeUnix };
};

export const mapMutedAlertsWithRules = (
  rules: RuleProps[],
  mutedAlerts: MutedAlertProps[],
): RuleProps[] => {
  const newRules = [...rules];
  mutedAlerts.forEach((mutedAlert) => {
    if (mutedAlert.status.state === 'active') {
      const matchers = mutedAlert.matchers;
      matchers.forEach((matcher) => {
        const ruleIndex = newRules.findIndex(
          (rule) => rule.name === matcher.value && matcher.name === 'alertname',
        );
        if (ruleIndex !== -1) {
          newRules[ruleIndex].mute = 'Muted';
          newRules[ruleIndex].muteId = mutedAlert.id;
          newRules[ruleIndex].muteEndAt = mutedAlert.endsAt;
        }
      });
    }
  });

  return newRules;
};

export const alertDetailsTimeConversion = (inputTime: string): string => {
  if (!inputTime) return inputTime;
  const time = inputTime.startsWith('now-') ? inputTime.slice(4) : inputTime;
  const seconds = getRollupToSecond(time);
  return convertSecondToReadable(seconds, true);
};

export const getUserCreatedAnnotations = (annotations: {
  [key: string]: string;
}): KeyPairProps[] => {
  const userCreatedAnnotations: KeyPairProps[] = [];
  Object.keys(annotations).forEach((key) => {
    if (!ReservedAnnotationKeys.includes(key)) {
      userCreatedAnnotations.push({ label: key, value: annotations[key] });
    }
  });

  return userCreatedAnnotations;
};

export const extractServiceIdFromSLOAlert = (rule: RuleProps): string => {
  if (!rule) {
    return null;
  }
  const tags = rule.tags || [];
  const serviceId = tags.find((tag) => tag.startsWith('slo_service:'));
  if (!serviceId) return null;
  if (serviceId.split(':').length !== 2) return null;
  return serviceId.split(':')[1];
};

export const extractServiceIdFromAPMAlert = (rule: RuleProps): string => {
  if (!rule) {
    return null;
  }
  const annotations = rule.annotations || {};
  const extraData = JSON.parse(annotations.extraData || '{}');
  return extraData.serviceHash;
};
