import {
  AutocompleteOption,
  AutocompleteV2,
  FacetGroup,
  FacetPicker,
  IconWithLabel,
  ServiceWithLabels,
} from 'components';
import { iconsBySpanType } from 'kfuse-constants';
import { useRequest, useTracesState } from 'hooks';
import { flatten, partition } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { aggregateTable, traceLabelValues } from 'requests';
import {
  DateSelection,
  FacetRegexTerm,
  KeyExists,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  Service,
  SpanFilter,
  ValueCount,
} from 'types';
import { getIsRangeFacet, groupTraceLabels } from 'utils';
import helpText from '../../helpText.js';

const getAttributesByName = (
  colorsByServiceHash: {
    [key: string]: string;
  },
  serviceByHash: Record<string, Service>,
) => ({
  service_hash: {
    forceExpanded: true,
    getSearchedValue: (value: string) => {
      const service = serviceByHash[value];
      return service ? JSON.stringify(service) : '';
    },
    renderName: () => 'Service',
    renderValue: (value: string) => {
      const service = serviceByHash[value];
      return service ? (
        <ServiceWithLabels
          color={colorsByServiceHash[value]}
          distinctLabels={service.distinctLabels || {}}
          labels={service.labels}
          name={service.name}
        />
      ) : null;
    },
  },
  span_type: {
    forceExpanded: true,
    renderValue: (value: string) => (
      <IconWithLabel icon={iconsBySpanType[value]} label={value} />
    ),
  },
});

type Props = {
  colorsByServiceHash: { [key: string]: string };
  colorsByServiceName: { [key: string]: string };
  facetsOfOptions: AutocompleteOption[];
  getLabelValues?: (args: {
    date: DateSelection;
    facetRegex: FacetRegexTerm[];
    keyExists: KeyExists;
    labelName: string;
    selectedFacetRangeByName?: SelectedFacetRangeByName;
    selectedFacetValuesByName?: SelectedFacetValuesByName;
    selectedHcFacetValuesByName?: SelectedFacetValuesByName;
    spanFilter: SpanFilter;
    traceIdSearch?: string;
  }) => Promise<ValueCount[]>;
  showUseFacetsOptionOnSideBar?: boolean;
  hideDuration?: boolean;
  serviceByHash: Record<string, Service>;
  traceLabelNamesRequest: ReturnType<typeof useRequest>;
  tracesState: ReturnType<typeof useTracesState>;
};

const TracesSidebar = ({
  colorsByServiceHash,
  getLabelValues = traceLabelValues,
  facetsOfOptions = [],
  showUseFacetsOptionOnSideBar = false,
  hideDuration,
  serviceByHash,
  traceLabelNamesRequest,
  tracesState,
}: Props) => {
  const {
    dateState,
    facetRegexState,
    keyExistsState,
    selectedQueryIndexString,
    setSelectedQueryIndexString,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    selectedHcFacetValuesByNameState,
    spanFilters,
    traceIdSearch,
  } = tracesState;

  const [date] = dateState;
  const { spanFilter } = spanFilters;
  const attributesByName = useMemo(
    () => getAttributesByName(colorsByServiceHash, serviceByHash),
    [colorsByServiceHash, serviceByHash],
  );

  const clearFacetHandler = (name: string) => () => {
    selectedFacetValuesByNameState.clearFacet(name);
  };

  const clearFacetRangeHandler = (name: string) => () => {
    selectedFacetRangeByNameState.clearFacet(name);
  };

  const handlersByName = (name: string) => ({
    changeFacetRange: selectedFacetRangeByNameState.changeFacetRange({ name }),
    excludeFacetValue: (value: string) => {
      selectedFacetValuesByNameState.excludeFacetValue({ name, value });
    },
    selectOnlyFacetValue: (value: string) => {
      selectedFacetValuesByNameState.selectOnlyFacetValue({ name, value });
    },
    toggleFacetValue: (value: string, allValues: Array<string>) => {
      selectedFacetValuesByNameState.toggleFacetPickerValueCheckbox({
        facetName: name,
        facetValueToToggle: value,
        allFacetValues: allValues,
      });
    },
  });

  const [lastRefreshedAt, setLastRefreshedAt] = useState(null);

  const requestByLabelName = (labelName: string) => () =>
    getLabelValues({
      date,
      facetRegex: facetRegexState.state,
      keyExists: keyExistsState.state,
      labelName,
      selectedFacetRangeByName: selectedFacetRangeByNameState.state,
      selectedFacetValuesByName: selectedFacetValuesByNameState.state,
      selectedHcFacetValuesByName: selectedHcFacetValuesByNameState.state,
      spanFilter,
      traceIdSearch,
    });

  const maxByLabelName = (labelName: string) => () =>
    aggregateTable({
      date,
      facetRegex: facetRegexState.state,
      keyExists: keyExistsState.state,
      selectedFacetValuesByName: selectedFacetValuesByNameState.state,
      selectedHcFacetValuesByName: selectedHcFacetValuesByNameState.state,
      spanFilter,
      traceIdSearch,
    }).then((result) =>
      result.length ? { min: 0, max: result[0].value } : { min: 0, max: 10 },
    );

  useEffect(() => {
    setLastRefreshedAt(new Date().valueOf());
  }, [
    date,
    facetRegexState.state,
    keyExistsState.state,
    selectedFacetValuesByNameState.state,
    selectedFacetRangeByNameState.state,
    selectedHcFacetValuesByNameState.state,
    spanFilter,
    traceIdSearch,
  ]);

  const { facetNamesByGroup, ungrouped: ungroupedWithoutDuration } =
    useMemo(() => {
      if (traceLabelNamesRequest.result) {
        return groupTraceLabels(traceLabelNamesRequest.result);
      }

      return {
        facetNamesByGroup: {},
        ungrouped: [],
      };
    }, [traceLabelNamesRequest.result]);

  const ungrouped = [
    ...ungroupedWithoutDuration,
    ...(hideDuration ? [] : ['duration']),
  ];

  const renderName = (s: string) => {
    if (s === 'component') {
      return 'Component';
    }

    if (s === 'duration') {
      return 'Duration (ms)';
    }

    if (s === 'error') {
      return 'Error';
    }

    if (s === 'service_name') {
      return 'Service Name';
    }

    if (s === 'span_name') {
      return 'Span Name';
    }

    if (s === 'span_type') {
      return 'Span Type';
    }

    return s;
  };

  const renderHelp = (s) => {
    if (helpText.hasOwnProperty(s)) {
      return helpText[s];
    }

    return null;
  };

  const sortedUngroupedNames = flatten(
    partition(
      ungrouped.sort((a, b) => renderName(a).localeCompare(renderName(b))),
      (group) => attributesByName[group]?.forceExpanded,
    ),
  );

  const renderPlaceholderText = (name: string) =>
    `No attributes for ${name === 'service_hash' ? 'Service' : name}`;
  const renderGroup = (group, idx, arr) => (
    <FacetGroup
      group={group}
      key={group}
      isLastListItem={idx === arr.length - 1}
    >
      {facetNamesByGroup[group]
        .filter((name) => name !== 'service_hash')
        .map((name) => (
          <FacetPicker
            clearFacet={clearFacetHandler(name)}
            isRangeFacet={getIsRangeFacet(name)}
            key={name}
            lastRefreshedAt={lastRefreshedAt}
            name={name}
            info={renderHelp(name)}
            renderName={renderName}
            renderPlaceholderText={renderPlaceholderText}
            request={requestByLabelName(name)}
            selectedFacetValues={
              selectedFacetValuesByNameState.state[name] || {}
            }
            selectedFacetRange={
              selectedFacetRangeByNameState.state[name] || null
            }
            {...handlersByName(name)}
          />
        ))}
    </FacetGroup>
  );

  return (
    <>
      {showUseFacetsOptionOnSideBar && (
        <div className="ml-2.5 button-group log-analytics-facets-query-selector">
          <div className="button-group__item button-group__item--label">
            Use Facets with
          </div>
          <AutocompleteV2
            className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value m-2"
            onChange={setSelectedQueryIndexString}
            options={facetsOfOptions}
            value={selectedQueryIndexString || '0'}
          />
        </div>
      )}
      {sortedUngroupedNames.map((name: string) => {
        const attributes = attributesByName[name];
        const isRangeFacet = getIsRangeFacet(name);
        return (
          <FacetPicker
            clearFacet={
              isRangeFacet
                ? clearFacetRangeHandler(name)
                : clearFacetHandler(name)
            }
            isRangeFacet={isRangeFacet}
            key={name}
            lastRefreshedAt={lastRefreshedAt}
            name={name}
            info={helpText[name]}
            renderName={renderName}
            renderPlaceholderText={renderPlaceholderText}
            request={
              isRangeFacet ? maxByLabelName(name) : requestByLabelName(name)
            }
            selectedFacetRange={
              selectedFacetRangeByNameState.state[name] || null
            }
            selectedFacetValues={
              selectedFacetValuesByNameState.state[name] || {}
            }
            {...handlersByName(name)}
            {...(attributes ? attributes : {})}
          />
        );
      })}
      <div className="left-sidebar__divider" />
      {['cloud', 'host', 'kubernetes']
        .filter(
          (group) =>
            facetNamesByGroup[group] && facetNamesByGroup[group].length,
        )
        .map(renderGroup)}
      <div className="left-sidebar__divider" />
      {[
        ...Object.keys(facetNamesByGroup)
          .filter(
            (group) =>
              group !== 'kubernetes' && group !== 'cloud' && group !== 'host',
          )
          .sort(),
      ]
        .filter(
          (group) =>
            facetNamesByGroup[group] && facetNamesByGroup[group].length,
        )
        .map(renderGroup)}
    </>
  );
};

export default TracesSidebar;
