import { Series } from 'uplot';
import { getColorForOneSeries, replaceLegendVariables } from 'utils';
import { CHART_LINE_WIDTH_MEDIUM } from 'utils/chartConfig';

import { DashboardPanelProps } from 'types';

export const formatDashboardLegend = ({
  seriesIdx,
  promIndex,
  metric,
  targets,
  colorStartIdx,
}: {
  seriesIdx: number;
  promIndex: number;
  metric: { [key: string]: any };
  targets: DashboardPanelProps['targets'];
  colorStartIdx: number;
}): Series => {
  const label = getFomattedLegend(promIndex, metric, targets);

  return {
    label,
    points: { show: false },
    stroke: getColorForOneSeries(metric, seriesIdx, colorStartIdx),
    show: true,
    width: CHART_LINE_WIDTH_MEDIUM,
  };
};

export const getFomattedLegend = (
  promIndex: number,
  metric: { [key: string]: any },
  targets: DashboardPanelProps['targets'],
): string => {
  const legendFormat = getActiveLegendFomart(promIndex, targets);
  if (legendFormat) {
    return replaceLegendVariables(legendFormat, metric);
  }

  const metricName = metric.__name__;
  delete metric.__name__;

  if (Object.keys(metric).length === 1) {
    return `${metricName ? metricName : ''}${Object.values(metric)[0]}`;
  }

  return `${metricName ? metricName : ''}${JSON.stringify(metric)}`;
};

export const getFormattedLegendByRefId = (
  refId: string,
  metric: { [key: string]: any },
  targets: DashboardPanelProps['targets'],
): string => {
  const legendFormat = getLegendFomartByRefId(refId, targets);
  if (legendFormat) {
    return replaceLegendVariables(legendFormat, metric);
  }

  const metricName = metric.__name__;
  delete metric.__name__;

  if (Object.keys(metric).length === 1) {
    return `${metricName ? metricName : ''}${Object.values(metric)[0]}`;
  }

  return `${metricName ? metricName : ''}${JSON.stringify(metric)}`;
};

const getLegendFomartByRefId = (
  refId: string,
  targets: DashboardPanelProps['targets'],
): string => {
  const target = targets.find((t) => t.refId === refId);
  if (!target) {
    return null;
  }

  const legendFormat = target.legendFormat;
  if (legendFormat && legendFormat !== '__auto') {
    return legendFormat;
  }

  return null;
};

const getActiveLegendFomart = (
  promIndex: number,
  targets: DashboardPanelProps['targets'],
): string => {
  const activeTargets = targets.filter((target) => !target.hide);

  if (activeTargets.length === 0) {
    return null;
  }

  if (activeTargets[promIndex].expression) {
    return activeTargets[promIndex].refId;
  }

  const legendFormat = activeTargets[promIndex]?.legendFormat;
  if (legendFormat && legendFormat !== '__auto') {
    return legendFormat;
  }

  return null;
};

export const getStatFormattedLegend = (
  promIndex: number,
  metric: { [key: string]: any },
  targets: DashboardPanelProps['targets'],
): string => {
  const legendFormat = getActiveLegendFomart(promIndex, targets);
  if (legendFormat) {
    return replaceLegendVariables(legendFormat, metric);
  }

  return '';
};
