import { ApmServiceMapTooltip } from 'components';
import { useRequest } from 'hooks';
import React, { useEffect } from 'react';
import { queryRange } from 'requests';
import { DateSelection } from 'types';
import { getTimeParameter } from './utils';

type FiltersArgs = {
  clientServiceHash: string;
  clientServiceName: string;
  serviceHash: string;
  serviceName: string;
};

const getFilterString = ({
  clientServiceHash,
  clientServiceName,
  serviceHash,
  serviceName,
}: FiltersArgs) => {
  const clientValue =
    clientServiceHash.indexOf('UNKNOWN') > -1 ? 'UNKNOWN' : clientServiceHash;
  const clientServiceNameString =
    clientValue === 'UNKNOWN'
      ? `,client_service_name="${clientServiceName}"`
      : '';

  const serverValue =
    serviceHash.indexOf('UNKNOWN') > -1 ? 'UNKNOWN' : serviceHash;
  const serverServiceNameString =
    serverValue === 'UNKNOWN' ? `,service_name="${serviceName}"` : '';

  return `client_service_hash="${clientValue}"${clientServiceNameString},service_hash="${serverValue}"${serverServiceNameString}`;
};

type QueryArgs = {
  date: DateSelection;
  clientServiceDistinctLabels: Record<string, string>;
  clientServiceHash: string;
  clientServiceName: string;
  serviceDistinctLabels: Record<string, string>;
  serviceHash: string;
  serviceName: string;
};

const errorsQuery = ({
  date,
  clientServiceDistinctLabels,
  clientServiceHash,
  clientServiceName,
  serviceDistinctLabels,
  serviceHash,
  serviceName,
}: QueryArgs) => {
  const filtersString = getFilterString({
    clientServiceHash,
    clientServiceName,
    serviceHash,
    serviceName,
  });
  const timeParameter = getTimeParameter(date);
  return `sum by (service_name, client_service_name) (rate(edge_latency_count{error="true",${filtersString}}[${timeParameter}]))`;
};

const latencyQuery = ({
  date,
  clientServiceDistinctLabels,
  clientServiceHash,
  clientServiceName,
  serviceDistinctLabels,
  serviceHash,
  serviceName,
}: QueryArgs) => {
  const filtersString = getFilterString({
    clientServiceHash,
    clientServiceName,
    serviceHash,
    serviceName,
  });
  const timeParameter = getTimeParameter(date);
  return `max (max_over_time(edge_latency_max{${filtersString}}[${timeParameter}])) by (service_name,client_service_name,span_name,client_span_name)`;
};

const requestsQuery = ({
  date,
  clientServiceDistinctLabels,
  clientServiceHash,
  clientServiceName,
  serviceDistinctLabels,
  serviceHash,
  serviceName,
}: QueryArgs) => {
  const filtersString = getFilterString({
    clientServiceHash,
    clientServiceName,
    serviceHash,
    serviceName,
  });
  const timeParameter = getTimeParameter(date);
  return `sum by (service_name, client_service_name) (rate(edge_latency_count{${filtersString}}[${timeParameter}]))`;
};

const queryValue = (date: DateSelection, query: string) =>
  queryRange({ date, instant: true, query }).then((result) =>
    result.length && result[0].value.length > 1
      ? Number(result[0].value[1])
      : 0,
  );

const queryValues = async ({
  date,
  clientServiceHash,
  clientServiceDistinctLabels,
  clientServiceName,
  serviceDistinctLabels,
  serviceHash,
  serviceName,
}) => {
  const values = await Promise.all([
    queryValue(
      date,
      errorsQuery({
        date,
        clientServiceDistinctLabels,
        clientServiceHash,
        clientServiceName,
        serviceDistinctLabels,
        serviceHash,
        serviceName,
      }),
    ),
    queryValue(
      date,
      latencyQuery({
        date,
        clientServiceDistinctLabels,
        clientServiceHash,
        clientServiceName,
        serviceDistinctLabels,
        serviceName,
        serviceHash,
      }),
    ),
    queryValue(
      date,
      requestsQuery({
        date,
        clientServiceDistinctLabels,
        clientServiceHash,
        clientServiceName,
        serviceDistinctLabels,
        serviceName,
        serviceHash,
      }),
    ),
  ]);

  return {
    errors: values[0],
    latency: values[1],
    requests: values[2],
  };
};

const TracesServiceMapLinkTooltipEdge = ({
  date,
  clientServiceHash,
  clientServiceDistinctLabels,
  clientServiceName,
  label,
  serviceDistinctLabels,
  serviceHash,
  serviceName,
  style = {},
}) => {
  const valuesRequest = useRequest(queryValues);

  useEffect(() => {
    valuesRequest.call({
      date,
      clientServiceDistinctLabels,
      clientServiceHash,
      clientServiceName,
      serviceDistinctLabels,
      serviceHash,
      serviceName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);
  return (
    <div className="traces__service-map__link__tooltip">
      <ApmServiceMapTooltip
        isLoading={valuesRequest.isLoading}
        style={style}
        title={label}
        values={valuesRequest.result || {}}
      />
    </div>
  );
};

export default TracesServiceMapLinkTooltipEdge;
