import {
  traceqlDataTransformer,
  traceqlDataTransformerInstant,
} from '../rangeTransformer';
import labelTransformer from '../labelTransformer/labelTransformer';
import traceTimeseriesDataTransformer from './traceTimeseriesDataTransformer';

const traceDataTransformer = (isInstant?: boolean) => {
  if (isInstant) {
    return [
      {
        id: 'prometheusToDataFrame',
        func: traceqlDataTransformerInstant,
      },
    ];
  }

  return [
    {
      id: 'prometheusToDataFrame',
      func: traceqlDataTransformer,
    },
    {
      id: 'transformToDisplayLabel',
      func: labelTransformer,
    },
    {
      id: 'transformToTimeseries',
      func: traceTimeseriesDataTransformer,
    },
  ];
};

export default traceDataTransformer;
