import { TooltipTrigger } from 'components';
import { Button } from 'components/base';
import { noop } from 'lodash';
import React, { ReactElement } from 'react';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { BiExport } from 'react-icons/bi';
import { MdNotificationAdd, MdSave } from 'react-icons/md';

const AnalyticsChartToolbar = ({
  onCreateAlert,
  onExportClick,
  onSaveClick,
  onViewFullscreen,
  saveButtonDisabledReasonIfDisabled,
}: {
  onCreateAlert?: () => void;
  onExportClick?: () => void;
  onSaveClick?: () => void;
  onViewFullscreen?: () => void;
  saveButtonDisabledReasonIfDisabled?: string | null;
}): ReactElement => {
  const shouldDisableSaveButton =
    'string' === typeof saveButtonDisabledReasonIfDisabled;
  return (
    <>
      {onCreateAlert && (
        <Button
          className="ml-1"
          variant="icon-outline"
          size="sm"
          onClick={onCreateAlert}
        >
          <TooltipTrigger tooltip="Create Alert">
            <MdNotificationAdd />
          </TooltipTrigger>
        </Button>
      )}
      {onExportClick && (
        <Button
          className="ml-1"
          variant="icon-outline"
          size="sm"
          onClick={onExportClick}
        >
          <TooltipTrigger tooltip="Export">
            <BiExport />
          </TooltipTrigger>
        </Button>
      )}
      {onSaveClick && (
        <Button
          className="ml-1"
          variant="icon-outline"
          size="sm"
          disabled={shouldDisableSaveButton}
          onClick={shouldDisableSaveButton ? noop : onSaveClick}
        >
          <TooltipTrigger
            tooltip={
              shouldDisableSaveButton
                ? saveButtonDisabledReasonIfDisabled
                : 'Save Metric'
            }
          >
            <MdSave />
          </TooltipTrigger>
        </Button>
      )}
      {onViewFullscreen && (
        <Button
          className="ml-1"
          variant="icon-outline"
          onClick={onViewFullscreen}
          size="sm"
        >
          <TooltipTrigger tooltip="View in fullscreen">
            <AiOutlineFullscreen />
          </TooltipTrigger>
        </Button>
      )}
    </>
  );
};

export default AnalyticsChartToolbar;
