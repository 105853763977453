import dayjs from 'dayjs';
import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { RUMEvent, RUMQueryServiceResponse, RumEventType } from '../types';
import { SelectedFacetValuesByName } from 'types/selectedFacets';
import { buildRumFilter } from './utils';

type Args = {
  eventType: RumEventType;
  endTimeUnix: number;
  startTimeUnix: number;
  idSearch: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
};

const VIEW_FIELDS = `[
  "view.id",
  "view.url_path",
  "view.loading_type",
  "view.largest_contentful_paint",
  "geo.country",
  "env",
  "service",
  "version",
  "session.type",
  "usr.id",
  "usr.name",
  "usr.email",
  "geo.country",
  "geo.city",
  "device.type",
  "browser.name",
  "os.name",
  "view.loading_type",
  "view.loading_time",
  "view.cumulative_layout_shift",
  "view.first_input_delay",
  "view.largest_contentful_paint",
  "view.interaction_to_next_paint",
  "view.time_spent",
]`;

const ACTION_FIELDS = `[
  "action.id",
  "action.type",
  "action.loading_time",
  "action.frustration.type",
  "application.id",
  "service",
  "device.type",
  "device.model",
  "display.viewport.width",
  "display.viewport.height",
  "view.name",
  "view.id",
]`;

const getRumEvents = async ({
  eventType,
  endTimeUnix,
  startTimeUnix,
  idSearch,
  selectedFacetValuesByName,
}: Args): Promise<RUMEvent[]> => {
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  return queryRumService<RUMQueryServiceResponse, 'eventDetail'>(`
  query {
    eventDetail(
      eventType: ${eventType},
      timestamp: "${endTime.format()}"
      durationSecs: ${durationSecs}
      filter: ${buildRumFilter({
        idSearch,
        selectedFacetValuesByName,
      })}
      fields: ${eventType === RumEventType.VIEW ? VIEW_FIELDS : ACTION_FIELDS}
    ) {
      events {
        time
        data
      }
      cursor
    }
  }
  `).then((data) => data?.eventDetail?.events || [], onPromiseError);
};

export default getRumEvents;
