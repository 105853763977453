import { BasicLogsTable, useColumnsState, useTableOptions } from 'components';
import {
  getLegacyLogsStateFromFiltersState,
  useFiltersState,
  useKeyExistsState,
} from 'hooks';
import React, { ReactElement, useMemo } from 'react';

import { getColumns, MESSAGE, SOURCE, TIMESTAMP } from 'screens/Logs/constants';
import { DashboardPanelComponentProps } from 'types/Dashboard';
import tryJsonParse from 'utils/tryJsonParse';

const DashboardPanelLogsTable = ({
  dashboardState,
  isInView = true,
  panel,
  panelIndex,
}: DashboardPanelComponentProps): ReactElement => {
  const { date, reloadPanels, setReloadPanels } = dashboardState;
  const { expr } = panel.targets[0];

  const parsedExpr = useMemo(
    () => tryJsonParse(expr),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [panel],
  );

  const filtersState = useFiltersState({
    initialState: parsedExpr?.filters || [],
  });

  const logsState = useMemo(
    () => ({ date, ...getLegacyLogsStateFromFiltersState({ filtersState }) }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, panel, filtersState.state],
  );

  const tableOptions = useTableOptions(
    false,
    parsedExpr?.tableOptions || undefined,
  );
  const customColumnsState = useKeyExistsState({
    urlStateKey: 'customColumns',
    shouldWriteToUrl: false,
    initalState: parsedExpr?.tableColumns?.customColumns || {},
  });

  const baseColumns = useMemo(
    () => getColumns({ customColumnsState, logsState }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customColumnsState.state],
  );
  const columnsState = useColumnsState({
    columns: baseColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: parsedExpr.tableColumns?.selectedColumns || {
        [MESSAGE]: 1,
        [SOURCE]: 1,
        [TIMESTAMP]: 1,
      },
    },
    key: 'logs-table',
    shouldUseLocalStorage: false,
  });

  return (
    <BasicLogsTable
      columnsState={columnsState}
      customColumnsState={customColumnsState}
      disableAddCustomColumn={true}
      isInView={isInView}
      isReload={Boolean(reloadPanels[panelIndex])}
      logsState={logsState}
      onLogsLoaded={() =>
        setReloadPanels((prev) => ({ ...prev, [panelIndex]: false }))
      }
      shouldDisableFilterActions
      tableOptions={tableOptions}
    />
  );
};

export default DashboardPanelLogsTable;
