import { delimiter } from 'kfuse-constants';
import dayjs from 'dayjs';
import { useReducer } from 'react';
import {
  Action,
  ActionType,
  DateSelection,
  FingerprintQueryProps,
  LogEvent,
  LogsFilter,
  LogsState,
  LogsWorkbook,
  LogsWorkbooksState,
  SearchItemProps,
} from 'types';
import { getFacetKey } from 'utils';
import clearSelectedFacetValuesByFacetKey from './clearSelectedFacetValuesByFacetKey';
import readLogsStateFromUrlParams from './readLogsStateFromUrlParams';
import writeLogsStateToUrlParamsAndLocalStorage from './writeLogsStateToUrlParamsAndLocalStorage';
import { FacetBase } from '../../types';
import { useThemeContext, useToaster } from 'components';
import { AddToastProps } from 'components/Toasts/context';

const endTimeUnix = dayjs().unix();
const startTimeUnix = dayjs()
  .subtract(60 * 5, 'seconds')
  .unix();

const getNewLogsState = (): LogsState => ({
  date: {
    startLabel: 'now-5m',
    endLabel: 'now',
    endTimeUnix,
    startTimeUnix,
  },
  keyExists: {},
  filterByFacets: [],
  filterOrExcludeByFingerprint: {},
  fingerprintQuery: {
    countOf: '*',
    groupBy: [`Core${delimiter}source${delimiter}string`],
    limit: 100,
    sortBy: '',
    sortOrder: 'Desc',
  },
  searchTerms: [],
  selectedFacetRanges: {},
  selectedFacetValues: {},
});

const getNewLogsWorkbook = (): LogsWorkbook => ({
  name: '',
  logsState: getNewLogsState(),
  history: [],
  createdAt: new Date(),
});

const getInitialLogsWorkbook = ({
  addToast,
  shouldWriteToUrl,
  utcTimeEnabled,
}: {
  addToast: (props: AddToastProps) => void;
  shouldWriteToUrl: boolean;
  utcTimeEnabled: boolean;
}): LogsWorkbook => ({
  name: '',
  logsState: shouldWriteToUrl
    ? readLogsStateFromUrlParams({ addToast, utcTimeEnabled })
    : getNewLogsState(),
  history: [],
  createdAt: new Date(),
});

const getInitialLogsWorkbooksState = ({
  addToast,
  shouldWriteToUrl,
  utcTimeEnabled,
}: {
  addToast: (props: AddToastProps) => void;
  shouldWriteToUrl: boolean;
  utcTimeEnabled: boolean;
}): LogsWorkbooksState => ({
  currentWorkbookIndex: 0,
  isReady: false,
  workbooks: [
    getInitialLogsWorkbook({ addToast, shouldWriteToUrl, utcTimeEnabled }),
  ],
});

const addFilterByFacet = (
  filterByFacets: LogsState['filterByFacets'],
  payload: SearchItemProps,
): LogsState['filterByFacets'] => {
  const { dataType, facetName, value, operator } = payload;
  const newFilterByFacets = [...filterByFacets];
  const dataTypePrefix = dataType ? `${dataType}${delimiter}` : '';
  newFilterByFacets.push(`${dataTypePrefix}${facetName}${operator}"${value}"`);
  return newFilterByFacets;
};

const addSearchTerm = (
  prevSearchTerms: LogsState['searchTerms'],
  searchTerm: string,
): LogsState['searchTerms'] => {
  const nextSearchTerms = [...prevSearchTerms, searchTerm];
  return nextSearchTerms;
};

const applyDeselectedFacetValues = (
  prevDeselectedFacetValues: LogsState['selectedFacetValues'],
  {
    component,
    facetName,
    facetType,
    facetValuesToSelect,
  }: {
    component: string;
    facetName: string;
    facetType: string;
    facetValuesToSelect: { [key: string]: number };
  },
): LogsState['selectedFacetValues'] => {
  const facetKey = `${component}${delimiter}${facetName}${delimiter}${
    facetType || 'STRING'
  }`;
  const nextSelectedFacetValues = clearSelectedFacetValuesByFacetKey(
    facetKey,
    prevDeselectedFacetValues,
  );

  return {
    ...nextSelectedFacetValues,
    ...facetValuesToSelect,
  };
};

const clearFilterOrExcludeByFingerprint = (
  prevFilterOrExcludeByFingerprint: LogsState['filterOrExcludeByFingerprint'],
  fpHash: string,
): LogsState['filterOrExcludeByFingerprint'] => {
  const nextFilterOrExcludeByFingerprint = {
    ...prevFilterOrExcludeByFingerprint,
  };
  delete nextFilterOrExcludeByFingerprint[fpHash];
  return nextFilterOrExcludeByFingerprint;
};

const changeFacetRange = (
  prevSelectedFacetRanges: LogsState['selectedFacetRanges'],
  {
    facetKey,
    range,
  }: {
    facetKey: string;
    range: {
      lower: number;
      upper: number;
    };
  },
): LogsState['selectedFacetRanges'] => {
  return {
    ...prevSelectedFacetRanges,
    [facetKey]: range,
  };
};

const excludeFingerprint = (
  prevFilterOrExcludeByFingerprint: LogsState['filterOrExcludeByFingerprint'],
  fpHash: string,
): LogsState['filterOrExcludeByFingerprint'] => {
  const nextFilterOrExcludeByFingerprint = {
    ...prevFilterOrExcludeByFingerprint,
  };
  nextFilterOrExcludeByFingerprint[fpHash] = false;
  return nextFilterOrExcludeByFingerprint;
};

const filterFingerpint = (
  prevFilterOrExcludeByFingerprint: LogsState['filterOrExcludeByFingerprint'],
  fpHash: string,
): LogsState['filterOrExcludeByFingerprint'] => {
  const nextFilterOrExcludeByFingerprint = {
    ...prevFilterOrExcludeByFingerprint,
  };
  nextFilterOrExcludeByFingerprint[fpHash] = true;

  return nextFilterOrExcludeByFingerprint;
};

const filterOnlyFacetValueFromLogEventDetail = (
  prevDeselectedFacetValues: LogsState['selectedFacetValues'],
  {
    name,
    source,
    type,
    value,
    exclude = false,
  }: {
    name: string;
    source: string;
    type: string;
    value: string;
    exclude?: boolean;
  },
): LogsState['selectedFacetValues'] => {
  const facetKey = `${source}${delimiter}${name}${delimiter}${type}`;
  const facetValueCompositeKey = `${facetKey}${delimiter}${value}`;
  const nextSelectedFacetValues: {
    [key: string]: number;
  } = clearSelectedFacetValuesByFacetKey(facetKey, prevDeselectedFacetValues);
  nextSelectedFacetValues[facetValueCompositeKey] = exclude ? 0 : 1;
  return nextSelectedFacetValues;
};

const removeFilterByFacetByIndex = (
  filterByFacets: LogsState['filterByFacets'],
  index: number,
): LogsState['filterByFacets'] => {
  const newFilterByFacets = [...filterByFacets];
  newFilterByFacets.splice(index, 1);
  return newFilterByFacets;
};

const removeSearchTermByIndex = (
  prevSearchTerms: LogsState['searchTerms'],
  i: number,
): LogsState['searchTerms'] => {
  const nextSearchTerms = [...prevSearchTerms];
  nextSearchTerms.splice(i, 1);

  return nextSearchTerms;
};

const resetFacet = (
  prevSelectedFacetValues: LogsState['selectedFacetValues'],
  facet: FacetBase,
): LogsState['selectedFacetValues'] => {
  const facetKey = getFacetKey(facet);

  const nextSelectedFacetValues = clearSelectedFacetValuesByFacetKey(
    facetKey,
    prevSelectedFacetValues,
  );

  return nextSelectedFacetValues;
};

const resetFacetRange = (
  prevSelectedFacetRanges: LogsState['selectedFacetRanges'],
  facet: FacetBase,
): LogsState['selectedFacetRanges'] => {
  const facetKey = getFacetKey(facet);

  const nextSelectedFacetRanges = { ...prevSelectedFacetRanges };
  delete nextSelectedFacetRanges[facetKey];

  return nextSelectedFacetRanges;
};

const toggleKeyExists = (
  prevKeyExists: LogsState['keyExists'],
  { component, name, type, isEnabled }: FacetBase & { isEnabled: number },
): LogsState['keyExists'] => {
  const nextKeyExists = { ...prevKeyExists };
  const facetKeyWithType = `${component}${delimiter}${name}${delimiter}${type}`;
  if (typeof isEnabled === 'number') {
    nextKeyExists[facetKeyWithType] = isEnabled;
    return nextKeyExists;
  }

  const toggledEnabled = prevKeyExists[facetKeyWithType] ? 0 : 1;
  if (toggledEnabled) {
    nextKeyExists[facetKeyWithType] = 1;
    return nextKeyExists;
  }

  delete nextKeyExists[facetKeyWithType];
  return nextKeyExists;
};

const logsStateReducer = (state: LogsState, action: Action): LogsState => {
  switch (action.type) {
    case ActionType.ADD_FILTER_BY_FACET:
      return {
        ...state,
        filterByFacets: addFilterByFacet(state.filterByFacets, action.payload),
      };

    case ActionType.ADD_SEARCH_TERM:
      return {
        ...state,
        searchTerms: addSearchTerm(state.searchTerms, action.payload),
      };

    case ActionType.APPLY_DESELECTED_FACET_VALUES:
      return {
        ...state,
        selectedFacetValues: applyDeselectedFacetValues(
          state.selectedFacetValues,
          action.payload,
        ),
      };

    case ActionType.CHANGE_FACET_RANGE:
      return {
        ...state,
        selectedFacetRanges: changeFacetRange(
          state.selectedFacetRanges,
          action.payload,
        ),
      };

    case ActionType.CLEAR:
      return {
        ...getNewLogsState(),
        date: state.date,
      };

    case ActionType.CLEAR_FILTER_OR_EXCLUDE_BY_FINGERPRINT:
      return {
        ...state,
        filterOrExcludeByFingerprint: clearFilterOrExcludeByFingerprint(
          state.filterOrExcludeByFingerprint,
          action.payload,
        ),
      };

    case ActionType.EXCLUDE_FINGERPRINT:
      return {
        ...state,
        filterOrExcludeByFingerprint: excludeFingerprint(
          state.filterOrExcludeByFingerprint,
          action.payload,
        ),
      };

    case ActionType.FILTER_FINGERPRINT:
      return {
        ...state,
        filterOrExcludeByFingerprint: filterFingerpint(
          state.filterOrExcludeByFingerprint,
          action.payload,
        ),
      };

    case ActionType.FILTER_ONLY_FACET_VALUE_FROM_LOG_EVENT_DETAIL:
      return {
        ...state,
        selectedFacetValues: filterOnlyFacetValueFromLogEventDetail(
          state.selectedFacetValues,
          action.payload,
        ),
      };

    case ActionType.REMOVE_FILTER_BY_FACET_BY_INDEX:
      return {
        ...state,
        filterByFacets: removeFilterByFacetByIndex(
          state.filterByFacets,
          action.payload,
        ),
      };

    case ActionType.REMOVE_SEARCH_TERM_BY_INDEX:
      return {
        ...state,
        searchTerms: removeSearchTermByIndex(state.searchTerms, action.payload),
      };

    case ActionType.RESET_FACET:
      return {
        ...state,
        selectedFacetValues: resetFacet(
          state.selectedFacetValues,
          action.payload,
        ),
      };

    case ActionType.RESET_FACET_RANGE:
      return {
        ...state,
        selectedFacetRanges: resetFacetRange(
          state.selectedFacetRanges,
          action.payload,
        ),
      };

    case ActionType.SET_DATE:
      return {
        ...state,
        date: action.payload,
      };

    case ActionType.SET_DATE_ZOOMED:
      return {
        ...state,
        date: {
          ...state.date,
          ...action.payload,
        },
      };

    case ActionType.SET_LOGS_STATE:
      return action.payload;

    case ActionType.TOGGLE_FACET_VALUE:
      return {
        ...state,
        selectedFacetValues: action.payload.nextSelectionByFacetKeys,
      };

    case ActionType.SET_FINGERPRINT_QUERY:
      return {
        ...state,
        fingerprintQuery: action.payload,
      };

    case ActionType.TOGGLE_KEY_EXISTS:
      return {
        ...state,
        keyExists: toggleKeyExists(state.keyExists, action.payload),
      };

    case ActionType.REPLACE_LOGS_FILTERS:
      return {
        ...state,
        filterByFacets: action.payload.filterByFacets,
        searchTerms: action.payload.searchTerms,
        selectedFacetRanges: action.payload.selectedFacetRanges,
        selectedFacetValues: action.payload.selectedFacetValues,
        keyExists: action.payload.keyExists,
      };

    default:
      return state;
  }
};

const logsWorkbookReducer = (
  state: LogsWorkbook,
  action: Action,
): LogsWorkbook => {
  if (action.type === ActionType.RESTORE_HISTORY_ENTRY_BY_INDEX) {
    const historyEntry = state.history[action.payload];
    return {
      ...state,
      history: state.history.slice(0, action.payload + 1),
      logsState: historyEntry.logsState,
      saved: false,
    };
  }

  if (action.type === ActionType.CLEAR_SELECTED_LOG_FROM_CONTEXT) {
    return {
      ...state,
      selectedLogFromContext: null,
    };
  }

  if (action.type === ActionType.SET_WORKBOOK_NAME) {
    return {
      ...state,
      name: action.payload,
      saved: true,
    };
  }

  const nextLogsState = logsStateReducer(state.logsState, action);
  writeLogsStateToUrlParamsAndLocalStorage(nextLogsState);
  return {
    ...state,
    logsState: nextLogsState,
    history: [
      ...state.history,
      { action, createdAt: new Date(), logsState: nextLogsState, userId: null },
    ],
    saved: false,
  };
};

const reducer =
  (shouldWriteToUrl: boolean) =>
  (state: LogsWorkbooksState, action: Action): LogsWorkbooksState => {
    if (typeof action.index === 'number') {
      const nextWorkbooks = [...state.workbooks];
      const nextWorkbook = logsWorkbookReducer(
        state.workbooks[action.index],
        action,
      );
      nextWorkbooks[action.index] = nextWorkbook;
      return {
        ...state,
        workbooks: nextWorkbooks,
      };
    }

    switch (action.type) {
      case ActionType.ADD_LOGS_WORKBOOK:
        const newLogsWorkbook = getNewLogsWorkbook();
        if (shouldWriteToUrl) {
          writeLogsStateToUrlParamsAndLocalStorage(newLogsWorkbook.logsState);
        }

        return {
          ...state,
          currentWorkbookIndex: state.workbooks.length,
          workbooks: [...state.workbooks, newLogsWorkbook],
        };

      case ActionType.REMOVE_WORKBOOK_BY_INDEX: {
        const nextWorkbooks = [...state.workbooks];
        nextWorkbooks.splice(action.payload, 1);

        const nextCurrentWorkbookIndex = Math.min(
          state.currentWorkbookIndex,
          nextWorkbooks.length - 1,
        );

        if (shouldWriteToUrl) {
          writeLogsStateToUrlParamsAndLocalStorage(
            nextWorkbooks[nextCurrentWorkbookIndex].logsState,
          );
        }

        return {
          ...state,
          currentWorkbookIndex: nextCurrentWorkbookIndex,
          workbooks: nextWorkbooks,
        };
      }

      case ActionType.SHOW_IN_CONTEXT: {
        const newLogsWorkbook = getNewLogsWorkbook();
        newLogsWorkbook.name = 'From Context';
        newLogsWorkbook.logsState = {
          ...newLogsWorkbook.logsState,
          ...action.payload.logsState,
        };
        newLogsWorkbook.selectedLogFromContext =
          action.payload.selectedLogFromContext;

        const nextWorkbooks = [...state.workbooks, newLogsWorkbook];

        return {
          ...state,
          currentWorkbookIndex: state.currentWorkbookIndex + 1,
          workbooks: nextWorkbooks,
        };
      }

      case ActionType.SET_CURRENT_WORKBOOK_INDEX:
        if (state.workbooks[action.payload]?.logsState) {
          if (shouldWriteToUrl) {
            writeLogsStateToUrlParamsAndLocalStorage(
              state.workbooks[action.payload]?.logsState,
            );
          }
        }

        return {
          ...state,
          currentWorkbookIndex: action.payload,
        };

      case ActionType.SET_WORKBOOKS:
        return {
          ...state,
          currentWorkbookIndex: action.payload.length
            ? action.payload.length - 1
            : 0,
          isReady: true,
          workbooks: action.payload.length ? action.payload : state.workbooks,
        };
      default:
        return state;
    }
  };

type Options = {
  shouldWriteToUrl: boolean;
};

const useLogsWorkbooksState = (options?: Options) => {
  const { addToast } = useToaster();
  const themeContext = useThemeContext();
  const { utcTimeEnabled } = themeContext || {};

  const shouldWriteToUrl =
    typeof options?.shouldWriteToUrl === 'boolean'
      ? options.shouldWriteToUrl
      : true;
  const [state, dispatch] = useReducer(
    reducer(shouldWriteToUrl),
    getInitialLogsWorkbooksState({
      addToast,
      shouldWriteToUrl,
      utcTimeEnabled,
    }),
  );
  const addLogsWorkbook = () => {
    dispatch({ type: ActionType.ADD_LOGS_WORKBOOK });
  };

  const setCurrentWorkbookIndex = (i: number) => {
    dispatch({ type: ActionType.SET_CURRENT_WORKBOOK_INDEX, payload: i });
  };

  const createActionByIndex =
    <P>(actionType: ActionType) =>
    (args: P) => {
      dispatch({
        type: actionType,
        index: state.currentWorkbookIndex,
        payload: args,
      });
    };

  const currentLogsState = {
    ...state.workbooks[state.currentWorkbookIndex].logsState,
    addFilterByFacet: createActionByIndex<SearchItemProps>(
      ActionType.ADD_FILTER_BY_FACET,
    ),
    addSearchTerm: createActionByIndex<string>(ActionType.ADD_SEARCH_TERM),
    applyDeselectedFacetValues: createActionByIndex<{
      component: string;
      facetName: string;
      facetValuesToSelect: { [key: string]: number };
    }>(ActionType.APPLY_DESELECTED_FACET_VALUES),
    clear: createActionByIndex<string>(ActionType.CLEAR),
    clearFilterOrExcludeByFingerprint: createActionByIndex<string>(
      ActionType.CLEAR_FILTER_OR_EXCLUDE_BY_FINGERPRINT,
    ),
    clearSelectedLogFromContext: createActionByIndex<string>(
      ActionType.CLEAR_SELECTED_LOG_FROM_CONTEXT,
    ),
    changeFacetRange: createActionByIndex<{
      facetKey: string;
      range: {
        lower: number;
        upper: number;
      };
    }>(ActionType.CHANGE_FACET_RANGE),
    excludeFingerprint: createActionByIndex<string>(
      ActionType.EXCLUDE_FINGERPRINT,
    ),
    filterFingerpint: createActionByIndex<string>(
      ActionType.FILTER_FINGERPRINT,
    ),
    filterOnlyFacetValueFromLogEventDetail: createActionByIndex<{
      name: string;
      source: string;
      type: string;
      value: string;
      exclude?: boolean;
    }>(ActionType.FILTER_ONLY_FACET_VALUE_FROM_LOG_EVENT_DETAIL),
    removeFilterByFacetByIndex: createActionByIndex<number>(
      ActionType.REMOVE_FILTER_BY_FACET_BY_INDEX,
    ),
    removeSearchTermByIndex: createActionByIndex<number>(
      ActionType.REMOVE_SEARCH_TERM_BY_INDEX,
    ),
    replaceFilters: createActionByIndex<LogsFilter>(
      ActionType.REPLACE_LOGS_FILTERS,
    ),
    resetFacet: createActionByIndex<FacetBase>(ActionType.RESET_FACET),
    resetFacetRange: createActionByIndex<FacetBase>(
      ActionType.RESET_FACET_RANGE,
    ),
    setDate: createActionByIndex<DateSelection>(ActionType.SET_DATE),
    setDateZoomed: createActionByIndex<DateSelection>(
      ActionType.SET_DATE_ZOOMED,
    ),
    setFingerprintQuery: createActionByIndex<FingerprintQueryProps>(
      ActionType.SET_FINGERPRINT_QUERY,
    ),
    setLogsState: createActionByIndex<Partial<LogsState>>(
      ActionType.SET_LOGS_STATE,
    ),
    toggleFacetValue: createActionByIndex<{
      facetKey: string;
      nextSelectionByFacetKeys: { [key: string]: number };
    }>(ActionType.TOGGLE_FACET_VALUE),
    toggleKeyExists: createActionByIndex<FacetBase>(
      ActionType.TOGGLE_KEY_EXISTS,
    ),
  };

  const removeWorkbookByIndex = (workbookIndex: number) => {
    dispatch({
      type: ActionType.REMOVE_WORKBOOK_BY_INDEX,
      payload: workbookIndex,
    });
  };

  const restoreHistoryEntryByIndex = (
    workbookIndex: number,
    historyEntryIndex: number,
  ) => {
    dispatch({
      type: ActionType.RESTORE_HISTORY_ENTRY_BY_INDEX,
      index: workbookIndex,
      payload: historyEntryIndex,
    });
  };

  const setWorkbookName = (workbookIndex: number, name: string) => {
    dispatch({
      type: ActionType.SET_WORKBOOK_NAME,
      index: workbookIndex,
      payload: name,
    });
  };

  const setWorkbooks = (workbooks: LogsWorkbook[]) => {
    dispatch({
      type: ActionType.SET_WORKBOOKS,
      payload: workbooks,
    });
  };

  const showInContext = (payload: {
    logsState: Partial<LogsState>;
    selectedLogFromContext: LogEvent;
  }) => {
    dispatch({
      type: ActionType.SHOW_IN_CONTEXT,
      payload,
    });
  };

  return {
    ...state,
    addLogsWorkbook,
    currentWorkbook:
      state.currentWorkbookIndex < state.workbooks.length
        ? state.workbooks[state.currentWorkbookIndex]
        : null,
    currentLogsState,
    removeWorkbookByIndex,
    restoreHistoryEntryByIndex,
    setWorkbookName,
    setWorkbooks,
    setCurrentWorkbookIndex,
    showInContext,
  };
};

export default useLogsWorkbooksState;
