import classNames from 'classnames';
import { Loader, useThemeContext } from 'components';
import React, { ReactElement } from 'react';
import { TimeseriesRenderProps } from 'types';
import { clearCanvasByContext, drawAnomalyBand } from 'utils/Timeseries';

import UPlotChart from './UplotChart';
import useTimeseries, { SERIES_LIMIT } from './useTimeseries';

const Timeseries = ({
  timeseries,
  ...props
}: TimeseriesRenderProps & {
  timeseries: ReturnType<typeof useTimeseries>;
}): ReactElement => {
  const { darkModeEnabled } = useThemeContext();
  const {
    chartData,
    className,
    isLoading,
    layoutType = 'explore',
    renderAnnotation,
    renderComponent,
    renderLegend,
    renderTooltip,
    renderToolbar,
    seriesLimit = SERIES_LIMIT,
    unit,
  } = props;
  const { activeChart, chartRef, config, data, showAll, showAllSeries } =
    timeseries;

  const onFocusSeries = (seriesIdx: number) => {
    const chart = chartRef.current;
    if (chart) {
      if (seriesIdx === null) {
        clearCanvasByContext(chart.tooltipCtx);
      }

      if (!config.series[seriesIdx]?.show) {
        return;
      }
      if (seriesIdx >= 0) {
        clearCanvasByContext(chart.tooltipCtx);
        const isDrawn = drawAnomalyBand({
          u: chart,
          seriesIndex: seriesIdx,
          darkModeEnabled,
        });

        if (!isDrawn) {
          chart.setSeries(seriesIdx, { focus: true });
        }
      } else {
        chart.setSeries(seriesIdx, null);
      }
    }
  };

  const toolProp = { ...timeseries, layoutType, onFocusSeries, unit };

  return (
    <Loader isLoading={isLoading} dataTestId={props.chartKey}>
      {config && data && (
        <div
          className={classNames({
            'uplot__chart-renderer': true,
            [className]: className,
          })}
        >
          {!showAll &&
            seriesLimit &&
            chartData.data.length > seriesLimit &&
            layoutType === 'explore' && (
              <div className="uplot__chart-renderer__show-all">
                Only {seriesLimit} series is shown by default &nbsp;
                <a
                  className="uplot__chart-renderer__show-all__button"
                  onClick={showAllSeries}
                >
                  Show All
                </a>
              </div>
            )}
          {renderToolbar && renderToolbar(toolProp)}
          <UPlotChart
            activeChart={activeChart}
            data={data}
            options={config}
            chartRef={chartRef}
          />
          {(() => {
            const labels =
              config?.series?.map((seriesObj) => seriesObj?.label || '') || [];
            const isLabelsAtLeastOneChar = labels.join('').length > 0;
            if (!isLabelsAtLeastOneChar) {
              return;
            }

            return (
              <>
                {renderLegend?.(toolProp)}
                {renderTooltip?.(toolProp)}
                {renderAnnotation?.(toolProp)}
              </>
            );
          })()}
          {renderComponent?.(toolProp)}
        </div>
      )}
    </Loader>
  );
};

export default Timeseries;
