import { useRequest } from 'hooks';
import { useState } from 'react';
import { analysisServiceCorrelation } from 'requests';
import dayjs from 'dayjs';
import { DateSelection } from 'types/DateSelection';

const useAlertsEventAnalysis = ({
  date,
  kfSource,
  promqls,
}: {
  date: DateSelection;
  kfSource: string;
  promqls: string;
}) => {
  const [activeAlertEvent, setActiveAlertEvent] = useState(null);
  const analysisServiceCorrelationRequest = useRequest(
    analysisServiceCorrelation,
  );

  const onAnalyze = (
    alertEvent: { activeAt: string; labels: { [key: string]: string } },
    rowIndex: number,
  ) => {
    setActiveAlertEvent({ ...alertEvent, rowIndex });
    const { activeAt, labels } = alertEvent;
    const newLabels = { ...labels };
    if (newLabels['alertname']) {
      delete newLabels['alertname'];
    }

    if (newLabels['__name__']) {
      delete newLabels['__name__'];
    }

    // check if activeAt is a valid date 0001-01-01T00:00:00Z is considered invalid
    const isDateValid = activeAt !== '0001-01-01T00:00:00Z';
    const activeDate = isDateValid ? dayjs(activeAt) : dayjs();

    const startDate = dayjs(activeDate).subtract(15, 'minute').unix();
    analysisServiceCorrelationRequest.call({
      anomalyStartTime: startDate,
      anomalyEndTime: activeDate.unix(),
      labelsScope: Object.keys(newLabels || {}).map(
        (key) => `${key}="${newLabels[key]}"`,
      ),
      kfSource,
      sourceExpr: promqls,
      step: 15,
    });
  };

  return {
    activeAlertEvent,
    analysisServiceCorrelationRequest,
    onAnalyze,
    setActiveAlertEvent,
  };
};

export default useAlertsEventAnalysis;
