import { useForm } from 'hooks';
import React, { useEffect } from 'react';
import { LayoutType } from 'recharts/types/util/types';
import { Chart, DateSelection } from 'types';
import ChartGridItemLegend from './ChartGridItemLegend';
import ChartGridItemTimeseries from './ChartGridItemTimeseries';
import ChartGridItemVisualization from './ChartGridItemVisualization';
import useChartGridItemData from './useChartGridItemData';

type Props = {
  chart: Chart;
  chartGridItemData: ReturnType<typeof useChartGridItemData>;
  date: DateSelection;
  form: ReturnType<typeof useForm>;
  tooltipProps: { layoutType: LayoutType };
  width: number;
};

const ChartGridItemBody = ({
  chart,
  chartGridItemData,
  date,
  form,
  tooltipProps,
  width,
}: Props) => {
  const { legendTableColumns, showCompactLegend, libraryType } = chart;
  const { colorMap, data, deselectedKeysMap, keys, legendRows } =
    chartGridItemData;

  useEffect(() => {
    chartGridItemData.fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart]);

  return (
    <div className="chart-grid__item__inner">
      {!libraryType && (
        <>
          <ChartGridItemVisualization
            chartGridItemData={chartGridItemData}
            chart={chart}
            date={date}
            form={form}
            width={width}
          />
          <ChartGridItemLegend
            colorMap={colorMap}
            data={data}
            deselectedKeysMap={deselectedKeysMap}
            key={keys.join('')}
            keys={keys}
            legendRows={legendRows}
            legendTableColumns={legendTableColumns}
            showCompactLegend={showCompactLegend}
          />
        </>
      )}
      {libraryType && libraryType === 'uplot' && (
        <ChartGridItemTimeseries
          chart={chart}
          chartGridItemData={chartGridItemData}
          date={date}
          form={form}
          tooltipProps={tooltipProps}
          width={width}
        />
      )}
    </div>
  );
};

export default ChartGridItemBody;
