import { AutocompleteOption } from 'components';
import {
  IGNORE_ANOMALY_LOWER_BAND_LABEL,
  IGNORE_ANOMALY_UPPER_BAND_LABEL,
} from 'kfuse-constants/timeseries';
import {
  ExplorerQueryProps,
  FormulaProps,
  MetricsQueryItemProps,
  MetricsQueriesDataProps,
  DateSelection,
  DashboardPanelType,
  MetricsQueriesDataTimeseriesProps,
  QueryCombinedStatusProps,
  QueryDataPropsRangeCombined,
} from 'types';
import { Band, Series } from 'uplot';
import {
  getColorForOneSeries,
  getNonRedColorForOneSeries,
  INACTIVE_COLOR_LIGHT,
  INACTIVE_COLOR_DARK,
  parsePromqlAndBuildQuery,
  DataFrame,
} from 'utils';

import { getMetricsExplorerDefaultQuery } from '..';
import { buildPromqlWithFunctions } from './query-utils';

/**
 * Transform for series list
 * @returns
 * example: [{"name": "value", "age": 21}, { "name": "some", "age": 22}] => ["name:value", "age:21", "name:some", "age:22"]
 */
export const transformSeriesList = (
  grafanaSeries: any,
): {
  labelsListOptions: AutocompleteOption[];
  seriesListOptions: AutocompleteOption[];
  seriesValuesOptions: { [key: string]: AutocompleteOption[] };
} => {
  const labels: { [key: string]: { [key: string]: boolean } } = {};
  grafanaSeries.map((series: any) => {
    delete series.__name__;
    Object.keys(series).map((item) => {
      if (!labels[item]) {
        labels[item] = {};
      }
      labels[item][series[item]] = true;
    });
  });

  const labelList = Object.keys(labels);
  const seriesValuesOptions: { [key: string]: AutocompleteOption[] } = {};
  const labelsListOptions: AutocompleteOption[] = [];
  const seriesListOptions: AutocompleteOption[] = [];

  labelList.map((label) => {
    labelsListOptions.push({ label, value: label });
    seriesListOptions.push({ label, value: `${label}=""` });

    const seriesValues = Object.keys(labels[label]).map((value) => {
      return { label: value, value };
    });
    seriesValuesOptions[label] = seriesValues;
  });

  return { labelsListOptions, seriesListOptions, seriesValuesOptions };
};

export const buildFormulaQuery = (
  promqlQueries: string[],
  queryKeys: string[],
  formulas: FormulaProps[],
): string[] => {
  const formulaQueries: string[] = [];
  const promqlBitmap: { [key: string]: string } = {};
  promqlQueries.forEach((query, index) => {
    promqlBitmap[queryKeys[index]] = query;
  });

  formulas.forEach((formula: FormulaProps) => {
    if (formula.isValid) {
      let expression = '';
      const formulaLength = formula.expression.length;
      for (let i = 0; i < formulaLength; i++) {
        const char = formula.expression.charAt(i);
        if (queryKeys.includes(char)) {
          expression += `( ${promqlBitmap[char]} )`;
        } else {
          expression += char;
        }
      }

      // replace + with %2B if exists
      expression = expression.replaceAll(/\+/g, '%2B');
      formulaQueries.push(expression);
    }
  });

  return formulaQueries;
};

export const getLabelValuesCount = (options: AutocompleteOption[]): any => {
  return options.reduce((acc, option) => {
    const [label, value] = option.value.split(':');
    if (acc[label]) {
      acc[label] += 1;
    } else {
      acc[label] = 1;
    }
    return acc;
  }, {});
};

export const getMetricsExplorerUrl = (
  metricName: string,
  tagValue?: string,
): string => {
  const defaultQuery = getMetricsExplorerDefaultQuery(metricName, 0);
  if (tagValue) {
    const splitTag = tagValue.split(':');
    const tag = splitTag[0];
    const value = splitTag.slice(1).join(':');
    const newSeries = [`${tag}="${value}"`];
    defaultQuery['series'] = newSeries;
  }
  const url = `/#/metrics?metricsQueries=${encodeURIComponent(
    JSON.stringify([defaultQuery]),
  )}`;

  return url;
};

export const getMetricsExplorerUrlByPromql = (promql: string): string => {
  const { queries, formulas } = parsePromqlAndBuildQuery([promql]);
  const queriesURI = encodeURIComponent(JSON.stringify(queries));
  const formulasURI = encodeURIComponent(JSON.stringify(formulas));
  const url = `/#/metrics?metricsQueries=${queriesURI}&metricsFormulas=${formulasURI}`;

  return url;
};

export const decodePromqlToReadable = (promql: string): string => {
  if (!promql) return promql;

  let encodedPromql = decodeURI(promql);

  // replace all the %2B with +
  if (encodedPromql.includes('%2B')) {
    encodedPromql = encodedPromql.replaceAll(/%2B/g, '+');
  }

  // replace all the %2F with /
  if (encodedPromql.includes('%2F')) {
    encodedPromql = encodedPromql.replaceAll(/%2F/g, '/');
  }

  return encodedPromql;
};

export const getPromqlQueryByIndex = (
  queryItem: MetricsQueryItemProps,
  date?: DateSelection,
): string | string[] => {
  const { formulas, queries, queryIndex, type, returnType } = queryItem;
  if (type === 'query') {
    const query = { ...queries[queryIndex] };
    if (query.showInput) {
      return query.promql;
    }

    if (!query.metric) {
      return '';
    }

    const promqlQuery = buildPromqlWithFunctions(query, returnType, date);
    if (promqlQuery) {
      return promqlQuery;
    }
  }

  if (type === 'formula') {
    const formula = formulas[queryIndex];
    const queriesForFormula: string[] = [];
    queries.forEach((query) => {
      const promqlQuery = buildPromqlWithFunctions(query);
      queriesForFormula.push(promqlQuery);
    });
    const queryKeys = queries.map((query) => query.queryKey);
    const promqlFormula = buildFormulaQuery(queriesForFormula, queryKeys, [
      formula,
    ]);

    if (promqlFormula[0]) {
      return promqlFormula[0];
    }
  }

  return '';
};

export const checkIfQueryHasAnomaly = (query: ExplorerQueryProps) => {
  if (!query || !query.functions) return false;
  return query.functions.some((func) => func.name === 'anomalies');
};

export const checkIfQueryHasForecast = (query: ExplorerQueryProps) => {
  if (!query || !query.functions) return false;
  return query.functions.some((func) => func.name === 'forecast');
};

const getSeriesColor = (
  idx: number,
  queryIndex: number,
  stroke: string,
  darkModeEnabled: boolean,
) => {
  if (stroke === INACTIVE_COLOR_LIGHT && darkModeEnabled) {
    return INACTIVE_COLOR_DARK;
  }

  if (stroke === INACTIVE_COLOR_LIGHT && !darkModeEnabled) {
    return INACTIVE_COLOR_LIGHT;
  }

  return getColorForOneSeries({}, idx + queryIndex);
};

const getAnomalySeriesColor = (
  index: number,
  queryIndex: number,
  seriesIdx: number,
) => {
  if (index === 0) {
    return getNonRedColorForOneSeries({}, queryIndex, seriesIdx);
  }
  return '';
};

const combineAnomalyQueryData = ({
  formulas,
  queries,
  queryData,
  timestamps,
  timestampsWithIndex,
}: {
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
  queryData: MetricsQueriesDataProps;
  timestamps: number[];
  timestampsWithIndex: { [key: string]: number };
}) => {
  const anomalyChartKeys = Object.keys(queryData);
  const isAnomaly = anomalyChartKeys.some((key) => key.includes('anomaly'));
  if (!isAnomaly) return;

  const newSeries: Series[] = [];
  let newMaxValue = -Infinity;
  let newMinValue = Infinity;
  const newData: Array<number[]> = [];
  const bands: Band[] = [];
  let isLoading = false;

  anomalyChartKeys.forEach((key, queryIndex) => {
    if (!key.includes('anomaly')) return;
    if (key.includes('upper') || key.includes('lower')) return;

    const [type, queryKey] = key.split('_');
    let query = null;
    if (type === 'query') {
      query = queries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = formulas.find((f) => f.queryKey === queryKey);
    }
    if (!query || !query.isActive) return;

    const upperKey = `${type}_${queryKey}_anomaly_upper`;
    const lowerKey = `${type}_${queryKey}_anomaly_lower`;
    const batchedData = [
      queryData[key],
      queryData[upperKey],
      queryData[lowerKey],
    ];
    const anomalyChartBands = [0, 0];

    batchedData.forEach((bdata, batchIndex) => {
      if (!bdata) return;
      if (bdata.isLoading) {
        isLoading = true;
      }

      if (!bdata.data) return;

      const { data, series, minValue, maxValue } = bdata.data;
      const currSeriesTimestamps = data[0];
      data.forEach((d: number[], index: number) => {
        if (index === 0) return; // skip first index as it is timestamp
        const preFillData = Array(timestamps.length).fill(undefined);
        d.forEach((value, valueIndex) => {
          const timestamp = currSeriesTimestamps[valueIndex];
          const timestampIndex = timestampsWithIndex[timestamp];
          preFillData[timestampIndex] = value;
        });
        newData.push(preFillData);
      });

      if (batchIndex === 1) {
        anomalyChartBands[0] = newData.length;
      }
      if (batchIndex === 2) {
        anomalyChartBands[1] = newData.length;
      }

      series.forEach((s: Series, seriesIdx: number) => {
        const anomalySeries = {
          ...s,
          stroke: getAnomalySeriesColor(batchIndex, queryIndex, seriesIdx),
        };
        if (batchIndex === 1) {
          anomalySeries.label = IGNORE_ANOMALY_UPPER_BAND_LABEL;
        }
        if (batchIndex === 2) {
          anomalySeries.label = IGNORE_ANOMALY_LOWER_BAND_LABEL;
        }
        newSeries.push(anomalySeries);
      });

      newMaxValue = maxValue > newMaxValue ? maxValue : newMaxValue;
      newMinValue = minValue < newMinValue ? minValue : newMinValue;

      bands.push({
        series: [anomalyChartBands[0], anomalyChartBands[1]],
        fill: 'rgba(255,0,0,0.2)',
        dir: -1,
      });
    });
  });

  return {
    newSeries,
    newMaxValue,
    newMinValue,
    newData,
    bands,
    isLoading,
  };
};

const getCombinedQueryDataTimestamps = ({
  combineTimestamp = false,
  queryData,
  queries,
  formulas,
}: {
  combineTimestamp?: boolean;
  formulas: QueryCombinedStatusProps[];
  queryData: MetricsQueriesDataProps;
  queries: QueryCombinedStatusProps[];
}): {
  timestamps: number[];
  timestampsWithIndex: { [key: string]: number };
} => {
  const chartKeys = Object.keys(queryData);
  const timestampsBitmask: { [key: string]: boolean } = {};

  chartKeys.forEach((key, chartIdx) => {
    const [type, queryKey] = key.split('_');
    let query = null;
    if (type === 'query') {
      query = queries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = formulas.find((f) => f.queryKey === queryKey);
    }
    if (!query || !query.isActive || !queryData[key].data) return;

    const { data } = queryData[key].data;
    if (!data || !data[0]) return;

    if (
      !combineTimestamp &&
      chartIdx > 0 &&
      Object.keys(timestampsBitmask).length !== 0
    ) {
      return;
    }

    const firstData = data[0];
    firstData.forEach((t) => {
      timestampsBitmask[t] = true;
    });
  });

  const timestamps = Object.keys(timestampsBitmask).sort();
  const timestampsWithIndex: { [key: string]: number } = {};
  timestamps.forEach((timestamp, index) => {
    timestampsWithIndex[timestamp] = index;
  });

  return { timestamps: timestamps.map(Number), timestampsWithIndex };
};

export const combineQueriesData = ({
  formulas,
  queries,
  queryData,
  darkModeEnabled,
  combineTimestamp = true,
}: {
  formulas: QueryCombinedStatusProps[];
  queries: QueryCombinedStatusProps[];
  queryData: MetricsQueriesDataProps<MetricsQueriesDataTimeseriesProps>;
  darkModeEnabled?: boolean;
  combineTimestamp?: boolean;
}): QueryDataPropsRangeCombined => {
  const chartKeys = Object.keys(queryData);
  const newData: Array<number[]> = [];
  let newMaxValue = 0;
  let newMinValue = Infinity;
  const newSeries: Series[] = [];
  let isLoading = false;
  const bands: Band[] = [];

  const { timestamps, timestampsWithIndex } = getCombinedQueryDataTimestamps({
    combineTimestamp,
    formulas,
    queryData,
    queries,
  });
  newData.push(timestamps);

  const anomalyData = combineAnomalyQueryData({
    formulas,
    queries,
    queryData,
    timestamps,
    timestampsWithIndex,
  });
  if (anomalyData) {
    newMaxValue = Math.max(newMaxValue, anomalyData.newMaxValue);
    newMinValue = Math.min(newMinValue, anomalyData.newMinValue);
    newData.push(...anomalyData.newData);
    newSeries.push(...anomalyData.newSeries);
    bands.push(...anomalyData.bands);
    isLoading = anomalyData.isLoading;
  }

  chartKeys.forEach((key, queryIndex) => {
    if (key.includes('anomaly')) return;
    if (queryData[key].isLoading) {
      isLoading = true;
    }

    const [type, queryKey] = key.split('_');
    let query = null;
    if (type === 'query') {
      query = queries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = formulas.find((q) => q.queryKey === queryKey);
    }
    if (!query || !query.isActive || !queryData[key].data) return;

    const { data, maxValue, minValue, series } = queryData[key].data;
    if (!data || !series || series.length === 0) return;
    if (data.length === 0) return;

    if (combineTimestamp) {
      const currSeriesTimestamps = data[0];
      data.forEach((d: number[], index: number) => {
        if (index === 0) return; // skip first index as it is timestamp
        const preFillData = Array(timestamps.length).fill(undefined);
        d.forEach((value, valueIndex) => {
          const timestamp = currSeriesTimestamps[valueIndex];
          const timestampIndex = timestampsWithIndex[timestamp];
          preFillData[timestampIndex] = value;
        });
        newData.push(preFillData);
      });
    } else {
      data.forEach((d: number[], index: number) => {
        if (index === 0) return; // skip first index as it is timestamp
        newData.push(d);
      });
    }

    newMaxValue = maxValue > newMaxValue ? maxValue : newMaxValue;
    newMinValue = minValue < newMinValue ? minValue : newMinValue;
    series.forEach((s: Series, idx: number) => {
      const stroke = s.stroke;
      newSeries.push({
        ...s,
        stroke: getSeriesColor(idx, queryIndex, stroke, darkModeEnabled),
      });
    });
  });

  return {
    data: newData,
    isLoading,
    maxValue: newMaxValue === -Infinity ? 0 : newMaxValue,
    minValue: newMinValue === Infinity ? 0 : newMinValue,
    series: newSeries,
    bands,
  };
};

export const combineInstantQueriesData = ({
  dataFormat,
  formulas,
  queries,
  queryData,
}: {
  dataFormat: DashboardPanelType;
  formulas: QueryCombinedStatusProps[];
  queries: QueryCombinedStatusProps[];
  queryData: { [key: string]: DataFrame };
}) => {
  const chartKeys = Object.keys(queryData);
  let newData: any = [];
  const uniqueLabels: string[] = [];
  let isLoading = false;
  const tableRows = [];

  chartKeys.forEach((key) => {
    if (!queryData[key]) return;
    if (queryData[key].isLoading) {
      isLoading = true;
    }

    const [type, queryKey] = key.split('_');
    let query = null;
    if (type === 'query') {
      query = queries.find((q) => q.queryKey === queryKey);
    }
    if (type === 'formula') {
      query = formulas.find((f) => f.queryKey === queryKey);
    }

    if (!query || !query.isActive || !queryData[key]?.data) return;
    const { data, labels, tableData, type: queryType } = queryData[key];

    if (queryType === 'range') {
      return;
    }

    if (Array.isArray(data)) {
      newData = [...newData, ...data];
    } else if (typeof data === 'object') {
      newData = data;
    }

    tableRows.push(...(tableData || []));
    uniqueLabels.push(...(labels || []));
  });

  return {
    data: newData,
    isLoading,
    labels: uniqueLabels,
    tableData: tableRows,
  };
};
