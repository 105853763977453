import { DateSelection } from 'types';

import { HydrateFormProps } from './types';

export const getDefaultHydrateForm = (
  date: DateSelection,
  archiveName: string,
): HydrateFormProps => {
  const { startTimeUnix, endTimeUnix } = date;
  return {
    archiveName,
    startTsMs: startTimeUnix * 1000,
    endTsMs: endTimeUnix * 1000,
    retentionDurationValue: 7,
    retentionDurationUnit: 'DAYS',
    filter: [{ key: '', value: '' }],
    addTags: [{ key: '', value: '' }],
  };
};

export const retentionUnitOptions = [
  { label: 'Hours', value: 'HOURS' },
  { label: 'Days', value: 'DAYS' },
  { label: 'Months', value: 'MONTHS' },
  { label: 'Years', value: 'YEARS' },
];
