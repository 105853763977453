import { Datepicker } from 'composite';
import { useDateState } from 'hooks';
import { colorsByAlertState } from 'kfuse-constants';
import React, { ReactElement, useMemo } from 'react';
import { AnomalyAlgorithmType } from 'types/MetricsQueryBuilder';

import AlertsDetailsChart from './AlertsDetailsChart';
import AlertsDetailsEventsList from './AlertsDetailsEventsList';
import AlertsDetailsProperties from './AlertsDetailsProperties';
import AlertsDetailsPropertiesCompact from './AlertsDetailsPropertiesCompact';
import { useAlertsState } from '../hooks';
import { AlertType, RuleProps } from '../types';
import { getDateForQuery } from '../utils';
import { parseAlertsQueryAndConditions } from '../AlertsCreateMetrics/utils';

const AlertsDetails = ({
  alertsState,
  enableAnalysis,
  fileNameForAlertEvents,
  isDetailedCompact = false,
  hideTitle = false,
  kfSource,
  rule,
}: {
  alertsState: ReturnType<typeof useAlertsState>;
  enableAnalysis?: boolean;
  fileNameForAlertEvents?: string;
  isDetailedCompact?: boolean;
  hideTitle?: boolean;
  kfSource?: string;
  rule: RuleProps;
}): ReactElement => {
  const [date, setDate] = useDateState();

  const alertParsedMetadata = useMemo(() => {
    const parsedMeta = parseAlertsQueryAndConditions({
      data: rule.ruleData,
      annotations: rule.annotations,
    });

    const newDate = getDateForQuery(parsedMeta.relativeTimeRange);
    setDate(newDate);
    return parsedMeta;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule]);

  const { promqls } = alertParsedMetadata;

  return (
    <>
      <div
        className="h-[4px] w-full"
        style={{ backgroundColor: colorsByAlertState[rule.status] }}
      ></div>
      <div className="alerts__details">
        {isDetailedCompact ? (
          <AlertsDetailsPropertiesCompact
            condition={alertParsedMetadata?.condition}
            hideTitle={hideTitle}
            properties={rule}
            status={rule.status}
          />
        ) : (
          <AlertsDetailsProperties
            alertsState={alertsState}
            condition={alertParsedMetadata?.condition}
            parsedMetadata={alertParsedMetadata}
            properties={rule}
          />
        )}
        <div className="alerts__details__events-list box-shadow">
          <div className="flex justify-between">
            <div className="pt-1 text-lg font-semibold">Evaluation Graph</div>
            <div className="alerts__create__datepicker">
              {rule.annotations?.alertType !== AlertType.FORECAST && (
                <Datepicker
                  className="logs__search__datepicker"
                  hasStartedLiveTail={false}
                  onChange={setDate}
                  startLiveTail={null}
                  value={date}
                />
              )}
            </div>
          </div>
          {rule && date && alertParsedMetadata && (
            <AlertsDetailsChart
              alertParsedMetadata={alertParsedMetadata}
              date={date}
              rule={rule}
            />
          )}
        </div>
        <AlertsDetailsEventsList
          date={date}
          enableAnalysis={enableAnalysis}
          fileNameForAlertEvents={fileNameForAlertEvents}
          kfSource={kfSource}
          promqls={
            alertParsedMetadata?.anomalyCondition?.anomalyAlgorithm ===
            AnomalyAlgorithmType.RRCF
              ? alertParsedMetadata.anomalyCondition?.rrcfRecordingRule
              : promqls[0]
          }
          ruleName={rule.name}
          ruleGroup={rule.group}
        />
      </div>
    </>
  );
};

export default AlertsDetails;
