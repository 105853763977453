import { useMemo } from 'react';
import { values, size } from 'lodash';
import { DashboardPanelType } from 'types/Dashboard';

function useAnalyticsChartOverlayMessage({
  chartData,
  activeVisualization,
  errorMessage,
}: {
  chartData: {
    [key: string]: any;
  };
  activeVisualization: DashboardPanelType;
  errorMessage?: string;
}): string | null {
  const overlayMessage: string | null = useMemo(() => {
    const isChartDataLoading = values(chartData).some(
      (query) => query?.isLoading,
    );

    const rangeKey =
      activeVisualization === DashboardPanelType.TIMESERIES
        ? 'range'
        : 'instant';
    const isChartDataNull =
      size(chartData) === 0 ||
      values(chartData).some((query) => query?.[rangeKey]?.data === null);

    if (!isChartDataLoading && isChartDataNull && errorMessage) {
      return errorMessage;
    }

    const isChartDataEmpty =
      values(chartData).filter((query) => {
        if (rangeKey === 'instant') {
          return query?.[rangeKey]?.data?.length > 0;
        }
        if (rangeKey === 'range') {
          return query?.[rangeKey]?.data?.[0]?.length > 0;
        }
      }).length === 0;

    if (isChartDataEmpty && !isChartDataLoading) {
      return 'Your query did not return any data.';
    }
    return null;
  }, [activeVisualization, errorMessage, chartData]);
  return overlayMessage;
}

export default useAnalyticsChartOverlayMessage;
