import { AnalyticsChart } from 'components';
import { useSearches, useSearchFormulas, useTracesState } from 'hooks';
import { size } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Service } from 'types';
import { formatDurationNs } from 'utils';
import useTracesTimeseries from './useTracesTimeseries';
import { useAnalyticsChartOverlayMessage } from 'hooks';
import { useFeatureFlag } from 'configcat-react';

type Props = {
  isLiveTailEnabled?: boolean;
  searches: ReturnType<typeof useSearches>;
  serviceByHash: Record<string, Service>;
  searchesFormulas?: ReturnType<typeof useSearchFormulas>;
  tracesState: ReturnType<typeof useTracesState>;
  tracesTab: string;
};

const TracesTimeseries = ({
  isLiveTailEnabled,
  searches,
  searchesFormulas,
  serviceByHash,
  tracesState,
  tracesTab,
}: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tracesTimeseries = useTracesTimeseries({
    searches,
    searchesFormulas,
    serviceByHash,
    tracesState,
    tracesTab,
  });
  const {
    analyticsChart,
    analyticsData,
    groupBysForTable,
    openExportToDashboardModal,
    openSaveMetricModal,
    onViewFullscreen,
  } = tracesTimeseries;

  const { dateState } = tracesState;
  const { value: enableSaveMetrics } = useFeatureFlag(
    'enableSaveMetrics',
    false,
  );
  const [date, setDate] = dateState;
  const saveButtonDisabledReasonIfDisabled: string | null =
    size(tracesState?.selectedHcFacetValuesByNameState?.state) > 0
      ? 'Save not allowed when filter includes a high cardinality attribute.'
      : null;

  useEffect(() => {
    const searchParamsString = searchParams.toString();
    if (isLiveTailEnabled) {
      navigate(
        `/apm/traces/list${searchParamsString ? `?${searchParamsString}` : ''}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLiveTailEnabled]);

  const overlayMessage = useAnalyticsChartOverlayMessage({
    chartData: analyticsData.chartData,
    activeVisualization: analyticsChart.activeVisualization,
  });

  const renderValue = useMemo(
    () =>
      searches[0].measure === 'duration_ns'
        ? (n: number) => formatDurationNs(n, 1, 2)
        : null,
    [searches],
  );

  return (
    <div className="traces__timeseries">
      <AnalyticsChart
        analyticsChart={analyticsChart}
        chartData={analyticsData.chartData}
        chartFormulas={analyticsData.formulaQueries}
        chartQueries={analyticsData.chartQueries}
        defaultActiveChart="Stacked Bar"
        groupBy={groupBysForTable}
        overlayMessage={overlayMessage}
        renderValue={renderValue}
        rightToolbar={{
          onExportClick: openExportToDashboardModal,
          onSaveClick: enableSaveMetrics ? openSaveMetricModal : undefined,
          onViewFullscreen,
          saveButtonDisabledReasonIfDisabled,
        }}
        setDate={setDate}
        settingOptions={{
          legendType: 'values',
          toolbarChartShowType: 'dropdown',
        }}
      />
    </div>
  );
};

export default TracesTimeseries;
