import { LimitTo, Operation, VisualizeAs } from 'types';
import { FormulaState, SearchState } from './types';

export const nextChar = (lastQueryKey: string) =>
  String.fromCharCode(lastQueryKey.charCodeAt(0) + 1);

export const getInitialState = (lastQueryKey?: string): SearchState => ({
  groupBys: ['*'],
  isActive: true,
  limitTo: LimitTo.top,
  limitToValue: 5,
  measure: null,
  operation: Operation.distinctcount,
  rollUpInSeconds: null,
  queryKey: lastQueryKey ? nextChar(lastQueryKey) : 'a',
  visualizeAs: VisualizeAs.list,
  searchBarState: null,
});

export const getInitialFormulaState = (lastQueryKey: string): FormulaState => ({
  expression: '',
  isActive: true,
  isValid: false,
  queryKey: lastQueryKey ? `${Number(lastQueryKey) + 1}` : '1',
});
