import {
  Button,
  Loader,
  PopoverPosition,
  PopoverTriggerV2,
  Stepper,
  useThemeContext,
} from 'components';
import { Separator } from 'components/shadcn';
import { CalendarPickerModal } from 'composite';
import dayjs from 'dayjs';
import { useRequest } from 'hooks';
import { dateTimeFormat } from 'kfuse-constants';
import React, { ReactElement, useMemo, useState } from 'react';
import { ChevronUp, ChevronDown } from 'react-feather';
import { DateSelection } from 'types';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { convertToUTC } from 'utils';

import LogsHydrationArchivesHydrateFilter from './LogsHydrationArchivesHydrateFilter';
import LogsHydrationArchivesHydrateRetention from './LogsHydrationArchivesHydrateRetention';
import LogsHydrationArchivesHydrateTags from './LogsHydrationArchivesHydrateTags';
import { hydrateArchive } from './requests';
import { HydrateFormProps } from './types';
import { getDefaultHydrateForm } from './utils';

const LogsHydrationArchivesHydrate = ({
  archiveName,
  close,
  onSuccess,
}: {
  archiveName: string;
  close: () => void;
  onSuccess: () => void;
}): ReactElement => {
  const { utcTimeEnabled } = useThemeContext();
  const hydrateArchiveRequest = useRequest(hydrateArchive);
  const defaultDate = getDateFromRange('now-1M', 'now');
  const [hydrateForm, setHydrateForm] = useState<HydrateFormProps>(
    getDefaultHydrateForm(defaultDate, archiveName),
  );

  const onDateChange = (date: DateSelection) => {
    const { startTimeUnix, endTimeUnix } = date;
    setHydrateForm((prev) => ({
      ...prev,
      startTsMs: startTimeUnix * 1000,
      endTsMs: endTimeUnix * 1000,
    }));
  };

  const onHydrate = () => {
    if (!utcTimeEnabled) {
      hydrateForm.startTsMs = convertToUTC(hydrateForm.startTsMs);
      hydrateForm.endTsMs = convertToUTC(hydrateForm.endTsMs);
    }

    hydrateArchiveRequest.call(hydrateForm).then((res) => {
      close();
      onSuccess();
    });
  };

  const { date, schedule } = useMemo(() => {
    const { startTsMs, endTsMs } = hydrateForm;
    const startTsSecs = startTsMs / 1000;
    const endTsSecs = endTsMs / 1000;
    const startAtDate = dayjs.unix(startTsSecs);
    const endAtDate = dayjs.unix(endTsSecs);

    const schedule = `${startAtDate.format(dateTimeFormat)} to ${endAtDate.format(dateTimeFormat)}`;
    const date = { startTimeUnix: startTsSecs, endTimeUnix: endTsSecs };
    return { date, schedule };
  }, [hydrateForm]);

  return (
    <div className="flex max-h-[95vh] w-[800px] flex-col px-4">
      <div className="p-4">
        <div className="text-xl font-bold">
          Hydrating from <span className="text-primary">{archiveName}</span>
        </div>
      </div>
      <Loader
        className="min-h-[200px]"
        isLoading={hydrateArchiveRequest.isLoading}
      >
        <div className="max-h-[75vh] flex-1 overflow-y-auto">
          <Stepper
            steps={[
              {
                title: 'Set Time Range',
                component: (
                  <div>
                    <label>Start Time and End Time</label>
                    <div
                      className="mt-1 max-w-[310px] rounded-sm border"
                      data-testid="hydrate-schedule-picker"
                    >
                      <PopoverTriggerV2
                        className="calendar-picker__trigger"
                        popoverPanelClassName="popover__panel--overflow"
                        popover={({ close }) => (
                          <CalendarPickerModal
                            absoluteTimeRangeStorage={undefined}
                            close={close}
                            onChange={onDateChange}
                            startLiveTail={null}
                            quickRangeOptions={[]}
                            value={date}
                          />
                        )}
                        position={PopoverPosition.BOTTOM_LEFT}
                        right
                        width={480}
                      >
                        <div className="calendar-picker__trigger__value">
                          {schedule}
                        </div>
                        <ChevronUp
                          className="calendar-picker__trigger__chevron calendar-picker__trigger__chevron--up"
                          size={16}
                        />
                        <ChevronDown
                          className="calendar-picker__trigger__chevron calendar-picker__trigger__chevron--down"
                          size={16}
                        />
                      </PopoverTriggerV2>
                    </div>
                  </div>
                ),
              },
              {
                title: 'Set Filter',
                component: (
                  <LogsHydrationArchivesHydrateFilter
                    hydrateForm={hydrateForm}
                    setHydrateForm={setHydrateForm}
                  />
                ),
              },
              {
                title: 'Add Tags',
                component: (
                  <LogsHydrationArchivesHydrateTags
                    hydrateForm={hydrateForm}
                    setHydrateForm={setHydrateForm}
                  />
                ),
              },
              {
                title: 'Set Retention',
                component: (
                  <LogsHydrationArchivesHydrateRetention
                    hydrateForm={hydrateForm}
                    setHydrateForm={setHydrateForm}
                  />
                ),
              },
            ]}
          />
        </div>
      </Loader>
      <Separator />
      <div className="flex justify-end gap-2 pb-4 pt-2">
        <Button variant="outline" onClick={close}>
          Cancel
        </Button>
        <Button variant="default" onClick={onHydrate}>
          Hydrate
        </Button>
      </div>
    </div>
  );
};

export default LogsHydrationArchivesHydrate;
