import dayjs from 'dayjs';
import {
  DateSelection,
  FacetRegexTerm,
  KeyExists,
  SelectedFacetValuesByName,
  SpanFilter,
  TableEntry,
  Trace,
} from 'types';
import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';
import { buildTracesFilter } from './utils';

type Args = {
  date: DateSelection;
  facetRegex: FacetRegexTerm[];
  keyExists: KeyExists;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  selectedHcFacetValuesByName: SelectedFacetValuesByName;
  spanFilter?: SpanFilter;
  ParentSpanIdFilter?: string;
  traceIdSearch: string;
};

const aggregateTable = async ({
  facetRegex,
  date,
  keyExists,
  selectedFacetValuesByName,
  selectedHcFacetValuesByName,
  ParentSpanIdFilter,
  spanFilter,
  traceIdSearch,
}: Args): Promise<TableEntry[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  return queryTraceService<TableEntry[], 'aggregateTable'>(`
    {
      aggregateTable(
        aggregation: "max"
        aggregationField: "duration_ns"
        durationSecs: ${durationSecs},
        filter: ${buildTracesFilter({
          facetRegex,
          keyExists,
          selectedFacetValuesByName,
          selectedHcFacetValuesByName,
          parentSpanIdFilter: ParentSpanIdFilter,
          spanFilter,
          traceIdSearch,
        })}
        groupBy: "*"
        timestamp: "${endTime.format()}",
      ) {
        value
        groupVal
      }
    }
  `).then(
    (data) =>
      (data.aggregateTable || []).map((datum) => ({
        ...datum,
        value: Math.floor(datum.value / 1000000),
      })),
    onPromiseError,
  );
};

export default aggregateTable;
