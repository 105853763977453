import uPlot from 'uplot';
import { themeColorLight, themeColorDark } from 'utils/colors/theme-color';
import { canvasPadding } from './tooltipUtils';

interface RoundedRectParams {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  width: number;
  height: number;
  radius: number;
}

export const roundedRect = ({
  ctx,
  x,
  y,
  width,
  height,
  radius,
}: RoundedRectParams): void => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.arcTo(x + width, y, x + width, y + radius, radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
  ctx.lineTo(x + radius, y + height);
  ctx.arcTo(x, y + height, x, y + height - radius, radius);
  ctx.lineTo(x, y + radius);
  ctx.arcTo(x, y, x + radius, y, radius);
  ctx.closePath();
  ctx.fill();
};

/**
 * Draw text on canvas
 * @param param0
 */
interface DrawTextOnTimeseriesParams {
  u: uPlot;
  ctx: CanvasRenderingContext2D;
  darkModeEnabled?: boolean;
  text: string;
  x: number;
  y: number;
  options?: {
    backgroundColor?: string;
    color?: string;
    fontSize?: number;
    fontWeight?: string;
    textAlign?: 'left' | 'center' | 'right';
  };
  padding?: { top: number; right: number; bottom: number; left: number };
  margin?: { bottom: number; top: number };
}

export const drawTextOnTimeseries = ({
  u,
  ctx,
  darkModeEnabled = false,
  text,
  x,
  y,
  options = {},
  padding = { top: 0, right: 0, bottom: 0, left: 0 },
}: DrawTextOnTimeseriesParams): void => {
  if (
    !ctx ||
    typeof text !== 'string' ||
    typeof x !== 'number' ||
    typeof y !== 'number'
  ) {
    return;
  }

  const canvPadding = canvasPadding(u);

  // Default options with dynamic handling
  const {
    color = '#fa6c7c',
    fontSize = 18,
    fontWeight = 'bold',
    backgroundColor = darkModeEnabled
      ? themeColorDark.dark06
      : themeColorLight.light02,
    textAlign = 'left',
  } = options;

  ctx.save();
  const fontSizePx = fontSize * devicePixelRatio;
  ctx.font = `${fontWeight} ${fontSizePx}px sans-serif`;
  ctx.fillStyle = color;

  const textWidth = ctx.measureText(text).width;
  const textHeight = fontSizePx + 8;

  // Calculate text and background positions based on alignment
  let textOffsetX;
  switch (textAlign) {
    case 'center':
      textOffsetX = x + textWidth / 2;
      break;
    case 'right':
      textOffsetX = x + textWidth;
      break;
    case 'left':
    default:
      textOffsetX = x;
      break;
  }

  const minTextOffset = textWidth + canvPadding.left;
  const adjustedTextOffsetX = Math.max(textOffsetX, minTextOffset);

  const backgroundX = adjustedTextOffsetX + padding.right;
  const backgroundY = y;
  const backgroundWidth = textWidth + padding.left + padding.right;
  const backgroundHeight = textHeight + padding.top + padding.bottom;

  // Draw the background
  ctx.fillStyle = backgroundColor;
  roundedRect({
    ctx,
    x: backgroundX - backgroundWidth,
    y: backgroundY - backgroundHeight / 2,
    width: backgroundWidth,
    height: backgroundHeight,
    radius: 3,
  });

  // Draw the text
  ctx.fillStyle = color;
  ctx.fillText(text, adjustedTextOffsetX, y);
  ctx.restore();
};

interface DrawLineParams {
  ctx: CanvasRenderingContext2D;
  start: { x: number; y: number };
  end: { x: number; y: number };
  options?: {
    color?: string;
    dash?: number[];
    lineWidth?: number;
    lineStyle?: 'solid' | 'dashed' | 'dotted';
  };
}

export const drawLineOnTimeseries = ({
  ctx,
  start,
  end,
  options = {},
}: DrawLineParams): void => {
  if (
    !ctx ||
    typeof start.x !== 'number' ||
    typeof start.y !== 'number' ||
    typeof end.x !== 'number' ||
    typeof end.y !== 'number'
  ) {
    return;
  }

  const {
    color = '#fa6c7c',
    dash,
    lineWidth = 1,
    lineStyle = 'solid',
  } = options;

  ctx.save();
  ctx.beginPath();
  ctx.moveTo(start.x, start.y);
  ctx.lineTo(end.x, end.y);
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;

  switch (lineStyle) {
    case 'dashed':
      ctx.setLineDash(dash || [5, 15]);
      break;
    case 'dotted':
      ctx.setLineDash(dash || [2, 2]);
      break;
    case 'solid':
    default:
      ctx.setLineDash([]);
      break;
  }

  if (options.dash) {
    ctx.setLineDash(options.dash);
  }

  ctx.stroke();
  ctx.restore();
};

export const drawCircleOnTimeseries = ({
  ctx,
  x,
  y,
  options = {},
}: {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  options?: { color?: string; lineWidth?: number; radius?: number };
}): void => {
  if (!ctx || typeof x !== 'number' || typeof y !== 'number') {
    return;
  }

  const { color = '#fa6c7c', lineWidth = 1, radius = 3 } = options;

  ctx.save();
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
  ctx.fillStyle = color;
  ctx.fill();
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = color;
  ctx.stroke();
  ctx.restore();
};

export const drawRectangeOnTimeseries = ({
  ctx,
  x,
  y,
  width,
  height,
  options = {},
}: {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  width: number;
  height: number;
  options?: { color?: string; lineWidth?: number };
}): void => {
  if (
    !ctx ||
    typeof x !== 'number' ||
    typeof y !== 'number' ||
    typeof width !== 'number' ||
    typeof height !== 'number'
  ) {
    return;
  }

  const { color = '#fa6c7c', lineWidth = 1 } = options;

  ctx.save();
  ctx.beginPath();
  ctx.rect(x, y, width, height);
  ctx.fillStyle = color;
  ctx.fill();
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = color;
  ctx.stroke();
  ctx.restore();
};

export const drawLineWithMultiplePoints = ({
  ctx,
  points,
  options = {},
}: {
  ctx: CanvasRenderingContext2D;
  points: { x: number; y: number }[];
  options: DrawLineParams['options'];
}): void => {
  const {
    color = '#fa6c7c',
    dash,
    lineWidth = 1,
    lineStyle = 'solid',
  } = options;

  ctx.beginPath();
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;

  switch (lineStyle) {
    case 'dashed':
      ctx.setLineDash(dash || [5, 15]);
      break;
    case 'dotted':
      ctx.setLineDash(dash || [2, 2]);
      break;
    case 'solid':
    default:
      ctx.setLineDash([]);
      break;
  }
  if (dash) {
    ctx.setLineDash(dash);
  }

  points.forEach((point, index) => {
    if (index === 0) {
      ctx.moveTo(point.x, point.y);
    } else {
      ctx.lineTo(point.x, point.y);
    }
  });

  ctx.stroke();
};

/**
 * Draw chevron on the tooltip canvas
 * @param ctx - The rendering context of the canvas.
 * @param x - The x coordinate of the top point of the chevron.
 * @param y - The y coordinate of the top point of the chevron.
 * @param size - The size of the chevron.
 */
export const drawChevron = ({
  bgColor,
  ctx,
  x,
  y,
  size,
}: {
  bgColor: string;
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  size: number;
}): void => {
  ctx.beginPath();
  ctx.fillStyle = bgColor;
  ctx.moveTo(x, y);
  ctx.lineTo(x - size / 2, y + size);
  ctx.lineTo(x + size / 2, y + size);
  ctx.closePath();
  ctx.fill();
};

export const drawVertcalCursor = ({
  ctx,
  leftX,
  bottomY,
  padding,
  bgColor,
}: {
  ctx: CanvasRenderingContext2D;
  leftX: number;
  bottomY: number;
  padding: ReturnType<typeof canvasPadding>;
  bgColor: string;
}): void => {
  drawLineOnTimeseries({
    ctx,
    start: { x: leftX, y: padding.top },
    end: { x: leftX, y: bottomY },
    options: { color: bgColor, lineWidth: 2 },
  });
};

export const clearCanvasByContext = (ctx: CanvasRenderingContext2D): void => {
  if (!ctx) return;
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
};
