import React from 'react';
import TracesTimeseriesSavedMetrics from './TracesTimeseriesSavedMetrics';
import useRequest from 'hooks/useRequest';
import getSavedTraceMetrics from 'requests/getSavedTraceMetrics';

const SavedTraceMetrics = () => {
  const getSavedTraceMetricsRequest = useRequest(getSavedTraceMetrics);
  return (
    <TracesTimeseriesSavedMetrics
      getSavedTraceMetricsRequest={getSavedTraceMetricsRequest}
    />
  );
};

export default SavedTraceMetrics;
