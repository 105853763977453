import {
  AutocompleteOption,
  AutocompleteListV2,
  OnEnterArgs,
  SearchHelp,
  SearchTag,
  ServiceWithLabels,
} from 'components';
import {
  useKeyExistsState,
  useMap,
  useSelectedFacetValuesByNameState,
} from 'hooks';
import React, { useMemo } from 'react';
import { traceLabelValues } from 'requests';
import { DateSelection, Service, SpanFilter } from 'types';
import { getLastParsedValue, parsePartialSearchQuery } from 'utils';
import TracesSearchInputPanelValues from './TracesSearchInputPanelValues';

const apmQueryOperators: { [key: string]: string } = {
  '!=': 'neq',
  '=': 'eq',
  '!~': 'notregex',
  '=~': 'regex',
  '>': 'gt',
  '>=': 'gte',
  '<': 'lt',
  '<=': 'lte',
};

const mapLabelName = (labelName) => ({
  dataType: labelName === 'duration_ns' ? 'NUMBER' : 'STRING',
  label: labelName === 'service_hash' ? 'service' : labelName,
  optionType: 'facet',
  value: labelName === 'service_hash' ? 'service' : labelName,
});

type Props = {
  close: () => void;
  colorsByServiceHash: Record<string, string>;
  date: DateSelection;
  editIndex: number;
  keyExistsState: ReturnType<typeof useKeyExistsState>;
  labelNames: string[];
  onEnter: (args: OnEnterArgs) => void;
  onValueSelect: (value: string) => void;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  serviceByHash: Record<string, Service>;
  setTyped: (typed: string) => void;
  spanFilter: SpanFilter;
  tags: SearchTag[];
  typed: string;
};

const TracesSearchInputPanel = ({
  close,
  colorsByServiceHash,
  date,
  editIndex,
  keyExistsState,
  labelNames,
  onValueSelect,
  serviceByHash,
  setTyped,
  spanFilter,
  tags,
  typed,
}: Props) => {
  const labelValuesByNameMap = useMap();
  const isLoadingByNameMap = useMap();

  const fetchLabelValuesByName = (labelName: string) => {
    isLoadingByNameMap.add(labelName, true);
    traceLabelValues({
      date,
      labelName: labelName === 'service' ? 'service_hash' : labelName,
      spanFilter,
    })
      .then((result) => {
        labelValuesByNameMap.add(
          parsedFacetName,
          result.map((valueCount) => valueCount.value),
        );
      })
      .finally(() => {
        isLoadingByNameMap.add(labelName, false);
      });
  };

  const { parsedFacetName, parsedOperator, parsedValue } = useMemo(() => {
    const parsed = parsePartialSearchQuery(typed, false);
    return {
      parsedFacetName: parsed.facetName,
      parsedOperator: parsed.operator,
      parsedValue: parsed.value,
    };
  }, [typed]);

  const isKeyExists = parsedFacetName === 'key exists';

  const searchedLabelNames: string[] = useMemo(() => {
    const searchLowered = typed.toLowerCase().trim();

    if (searchLowered) {
      return labelNames.filter(
        (labelName) => labelName.toLowerCase().indexOf(searchLowered) > -1,
      );
    }

    return labelNames;
  }, [labelNames, typed]);

  const searchedLabelOptions: AutocompleteOption[] = useMemo(() => {
    return searchedLabelNames.map(mapLabelName);
  }, [searchedLabelNames]);

  const searchedKeyExistsOptions: AutocompleteOption[] = useMemo(() => {
    if (isKeyExists) {
      const searchLabelValueLowered = parsedValue.toLowerCase().trim();
      if (searchLabelValueLowered) {
        return labelNames
          .filter(
            (labelName) =>
              labelName.toLowerCase().indexOf(searchLabelValueLowered) > -1,
          )
          .map(mapLabelName);
      }

      return labelNames.map(mapLabelName);
    }
    return [];
  }, [isKeyExists, labelNames, parsedValue]);

  const lastParsedValue = useMemo(
    () => getLastParsedValue(parsedValue),
    [parsedValue],
  );

  const searchedLabelValues: string[] = useMemo(() => {
    const labelValues = labelValuesByNameMap.state[parsedFacetName] || [];
    const searchLabelValueLowered = lastParsedValue.toLowerCase().trim();

    if (searchLabelValueLowered && labelValues.length) {
      return labelValues.filter(
        (labelValue: string) =>
          labelValue.toLowerCase().indexOf(searchLabelValueLowered) > -1,
      );
    }

    return labelValues;
  }, [labelValuesByNameMap.state, parsedFacetName, lastParsedValue]);

  const searchedLabelValueOptions = useMemo(() => {
    return searchedLabelValues.map((labelValue) => {
      if (parsedFacetName === 'service_hash' || parsedFacetName === 'service') {
        const service = serviceByHash[labelValue];
        return {
          label: service ? (
            <ServiceWithLabels
              color={colorsByServiceHash[labelValue]}
              distinctLabels={service.distinctLabels || {}}
              labels={service.labels}
              name={service.name}
            />
          ) : (
            labelValue
          ),
          value: labelValue,
        };
      }

      return {
        label: labelValue,
        value: labelValue,
      };
    });
  }, [
    colorsByServiceHash,
    parsedFacetName,
    searchedLabelValues,
    serviceByHash,
  ]);

  const onClickLabelNameHandler = ({ option }) => {
    const { value: optionValue } = option;
    const nextFacetName = optionValue;
    const nextOperator = parsedOperator || '=';
    const nextValue = '';

    const nextTyped = `${nextFacetName}${nextOperator}"${nextValue}"`;

    setTyped(nextTyped);
  };

  const onClickKeyExistHandler = ({ option }) => {
    const { value } = option;

    keyExistsState.setKeyExists(value);
    setTyped('');
    close();
  };

  const onClickLabelValueHandler = ({ option }) => {
    const parsedValues = parsedValue.split(' OR ');
    const nextValue = [
      ...parsedValues.slice(0, parsedValues.length - 1),
      option.value,
    ].join(' OR ');

    onValueSelect(nextValue);
    setTyped('');
    close();
  };

  const namesBitmap: Record<string, number> = useMemo(() => {
    return tags
      .filter((tag, i) => tag.name && i !== editIndex)
      .reduce((obj, tag) => ({ ...obj, [tag.name]: 1 }), {});
  }, [editIndex, tags]);

  const warning =
    parsedFacetName && namesBitmap[parsedFacetName]
      ? `You currently have another filter with '${parsedFacetName}'`
      : '';

  return (
    <div className="traces-search__input__panel">
      <div className="traces-search__input__panel__suggestions">
        {!parsedOperator ? (
          <AutocompleteListV2
            close={() => {}}
            disableKeyExist
            emptyPlaceholder="No suggestions"
            onClickHandler={onClickLabelNameHandler}
            onClickKeyExistHandler={onClickKeyExistHandler}
            options={searchedLabelOptions}
            typed={typed}
          />
        ) : null}
        {parsedFacetName &&
        parsedOperator &&
        (parsedOperator === '=' || parsedOperator === '!=') ? (
          <TracesSearchInputPanelValues
            fetchLabelValuesByName={fetchLabelValuesByName}
            isLoadingByNameMap={isLoadingByNameMap}
            labelValuesByNameMap={labelValuesByNameMap}
            lastParsedValue={lastParsedValue}
            onClickLabelValueHandler={onClickLabelValueHandler}
            parsedFacetName={parsedFacetName}
            searchedLabelValueOptions={
              isKeyExists ? searchedKeyExistsOptions : searchedLabelValueOptions
            }
          />
        ) : null}
      </div>
      <SearchHelp operators={apmQueryOperators} warning={warning} />
    </div>
  );
};

export default TracesSearchInputPanel;
