import { ChartGridV2 } from 'components';
import { Filter, useSearch, useTracesState } from 'hooks';
import React, { useMemo } from 'react';
import { aggregateTimeSeries } from 'requests';
import {
  DateSelection,
  FacetRegexTerm,
  KeyExists,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
  TimeSeries,
} from 'types';
import {
  convertNumberToReadableUnit,
  formatAggregateTimeSeriesData,
  getRollupByVisualization,
} from 'utils';

type GetRowArgs = {
  facetRegex: FacetRegexTerm[];
  filters: Filter[];
  keyExists: KeyExists;
  search: ReturnType<typeof useSearch>;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  selectedHcFacetValuesByName: SelectedFacetValuesByName;
  setDate: (date: DateSelection) => void;
  spanFilter: SpanFilter;
  traceIdSearch?: string;
};

const getRows = ({
  facetRegex,
  filters,
  keyExists,
  search,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  selectedHcFacetValuesByName,
  setDate,
  spanFilter,
  traceIdSearch,
}: GetRowArgs) => {
  const onSelection = (startTimeUnix: number, endTimeUnix: number) => {
    setDate({ startTimeUnix, endTimeUnix });
  };

  const { groupBys, measure, operation, rollUpInSeconds } = search.state;

  const traceQueries = {
    isActive: true,
    type: 'query',
    queryKey: 'query_a',
    query: {
      ...search.state,
      searchBarState: {
        filters,
      },
    },
  };

  return [
    [
      {
        charts: [
          {
            key: 'requests',
            chartType: 'bar',
            colorMap: { count: '#26BBF0' },
            label: 'Requests',
            renderTotalCount: (totalCount: number) =>
              convertNumberToReadableUnit(totalCount),
            totalKey: 'count',
            onSelection,
            traceQueries: [traceQueries],
            query: ({ date, width }) =>
              aggregateTimeSeries({
                aggregation: operation,
                aggregationField: measure,
                date,
                facetRegex,
                groupBys,
                keyExists,
                rollUpSeconds:
                  rollUpInSeconds || getRollupByVisualization(date, 'bar'),
                selectedFacetRangeByName,
                selectedFacetValuesByName,
                selectedHcFacetValuesByName,
                spanFilter,
                traceIdSearch,
              }).then((points: TimeSeries[]) =>
                formatAggregateTimeSeriesData({
                  date,
                  points,
                  step:
                    rollUpInSeconds || getRollupByVisualization(date, 'bar'),
                  fillMissingTimestamps: true,
                }),
              ),
          },
        ],
      },
    ],
  ];
};

type Props = {
  tracesState: ReturnType<typeof useTracesState>;
};

const TracesChartGrid = ({ tracesState }: Props) => {
  const {
    dateState,
    facetRegexState,
    filtersState,
    keyExistsState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    selectedHcFacetValuesByNameState,
    spanFilters,
    traceIdSearch,
  } = tracesState;
  const [date, setDate] = dateState;
  const keyExists = keyExistsState.state;
  const selectedFacetRangeByName = selectedFacetRangeByNameState.state;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
  const selectedHcFacetValuesByName = selectedHcFacetValuesByNameState.state;
  const { spanFilter } = spanFilters;

  const search = useSearch();

  const rows = useMemo(
    () =>
      getRows({
        facetRegex: facetRegexState.state,
        filters: filtersState.state,
        keyExists,
        search,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        selectedHcFacetValuesByName,
        setDate,
        spanFilter,
        traceIdSearch,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      date,
      facetRegexState.state,
      keyExists,
      selectedFacetValuesByName,
      selectedFacetRangeByName,
      selectedHcFacetValuesByName,
      search.state,
      spanFilter,
      traceIdSearch,
    ],
  );

  return (
    <div className="traces__chart-grid" data-testid="traces_chart_grid">
      <ChartGridV2.ChartGrid date={date} rows={rows} />
    </div>
  );
};

export default TracesChartGrid;
