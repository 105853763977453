import {
  Json,
  ResourceWithStatusCode,
  StackTrace,
  Tab,
  Tabs,
  TraceErrorIssue,
  useTabs,
} from 'components';
import { useTracesState } from 'hooks';
import React from 'react';
import { Service, TraceError } from 'types';

type Props = {
  colorsByServiceHash: Record<string, string>;
  errorGroupingKey?: string;
  serviceByHash: Record<string, Service>;
  serviceHash?: string;
  traceError: TraceError;
  tracesState: ReturnType<typeof useTracesState>;
};

const SidebarTraceError = ({
  colorsByServiceHash,
  errorGroupingKey,
  serviceByHash,
  serviceHash,
  traceError,
  tracesState,
}: Props) => {
  const { method, statusCode, url } = traceError;
  const { dateState } = tracesState;
  const date = dateState[0];

  const stackTrace = traceError.parsedError.exception?.stacktrace || [];

  const tabs = useTabs();

  const onOpenInErrorPage = () => {
    const selectedFacetValuesByName = {
      service_hash: { [serviceHash]: 1 },
      error_grouping_key: { [errorGroupingKey]: 1 },
    };
    window.open(
      `#/apm/errors/all?selectedFacetValuesByName=${JSON.stringify(
        selectedFacetValuesByName,
      )}&date=${JSON.stringify(date)}`,
    );
  };

  return (
    <div>
      <div className="apm-errors__group-sidebar__trace-error__header">
        <div className="flex--justify-content-between flex">
          <div className="text--h3">Latest Error</div>
          {serviceHash && errorGroupingKey && (
            <div className="link" onClick={onOpenInErrorPage}>
              Open in error page
            </div>
          )}
        </div>
        <div className="apm-errors__group-sidebar__trace-error__issue">
          <TraceErrorIssue
            colorsByServiceHash={colorsByServiceHash}
            serviceByHash={serviceByHash}
            traceError={traceError}
          />
        </div>
        <div className="apm-errors__group-sidebar__trace-error__resouce-with-status-code">
          {url && method ? (
            <ResourceWithStatusCode
              endpoint={url}
              method={method}
              statusCode={statusCode}
            />
          ) : null}
        </div>
      </div>
      <Tabs className="tabs--underline" tabs={tabs} dataTestId="tabs">
        <Tab label="Metadata">
          <Json
            data={{
              attributes: traceError.attributes,
              error: traceError.parsedError,
            }}
          />
        </Tab>
        <Tab label="Stack Trace">
          {Array.isArray(stackTrace) ? (
            <StackTrace frames={stackTrace} />
          ) : (
            <div
              className="apm-errors__group-sidebar__trace-error__stack-trace"
              data-testid="stack-trace"
            >
              {stackTrace}
            </div>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default SidebarTraceError;
