export enum RumViewColumnKey {
  browserName = 'browser.name',
  city = 'geo.city',
  country = 'geo.country',
  cumulativeLayoutShift = 'view.cumulative_layout_shift',
  date = 'time',
  deviceType = 'device.type',
  environment = 'data.env',
  firstInputDelay = 'view.first_input_delay',
  interactionToNextPaint = 'view.interaction_to_next_paint',
  loadingTime = 'view.loading_time',
  loadingType = 'view.loading_type',
  largestContentfulPaint = 'view.largest_contentful_paint',
  osName = 'os.name',
  service = 'data.service',
  timeSpent = 'view.time_spent',
  version = 'data.version',
  sessionType = 'session.type',
  urlPath = 'view.url_path',
  userId = 'usr.id',
  userName = 'usr.name',
  userEmail = 'usr.email',
}

export enum RumActionColumnKey {
  actionId = 'action.id',
  actionType = 'action.type',
  actionLoadingTime = 'action.loading_time',
  actionFrustrationType = 'action.frustration.type',
  applicationId = 'application.id',
  date = 'time',
  service = 'data.service',
  deviceType = 'device.type',
  deviceModel = 'device.model',
  displayViewportWidth = 'display.viewport.width',
  displayViewportHeight = 'display.viewport.height',
  viewName = 'view.name',
  viewId = 'view.id',
}

export const RumTableColumnKey = {
  ...RumViewColumnKey,
  ...RumActionColumnKey,
};
