import { Filter, useDateState, useFiltersState, useSortState } from 'hooks';
import getSelectedFacetValuesByNameState from 'hooks/useFiltersState/getSelectedFacetValuesByNameState';
import { useMemo, useState } from 'react';
import { RumTableColumnKey } from '../contants';
import { RumEventType } from '../types';

type Options = {
  dateState?: ReturnType<typeof useDateState>;
  initialEventType?: RumEventType;
  initialFilters?: Filter[];
  initialSelectedQueryIndex?: string;
  initialIdSearch?: string;
  shouldWriteToUrl?: boolean;
  urlStateKey?: string;
};

const useRumState = ({
  dateState,
  initialEventType,
  initialFilters,
  initialIdSearch,
  shouldWriteToUrl = true,
  urlStateKey,
}: Options) => {
  const [eventType, setEventType] = useState<RumEventType>(initialEventType);
  const filtersState = useFiltersState({
    initialState: initialFilters,
    shouldWriteToUrl,
    urlStateKey,
  });

  const selectedFacetValuesByNameState = useMemo(
    () => getSelectedFacetValuesByNameState(filtersState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersState.state],
  );

  const rumTableSortState = useSortState({
    urlStateKey: 'rumTableSort',
    initalState: {
      key: RumTableColumnKey.date,
      isAsc: false,
    },
  });

  const [idSearch, setIdSearch] = useState(initialIdSearch || '');

  const state = useMemo(() => {
    return {
      date: dateState[0],
      filters: filtersState.state,
      idSearch,
      selectedFacetValuesByName: selectedFacetValuesByNameState.state,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dateState[0],
    filtersState.state,
    idSearch,
    selectedFacetValuesByNameState.state,
  ]);

  return {
    dateState,
    eventType,
    filtersState,
    selectedFacetValuesByNameState,
    setEventType,
    state,
    idSearch,
    rumTableSortState,
    setIdSearch,
  };
};

export default useRumState;
