import { ThemeContextProvider } from 'components';
import React from 'react';
import Root from './Root';

const RootWithContexts = () => {
  return (
    <ThemeContextProvider>
      <Root />
    </ThemeContextProvider>
  );
};

export default RootWithContexts;
