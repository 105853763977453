import { Loader, TooltipTrigger } from 'components';
import React, { ReactElement } from 'react';
import { DateSelection } from 'types';

import { AlertsDetailsAnalysisChart } from '../AlertsChart';
import { useAlertsEventAnalysis } from '../hooks';

const AlertsDetailsAnalysis = ({
  alertsEventAnalysis,
  date,
}: {
  alertsEventAnalysis: ReturnType<typeof useAlertsEventAnalysis>;
  date: DateSelection;
}): ReactElement => {
  const { analysisServiceCorrelationRequest } = alertsEventAnalysis;

  return (
    <Loader
      className="alerts__details__analysis box-shadow"
      isLoading={analysisServiceCorrelationRequest.isLoading}
    >
      <TooltipTrigger
        className="alerts__details__subheader"
        tooltip="Historical events on this alerts."
      >
        <h2 className="text-lg font-semibold">Alert Event Analysis</h2>
      </TooltipTrigger>
      <div className="new-metrics__charts">
        {analysisServiceCorrelationRequest.result &&
          analysisServiceCorrelationRequest.result.map(
            ({
              correlatedSeries,
              name,
            }: {
              correlatedSeries: string;
              name: string;
            }) => {
              return (
                <div
                  className="new-metrics__charts__item alerts__details__analysis__chart__item"
                  key={correlatedSeries}
                >
                  <AlertsDetailsAnalysisChart
                    promql={correlatedSeries}
                    date={date}
                    title={name}
                  />
                </div>
              );
            },
          )}
      </div>
    </Loader>
  );
};

export default AlertsDetailsAnalysis;
