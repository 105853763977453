import {
  Button,
  useModalsContext,
  DashboardExport,
  useColumnsState,
} from 'components';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { DashboardPanelType, FingerprintQueryProps } from 'types';
import { useLogsState } from './hooks';
import { useKeyExistsState } from 'hooks';

const LogsAddToDashboardPopover = ({
  customColumnsState,
  columnsState,
  fingerprintQuery,
  logsState,
}: {
  customColumnsState: ReturnType<typeof useKeyExistsState>;
  columnsState: ReturnType<typeof useColumnsState>;
  fingerprintQuery: FingerprintQueryProps;
  logsState: ReturnType<typeof useLogsState>;
}): ReactElement => {
  const { tab } = useParams();
  const model = useModalsContext();

  const onAddNewDashboard = () => {
    const filter = {
      tableColumns: {
        customColumns: customColumnsState.state || {},
        selectedColumns: columnsState.state.selectedColumns,
      },
      filters: logsState.filtersState.state,
    };

    if (tab === 'fingerprints') {
      filter.fingerprintQuery = fingerprintQuery;
    }
    const filterStr = JSON.stringify(filter);

    model.push(
      <DashboardExport
        closeModal={model.pop}
        date={logsState.date}
        panel={{
          targets: [
            {
              editorMode: 'code',
              expr: filterStr,
              key: 'Q-09b106c9-2f31-4591-b382-480e5236903f-0',
              queryType: 'range',
              refId: 'A',
            },
          ],
          type:
            tab === 'fingerprints'
              ? DashboardPanelType.FINGERPRINT
              : DashboardPanelType.LOGS,
        }}
      />,
    );
  };

  if (tab !== undefined && tab !== 'fingerprints') return null;

  return (
    <Button onClick={onAddNewDashboard} size="xs" variant="outline-secondary">
      Add to Dashboard
    </Button>
  );
};

export default LogsAddToDashboardPopover;
