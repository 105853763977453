import {
  DateSelection,
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
} from 'types';

export const onCreateAlertLogs = ({
  date,
  queryItem,
}: {
  date: DateSelection;
  queryItem: {
    formulas?: LogsMetricForumlaProps[];
    queries: LogsMetricQueryProps[];
    queryIndex?: number;
  };
}) => {
  const { formulas, queries, queryIndex } = queryItem;
  if (queryIndex !== undefined) {
    const query = { ...queries[queryIndex] };
    const encodeQueryURI = encodeURIComponent(JSON.stringify([query]));
    window.open(
      `/#/alerts/create?ruleType=${encodeURIComponent(
        JSON.stringify({ value: 'logs' }),
      )}&LogsMetricsQueries=${encodeQueryURI}&date=${encodeURIComponent(
        JSON.stringify(date),
      )}`,
      '_blank',
    );
    return;
  }

  let newQueries = [...queries];
  if (newQueries.length === 1 && formulas.length === 0) {
    newQueries[0] = { ...newQueries[0], queryKey: 'a' };
  }
  if (formulas && formulas.length > 0) {
    newQueries = newQueries.map((query) => ({ ...query, isActive: false }));
  }

  const encodeQueryURI = encodeURIComponent(JSON.stringify(newQueries));
  window.open(
    `/#/alerts/create?ruleType=${encodeURIComponent(
      JSON.stringify({ value: 'logs' }),
    )}&LogsMetricsQueries=${encodeQueryURI}&date=${encodeURIComponent(
      JSON.stringify(date),
    )}&LogsMetricsFormulas=${encodeURIComponent(JSON.stringify(formulas))}`,
    '_blank',
  );
  return;
};
