import { useDateState } from 'hooks';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { extractPathNameFromURL, parseUrlParamByKey } from 'utils';
import { useSearches } from '../useSearch';
import useTracesState from '../useTracesState';
import { SpanFilter } from 'types/SpanFilter';

type Args = {
  apmDateState: ReturnType<typeof useDateState>;
};

const useApmErrorsPageState = ({ apmDateState }: Args) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { searches } = useSearches({});

  const tracesState = useTracesState({
    dateState: apmDateState,
    initialFilters: parseUrlParamByKey('apmErrorsFilters'),
    initialSpanFilter: SpanFilter.allSpans,
    shouldWriteToUrl: false,
    urlStateKey: 'apmErrorsFilters',
  });

  const date = tracesState.dateState[0];

  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(
    () => [
      date,
      searches,
      tracesState.spanFilters.spanFilter,
      tracesState.filtersState.state,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      date,
      searches,
      tracesState.spanFilters.spanFilter,
      tracesState.filtersState.state,
    ],
  );

  // Should be called on state change when on the Traces page
  const writeStateToUrl = () => {
    const { filtersState, spanFilters } = tracesState;
    params.set('apmDate', JSON.stringify(tracesState.dateState[0]));
    params.set('apmErrorsFilters', JSON.stringify(filtersState.state));
    params.set('apmErrorsSpanFilter', JSON.stringify(spanFilters.spanFilter));
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    dependenciesForWriteStateToUrl,
    searches,
    tracesState,
    writeStateToUrl,
  };
};

export default useApmErrorsPageState;
