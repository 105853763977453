import { Json, RightSidebar } from 'components';
import React from 'react';
import useRequest from 'hooks/useRequest';
import rawEvent from './requests/rawEvent';
import {
  RUMEvent,
  RumActionEventData,
  RumEventType,
  RumViewEventData,
} from './types';
import useDebouncedEffect from 'use-debounced-effect';

type Props = {
  close: VoidFunction;
  activeRumEvent: RUMEvent;
  eventType: RumEventType;
};

const RumEventDrawer = ({ activeRumEvent, close, eventType }: Props) => {
  const rumRawEventRequest = useRequest(rawEvent);

  useDebouncedEffect(
    () => {
      if (!activeRumEvent) {
        return;
      }
      rumRawEventRequest.call({
        eventTimeUnix: activeRumEvent.time,
        eventId:
          eventType === RumEventType.VIEW
            ? (activeRumEvent.data as RumViewEventData)['view.id']
            : (activeRumEvent.data as RumActionEventData)['action.id'],
        eventType,
      });
    },
    {
      timeout: 50,
      ignoreInitialCall: false,
    },
    [activeRumEvent, eventType],
  );

  return (
    <RightSidebar
      className="rum-sidebar flex flex-col"
      close={close}
      title={'Event Attributes'}
      dataTestId="rum-sidebar"
    >
      {rumRawEventRequest.result && <Json data={rumRawEventRequest.result} />}
    </RightSidebar>
  );
};

export default RumEventDrawer;
