import { useToaster } from 'components';
import { useDateState, useRequest, useUrlState } from 'hooks';
import { getKfuseDatasource } from 'kfuse-constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createGrafanaAlertsRule,
  getDatasources,
  getGrafanaAlertsFolders,
  getGrafanaAlertsRuleByGroup,
  mutateGrafanaProvisionAlertById,
} from 'requests';
import { DateSelection } from 'types';
import { decodePromqlToReadable, getUrlParamByKey } from 'utils';

import {
  AlertsCreateDetailsProps,
  AlertsEvaluateProps,
  AlertType,
  MutateAlertsFunctionProps,
  RuleProps,
  RuleType,
} from '../types';
import {
  getCreateAlertQueries,
  getCreateAlertQueriesForAnomaly,
  getCustomAnnotations,
  getCustomLabels,
  getContactPointsForCreateAlert,
  getGroupNameAndFrequency,
  getTransferRuleFolderGroupUid,
} from '../utils';

const useAlertsCreate = (defaultContactPoints?: string[]) => {
  const { addToast } = useToaster();
  const navigate = useNavigate();
  const [ruleType, setRuleType] = useUrlState<{
    value: RuleType;
  }>('ruleType', { value: RuleType.METRICS });
  const [alertType, setAlertType] = useUrlState<{
    value: AlertType;
  }>('alertType', { value: AlertType.THRESHOLD });
  const [date, setDate] = useDateState();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [prevRule, setPrevRule] = useState<RuleProps>(null);

  const [alertsDetails, setAlertsDetails] = useState<AlertsCreateDetailsProps>({
    allAnnotations: {},
    customAnnotations: [],
    customLabels: [],
    description: '',
    folderName: '',
    groupName: '',
    ruleName: '',
    summary: '',
    uid: '',
  });
  const [evaluate, setEvaluate] = useState<AlertsEvaluateProps>({
    every: '2m',
    for: '5m',
  });
  const [contactPoints, setContactPoints] = useState<string[]>(
    defaultContactPoints || [],
  );
  const [errorHandling, setErrorHandling] = useState<{
    [key: string]: string;
  }>({});

  const grafanaAlertsRuleByGroupRequest = useRequest(
    getGrafanaAlertsRuleByGroup,
  );
  const createGrafanaAlertsRuleRequest = useRequest(createGrafanaAlertsRule);
  const getGrafanaAlertsFoldersRequest = useRequest(getGrafanaAlertsFolders);
  const mutateSLOAlertsRequest = useRequest(mutateGrafanaProvisionAlertById);
  const getDatasourcesRequest = useRequest(getDatasources);

  const onDateChange = (nextDate: DateSelection) => {
    setDate(nextDate);
  };

  const mutateAlertsRule = async ({
    condition,
    datasourceType,
    interval,
    promqlQuery,
    ruleAnnotations,
  }: MutateAlertsFunctionProps) => {
    return new Promise((resolve, reject) => {
      const customAnnotations: { [key: string]: string } = {};
      alertsDetails.customAnnotations.forEach(({ label, value }) => {
        if (label && value) {
          customAnnotations[label] = value;
        }
      });

      if (!getDatasourcesRequest.result) {
        addToast({ text: 'Datasource not found', status: 'error' });
        setIsSaving(false);
        reject();
        return;
      }

      const datasource = getKfuseDatasource(
        getDatasourcesRequest.result,
        datasourceType,
      );

      const rulesBasic = {
        for: ['change', 'threshold', 'anomaly'].includes(alertType.value)
          ? '0s'
          : evaluate.for,
        annotations: {
          ...alertsDetails.allAnnotations,
          description: alertsDetails.description,
          forWindow: evaluate.for,
          runbook_url: alertsDetails.runbookUrl,
          summary: alertsDetails.summary,
          ...ruleAnnotations,
          ...customAnnotations,
        },
        labels: getContactPointsForCreateAlert(
          contactPoints,
          alertsDetails.customLabels,
        ),
      };

      const alertsDetailsRule = {
        title: alertsDetails.ruleName,
        condition: alertType.value === AlertType.ANOMALY ? 'D' : 'C',
        no_data_state: condition.noData,
        exec_err_state: condition.executionError,
      };

      const sanitizedPromql = decodePromqlToReadable(promqlQuery);
      const dataQueriesPayload = {
        condition,
        promqlQuery: sanitizedPromql,
        datasourceUid: datasource.uid,
        datasourceType,
        evaluate,
        interval,
        ruleType: ruleAnnotations.ruleType,
      };
      let dataQueries: any = [];
      if (
        (ruleAnnotations.ruleType === RuleType.METRICS ||
          ruleAnnotations.ruleType === RuleType.APM) &&
        ruleAnnotations.alertType === AlertType.ANOMALY
      ) {
        dataQueries = getCreateAlertQueriesForAnomaly(dataQueriesPayload);
      } else {
        dataQueries = getCreateAlertQueries(dataQueriesPayload);
      }

      grafanaAlertsRuleByGroupRequest
        .call(alertsDetails.folderName)
        .then(async (res: any) => {
          const { folderName, groupName, uid } = alertsDetails;
          const grafanaAlertsRule = getGroupNameAndFrequency({
            folderName,
            forWindow: evaluate.for,
            rulesByGroup: res,
            prevGroupName: groupName,
          });

          const ruleIndex = grafanaAlertsRule.rules.findIndex(
            (rule: any) => rule.grafana_alert.uid === uid,
          );
          let successMessage = 'Alerts rule created successfully';

          if (isEditing && uid && ruleIndex > -1) {
            const oldRule = grafanaAlertsRule.rules[ruleIndex];
            const updatedRules = {
              ...rulesBasic,
              grafana_alert: {
                ...oldRule.grafana_alert,
                data: dataQueries,
                ...alertsDetailsRule,
              },
            };

            grafanaAlertsRule.rules[ruleIndex] = updatedRules;
            successMessage = 'Alerts rule updated successfully';
          } else {
            grafanaAlertsRule.rules.push({
              ...rulesBasic,
              grafana_alert: { ...alertsDetailsRule, data: dataQueries },
            });
          }

          const transferData = getTransferRuleFolderGroupUid({
            folderName: alertsDetails.folderName,
            groupName: grafanaAlertsRule.name,
            rule: prevRule,
          });
          if (transferData) {
            await removeTransferRule(transferData);
          }
          createGrafanaAlertsRuleRequest
            .call(alertsDetails.folderName, grafanaAlertsRule)
            .then((resCreate: any) => {
              setIsSaving(false);
              addToast({ text: successMessage, status: 'success' });
              navigate(`/alerts`);
            })
            .catch((err: any) => {
              setIsSaving(false);
              addToast({ text: err.message, status: 'error' });
              reject();
            });
        })
        .catch((err: any) => {
          setIsSaving(false);
          addToast({ text: err.message, status: 'error' });
          reject();
        });
    });
  };

  const removeTransferRule = (transferData: {
    folder: string;
    group: string;
    uid: string;
  }) => {
    return new Promise((resolve, reject) => {
      if (!prevRule) return;
      const { folder, group, uid } = transferData;
      if (!folder || !group || !uid) return;

      grafanaAlertsRuleByGroupRequest
        .call(folder, group)
        .then((res: any) => {
          const payload = { ...res };
          payload.rules = payload.rules.filter(
            (rule: any) => rule.grafana_alert.uid !== uid,
          );

          createGrafanaAlertsRuleRequest
            .call(folder, payload)
            .then(() => resolve(true))
            .catch((err: any) => reject(err));
        })
        .catch((err: any) => reject(err));
    });
  };

  const mutateSLOAlertsRule = async (provisionRule: any) => {
    setIsSaving(true);
    const rulesBasic = {
      annotations: {
        description: alertsDetails.description,
        runbook_url: alertsDetails.runbookUrl,
        summary: alertsDetails.summary,
      },
      labels: getContactPointsForCreateAlert(
        contactPoints,
        alertsDetails.customLabels,
      ),
      title: alertsDetails.ruleName,
    };

    mutateSLOAlertsRequest
      .call({ ...provisionRule, ...rulesBasic }, alertsDetails.uid)
      .then((res: any) => {
        setIsSaving(false);
        addToast({
          text: 'Alerts rule updated successfully',
          status: 'success',
        });
        navigate(`/alerts`);
      })
      .catch((err: any) => {
        setIsSaving(false);
        addToast({ text: err.message, status: 'error' });
      });
  };

  const setUpdateAlertsRuleState = (rule: RuleProps) => {
    const newValues = {
      allAnnotations: rule.annotations,
      description: rule.annotations.description,
      folderName: rule.groupFile,
      groupName: rule.group,
      ruleName: rule.name,
      runbookUrl: rule.annotations.runbook_url,
      summary: rule.annotations.summary,
      customAnnotations: getCustomAnnotations(rule.annotations),
      customLabels: getCustomLabels(rule.tags),
      uid: rule.uid,
    };

    setAlertsDetails(newValues);
    setEvaluate(rule.evaluate);
    setContactPoints(rule.contactPointLabels);
    if (!getUrlParamByKey('copy')) {
      setIsEditing(true);
    }
    setPrevRule({ ...rule });
  };

  useEffect(() => {
    getDatasourcesRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    addToast,
    alertsDetails,
    alertType: alertType.value,
    contactPoints,
    date,
    errorHandling,
    evaluate,
    isEditing,
    isSaving,
    getDatasourcesRequest,
    getGrafanaAlertsFoldersRequest,
    mutateAlertsRule,
    mutateSLOAlertsRule,
    ruleType: ruleType.value,
    setAlertsDetails,
    setAlertType,
    setContactPoints,
    setDate: (date: DateSelection) => onDateChange(date),
    setEvaluate,
    setErrorHandling,
    setIsSaving,
    setRuleType,
    setUpdateAlertsRuleState,
  };
};

export default useAlertsCreate;
