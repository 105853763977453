import { BasicLogsSheet } from 'components';
import { delimiter } from 'kfuse-constants';
import React, { useMemo } from 'react';
import { DateSelection } from 'types';

type Props = {
  date: DateSelection;
  functionArn: string;
};

const ServerlessRightSidebarLogs = ({ date, functionArn }: Props) => {
  const logsState = useMemo(() => {
    const key = 'function_arn';
    return {
      date,
      ...{
        selectedFacetValues: {
          [`Additional${delimiter}${key}${delimiter}string${delimiter}${functionArn}`]: 1,
          [`Core${delimiter}source${delimiter}string${delimiter}lambda`]: 1,
        },
      },
    };
  }, [date]);

  return (
    <div className="serverless__sidebar__logs">
      <BasicLogsSheet logsState={logsState} renderToolbarLeft={() => null} />
    </div>
  );
};

export default ServerlessRightSidebarLogs;
