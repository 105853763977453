import dayjs from 'dayjs';
import { DateSelection, SelectedFacetValuesByName, TimeSeries } from 'types';
import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { buildRumFilter } from './utils';
import { RumEventType } from '../types';

type Args = {
  aggregation: string;
  aggregationField: string;
  date: DateSelection;
  groupBys?: string[];
  eventType: RumEventType;
  idSearch?: string;
  instant?: boolean;
  rollUpSeconds: number;
  selectedFacetValuesByName: SelectedFacetValuesByName;
};

const aggregateRumTimeSeries = async ({
  aggregation,
  aggregationField,
  date,
  groupBys = [],
  eventType,
  idSearch,
  instant = false,
  rollUpSeconds,
  selectedFacetValuesByName,
}: Args): Promise<TimeSeries[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  return queryRumService<TimeSeries[], 'aggregateRumTimeSeries'>(`
  query {
    timeSeries(
      eventType: ${eventType},
      timestamp: "${endTime.format()}",
      durationSecs: ${durationSecs},
      filter: ${buildRumFilter({ idSearch, selectedFacetValuesByName })},
      rollupSeconds: ${instant ? 0 : rollUpSeconds},
      aggregate: {
        field: "${aggregationField}",
        aggregation: "${aggregation}",
      }
      groupBy: []
    ) {
      dimensions,
      points {
        ts,
        value,
      }
    }
  }
  `).then((data) => data?.timeSeries, onPromiseError);
};

export default aggregateRumTimeSeries;
