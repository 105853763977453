import { TableColumn } from 'components';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useRequest } from 'hooks';
import { useRef, useState } from 'react';
import { getTraces } from 'requests';
import { DownloaderState, Downloader, Trace } from 'types';
import { getFileExtension, getFormatter, tryUnmarshallSpan } from 'utils';
import { getTimeMs } from './utils';
import { MAX_LOG_OR_TRACE_DOWNLOAD_SIZE } from 'kfuse-constants';

const limit = 1000;

type Args = {
  columns: TableColumn<any>;
  onDone?: VoidFunction;
  downloadType: string;
};

const useTracesDownloader = (args: Args): Downloader => {
  const { columns, downloadType, onDone } = args;
  const [state, setState] = useState<DownloaderState>({
    isDone: false,
    result: [],
    size: 0,
  });

  const lastTimestampRef = useRef(null);
  const getTracesRequest = useRequest(getTraces);
  const fileExtension = getFileExtension(downloadType);
  const format = getFormatter(downloadType);

  const download = (s: string) => {
    const blob = new Blob([s], {
      type: 'text/plain;charset=utf-8',
    });

    if (onDone) {
      onDone();
    }

    saveAs(blob, `traces.${fileExtension}`);
  };

  const fetchTraces = ({
    date,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    selectedHcFacetValuesByNameState,
    service,
    spanFilter,
    traceIdSearch,
  }) => {
    const lastTimestamp = lastTimestampRef.current;
    const { startTimeMs } = getTimeMs({ date, lastTimestamp });
    const validEndTimeMs = dayjs.unix(date.endTimeUnix).valueOf();

    getTracesRequest
      .call({
        endTimeMs: validEndTimeMs,
        startTimeMs,
        includeSpan: true,
        limit,
        service,
        selectedFacetRangeByName: selectedFacetRangeByNameState.state,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        spanFilter,
        traceIdSearch,
      })
      .then((traces: Trace[]) => {
        setState((prevState) => {
          const filteredTraces = traces
            .filter(
              (trace) => trace.span.startTimeNs < validEndTimeMs * 1000000,
            )
            .map((trace) => ({
              ...trace,
              span: { ...trace.span, span: tryUnmarshallSpan(trace.span) },
            }));
          const nextResult = [...prevState.result, ...filteredTraces];
          const nextResultJsonString = format({ columns, rows: nextResult });
          const size = new TextEncoder().encode(nextResultJsonString).length;
          const sizeInKiloBytes = size / 1024;
          const sizeInMegaBytes = sizeInKiloBytes / 1024;

          if (
            sizeInMegaBytes > MAX_LOG_OR_TRACE_DOWNLOAD_SIZE ||
            filteredTraces.length < limit
          ) {
            download(nextResultJsonString);

            return {
              isDone: true,
              size,
              result: nextResult,
            };
          }

          const lastSpan = filteredTraces.length
            ? filteredTraces[filteredTraces.length - 1]
            : null;
          const nextLastTimestamp = lastSpan?.span?.startTimeNs;
          lastTimestampRef.current = nextLastTimestamp;

          fetchTraces({
            date,
            selectedFacetRangeByNameState,
            selectedFacetValuesByNameState,
            selectedHcFacetValuesByNameState,
            service,
            spanFilter,
            traceIdSearch,
          });

          return {
            isDone: false,
            size,
            result: nextResult,
          };
        });
      });
  };

  const stopAndDownload = () => {
    const string = format({ columns, rows: state.result });
    download(string);
  };

  return {
    fetch: fetchTraces,
    isLoading: getTracesRequest.isLoading,
    state,
    stopAndDownload,
  };
};

export default useTracesDownloader;
