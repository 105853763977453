import { DateFormatter } from 'components';
import { dateTimeFormatWithMilliseconds } from 'kfuse-constants';
import React from 'react';
import { RumActionColumnKey, RumViewColumnKey } from './contants';
import { RUMActionEvent, RUMViewEvent } from './types';

type RenderCellPropsOfView = {
  row: RUMViewEvent;
  value: any;
};

type RenderCellPropsOfAction = {
  row: RUMActionEvent;
  value: any;
};

export const rumActionTableColumns = () => [
  {
    key: RumActionColumnKey.date,
    label: 'Date',
    renderCell: ({ value }: RenderCellPropsOfAction) => (
      <DateFormatter
        formatString={dateTimeFormatWithMilliseconds}
        unixTimestamp={value}
      />
    ),
  },
  {
    key: RumActionColumnKey.actionId,
    label: 'Action ID',
    value: ({ row }: RenderCellPropsOfAction) =>
      row.data[RumActionColumnKey.actionId],
  },
  {
    key: RumActionColumnKey.actionType,
    label: 'Action Type',
    value: ({ row }: RenderCellPropsOfAction) =>
      row.data[RumActionColumnKey.actionType],
  },
  {
    key: RumActionColumnKey.actionFrustrationType,
    label: 'Action Frustration Type',
    value: ({ row }: RenderCellPropsOfAction) =>
      row.data[RumActionColumnKey.actionFrustrationType],
  },
  {
    key: RumActionColumnKey.applicationId,
    label: 'Application ID',
    value: ({ row }: RenderCellPropsOfAction) =>
      row.data[RumActionColumnKey.applicationId],
  },
  {
    key: RumActionColumnKey.service,
    label: 'Service',
  },
  {
    key: RumActionColumnKey.deviceType,
    label: 'Device Type',
    value: ({ row }: RenderCellPropsOfAction) =>
      row.data[RumActionColumnKey.deviceType],
  },
  {
    key: RumActionColumnKey.deviceModel,
    label: 'Device Model',
    value: ({ row }: RenderCellPropsOfAction) =>
      row.data[RumActionColumnKey.deviceModel],
  },
  {
    key: RumActionColumnKey.displayViewportWidth,
    label: 'Display Viewport Width',
    value: ({ row }: RenderCellPropsOfAction) =>
      row.data[RumActionColumnKey.displayViewportWidth],
  },
  {
    key: RumActionColumnKey.displayViewportHeight,
    label: 'Display Viewport Height',
    value: ({ row }: RenderCellPropsOfAction) =>
      row.data[RumActionColumnKey.displayViewportHeight],
  },
  {
    key: RumActionColumnKey.viewName,
    label: 'View Name',
    value: ({ row }: RenderCellPropsOfAction) =>
      row.data[RumActionColumnKey.viewName],
  },
  {
    key: RumActionColumnKey.viewId,
    label: 'View ID',
    value: ({ row }: RenderCellPropsOfAction) =>
      row.data[RumActionColumnKey.viewId],
  },
];

export const rumViewTableColumns = () => [
  {
    key: RumViewColumnKey.date,
    label: 'Date',
    renderCell: ({ value }: RenderCellPropsOfView) => (
      <DateFormatter
        formatString={dateTimeFormatWithMilliseconds}
        unixTimestamp={value}
      />
    ),
  },
  {
    key: RumViewColumnKey.urlPath,
    label: 'URL Path',
    renderCell: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.urlPath],
  },
  {
    key: RumViewColumnKey.loadingType,
    label: 'Loading Type',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.loadingType],
  },
  {
    key: RumViewColumnKey.largestContentfulPaint,
    label: 'Largest Contentful Paint',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.largestContentfulPaint],
  },
  {
    key: RumViewColumnKey.country,
    label: 'Country',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.country],
  },
  {
    key: RumViewColumnKey.environment,
    label: 'Environment',
  },
  {
    key: RumViewColumnKey.service,
    label: 'Service',
  },
  {
    key: RumViewColumnKey.version,
    label: 'Version',
  },
  {
    key: RumViewColumnKey.sessionType,
    label: 'Session Type',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.sessionType],
  },
  {
    key: RumViewColumnKey.userId,
    label: 'User ID',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.userId],
  },
  {
    key: RumViewColumnKey.userName,
    label: 'User Name',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.userName],
  },
  {
    key: RumViewColumnKey.userEmail,
    label: 'User Email',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.userEmail],
  },
  {
    key: RumViewColumnKey.city,
    label: 'City',
    value: ({ row }: RenderCellPropsOfView) => row.data[RumViewColumnKey.city],
  },
  {
    key: RumViewColumnKey.deviceType,
    label: 'Device Type',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.deviceType],
  },
  {
    key: RumViewColumnKey.browserName,
    label: 'Browser Name',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.browserName],
  },
  {
    key: RumViewColumnKey.osName,
    label: 'OS Name',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.osName],
  },
  {
    key: RumViewColumnKey.loadingTime,
    label: 'Loading Time',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.loadingTime],
  },
  {
    key: RumViewColumnKey.cumulativeLayoutShift,
    label: 'Cumulative Layout Shift',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.cumulativeLayoutShift],
  },
  {
    key: RumViewColumnKey.firstInputDelay,
    label: 'First Input Delay',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.firstInputDelay],
  },
  {
    key: RumViewColumnKey.interactionToNextPaint,
    label: 'Interaction to Next Paint',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.interactionToNextPaint],
  },
  {
    key: RumViewColumnKey.timeSpent,
    label: 'Time Spent',
    value: ({ row }: RenderCellPropsOfView) =>
      row.data[RumViewColumnKey.timeSpent],
  },
];
