import { useToaster } from 'components';

import React, { ReactElement } from 'react';
import { Search, XCircle, Clipboard } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useLogsState } from './hooks';
import { readUrlSearchParams } from 'utils';
import { useRequest } from 'hooks';

type Props = {
  clearTabFetched: VoidFunction;
  close: () => void;
  hash: string;
  fpPattern: string;
  getFpListRequest?: ReturnType<typeof useRequest>;
  logsState: ReturnType<typeof useLogsState>;
  shouldDisableFilterActions: boolean;
};

const LogsFingerprintsListItemPanel = ({
  clearTabFetched,
  close,
  hash,
  fpPattern,
  getFpListRequest,
  logsState,
  shouldDisableFilterActions,
}: Props): ReactElement => {
  const { excludeFingerprint, filterFingerpint } = logsState;
  const navigate = useNavigate();
  const { addToast } = useToaster();

  const onExcludeFacetValue = () => {
    if (clearTabFetched) {
      clearTabFetched();
    }
    if (getFpListRequest) {
      getFpListRequest.clear();
    }
    setTimeout(() => {
      close();
      excludeFingerprint(hash);

      // TODO: Clean up these setTimeouts when doing logs state persisting
      setTimeout(() => {
        const urlSearchParams = readUrlSearchParams();
        navigate(`/logs?${urlSearchParams.toString()}`, { replace: true });
      }, 400);
    }, 200);
  };

  const onSelectOnlyFacetValue = () => {
    if (clearTabFetched) {
      clearTabFetched();
    }
    if (getFpListRequest) {
      getFpListRequest.clear();
    }
    setTimeout(() => {
      close();
      filterFingerpint(hash);
      // TODO: Clean up these setTimeouts when doing logs state persisting
      setTimeout(() => {
        const urlSearchParams = readUrlSearchParams();
        navigate(`/logs?${urlSearchParams.toString()}`, { replace: true });
      }, 400);
    }, 200);
  };

  return (
    <div className="logs__selected-log__attribute__panel">
      {shouldDisableFilterActions ? null : (
        <>
          <button
            className="logs__selected-log__attribute__panel__item"
            onClick={onSelectOnlyFacetValue}
          >
            <Search
              className="logs__selected-log__attribute__panel__item__icon"
              size={14}
            />
            <span className="logs__selected-log__attribute__panel__item__label">
              Filter By{' '}
            </span>
            <span className="logs__selected-log__attribute__panel__item__value">
              Pattern
            </span>
          </button>
          <button
            className="logs__selected-log__attribute__panel__item"
            onClick={onExcludeFacetValue}
          >
            <XCircle
              className="logs__selected-log__attribute__panel__item__icon"
              size={14}
            />
            <span className="logs__selected-log__attribute__panel__item__label">
              Exclude{' '}
            </span>
            <span className="logs__selected-log__attribute__panel__item__value">
              Pattern
            </span>
          </button>
        </>
      )}
      <button
        className="logs__selected-log__attribute__panel__item"
        onClick={() => {
          navigator.clipboard.writeText(fpPattern);
          addToast({ status: 'success', text: 'Copied to clipboard' });
          close();
        }}
      >
        <Clipboard
          className="logs__selected-log__attribute__panel__item__icon"
          size={14}
        />
        <span className="logs__selected-log__attribute__panel__item__label">
          Copy{' '}
        </span>
        <span className="logs__selected-log__attribute__panel__item__value">
          Pattern
        </span>
      </button>
    </div>
  );
};

export default LogsFingerprintsListItemPanel;
