import { AnalyticsChart } from 'components';
import { useSearches } from 'hooks';
import React, { useMemo } from 'react';
import { formatDurationNs } from 'utils';
import { useAnalyticsChartOverlayMessage } from 'hooks';
import { useEventsState, useEventsTimeseries } from '../hooks';

type Props = {
  searches: ReturnType<typeof useSearches>;
  eventsState: ReturnType<typeof useEventsState>;
  tab: string;
};

const EventsTimeseries = ({ searches, eventsState, tab }: Props) => {
  const eventsTimeseries = useEventsTimeseries({
    searches,
    eventsState,
    tab,
  });
  const { analyticsChart, analyticsData, groupBysForTable, onViewFullscreen } =
    eventsTimeseries;

  const { setDate } = eventsState;

  const overlayMessage = useAnalyticsChartOverlayMessage({
    chartData: analyticsData.chartData,
    activeVisualization: analyticsChart.activeVisualization,
  });

  const renderValue = useMemo(
    () =>
      searches[0].measure === 'duration_ns'
        ? (n: number) => formatDurationNs(n, 1, 2)
        : null,
    [searches],
  );

  return (
    <div className="flex-1 p-2 px-5 overflow-auto w-full events__timeseries">
      <AnalyticsChart
        analyticsChart={analyticsChart}
        chartData={analyticsData.chartData}
        chartFormulas={[]}
        chartQueries={analyticsData.chartQueries}
        defaultActiveChart="Stacked Bar"
        groupBy={groupBysForTable}
        overlayMessage={overlayMessage}
        renderValue={renderValue}
        rightToolbar={{
          onViewFullscreen,
        }}
        setDate={setDate}
        settingOptions={{
          legendType: 'values',
          toolbarChartShowType: 'dropdown',
        }}
      />
    </div>
  );
};

export default EventsTimeseries;
