import { AutocompleteOption, AutocompleteListV2 } from 'components';
import { useRequest } from 'hooks';
import React, { useMemo } from 'react';
import { getFacetNamesByCursor } from 'requests';
import useDebouncedEffect from 'use-debounced-effect';
import { getFacetKey, getFacetNamesOptions, mergeLabels } from 'utils';

const getPrefix = (typed: string) => {
  const hasAmpersand = typed.startsWith('@');
  const withoutAmpersand = hasAmpersand ? typed.slice(1) : typed;

  return withoutAmpersand.replace(/\\/g, '');
};

const sortFacetnames = (facetNames: any) => {
  const facetNamesOptions = getFacetNamesOptions(facetNames, 'facet');
  const sortedFacetNamesOptions = facetNamesOptions.sort((a, b) =>
    a.label > b.label ? 1 : -1,
  );

  return sortedFacetNamesOptions;
};

const LogsSearchBarV2PanelNames = ({
  close,
  date,
  getFacetNamesRequest,
  getLabelNamesRequest,
  onToggleKeyExists,
  parsedOperator,
  setMeta,
  setTyped,
  typed,
}) => {
  const typedGetFacetNamesRequest = useRequest((args) =>
    getFacetNamesByCursor(args).then((result) => {
      return {
        ...result,
        facetNames: args.cursor
          ? [
              ...(typedGetFacetNamesRequest.result?.facetNames || []),
              ...result.facetNames,
            ]
          : result.facetNames,
      };
    }),
  );

  useDebouncedEffect(
    () => {
      if (typed) {
        const prefix = getPrefix(typed);
        typedGetFacetNamesRequest.call({
          date,
          logsState: { date },
          limit: '200',
          prefix,
        });
      }
    },
    300,
    [typed],
  );

  const onScrollToBottom = () => {
    if (typedGetFacetNamesRequest.result?.cursor) {
      const hasAmpersand = typed.startsWith('@');
      const prefix = hasAmpersand ? typed.slice(1) : typed;
      typedGetFacetNamesRequest.call({
        date,
        cursor: typedGetFacetNamesRequest.result?.cursor,
        logsState: { date },
        limit: '200',
        prefix,
      });
    }
  };

  const labelOptions = useMemo(
    () =>
      getFacetNamesOptions(mergeLabels(getLabelNamesRequest.result), 'label'),
    [getLabelNamesRequest.result],
  );

  const facetNamesOptions = useMemo(() => {
    const facetNames = typed
      ? typedGetFacetNamesRequest.result?.facetNames || []
      : getFacetNamesRequest.result?.facetNames || [];
    return sortFacetnames(facetNames);
  }, [getFacetNamesRequest.result, typedGetFacetNamesRequest.result, typed]);

  const facetNameOptions = useMemo(() => {
    return [...labelOptions, ...facetNamesOptions];
  }, [labelOptions, facetNamesOptions]);

  const onFacetNameAutocompleteOptionClick = ({
    option,
  }: {
    option: AutocompleteOption;
  }) => {
    const { dataType, value: optionValue } = option;
    const nextFacetName = optionValue;
    const nextOperator = parsedOperator || '=';
    const nextValue = '';

    const nextTyped = `${nextFacetName}${nextOperator}"${nextValue}"`;

    setMeta({ facetName: nextFacetName, type: dataType });
    setTyped(nextTyped);
  };

  const onClickKeyExistHandler = ({ option }) => {
    const { dataType, value } = option;
    const sanitizeValue = value?.startsWith('@') ? value?.slice(1) : value;
    const facetKey = getFacetKey({
      component: '',
      name: sanitizeValue,
      type: dataType || 'string',
    });

    onToggleKeyExists(facetKey);
    setTyped('');
    close();
  };

  return (
    <AutocompleteListV2
      close={() => {}}
      onClickHandler={onFacetNameAutocompleteOptionClick}
      onClickKeyExistHandler={onClickKeyExistHandler}
      onScrollToBottom={onScrollToBottom}
      options={facetNameOptions}
      typed={typed}
    />
  );
};

export default LogsSearchBarV2PanelNames;
