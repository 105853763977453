import { FlyoutCaret, TooltipTrigger, FacetAccordion } from 'components';
import { useToggle } from 'hooks';
import React, { ReactElement, ReactNode, useState } from 'react';
import {
  FacetBase,
  FacetName,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

import { useLogsState, useQueryScheduler } from './hooks';
import LogsFacetGroupExpanded from './LogsFacetGroupExpanded';

type Props = {
  component: string;
  handlersByName: (facet: FacetBase) => {
    clearFacet: VoidFunction;
    changeFacetRange: VoidFunction;
    excludeFacetValue: (value: string) => void;
    selectOnlyFacetValue: (value: string) => void;
    toggleFacetValue: (value: string, values: any[]) => void;
    toggleKeyExists?: () => void;
  };
  getActions: (facet: FacetBase) => FacetPickerAction[];
  hardcodedFacets?: FacetName[];
  keepOpen?: boolean;
  logsState: ReturnType<typeof useLogsState>;
  queryScheduler: ReturnType<typeof useQueryScheduler>;
  renderNameHandler: (facet: FacetBase) => () => ReactNode;
  renderPlaceholderText: (facetKey: string) => string;
  requestByFacet: (
    facet: FacetBase,
  ) => Promise<{ count: number; value: string }[]>;
  selectedFacetRanges: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
};

const LogsFacetGroup = ({
  component,
  handlersByName,
  hardcodedFacets,
  getActions,
  keepOpen,
  logsState,
  queryScheduler,
  renderNameHandler,
  renderPlaceholderText,
  requestByFacet,
  selectedFacetRanges,
  selectedFacetValuesByName,
}: Props): ReactElement => {
  const expandedToggle = useToggle();
  const toogleHideAutogeneratedFacets = useToggle(true);
  // const expanded = expandedToggle.value;
  const hideAutogeneratedFacets = toogleHideAutogeneratedFacets.value;
  const [expanded, setExpanded] = useState(false);

  return (
    <FacetAccordion
      renderContent={() => {
        return expanded || keepOpen ? (
          <LogsFacetGroupExpanded
            component={component}
            getActions={getActions}
            handlersByName={handlersByName}
            hardcodedFacets={hardcodedFacets}
            hideAutogeneratedFacets={hideAutogeneratedFacets}
            logsState={logsState}
            queryScheduler={queryScheduler}
            renderNameHandler={renderNameHandler}
            renderPlaceholderText={renderPlaceholderText}
            requestByFacet={requestByFacet}
            selectedFacetRanges={selectedFacetRanges}
            selectedFacetValuesByName={selectedFacetValuesByName}
          />
        ) : null;
      }}
      renderTrigger={() => component}
      expanded={expanded}
      setExpanded={(updatedExpanded) => {
        if (updatedExpanded) {
          expandedToggle.toggle(); // no need?
        }
        setExpanded(updatedExpanded);
      }}
      isVisuallyHidden={false}
      triggerClassName="leading-[28px] pl-2 mb-1 w-full active:bg-interaction-nested-contrast hover:bg-interaction-nested data-[state=open]:bg-interaction-nested border-t-0 border-transparent cursor-pointer group"
    />
  );

  return (
    <>
      <div className="facet-group">
        {!keepOpen ? (
          <button
            className="facet-group__button"
            onClick={expandedToggle.toggle}
          >
            <FlyoutCaret
              className="facet-group__button__flyout-caret"
              isOpen={expanded}
            />
            <div className="facet-group__button__text">{component}</div>
            {!hardcodedFacets && (
              <span
                className="logs__facet-group__button__hide-autogenerated"
                onClick={(e) => {
                  e.stopPropagation();
                  toogleHideAutogeneratedFacets.toggle();
                }}
              >
                <TooltipTrigger
                  tooltip={
                    hideAutogeneratedFacets
                      ? 'Show autogenerated'
                      : 'Hide autogenerated'
                  }
                >
                  {hideAutogeneratedFacets ? (
                    <BsFillEyeFill />
                  ) : (
                    <BsFillEyeSlashFill />
                  )}
                </TooltipTrigger>
              </span>
            )}
          </button>
        ) : null}
      </div>
      {expanded || keepOpen ? (
        <LogsFacetGroupExpanded
          component={component}
          getActions={getActions}
          handlersByName={handlersByName}
          hardcodedFacets={hardcodedFacets}
          hideAutogeneratedFacets={hideAutogeneratedFacets}
          logsState={logsState}
          queryScheduler={queryScheduler}
          renderNameHandler={renderNameHandler}
          renderPlaceholderText={renderPlaceholderText}
          requestByFacet={requestByFacet}
          selectedFacetRanges={selectedFacetRanges}
          selectedFacetValuesByName={selectedFacetValuesByName}
        />
      ) : null}
    </>
  );
};

export default LogsFacetGroup;
