import { SelectedFacetValuesByName } from 'types';
import useSelectedFacetValuesByNameState from '../useSelectedFacetValuesByNameState';
import {
  Filter,
  FilterType,
  Operator,
  SelectedFacetValueFilter,
} from './types';
import useFiltersState from './useFiltersState';

const findSelectedFacetValueFilterByKey =
  (facet: string, operator?: Operator) => (filter: Filter) =>
    filter.type === FilterType.selectedFacetValue &&
    filter.value.facet === facet &&
    (operator ? filter.value.operator === operator : true);

type Args = {
  facet: string;
  operator: Operator.equal | Operator.notEqual;
  values: Record<string, 1>;
};

const getSelectedFacetValueFilter = ({
  facet,
  operator,
  values,
}: Args): SelectedFacetValueFilter => ({
  disabled: false,
  type: FilterType.selectedFacetValue,
  value: {
    facet,
    operator,
    values,
  },
});

const getState = (state: ReturnType<typeof useFiltersState>['state']) => {
  const selectedFacetValuesByNameFilters = state.filter(
    (filter) =>
      filter.type === FilterType.selectedFacetValue &&
      (filter.value.operator === Operator.equal ||
        filter.value.operator === Operator.notEqual),
  ) as SelectedFacetValueFilter[];

  return selectedFacetValuesByNameFilters.reduce(
    (obj, filter) => ({
      ...obj,
      [filter.value.facet]: Object.keys(filter.value.values).reduce(
        (valuesObj, value) => ({
          ...valuesObj,
          [value]: filter.value.operator === Operator.equal ? 1 : 0,
        }),
        {},
      ),
    }),
    {},
  );
};

export const getLegacySelectedFacetValuesByNameState = (
  state: ReturnType<typeof useFiltersState>['state'],
) => {
  const selectedFacetValuesFilters = state.filter(
    (filter) =>
      filter.type === FilterType.selectedFacetValue &&
      (filter.value.operator === Operator.equal ||
        filter.value.operator === Operator.notEqual),
  ) as SelectedFacetValueFilter[];

  return selectedFacetValuesFilters.reduce(
    (obj, filter) => ({
      ...obj,
      [filter.value.facet]: Object.keys(filter.value.values).reduce(
        (obj, value) => ({
          ...obj,
          [value]: filter.value.operator === Operator.equal ? 1 : 0,
        }),
        {},
      ),
    }),
    {},
  );
};

const getSetState = (setState) => (nextStateOrCallback) => {
  setState((prevState) => {
    const nextSelectedFacetValuesState =
      typeof nextStateOrCallback === 'function'
        ? nextStateOrCallback(getState(prevState))
        : nextStateOrCallback;

    const nextState = [...prevState];
    Object.keys(nextSelectedFacetValuesState).forEach((facet) => {
      const prevValues = nextSelectedFacetValuesState[facet];

      const operator =
        Object.values(prevValues).length && !Object.values(prevValues)[0]
          ? Operator.notEqual
          : Operator.equal;

      const nextValues = Object.keys(prevValues).reduce(
        (obj, value) => ({ ...obj, [value]: 1 }),
        {},
      );

      const index = nextState.findIndex(
        findSelectedFacetValueFilterByKey(facet),
      );
      const nextIndex = index > -1 ? index : nextState.length;

      nextState[nextIndex] = getSelectedFacetValueFilter({
        facet,
        operator,
        values: nextValues,
      });
    });

    return nextState;
  });
};

const getSelectedFacetValuesByNameState = (
  filtersState: ReturnType<typeof useFiltersState>,
): ReturnType<typeof useSelectedFacetValuesByNameState> => {
  const { setState, state } = filtersState;
  const clearFacet = (name: string) => {
    setState((prevState) => {
      const nextState = [...prevState];
      const index = nextState.findIndex(
        findSelectedFacetValueFilterByKey(name),
      );

      if (index > -1) {
        nextState.splice(index, 1);
      }

      return nextState;
    });
  };

  const excludeFacetValue = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }) => {
    setState((prevState) => {
      const nextState = [...prevState];
      const filterIndex = prevState.findIndex(
        findSelectedFacetValueFilterByKey(name),
      );
      const nextIndex = filterIndex > -1 ? filterIndex : prevState.length;
      const prevValues =
        nextIndex > -1
          ? (nextState[filterIndex] as SelectedFacetValueFilter).value.values
          : {};

      const nextValues: Record<string, 1> = {
        ...prevValues,
        [value]: 1,
      };

      nextState[nextIndex] = getSelectedFacetValueFilter({
        facet: name,
        operator: Operator.notEqual,
        values: nextValues,
      });

      return nextState;
    });
  };

  const set = ({
    selectedFacetValuesByName,
  }: {
    selectedFacetValuesByName: SelectedFacetValuesByName;
  }) => {
    setState((prevState) => {
      const nextState = [...prevState];
      Object.keys(selectedFacetValuesByName).forEach((facet) => {
        const prevValues = selectedFacetValuesByName[facet];

        const nextValues = Object.keys(prevValues).reduce(
          (obj, value) => ({ ...obj, [value]: 1 }),
          {},
        );

        const operator =
          Object.values(prevValues) && !Object.values(prevValues)[0]
            ? Operator.notEqual
            : Operator.equal;

        const filterIndex = nextState.findIndex(
          findSelectedFacetValueFilterByKey(facet),
        );

        const nextIndex = filterIndex > -1 ? filterIndex : nextState.length;
        nextState[nextIndex] = getSelectedFacetValueFilter({
          facet,
          operator,
          values: nextValues,
        });

        return nextState;
      });
    });
  };

  const selectOnlyFacetValue = ({ name, value }) => {
    setState((prevState) => {
      const nextState = [...prevState];
      const filterIndex = nextState.findIndex(
        findSelectedFacetValueFilterByKey(name),
      );

      const nextIndex = filterIndex > -1 ? filterIndex : nextState.length;
      nextState[nextIndex] = getSelectedFacetValueFilter({
        facet: name,
        operator: Operator.equal,
        values: {
          [value]: 1,
        },
      });

      return nextState;
    });
  };

  const toggleFacetPickerValueCheckbox = ({
    facetName,
    facetValueToToggle,
    allFacetValues,
  }: {
    facetName: string;
    facetValueToToggle: string;
    allFacetValues: Array<string>;
  }) => {
    setState((prevState) => {
      const nextState = [...prevState];
      const filterIndex = nextState.findIndex(
        findSelectedFacetValueFilterByKey(facetName),
      );

      const nextIndex = filterIndex > -1 ? filterIndex : nextState.length;
      const prevFilter =
        filterIndex > -1
          ? (nextState[filterIndex] as SelectedFacetValueFilter)
          : null;
      const prevValues = prevFilter ? prevFilter.value?.values : {};

      const nextOperator = prevFilter
        ? prevFilter.value.operator
        : Operator.notEqual;
      const nextValues = { ...prevValues };
      if (nextValues[facetValueToToggle]) {
        delete nextValues[facetValueToToggle];
      } else {
        nextValues[facetValueToToggle] = 1;
      }

      // If facet values count is empty we should either omit the filter or remove it
      if (Object.keys(nextValues).length === 0) {
        // Remove the filter
        if (nextIndex < nextState.length) {
          nextState.splice(nextIndex, 1);
          return nextState;

          // omit the filter
        } else {
          return nextState;
        }
      }

      nextState[nextIndex] = getSelectedFacetValueFilter({
        facet: facetName,
        operator: nextOperator as Operator.equal | Operator.notEqual,
        values: nextValues,
      });

      return nextState;
    });
  };

  const toggleFacetValue = ({ isSelecting = null, name, value }) => {
    setState((prevState) => {
      const nextState = [...prevState];
      const filterIndex = nextState.findIndex(
        findSelectedFacetValueFilterByKey(name),
      );

      const nextIndex = filterIndex > -1 ? filterIndex : nextState.length;
      const prevValues =
        filterIndex > -1
          ? (nextState[filterIndex] as SelectedFacetValueFilter).value.values
          : {};
      const nextValues = { ...prevValues };
      if (nextValues[value]) {
        delete nextValues[value];
      } else {
        nextValues[value] = 1;
      }

      nextState[nextIndex] = getSelectedFacetValueFilter({
        facet: name,
        operator: Operator.equal,
        values: nextValues,
      });

      return nextState;
    });
  };

  const setFacetValues = ({ facetValues, name }) => {
    if (Object.keys(facetValues)) {
      setState((prevState) => {
        const nextState = [...prevState];
        const filterIndex = nextState.findIndex(
          findSelectedFacetValueFilterByKey(name),
        );
        const nextIndex = filterIndex > -1 ? filterIndex : nextState.length;
        const operator = Object.values(facetValues)[0]
          ? Operator.equal
          : Operator.notEqual;

        const nextValues = Object.keys(facetValues).reduce(
          (obj, value) => ({ ...obj, [value]: 1 }),
          {},
        );

        nextState[nextIndex] = getSelectedFacetValueFilter({
          facet: name,
          operator,
          values: nextValues,
        });

        return nextState;
      });
    }
  };

  return {
    clearFacet,
    excludeFacetValue,
    selectOnlyFacetValue,
    setFacetValues,
    set,
    state: getLegacySelectedFacetValuesByNameState(state),
    setState: getSetState(state),
    toggleFacetValue,
    toggleFacetPickerValueCheckbox,
  };
};

export default getSelectedFacetValuesByNameState;
