import React, { MutableRefObject, ReactNode } from 'react';
import { PopoverTriggerV2 } from 'components';
import { useKeyExistsState } from 'hooks';
import { FacetBase } from 'types';

import { useLogsWorkbooksState } from './hooks';
import LogsFacetGroupFacetIcon from './LogsFacetGroupFacetIcon';
import LogsSelectedLogAttributePanel from './LogsSelectedLogAttributePanel';

type Props = {
  containerRef?: MutableRefObject<HTMLDivElement>;
  customColumnsState?: ReturnType<typeof useKeyExistsState>;
  disableAddCustomColumn?: boolean;
  enableKeyExistsFilter?: boolean;
  fpHash: string;
  logFacet: FacetBase;
  index: number;
  label: ReactNode;
  logsState: ReturnType<typeof useLogsWorkbooksState>['currentLogsState'];
  offsetX?: number;
  offsetY?: number;
  showType?: boolean;
  shouldDisableFilterActions?: boolean;
  source: string;
};

const LogsSelectedLogAttribute = ({
  containerRef,
  customColumnsState,
  disableAddCustomColumn,
  enableKeyExistsFilter,
  fpHash,
  label,
  logFacet,
  logsState,
  offsetX,
  offsetY,
  showType,
  shouldDisableFilterActions,
  source,
}: Props) => {
  return (
    <PopoverTriggerV2
      className="logs__selected-log__attribute"
      container={containerRef?.current}
      popover={(props) => (
        <LogsSelectedLogAttributePanel
          customColumnsState={customColumnsState}
          disableAddCustomColumn={disableAddCustomColumn}
          enableKeyExistsFilter={enableKeyExistsFilter}
          fpHash={fpHash}
          logFacet={logFacet}
          logsState={logsState}
          shouldDisableFilterActions={shouldDisableFilterActions}
          source={source}
          {...props}
        />
      )}
      offsetX={offsetX}
      offsetY={offsetY}
      shouldStopPropagation={true}
    >
      <span className="logs__selected-log__attribute__label">
        {showType && logFacet.type ? (
          <div className="logs__selected-log__attribute__label__icon">
            <LogsFacetGroupFacetIcon type={logFacet.type} />
          </div>
        ) : null}
        {label}
      </span>
    </PopoverTriggerV2>
  );
};

export default LogsSelectedLogAttribute;
