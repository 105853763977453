import { Filter, FilterType, useFiltersState } from 'hooks';
import getFacetValueTag from './getFacetValueTag';
import React from 'react';

type Args = {
  filtersState: ReturnType<typeof useFiltersState>;
  idSearch?: string;
  setIdSearch: (idSearch: string) => void;
};

type GetTagAndLabelTextArgs = {
  filter: Filter;
};

const getTagLabelAndText = ({ filter }: GetTagAndLabelTextArgs) => {
  const { type } = filter;
  switch (type) {
    case FilterType.selectedFacetValue:
      return getFacetValueTag({
        selectedFacetValueFilter: filter,
      });
    default:
      return {
        label: null as null,
        text: '',
      };
  }
};

type GetTagArgs = {
  deleteByIndex: (i: number) => void;
};

const getTag =
  ({ deleteByIndex }: GetTagArgs) =>
  (filter: Filter, i: number) => {
    const onRemove = () => {
      deleteByIndex(i);
    };

    return {
      ...getTagLabelAndText({ filter }),
      onRemove,
    };
  };

const getTags = ({ filtersState, idSearch, setIdSearch }: Args) => {
  const { deleteByIndex } = filtersState;
  const tags = filtersState.state.map(getTag({ deleteByIndex }));

  if (idSearch) {
    const label = (
      <>
        <span className="rum-search__input__trigger__tag__name">
          {'View ID'}
        </span>
        <span>:</span>
        <span className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {idSearch}
        </span>
      </>
    );

    tags.push({
      name: 'View ID',
      label,
      onRemove: () => {
        setIdSearch('');
      },
      text: idSearch,
    });
  }

  return tags;
};

export default getTags;
