import { useMemo, useState } from 'react';
import { Operation } from 'types';
import { SearchState } from './types';
import { getInitialState, nextChar } from './utils';
import { useUrlState } from '..';

const useSearches = ({
  initialSearches = [getInitialState()],
  shouldWriteToUrl = true,
}: {
  initialSearches?: Array<SearchState>;
  shouldWriteToUrl?: boolean;
}) => {
  const [urlStateSearches, setUrlStateSearches] = useUrlState<
    Array<SearchState>
  >('searches', initialSearches);

  const [stateSearches, setStateSearches] =
    useState<Array<SearchState>>(initialSearches);

  const searches = shouldWriteToUrl ? urlStateSearches : stateSearches;
  const setSearches = shouldWriteToUrl ? setUrlStateSearches : setStateSearches;

  const addNewSearch = (isNewOnlyActive?: boolean) => {
    if (isNewOnlyActive) {
      setSearches((prevSearches) => [
        ...prevSearches.map((search) => ({
          ...search,
          isActive: false,
        })),
        {
          ...prevSearches[prevSearches.length - 1],
          queryKey: prevSearches[prevSearches.length - 1].queryKey
            ? nextChar(prevSearches[prevSearches.length - 1].queryKey)
            : 'a',
          isActive: true,
        },
      ]);
    } else {
      setSearches((prevSearches) => [
        ...prevSearches,
        {
          ...prevSearches[prevSearches.length - 1],
          queryKey: prevSearches[prevSearches.length - 1].queryKey
            ? nextChar(prevSearches[prevSearches.length - 1].queryKey)
            : 'a',
        },
      ]);
    }
  };

  const deactivateAll = () => {
    setSearches((prevSearches) =>
      prevSearches.map((search) => ({ ...search, isActive: false })),
    );
  };

  const setStateByIndex = (index, nextState) => {
    setSearches((prevSearches) => {
      const nextSearches = [...prevSearches];
      const prevSearch = prevSearches[index];
      const nextSearch =
        typeof nextState === 'function' ? nextState(prevSearch) : nextState;
      nextSearches[index] = { ...prevSearch, ...nextSearch };
      return nextSearches;
    });
  };

  const addGroupByIndex = (index: number) => (group: string) => {
    setStateByIndex(index, (prevState) => {
      const nextGroupBys = [...prevState.groupBys, group];
      return {
        groupBys: nextGroupBys,
      };
    });
  };

  const removeGroupByIndexHandlerByIndex = (index: number) => (i: number) => {
    setStateByIndex(index, (prevState) => {
      const nextGroupBys = [...prevState.groupBys];
      nextGroupBys.slice(i, 1);
      return {
        groupBys: nextGroupBys,
      };
    });
  };

  const changeHandlerByIndex =
    <T extends keyof SearchState>(index: number, key: T) =>
    (value: SearchState[T]) => {
      setStateByIndex(index, { [key]: value });
    };

  const changeMeasureByIndex = (index: number) => (nextMeasure: string) => {
    setStateByIndex(index, {
      measure: nextMeasure,
      operation:
        nextMeasure === 'duration_ns' ? Operation.avg : Operation.distinctcount,
    });
  };

  const searchBarStateByIndex = (
    index: number,
    searchBarState: SearchState['searchBarState'],
  ) => {
    setStateByIndex(index, { searchBarState });
  };

  const selectOnlySingeQuery = (index: number) => {
    setSearches((prevSearches) => {
      const nextSearches = prevSearches.map((search, i) => ({
        ...search,
        isActive: i === index,
      }));
      return nextSearches;
    });
  };

  const memoizedSearches = useMemo(() => {
    return searches.map((state, i) => ({
      ...state,
      addGroupBy: addGroupByIndex(i),
      addNewSearch,
      changeActive: changeHandlerByIndex(i, 'isActive'),
      changeGroupBys: changeHandlerByIndex(i, 'groupBys'),
      changeLimitTo: changeHandlerByIndex(i, 'limitTo'),
      changeLimitToValue: changeHandlerByIndex(i, 'limitToValue'),
      changeMeasure: changeMeasureByIndex(i),
      changeOperation: changeHandlerByIndex(i, 'operation'),
      changeRollUpInSeconds: changeHandlerByIndex(i, 'rollUpInSeconds'),
      changeVisualizeAs: changeHandlerByIndex(i, 'visualizeAs'),
      deactivateAll,
      selectOnlySingeQuery: () => selectOnlySingeQuery(i),
      removeGroupByByIndexHandler: removeGroupByIndexHandlerByIndex(i),
      removeExistingSearch: () => {
        setSearches((prevSearches) => {
          if (prevSearches.length === 1) {
            return prevSearches;
          }
          const nextSearches = [...prevSearches];
          nextSearches.splice(i, 1);
          return nextSearches;
        });
      },
      setSearchBarState: (searchBarState: SearchState['searchBarState']) =>
        searchBarStateByIndex(i, searchBarState),
      state,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searches]);

  return { searches: memoizedSearches, searchesState: searches };
};

export default useSearches;
