import { LogsMetricQueryProps } from 'types';

export const defaultLogsQuery: LogsMetricQueryProps = {
  isActive: true,
  filters: [],
  limit: { direction: 'topk', count: '10' },
  showInput: false,
  metric: '*',
  normalizeFunction: 'number',
  queryKey: 'a',
  rangeAggregate: 'count_over_time',
  rangeAggregateGrouping: [],
  rangeAggregateExclude: false,
  step: null,
  vectorAggregate: 'count',
  vectorAggregateGrouping: [],
  vectorAggregateExclude: false,
};

export const getLogsExplorerDefaultQueryByFilter = (
  filters: LogsMetricQueryProps['filters'],
): LogsMetricQueryProps => {
  return {
    ...defaultLogsQuery,
    filters,
  };
};
