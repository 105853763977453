import { Json, Loader } from 'components';
import { useRequest } from 'hooks';
import * as yaml from 'js-yaml';
import React, { useEffect, useMemo } from 'react';
import { kubeYaml } from 'requests';
import { useKubernetesController } from '../KubernetesController';

import { EntityTypes } from '../types';
import { entityKeyMapping, entityMappedToGraphQL } from '../utils';

type Props = {
  entity: any;
  entityType: EntityTypes;
  kubeViewFilterParams: any;
};

function convertYamlToJson(yamlString: any) {
  try {
    return yaml.load(yamlString);
  } catch (e) {
    return e;
  }
}

const KubernetesSideBarYaml = ({
  entity,
  entityType,
  kubeViewFilterParams,
}: Props) => {
  const { facets: selectedFacetValuesByNameState } = useKubernetesController();
  const kubeYamlRequest = useRequest(kubeYaml);
  useEffect(() => {
    if (EntityTypes.Cluster === entityType) {
      return;
    }

    const keyData = entityKeyMapping[entityType];
    const valueData = entity?.tags?.find((tag) => tag?.startsWith(keyData));

    const nameFilter = valueData?.split(':');
    const nameFilterValue = nameFilter?.length > 1 ? nameFilter?.[1] : '';

    const controller = new AbortController();

    if (kubeViewFilterParams) {
      kubeYamlRequest
        .call(
          {
            entityType: entityType,
            ...kubeViewFilterParams,
            selectedFacetValuesByName: {
              ...kubeViewFilterParams?.selectedFacetValuesByName,
              [keyData]: {
                [nameFilterValue]: 1,
              },
            },
          },
          { signal: controller.signal },
        )
        .catch((err) => {
          if (err.name !== 'AbortError') {
            throw err;
          }
        });
    }

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    entity?.tags,
    entityType,
    kubeViewFilterParams,
    selectedFacetValuesByNameState.state,
  ]);

  const yamlValue = useMemo(() => {
    const yamlData = kubeYamlRequest.result;
    return yamlData && yamlData.length > 0
      ? yamlData?.[0]?.[entityMappedToGraphQL(entityType)]?.yaml
      : '';
  }, [entityType, kubeYamlRequest.result]);

  return (
    <Loader isLoading={kubeYamlRequest.isLoading}>
      <div className="kubernetes-sidebar__yaml__head">
        <div className="kubernetes-sidebar__yaml__subhead">
          <div className="kubernetes-sidebar__yaml__subhead__tree">
            <Json data={convertYamlToJson(yamlValue)} />
          </div>
        </div>
      </div>
    </Loader>
  );
};

export default KubernetesSideBarYaml;
