import dayjs from 'dayjs';
import { UplotExtended } from 'types/Timeseries';
import { themeColorDark, themeColorLight } from 'utils/colors';

import { canvasPadding } from './tooltipUtils';
import { roundedRect } from './drawUtils';

const getChartDuration = (data: Array<any>): number => {
  const first = data[0][0];
  const last = data[0][data[0].length - 1];
  return last - first;
};

/**
 * Format a timestamp into a string for the tooltip
 * @param timestamp
 * @param duration
 * @returns {string}
 * logic from
 * 1. If duration is less than 1 day, show hh:mm:ss
 * 2. If duration is less than 1 week and more than or equal to 1 day 09:50 Mon Dec 21
 * 3. If duration is more than 1 week, show 09:50 Dec 21
 */
const formatTooltipTimestamp = ({
  timestamp,
  duration,
  utcTimeEnabled,
}: {
  timestamp: number;
  duration: number;
  utcTimeEnabled: boolean;
}): string => {
  const date = utcTimeEnabled
    ? dayjs.unix(timestamp).utc()
    : dayjs.unix(timestamp);
  const isLessThanOneDay = duration < 86400;
  if (isLessThanOneDay) return date.format('HH:mm:ss');

  const isLessThanOneWeek = duration < 604800;
  if (isLessThanOneWeek) return date.format('HH:mm ddd MMM D');

  const isLessThanOneYear = duration < 31536000;
  if (isLessThanOneYear) return date.format('HH:mm MMM D');

  return date.format('HH:mm MMM D YYYY');
};

const drawTooltipTimestamp = ({
  bbox,
  leftX,
  timestamp,
  u,
}: {
  bbox: DOMRect;
  leftX: number;
  timestamp: number;
  u: UplotExtended;
}): void => {
  const { darkModeEnabled, data, tooltipCtx, utcTimeEnabled } = u;
  const ctx = tooltipCtx;
  const devicePixelRatio = window.devicePixelRatio || 1;
  const CHEVRON_SIZE = 10 * devicePixelRatio;
  const padding = canvasPadding(u);
  const { width } = bbox;
  const bottomY = u.height * devicePixelRatio - padding.bottom + CHEVRON_SIZE;

  const text = formatTooltipTimestamp({
    timestamp,
    duration: getChartDuration(data),
    utcTimeEnabled,
  });
  const textWidth = ctx.measureText(text).width;
  const fontSize = 10 * devicePixelRatio;
  const textHeight = fontSize + 8 * devicePixelRatio;

  ctx.save();
  const bgColor = darkModeEnabled
    ? themeColorLight.light01
    : themeColorDark.dark01;
  ctx.fillStyle = bgColor;
  const paddingLeft = 8 * devicePixelRatio;
  const paddingTop = 8 * devicePixelRatio;

  let tLeftX = leftX - textWidth / 2;
  const tRightX = leftX + textWidth / 2 + paddingLeft;
  const canvasWidth = width * devicePixelRatio + padding.left;
  if (tLeftX < padding.left) {
    tLeftX = padding.left;
  } else if (tRightX > canvasWidth) {
    tLeftX = canvasWidth - textWidth;
  }

  roundedRect({
    ctx,
    x: tLeftX - paddingLeft,
    y: bottomY,
    width: textWidth + paddingLeft,
    height: textHeight,
    radius: 0,
  });

  const textColor = darkModeEnabled
    ? themeColorDark.dark02
    : themeColorLight.light00;
  ctx.font = `normal ${fontSize}pt Inter`;
  ctx.fillStyle = textColor;
  const textLeftX = tLeftX - paddingLeft / 2;
  const textBottomY = bottomY + textHeight / 2 + paddingTop / 2;
  ctx.fillText(text, textLeftX, textBottomY);
};

export default drawTooltipTimestamp;
