import { useDateState, useSearchFormulas } from 'hooks';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ChartGridKeysState } from 'types';
import { extractPathNameFromURL, parseUrlParamByKey } from 'utils';
import { useSearches } from '../useSearch';
import useTracesState from '../useTracesState';
import { isTraceAnalyticsView } from 'screens/Traces/utils';
type Args = {
  apmDateState: ReturnType<typeof useDateState>;
  chartGridKeysState: ChartGridKeysState;
};

const useTracesPageState = ({ apmDateState, chartGridKeysState }: Args) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const parsedSearches = parseUrlParamByKey('traceSearches');
  const { searches, searchesState } = useSearches({
    shouldWriteToUrl: false,
    initialSearches: parsedSearches ? parsedSearches : undefined,
  });

  const parsedFormulas = parseUrlParamByKey('traceFormulas');

  const queries = searches.map((search) => search.state);
  const searchesFormulas = useSearchFormulas(queries, parsedFormulas);

  const tracesState = useTracesState({
    dateState: apmDateState,
    initialFilters: parseUrlParamByKey('tracesFilters'),
    initialSelectedQueryIndex: parseUrlParamByKey('selectedQueryIndexString'),
    initialSpanFilter: parseUrlParamByKey('tracesSpanFilter'),
    shouldWriteToUrl: false,
    urlStateKey: 'tracesFilters',
  });

  const date = tracesState.dateState[0];

  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(
    () => [
      date,
      searchesFormulas.formulasState,
      searches,
      tracesState.selectedQueryIndexString,
      tracesState.spanFilters.spanFilter,
      tracesState.filtersState.state,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      date,
      searchesFormulas.formulasState,
      searches,
      tracesState.selectedQueryIndexString,
      tracesState.spanFilters.spanFilter,
      tracesState.filtersState.state,
    ],
  );

  // Should be called on state change when on the Traces page
  const writeStateToUrl = () => {
    const { filtersState, spanFilters } = tracesState;
    const pathName = extractPathNameFromURL();
    params.set('apmDate', JSON.stringify(tracesState.dateState[0]));
    if (tracesState.selectedQueryIndexString) {
      params.set(
        'selectedQueryIndexString',
        JSON.stringify(tracesState.selectedQueryIndexString),
      );
    }
    if (isTraceAnalyticsView(pathName)) {
      params.set('traceSearches', JSON.stringify(searchesState));
      params.set(
        'traceFormulas',
        JSON.stringify(searchesFormulas.formulasState),
      );
    } else {
      params.delete('traceSearches');
      params.delete('traceFormulas');
    }
    params.set('tracesFilters', JSON.stringify(filtersState.state));
    params.set('tracesSpanFilter', JSON.stringify(spanFilters.spanFilter));
    const navigateUrl = `${pathName}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    chartGridKeysState,
    dependenciesForWriteStateToUrl,
    searches,
    searchesFormulas,
    tracesState,
    writeStateToUrl,
  };
};

export default useTracesPageState;
