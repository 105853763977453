import { Loader, Table, useTableSort } from 'components';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  DashboardPanelComponentProps,
  DashboardPanelType,
  DashboardStreamType,
} from 'types';

import { useDashboardDataLoader } from '../hooks';
import {
  getActivePromqlQueryRefId,
  getPanelStreamType,
  getTableColumns,
  mapPanelTableData,
  organizeTableData,
} from '../utils';

const DashboardPanelTable = ({
  baseWidth,
  dashboardState,
  dashboardTemplateState,
  isInView,
  nestedIndex,
  panel,
  panelIndex,
}: DashboardPanelComponentProps): ReactElement => {
  const [tableData, setTableData] = useState<any[]>([]);
  const { templateValues } = dashboardTemplateState;
  const stream = getPanelStreamType(panel);

  const dashboardDataLoader = useDashboardDataLoader({
    baseWidth,
    dashboardState,
    isInView,
    nestedIndex,
    panelIndex,
    templateValues,
    type:
      stream === DashboardStreamType.LOG
        ? DashboardPanelType.TABLE
        : 'unflattened',
  });

  const unit = panel.fieldConfig?.defaults?.unit;

  useEffect(() => {
    if (!dashboardDataLoader.result) {
      return;
    }

    if (stream === DashboardStreamType.LOG) {
      return setTableData(dashboardDataLoader.result?.data || []);
    }

    const activePromqlQueryRefId = getActivePromqlQueryRefId(panel.targets);
    const transformedData = organizeTableData(
      activePromqlQueryRefId,
      dashboardDataLoader.result,
      panel.fieldConfig?.overrides,
      panel.transformations,
    );
    const mappedData = mapPanelTableData({
      fieldConfig: panel.fieldConfig,
      data: transformedData,
    });
    setTableData(mappedData);
  }, [dashboardDataLoader.result, panel, stream]);

  const columns = useMemo(() => {
    if (!tableData) return [];
    if (stream === DashboardStreamType.LOG) {
      const logsTableCols = dashboardDataLoader.result?.labels.map((d) => ({
        key: d,
        label: d,
      }));
      if (!logsTableCols || logsTableCols.length === 0) return [];

      logsTableCols.push({ key: 'count', label: 'count' });
      return logsTableCols;
    }

    return getTableColumns({
      data: tableData,
      overrides: panel.fieldConfig?.overrides,
      transformations: panel.transformations,
      unit,
    });
  }, [
    tableData,
    stream,
    panel.fieldConfig?.overrides,
    panel.transformations,
    unit,
    dashboardDataLoader.result?.labels,
  ]);

  const tableSort = useTableSort({ columns, rows: tableData });

  return (
    <Loader isLoading={dashboardDataLoader.isLoading}>
      <div className="dashboard__panel__table">
        {tableData && tableData.length > 0 && (
          <Table
            className="dashboard__panel__table__table"
            columns={columns}
            externalTableSort={tableSort}
            isStickyHeaderEnabled={true}
            isSortingEnabled
            rows={tableData}
          />
        )}
      </div>
    </Loader>
  );
};

export default DashboardPanelTable;
