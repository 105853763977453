import {
  TimeseriesRenderer,
  useCursorContextState,
  useThemeContext,
} from 'components';
import React, { ReactElement, useMemo } from 'react';
import {
  drawForecastVerticalLine,
  mergeTwoTimeSeries,
  drawForecastSeriesByIndex,
  setDateRangeOnChartZoom,
} from 'utils';
import { DashboardPanelProps } from 'types';

import { DashboardPanelNoData } from '../../components';
import { useDashboardState, useDashboardDataLoader } from '../../hooks';
import { DashboardPanelComponentProps } from '../../types';
import {
  checkIfDataNotAvailable,
  getPanelStyles,
  mapTimeseriesLegendMode,
} from '../../utils';

const defaultChartTypes = ['Line'];

const Forecast = ({
  dashboardState,
  dashboardDataLoader,
  panel,
  panelSize,
}: {
  dashboardState: ReturnType<typeof useDashboardState>;
  dashboardDataLoader: ReturnType<typeof useDashboardDataLoader>;
  panel: DashboardPanelProps;
  panelSize: DashboardPanelComponentProps['panelSize'];
}): ReactElement => {
  const { cursorState, setCursorState } = useCursorContextState();

  const { date, onDateChange } = dashboardState;
  const { options } = panel;
  const { darkModeEnabled } = useThemeContext();

  const mergedEvalAndHistoricalData = useMemo(() => {
    if (!dashboardDataLoader.result) return null;
    if (dashboardDataLoader.result?.length === 0) return null;

    const historicalData = dashboardDataLoader.result?.[0];
    const evaluationData = dashboardDataLoader.result?.[1];

    if (!evaluationData || !historicalData) return null;
    const mergedData = mergeTwoTimeSeries({
      timeSeries1: evaluationData,
      timeSeries2: historicalData,
    });

    return mergedData;
  }, [dashboardDataLoader.result]);

  const getVerticalLineHook = () => {
    if (
      !mergedEvalAndHistoricalData ||
      mergedEvalAndHistoricalData.series?.length === 0
    ) {
      return {};
    }

    const historicalData = dashboardDataLoader.result?.[0];
    const breakPoint = historicalData?.data[0].length - 1 || 0;

    return {
      hooks: {
        draw: (u) => {
          drawForecastVerticalLine({
            u,
            darkModeEnabled,
            forcastDuration: '',
            breakPoint,
          });
        },
        drawSeries: (u, seriesIndex) => {
          drawForecastSeriesByIndex({ u, seriesIndex, breakPoint });
        },
      },
    };
  };

  const panelStyles = useMemo(
    () => getPanelStyles(panel.fieldConfig?.defaults),
    [panel],
  );

  const hooks = useMemo(
    () => [
      {
        hook: (u) => setDateRangeOnChartZoom(u, onDateChange),
        type: 'setSelect',
      },
    ],
    [onDateChange],
  );

  return (
    <>
      {!checkIfDataNotAvailable(mergedEvalAndHistoricalData) ? (
        <TimeseriesRenderer
          chartData={mergedEvalAndHistoricalData || { data: [], series: [] }}
          chartTypes={defaultChartTypes}
          cursorState={cursorState}
          date={date || null}
          isLoading={dashboardDataLoader.isLoading}
          hooks={hooks}
          layoutType="dashboard"
          legend={{
            legendHeight: panelSize.height * 0.25,
            legendType: mapTimeseriesLegendMode(options.legend.displayMode),
          }}
          plugins={[getVerticalLineHook()]}
          onCursorStateChange={setCursorState}
          size={{
            width: panelSize.width,
            height:
              options.legend.displayMode === 'list'
                ? panelSize.heightContainer - panelSize.heightContainer * 0.25
                : panelSize.heightContainer,
          }}
          styles={panelStyles}
          tooltipType={'compact'}
          unit={panel.fieldConfig.defaults.unit || 'number'}
        />
      ) : (
        <DashboardPanelNoData panelSize={panelSize} />
      )}
    </>
  );
};

export default Forecast;
