import {
  getAgileAnomaliesPromQL,
  getBasicAnomaliesPromQL,
  getBoundNumberByValue,
  getRobustAnomaliesPromQL,
  getRollupToMinute,
  getAgileRobustAnomaliesPromQL,
} from 'utils';
import { AnomalyAlgorithmType } from 'types/MetricsQueryBuilder';

import { AnomalyConditionProps } from '../../AlertsCreateCondition';
import { getBasicAnomalyAlertPromql } from './alertsCreateMetricsAnomalyBasic';
import { alertQueryAndVariableParser } from '../../utils';

export const anomalyBandMap: { [key: string]: string } = {
  lower: `4`,
  upper: `5`,
  both: `6`,
};
const anomalyBandMapReverse: { [key: string]: string } = {
  '4': `lower`,
  '5': `upper`,
  '6': `both`,
};

export const getAnomalyAlertsCreatePromQL = ({
  anomalyCondition,
  query,
  step,
}: {
  anomalyCondition: AnomalyConditionProps;
  query: string;
  step?: number;
}): string => {
  if (!query) return '';
  const {
    anomalyAlgorithm,
    band,
    bound,
    model,
    agileRobustSeasonality,
    seasonality,
    window,
  } = anomalyCondition;

  const bandMapped = anomalyBandMap[band];
  if (anomalyAlgorithm === AnomalyAlgorithmType.BASIC) {
    const basic = getBasicAnomalyAlertPromql(anomalyCondition, query);
    return basic;
  }

  if (anomalyAlgorithm === AnomalyAlgorithmType.AGILE) {
    const [upper] = getAgileAnomaliesPromQL({ band: bandMapped, bound, query });
    return upper;
  }

  if (anomalyAlgorithm === AnomalyAlgorithmType.ROBUST) {
    const [upper] = getRobustAnomaliesPromQL({
      band: bandMapped,
      bound,
      model,
      query,
      seasonality,
      step,
      window,
    });
    return upper;
  }

  if (anomalyAlgorithm === AnomalyAlgorithmType.AGILE_ROBUST) {
    const [upper] = getAgileRobustAnomaliesPromQL({
      band: bandMapped,
      bound,
      query,
      seasonality: agileRobustSeasonality,
    });
    return upper;
  }
};

export const getAnomalyAlertsLoadPromQL = ({
  anomalyCondition,
  query,
  step,
}: {
  anomalyCondition: AnomalyConditionProps;
  query: string;
  step?: number;
}): string[] => {
  if (!query) return [];
  const { anomalyAlgorithm, bound, model, seasonality, window } =
    anomalyCondition;

  const bandMapped = '3';
  if (anomalyAlgorithm === AnomalyAlgorithmType.BASIC) {
    return getBasicAnomaliesPromQL({ bound, query, window });
  }

  if (anomalyAlgorithm === AnomalyAlgorithmType.AGILE) {
    const [upper] = getAgileAnomaliesPromQL({
      band: bandMapped,
      bound,
      query,
    });
    return [upper];
  }

  if (anomalyAlgorithm === AnomalyAlgorithmType.ROBUST) {
    const [upper] = getRobustAnomaliesPromQL({
      band: bandMapped,
      bound,
      model,
      query,
      seasonality,
      step,
      window,
    });
    return [upper];
  }

  if (anomalyAlgorithm === AnomalyAlgorithmType.AGILE_ROBUST) {
    const [upper] = getAgileRobustAnomaliesPromQL({
      band: bandMapped,
      bound,
      query,
      seasonality,
    });
    return [upper];
  }
};

export const parseEditAlertAnomalyPromql = ({
  promql,
  step,
  annotations,
}: {
  promql: string;
  step?: number;
  annotations: any;
}) => {
  const anomalyCondition: AnomalyConditionProps = {};
  let newPromql = '';
  const promqlArray = promql.split(' - ');
  if (promqlArray.length > 1) {
    newPromql = promqlArray[0];
  }

  if (promql.includes('quantile_over_time')) {
    anomalyCondition.anomalyAlgorithm = AnomalyAlgorithmType.BASIC;
    const [inner, upper] = promqlArray;
    // remove first character
    newPromql = inner.slice(1);

    const quantileIndex = upper.indexOf('quantile_over_time(');
    const boundValue = upper.slice(quantileIndex + 19, quantileIndex + 23);

    const regexW = /\[\w+:\]/g;
    const window = upper.match(regexW)[0];
    const bound = getBoundNumberByValue(
      Number(boundValue),
      AnomalyAlgorithmType.BASIC,
    );

    let band = 'both';
    if (promql.includes('>') && upper.includes('<')) {
      band = 'both';
    } else if (promql.includes('<') && !promql.includes('>')) {
      band = 'lower';
    } else if (promql.includes('>') && !promql.includes('<')) {
      band = 'upper';
    }

    const regex = /(\d+)\s*\*\s*stddev_over_time/;
    const match = promql.match(regex);
    let parsedBound = null;
    if (match && match[1]) {
      parsedBound = match[1];
    }

    anomalyCondition.window = window.replace('[', '').replace(':]', '');
    anomalyCondition.bound = parsedBound ? parsedBound : bound;
    anomalyCondition.band = band;
  }

  if (promql.includes('seasonal_decompose(')) {
    anomalyCondition.anomalyAlgorithm = AnomalyAlgorithmType.ROBUST;
    const { query, variables } = alertQueryAndVariableParser({
      algoKey: 'seasonal_decompose',
      promql,
      numberOfVariable: 5,
      hasWindow: false,
    });

    newPromql = query;
    if (variables.length > 0 && variables.length === 5) {
      const period = variables[0];
      const model = variables[1];
      const milisecond = variables[2];
      const band = variables[4];
      const window = getRollupToMinute(`${Number(milisecond)}ms`);

      anomalyCondition.window = window;
      anomalyCondition.model = model;
      anomalyCondition.bound = variables[3];
      anomalyCondition.period = period;
      anomalyCondition.band = anomalyBandMapReverse[band];
      anomalyCondition.seasonality = String(
        step ? Number(period) * step : Number(period) * 60,
      );
    }
  }

  if (promql.includes('sarima(')) {
    anomalyCondition.anomalyAlgorithm = AnomalyAlgorithmType.AGILE;
    const { query, variables } = alertQueryAndVariableParser({
      algoKey: 'sarima',
      promql,
      numberOfVariable: 9,
      hasWindow: false,
    });
    newPromql = query;
    if (variables.length > 0) {
      const band = variables[variables.length - 1];
      const bound = variables[variables.length - 2];

      anomalyCondition.band = anomalyBandMapReverse[band];
      anomalyCondition.bound = bound;
    }
  }

  if (promql.includes('prophet(')) {
    anomalyCondition.anomalyAlgorithm = AnomalyAlgorithmType.AGILE_ROBUST;
    const { query, variables } = alertQueryAndVariableParser({
      algoKey: 'prophet',
      promql,
      numberOfVariable: 3,
      hasWindow: false,
    });
    newPromql = query;
    if (variables.length > 0) {
      const agileRobustSeasonality = variables[0];
      const bound = variables[1];
      const band = variables[2];
      anomalyCondition.agileRobustSeasonality = agileRobustSeasonality;
      anomalyCondition.band = anomalyBandMapReverse[band];
      anomalyCondition.bound = bound;
    }
  }

  return { promql: newPromql, anomalyCondition };
};
