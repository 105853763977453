import { AutocompleteV2, AutocompleteOption } from 'components';
import React, { ReactElement, useEffect } from 'react';

import {
  AlertsCreateConditionInfo,
  AlertsCreateConditionOf,
  AlertsCreateConditionQueryKey,
  AlertsCreateConditionThreshold,
  AlertsCreateConditionWhen,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import AlertsCreateForecastConditionLinear from './AlertsCreateForecastConditionLinear';
import AlertsCreateForecastConditionSeasonal from './AlertsCreateForecastConditionSeasonal';
import { forecastAlertPopupMessage } from '../constants';
import { useAlertsCreate } from '../hooks';
import { forecastAlgorithmType, forecastConditionByLabel } from './utils';

const AlertsCreateForecastCondition = ({
  alertsCreateState,
  conditionState,
  queryAndFormulaKeysLabel,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  queryAndFormulaKeysLabel: AutocompleteOption[];
}): ReactElement => {
  const { forecastCondition, setForecastCondition } = conditionState;
  const { alertType, setEvaluate } = alertsCreateState;

  const handleForecastDurationChange = (val: string) => {
    setForecastCondition((prev) => ({ ...prev, forecastDuration: val }));
  };

  useEffect(() => {
    setEvaluate((prev) => ({
      ...prev,
      for: forecastCondition.forecastDuration,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastCondition.forecastDuration]);

  return (
    <div>
      <div className="alerts__create__section__item">
        <div className="alerts__create__conditions__item__text">
          Trigger when
        </div>
        <AlertsCreateConditionWhen conditionState={conditionState} />
        <div className="alerts__create__conditions__item__text">
          of forecasted values of
        </div>
        <AlertsCreateConditionQueryKey
          conditionState={conditionState}
          queryAndFormulaKeysLabel={queryAndFormulaKeysLabel}
        />
        <div className="alerts__create__conditions__item__text">is</div>
        <AlertsCreateConditionOf
          conditionState={conditionState}
          options={forecastConditionByLabel}
        />
        <div className="alerts__create__conditions__item__text">than</div>
        <AlertsCreateConditionThreshold
          alertType={alertType}
          conditionState={conditionState}
        />
      </div>
      <div className="alerts__create__section__header">
        Forecast algorithm options
      </div>
      <div className="alerts__create__section__item">
        <div className="alerts__create__conditions__item__text">
          Using Algorithm
        </div>
        <div className="alert__create__anomaly__detaction_desciption">
          {forecastCondition.forecastAlgorithm !== '' &&
          forecastCondition.forecastAlgorithm == 'linear' ? (
            <AlertsCreateConditionInfo
              description={
                forecastAlertPopupMessage.LINEAR_PREDICTION_ALGORITHM_DESCRIPTION
              }
            />
          ) : forecastCondition.forecastAlgorithm !== '' &&
            forecastCondition.forecastAlgorithm == 'seasonal' ? (
            <AlertsCreateConditionInfo
              description={
                forecastAlertPopupMessage.SEASONAL_PREDICTION_ALGORITHM_DESCRIPTION
              }
            />
          ) : null}
        </div>
        <div className="alerts__create__conditions__algorithm">
          <AutocompleteV2
            onChange={(val: string) =>
              setForecastCondition({
                ...forecastCondition,
                forecastAlgorithm: val,
                forecastDuration: val === 'linear' ? '4h' : '12h',
              })
            }
            options={forecastAlgorithmType}
            value={forecastCondition.forecastAlgorithm}
            placeholder={'Select'}
          />
        </div>
        {forecastCondition.forecastAlgorithm === 'linear' ? (
          <AlertsCreateForecastConditionLinear
            conditionState={conditionState}
            handleForecastDurationChange={handleForecastDurationChange}
          />
        ) : forecastCondition.forecastAlgorithm === 'seasonal' ? (
          <AlertsCreateForecastConditionSeasonal
            conditionState={conditionState}
            handleForecastDurationChange={handleForecastDurationChange}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AlertsCreateForecastCondition;
