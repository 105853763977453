import classnames from 'classnames';
import { useUrlSearchParams } from 'hooks';
import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

const getCustomActive = ({ link, pathname, subItems }) => {
  if (link.route === pathname) {
    return true;
  }

  if (link.route === '/logs' || link.route === '/apm/services') {
    if (pathname === '/alerts') {
      return false;
    }
  }

  return subItems.reduce((acc: boolean, item: { route: string }) => {
    return item?.route === pathname || acc;
  }, false);
};

const HeaderCustomNavLink = ({ link }: any) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const urlSearchParams = useUrlSearchParams();

  const subItems = link?.subItemsFunc
    ? link.subItemsFunc(navigate, urlSearchParams)
    : [];

  return (
    <NavLink
      className={({ isActive }) =>
        classnames({
          header__nav__item__link: true,
          'header__nav__item__link--active':
            isActive || getCustomActive({ link, pathname, subItems }),
        })
      }
      to={link.route}
    >
      {link.label}
    </NavLink>
  );
};

export default HeaderCustomNavLink;
