import dayjs from 'dayjs';
import {
  DateSelection,
  FacetName,
  FacetNameList,
  FilterProps,
  LogsState,
} from 'types';
import { onPromiseError } from 'utils';
import query from './query';
import { buildQuery, formatFacetNames } from './utils';

type Args = FilterProps & {
  date: DateSelection;
  cursor?: string;
  limit?: string;
  logsState: LogsState;
  prefix?: string;
  source?: string;
};

const getFacetNamesByCursor = async ({
  date,
  cursor,
  limit,
  logsState,
  prefix,
  source,
}: Args): Promise<{
  cursor?: string;
  facetNames: FacetName[];
}> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const logQuery = buildQuery(logsState);

  return query<FacetNameList, 'getFacetNames'>(`
    query GetFacetNames {
      getFacetNames(
        ${cursor ? `cursor: "${cursor}",` : ''}
        durationSecs: ${durationSecs}
        limit: ${limit || '100000'},
        logQuery: ${logQuery},
        ${prefix ? `prefix: "${prefix.replace(/"/g, '\\"')}",` : ''}
        ${source ? `source: "${source}",` : ''}
        timestamp: "${endTime.format()}",
      ) {
        cursor,
        facetNames {
          name,
          source,
          type,
          fpHash
        }
      }
    }
  `).then((data) => {
    const facetNames = data.getFacetNames?.facetNames || [];
    return {
      cursor: data.getFacetNames?.cursor,
      facetNames: formatFacetNames(facetNames),
    };
  }, onPromiseError);
};

export default getFacetNamesByCursor;
