import { SearchState } from 'hooks/useSearch';
import { SpanFilter } from 'types/SpanFilter';
import { ArithmeticParser, transformJSONToGraphql } from 'utils';
import buildTracesFilter from './buildTracesFilter';
import { getLegacyFiltersFromFiltersState } from 'hooks/useFiltersState';

interface QueryBitmap {
  [key: string]: {
    aggregation: string;
    aggregationField: string;
    filter: string;
  };
}

interface FormulaNode {
  left?: FormulaNode;
  name?: string;
  op?: string;
  right?: FormulaNode;
  type: string;
  value?: any;
}

const unQuoteOperation = [
  'Addition',
  'Subtraction',
  'Multiplication',
  'Division',
];

const buildAggregateTimeSeriesFormulaV2 = ({
  expression,
  queries,
  spanFilter,
}: {
  expression: string;
  queries: Array<SearchState>;
  spanFilter: SpanFilter;
}): string | Error => {
  const queryBitmap: QueryBitmap = {};

  queries.forEach((query) => {
    const filterPerQuery = buildFilterPerQuery(query, spanFilter);
    const aggregationInfo = determineAggregationInfo(query);

    queryBitmap[query.queryKey] = {
      ...aggregationInfo,
      filter: filterPerQuery,
    };
  });

  try {
    const parser = new ArithmeticParser();
    const formula = parser.parse(expression);
    const result = buildNestedFormula(formula, queryBitmap);
    const graphql = transformJSONToGraphql(result, unQuoteOperation, [
      'filter',
    ]);
    return graphql;
  } catch (e) {
    return new Error(e.message);
  }
};

function buildFilterPerQuery(query: SearchState, spanFilter: SpanFilter) {
  const { searchBarState } = query;
  if (!searchBarState) return null;
  const { filters, traceIdSearch } = searchBarState;

  const {
    keyExists,
    facetRegex,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
  } = getLegacyFiltersFromFiltersState(filters);

  return buildTracesFilter({
    facetRegex,
    keyExists,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
    spanFilter,
    traceIdSearch,
  });
}

function determineAggregationInfo(query: SearchState) {
  const { measure, operation } = query;
  const shouldUseCount = operation === 'distinctcount' && !measure;
  return {
    aggregation: shouldUseCount ? 'count' : operation,
    aggregationField: measure ? measure : '*',
  };
}

const buildNestedFormula = (
  formula: FormulaNode,
  queryBitmap: QueryBitmap,
): Record<string, any> => {
  if (formula.type === 'BinaryExpr' || formula.type === 'AddSubExpr') {
    return {
      binaryExpr: {
        op: formula.op,
        leftExpr: buildNestedFormula(formula.left, queryBitmap),
        rightExpr: buildNestedFormula(formula.right, queryBitmap),
      },
    };
  } else if (formula.type === 'Variable') {
    if (!queryBitmap[formula.name]) {
      throw new Error('Invalid formula');
    }
    return { aggregationExpr: queryBitmap[formula.name] };
  } else if (formula.type === 'NumberLiteral') {
    return { scalarExpr: formula.value };
  }
};

export default buildAggregateTimeSeriesFormulaV2;
