import classnames from 'classnames';
import { Input, useToaster } from 'components';
import { useRequest } from 'hooks';
import React, { useState } from 'react';
import { createWorkbook } from 'requests';

type Props = {
  close: () => void;
  getWorkbooksRequest: () => void;
  logsState: any;
  customColumnsState: any;
};

const LogsSheetToolbarSaveView = ({
  close,
  getWorkbooksRequest,
  logsState,
  customColumnsState,
}: Props) => {
  const toastmaster = useToaster();
  const createWorkbookRequest = useRequest(createWorkbook);
  const [name, setName] = useState('');

  const submit = () => {
    const onSuccess = () => {
      toastmaster.addToast({
        status: 'success',
        text: `${name} successfully saved`,
      });

      close();

      getWorkbooksRequest.call();
    };

    createWorkbookRequest
      .call({
        name,
        logsState: JSON.stringify({
          customColumns: customColumnsState?.state || {},
          filters: logsState.filtersState.state,
        }),
      })
      .then(onSuccess, () => {});
  };

  return (
    <div className="logs__sheet__toolbar__save-view">
      <Input onChange={setName} type="text" value={name} />
      <button
        className={classnames({
          button: true,
          'button--disabled': !name.trim(),
          'button--loading': createWorkbookRequest.isLoading,
          'button--primary': true,
          'logs__sheet__toolbar__save-view__button': true,
        })}
        onClick={submit}
      >
        Save
      </button>
    </div>
  );
};

export default LogsSheetToolbarSaveView;
