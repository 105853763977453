import { FacetGroup, FacetPicker } from 'components';
import { useRequest } from 'hooks';
import { flatten, partition } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { DateSelection, SelectedFacetValuesByName, ValueCount } from 'types';
import { getIsRangeFacet } from 'utils';
import helpText from '../../helpText';
import useRumState from './hooks/useRumState';
import rumLabelValues from './requests/rumLabelValues';
import groupRumLabels from './utils/groupRumLabels';
import { RumEventType } from './types';

type Props = {
  getLabelValues?: (args: {
    date: DateSelection;
    eventType: RumEventType;
    idSearch: string;
    labelName: string;
    selectedFacetValuesByName: SelectedFacetValuesByName;
  }) => Promise<ValueCount[]>;
  rumLabelNamesRequest: ReturnType<typeof useRequest>;
  rumState: ReturnType<typeof useRumState>;
};

const attributesByName: {
  [key: string]: {
    forceExpanded: boolean;
    renderName: () => string;
  };
} = {
  env: {
    forceExpanded: true,
    renderName: () => 'Environment',
  },
  source: {
    forceExpanded: true,
    renderName: () => 'Source',
  },
};

const RumSidebar = ({
  getLabelValues = rumLabelValues,
  rumLabelNamesRequest,
  rumState,
}: Props) => {
  const { dateState, eventType, idSearch, selectedFacetValuesByNameState } =
    rumState;

  const [date] = dateState;

  const clearFacetHandler = (name: string) => () => {
    selectedFacetValuesByNameState.clearFacet(name);
  };

  const handlersByName = (name: string) => ({
    changeFacetRange: () => {},
    excludeFacetValue: (value: string) => {
      selectedFacetValuesByNameState.excludeFacetValue({ name, value });
    },
    selectOnlyFacetValue: (value: string) => {
      selectedFacetValuesByNameState.selectOnlyFacetValue({ name, value });
    },
    toggleFacetValue: (value: string, allValues: Array<string>) => {
      selectedFacetValuesByNameState.toggleFacetPickerValueCheckbox({
        facetName: name,
        facetValueToToggle: value,
        allFacetValues: allValues,
      });
    },
  });

  const [lastRefreshedAt, setLastRefreshedAt] = useState(null);

  const requestByLabelName = (labelName: string) => () =>
    getLabelValues({
      date,
      eventType,
      labelName,
      idSearch,
      selectedFacetValuesByName: selectedFacetValuesByNameState.state,
    });

  useEffect(() => {
    setLastRefreshedAt(new Date().valueOf());
  }, [date, idSearch, selectedFacetValuesByNameState.state]);

  const { facetNamesByGroup, ungrouped } = useMemo(() => {
    if (rumLabelNamesRequest.result) {
      return groupRumLabels(rumLabelNamesRequest.result as string[]);
    }

    return {
      facetNamesByGroup: {},
      ungrouped: [],
    };
  }, [rumLabelNamesRequest.result]);

  const renderName = (s: string) => {
    return s;
  };

  const renderHelp = (s) => {
    if (helpText.hasOwnProperty(s)) {
      return helpText[s];
    }

    return null;
  };

  const sortedUngroupedNames = flatten(
    partition(
      ungrouped.sort((a, b) => renderName(a).localeCompare(renderName(b))),
      (group) => attributesByName[group]?.forceExpanded,
    ),
  );

  const renderPlaceholderText = (name: string) => `No attributes for ${name}`;
  const renderGroup = (group, idx, arr) => (
    <FacetGroup
      group={group}
      key={group}
      isLastListItem={idx === arr.length - 1}
    >
      {facetNamesByGroup[group].map((name) => (
        <FacetPicker
          clearFacet={clearFacetHandler(name)}
          isRangeFacet={getIsRangeFacet(name)}
          key={name}
          lastRefreshedAt={lastRefreshedAt}
          name={name}
          info={renderHelp(name)}
          renderName={renderName}
          renderPlaceholderText={renderPlaceholderText}
          request={requestByLabelName(name)}
          selectedFacetValues={selectedFacetValuesByNameState.state[name] || {}}
          selectedFacetRange={null}
          {...handlersByName(name)}
        />
      ))}
    </FacetGroup>
  );

  return (
    <>
      {sortedUngroupedNames.map((name: string) => {
        const attributes = attributesByName[name];
        const isRangeFacet = getIsRangeFacet(name);
        return (
          <FacetPicker
            clearFacet={clearFacetHandler(name)}
            isRangeFacet={isRangeFacet}
            key={name}
            lastRefreshedAt={lastRefreshedAt}
            name={name}
            info={helpText[name]}
            renderName={renderName}
            renderPlaceholderText={renderPlaceholderText}
            request={requestByLabelName(name)}
            selectedFacetValues={
              selectedFacetValuesByNameState.state[name] || {}
            }
            {...handlersByName(name)}
            {...(attributes ? attributes : {})}
          />
        );
      })}
      <div className="left-sidebar__divider" />
      {['geo', 'core', 'error']
        .filter(
          (group) =>
            facetNamesByGroup[group] && facetNamesByGroup[group].length,
        )
        .map(renderGroup)}
      <div className="left-sidebar__divider" />
      {[...Object.keys(facetNamesByGroup).sort()]
        .filter(
          (group) =>
            group !== 'geo' &&
            group !== 'core' &&
            group !== 'error' &&
            facetNamesByGroup[group] &&
            facetNamesByGroup[group].length,
        )
        .map(renderGroup)}
    </>
  );
};

export default RumSidebar;
