import { RightSidebar } from 'components';
import { useToggle } from 'hooks';
import React, { ReactElement, useRef } from 'react';
import {
  DashboardPanelType,
  DashboardStreamType,
  WidgetItemProps,
} from 'types/Dashboard';

import { DashboardEdit } from './DashboardEdit';
import { useDashboardGridlineContext } from './DashboardGridline';
import { useDashboardState, useDashboardTemplateState } from './hooks';
import { getLargestYAxisValue, getPanelWidgetSize, widgetList } from './utils';
import classNames from 'classnames';

const DashboardSidebarPanelItem = ({
  item,
  hideRightSidebar,
  onAddPanelClick,
  onDragEnd,
  onDragStart,
}: {
  hideRightSidebar: ReturnType<typeof useToggle>;
  item: WidgetItemProps;
  onAddPanelClick: (panelType: DashboardPanelType) => void;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  const innerWidth = window.innerWidth;
  return (
    <div
      ref={ref}
      className="dashboard__right-sidebar__widget__item"
      key={item.name}
      draggable={true}
      unselectable="on"
      onClick={() => onAddPanelClick(item.name)}
      onDrag={(e) => {
        if (e.clientX === 0) return;
        if (!ref.current) return;
        const rect = ref.current.getBoundingClientRect();

        if (e.clientX < rect.x && !hideRightSidebar.value) {
          hideRightSidebar.on();
        }

        if (e.clientX > innerWidth - 150 && hideRightSidebar.value) {
          hideRightSidebar.off();
        }
      }}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
    >
      {item.icon && item.icon}
      <div>{item.label}</div>
    </div>
  );
};

const DashboardSidebar = ({
  dashboardState,
  dashboardTemplateState,
}: {
  dashboardState: ReturnType<typeof useDashboardState>;
  dashboardTemplateState: ReturnType<typeof useDashboardTemplateState>;
}): ReactElement => {
  const hideRightSidebar = useToggle(false);
  const dashboardGridlineState = useDashboardGridlineContext();
  const {
    date,
    addPlaceholder,
    isRightSidebarOpenToggle,
    panels,
    panelSetupModal,
    removePlaceholder,
    setDragItemSize,
    updateEditedPanel,
    userActionRef,
  } = dashboardState;

  const onAddPanelClick = (panelType: DashboardPanelType) => {
    const newY = getLargestYAxisValue(panels);
    const itemLayout = {
      i: '',
      x: 0,
      y: newY,
      ...getPanelWidgetSize(panelType),
    };
    panelSetupModal.push(
      <DashboardEdit
        close={() => {
          panelSetupModal.pop();
          addPlaceholder();
          isRightSidebarOpenToggle.off();
        }}
        date={date}
        panelIndex={null}
        itemLayout={itemLayout}
        panelType={panelType}
        panel={null}
        streamType={DashboardStreamType.METRIC}
        templateValues={dashboardTemplateState.templateValues}
        updateEditedPanel={updateEditedPanel}
      />,
    );
    dashboardGridlineState.setIsDragging(false);
  };

  return (
    <div>
      {isRightSidebarOpenToggle.value && (
        <RightSidebar
          className={classNames({
            'dashboard__right-sidebar': true,
            'dashboard__right-sidebar--hide': hideRightSidebar.value,
          })}
          close={isRightSidebarOpenToggle.off}
          closeOnOutsideClick={() => {}}
          title="Add Panels"
          dataTestId="dashboard-right-sidebar"
        >
          <div className="dashboard__right-sidebar__box">
            {widgetList.map((widget) => {
              return (
                <div
                  key={widget.name}
                  className="dashboard__right-sidebar__widget"
                >
                  <div className="dashboard__right-sidebar__widget__title">
                    {widget.label}
                  </div>
                  <div>
                    {widget.list.map((item) => (
                      <DashboardSidebarPanelItem
                        key={item.name}
                        hideRightSidebar={hideRightSidebar}
                        item={item}
                        onAddPanelClick={onAddPanelClick}
                        onDragEnd={() => {
                          addPlaceholder();
                          dashboardGridlineState.setIsDragging(false);
                          if (hideRightSidebar.value) {
                            hideRightSidebar.off();
                          }
                        }}
                        onDragStart={(e) => {
                          e.dataTransfer.setData('widgetType', item.name);
                          const panelSize = getPanelWidgetSize(item.name);
                          setDragItemSize(panelSize);
                          removePlaceholder();
                          dashboardGridlineState.setIsDragging(true);
                          userActionRef.current.isDragging = true;
                        }}
                      />
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </RightSidebar>
      )}
    </div>
  );
};

export default DashboardSidebar;
