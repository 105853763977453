import { LogsMetricsQueryBuilder, Stepper } from 'components';
import {
  Filter,
  parseFiltersFromLogsState,
  useLogsMetricsQueryState,
} from 'hooks';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Plus } from 'react-feather';
import {
  DashboardPanelProps,
  DashboardPanelType,
  DashboardStreamType,
  DashboardTemplateValueProps,
  LogsMetricQueryProps,
} from 'types';
import {
  combineInstantQueryData,
  combineRangeQueryData,
  getRollupToSecond,
  getRollupByVisualization,
} from 'utils';

import {
  DashboardPanelModalFooter,
  DashboardPanelModalHeader,
} from '../components';
import {
  DashboardEditGraphHostmap,
  DashboardEditGraphSunburst,
  DashboardEditGraphTable,
  DashboardEditGraphTimeseries,
  DashboardEditGraphToplist,
} from './DashboardEditGraph';
import DashboardEditPanelName from './DashboardEditPanelName';
import DashboardEditMetricsVisualType from './DashboardEditMetricsVisualType';
import DashboardEditStreamType from './DashboardEditStreamType';
import { useDashboardEditState } from './hooks';
import { getPanelStreamType } from '../utils';
import {
  defaultTransformations,
  getQueryBuilderTransformedLogQl,
} from './utils';

const newArray = [];
const newObj = {};

const DashboardEditLogs = ({
  baseWidth,
  close,
  dashboardEditState,
  panel,
  panelType,
  templateValues,
}: {
  baseWidth: number;
  close: () => void;
  dashboardEditState: ReturnType<typeof useDashboardEditState>;
  panel: DashboardPanelProps;
  panelType?: DashboardPanelType;
  templateValues?: DashboardTemplateValueProps;
}): ReactElement => {
  const { date, editPanel, onSaveClickLogs, setDate, stream } =
    dashboardEditState;
  const [onChangeLoad, setOnChangeLoad] = useState(false);
  const isFirstRender = useRef({ date: true, type: true });

  const transformedAnnotations = getQueryBuilderTransformedLogQl(panel);
  const initialState = (transformedAnnotations.queries || []).map((query) => ({
    ...query,
    filters: parseFiltersFromLogsState(query),
  }));

  const queriesState = useState<LogsMetricQueryProps[]>(initialState);
  const logsMetricsQueryState = useLogsMetricsQueryState({
    chartWidth: baseWidth,
    date,
    dataFormat: panelType,
    isRange: DashboardPanelType.TIMESERIES === panelType,
    onChangeLoad: onChangeLoad,
    queriesState,
    urlCleanUp: true,
  });

  const {
    loadInstantMultipleLogsChartData,
    loadMultipleLogsChartData,
    queries,
    formulas,
    logsChartData,
    setFormulas,
  } = logsMetricsQueryState;

  const setQueryBuilderLogQls = () => {
    if (!panel.type || !panel.targets) return;
    if (getPanelStreamType(panel) !== DashboardStreamType.LOG) return;

    if (!transformedAnnotations) return;
    const { queries, formulas } = transformedAnnotations;
    if (formulas && formulas.length > 0) setFormulas(formulas);
  };

  const { formulasStatus, queriesStatus } = useMemo(() => {
    const queriesStatus = queries.map((query) => {
      return { isActive: query.isActive, queryKey: query.queryKey };
    });

    const formulasStatus = formulas.map((formula) => {
      return { isActive: formula.isActive, queryKey: formula.queryKey };
    });

    return { formulasStatus, queriesStatus };
  }, [queries, formulas]);

  const handleGenerateChart = useCallback(
    (date) => {
      const minStep = '1s';
      const step = getRollupByVisualization(date, 'bar');
      const minStepSeconds = getRollupToSecond(minStep);
      const stepSeconds = Math.max(minStepSeconds, step / 1000);
      const tranformedQueries = queries.map((query) => {
        return {
          ...query,
          step: `${stepSeconds}s`,
        };
      });
      if (editPanel.type === DashboardPanelType.TIMESERIES) {
        loadMultipleLogsChartData({
          formulas,
          queries: tranformedQueries,
          chartWidth: baseWidth,
        });
        return;
      }
      loadInstantMultipleLogsChartData({
        formulas,
        queries: tranformedQueries,
        chartWidth: baseWidth,
      });
    },
    [
      baseWidth,
      editPanel.type,
      formulas,
      loadInstantMultipleLogsChartData,
      loadMultipleLogsChartData,
      queries,
    ],
  );

  useEffect(() => {
    if (!editPanel.type) return;
    if (isFirstRender.current && isFirstRender.current.type && !panel) {
      isFirstRender.current.type = false;
      return;
    }
    if (editPanel.type === DashboardPanelType.TIMESERIES) {
      loadMultipleLogsChartData({
        formulas,
        queries,
        chartWidth: baseWidth,
      });
      return;
    }
    loadInstantMultipleLogsChartData({
      formulas,
      queries,
      chartWidth: baseWidth,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPanel?.type]);

  useEffect(() => {
    if (!editPanel.type) return;
    if (isFirstRender.current && isFirstRender.current.date) {
      isFirstRender.current.date = false;
      return;
    }
    handleGenerateChart(date);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    setTimeout(() => {
      setOnChangeLoad(true);
    }, 200);

    if (!panel) return;
    setQueryBuilderLogQls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dataFormatterRange = useCallback(() => {
    if (editPanel.type !== DashboardPanelType.TIMESERIES) return null;
    return combineRangeQueryData({
      queryData: logsChartData,
      queries: queriesStatus,
      formulas: formulasStatus,
    });
  }, [editPanel.type, formulasStatus, logsChartData, queriesStatus]);

  const dataFormatterInstant = useCallback(() => {
    if (editPanel.type === DashboardPanelType.TIMESERIES) return null;
    return combineInstantQueryData({
      dataFormat: editPanel.type,
      queries: queriesStatus,
      queryData: logsChartData,
      formulas: formulasStatus,
      transformations: defaultTransformations({
        dataFormat: editPanel.type,
        stream: stream,
      }),
    });
  }, [editPanel.type, formulasStatus, logsChartData, queriesStatus, stream]);

  return (
    <>
      <DashboardPanelModalHeader
        close={close}
        modalDate={date}
        setModalDate={setDate}
        title={!panel ? 'Add Panel' : editPanel.title || 'Edit Panel'}
      />
      <div className="dashboard-edit__metric__body">
        {editPanel.type === DashboardPanelType.TIMESERIES && (
          <DashboardEditGraphTimeseries
            baseWidth={baseWidth}
            onDateChange={setDate}
            panel={editPanel}
            dataFormatter={dataFormatterRange}
          />
        )}
        {editPanel.type === DashboardPanelType.TABLE && (
          <DashboardEditGraphTable dataFormatter={dataFormatterInstant} />
        )}
        {editPanel.type === DashboardPanelType.TOP_LIST && (
          <DashboardEditGraphToplist
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
          />
        )}
        {editPanel.type === DashboardPanelType.GRAFANA_POLYSTAT_PANEL && (
          <DashboardEditGraphHostmap
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
            panel={editPanel}
            stream={DashboardStreamType.LOG}
          />
        )}
        {editPanel.type === DashboardPanelType.PIECHART && (
          <DashboardEditGraphSunburst
            baseWidth={baseWidth}
            dataFormatter={dataFormatterInstant}
            panel={editPanel}
          />
        )}
        <Stepper
          steps={[
            {
              title: 'Choose Visualization',
              component: (
                <DashboardEditMetricsVisualType
                  dashboardEditState={dashboardEditState}
                />
              ),
            },
            {
              title: 'Graph your data',
              component: (
                <div className="dashboard__widget__metrics-query-builder">
                  <DashboardEditStreamType
                    setStream={dashboardEditState.setStream}
                    stream={dashboardEditState.stream}
                  />
                  <LogsMetricsQueryBuilder
                    allowMultipleQueries={true}
                    logsMetricsQueryState={logsMetricsQueryState}
                    logsState={{
                      date,
                      filterByFacets: newArray,
                      searchTerms: newArray,
                      selectedFacetValues: newObj,
                    }}
                    minStep="1s"
                  />
                  <button
                    className="button button--blue"
                    onClick={() => logsMetricsQueryState.addQuery()}
                  >
                    <Plus size={16} /> Add Query
                  </button>
                  <button
                    className="button button--blue"
                    onClick={logsMetricsQueryState.addFormula}
                  >
                    <Plus size={16} /> Add Formula
                  </button>
                </div>
              ),
            },
            {
              title: 'Enter chart title',
              component: (
                <DashboardEditPanelName
                  dashboardEditState={dashboardEditState}
                />
              ),
            },
          ]}
        />
      </div>
      <DashboardPanelModalFooter
        close={close}
        onSave={() => onSaveClickLogs({ formulas, queries })}
      />
    </>
  );
};

export default DashboardEditLogs;
