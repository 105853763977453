import {
  ChartToolbar,
  Loader,
  TimeseriesRenderer,
  TooltipTrigger,
} from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { promqlQueryRange } from 'requests';
import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';
import { DateSelection, LegendTypes } from 'types';
import { getMetricsExplorerUrlByPromql } from 'utils';

const defaultChartTypes = ['Line'];

const AlertsDetailsAnalysisChart = ({
  date,
  promql,
  title,
}: {
  date?: DateSelection;
  promql: string;
  title?: string;
}): ReactElement => {
  const [chartWidth, setChartWidth] = useState(1000);
  const promqlQueryRangeRequest = useRequest(promqlQueryRange);

  const loadChartData = () => {
    promqlQueryRangeRequest.call({
      date: date,
      promqlQueries: [promql],
      metricNames: [''],
      type: 'timeseries',
    });
  };

  useEffect(() => {
    loadChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promql, date]);

  return (
    <>
      <Loader
        className={classNames({
          alerts__chart__container: true,
          'alerts__chart__container--placeholder':
            promqlQueryRangeRequest.isLoading,
        })}
        isLoading={promqlQueryRangeRequest.isLoading}
      >
        <ResizeObserver onResize={(size) => setChartWidth(size.width)}>
          <TimeseriesRenderer
            chartTypes={defaultChartTypes}
            date={date || null}
            chartData={
              promqlQueryRangeRequest.result || { data: [], series: [] }
            }
            isLoading={false}
            legend={{
              legendType: LegendTypes.COMPACT_ONE_LINE,
              legendHeight: 120,
            }}
            renderToolbar={({ activeChart, setActiveChart }) => (
              <ChartToolbar
                activeChart={activeChart}
                chartTypes={['Line']}
                setActiveChart={setActiveChart}
                toolbar={{
                  leftToolbar: (
                    <TooltipTrigger tooltip={promql}>
                      <div className="new-metrics__chart__left-toolbar__title">
                        {title}
                      </div>
                    </TooltipTrigger>
                  ),
                  rightToolbar: (
                    <div className="new-metrics__chart__right-toolbar">
                      <div
                        className="new-metrics__chart__right-toolbar__icon"
                        onClick={() => {
                          window.open(
                            getMetricsExplorerUrlByPromql(promql),
                            '_blank',
                          );
                        }}
                      >
                        <TooltipTrigger tooltip="Open in metrics explorer">
                          <BiLinkExternal onClick={null} />
                        </TooltipTrigger>
                      </div>
                    </div>
                  ),
                }}
              />
            )}
            tooltipType="compact"
            unit="number"
            size={{ height: 260, width: chartWidth }}
          />
        </ResizeObserver>
      </Loader>
    </>
  );
};

export default AlertsDetailsAnalysisChart;
