import { yupResolver } from '@hookform/resolvers/yup';
import { useRequest, useSearch } from 'hooks';
import {
  Button,
  InputWithValidatorV2,
  Loader,
  useModalsContext,
  useToaster,
} from 'components';
import React, { ReactElement } from 'react';
import { saveTraceMetric } from 'requests';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { RequestResult } from 'types';
import {
  KeyExists,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
} from 'types';
import { saveTraceAnalyticsValidator } from './utils';

type Props = {
  keyExists: KeyExists;
  getSavedTraceMetricsRequest: ReturnType<typeof useRequest>;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  selectedHcFacetValuesByName: SelectedFacetValuesByName;
  spanFilter: SpanFilter;
  search: ReturnType<typeof useSearch>;
  traceIdSearch: string;
};
type SaveMetricFormProps = {
  name: string;
};

const TracesSaveMetricsModal = ({
  keyExists,
  getSavedTraceMetricsRequest,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  selectedHcFacetValuesByName,
  spanFilter,
  search,
  traceIdSearch,
}: Props): ReactElement => {
  const modals = useModalsContext();
  const saveTraceMetricRequest = useRequest(saveTraceMetric);
  const { addToast } = useToaster();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SaveMetricFormProps>({
    resolver: yupResolver(saveTraceAnalyticsValidator()),
    defaultValues: { name: '' },
  });

  const closeModal = () => {
    modals.pop();
  };

  const saveMetrics = (payload: any, request: RequestResult) => {
    request
      .call(payload)
      .then((saveMetricResponse: any) => {
        if (saveMetricResponse) {
          getSavedTraceMetricsRequest.call();
          addToast({ status: 'success', text: 'Metric saved successfully.' });
          closeModal();
        }
      })
      .catch(() => {
        addToast({
          status: 'error',
          text: 'Failed to create metric',
        });
      });
  };

  const onSaveTraceAnalyticsMetrics = (data: SaveMetricFormProps) => {
    const payload = {
      keyExists,
      name: data.name,
      search,
      selectedFacetRangeByName,
      selectedFacetValuesByName,
      selectedHcFacetValuesByName,
      spanFilter,
      traceIdSearch,
    };

    saveMetrics(payload, saveTraceMetricRequest);
  };

  return (
    <div className="trace__analytics__save-metrics">
      <div className="trace__analytics__save-metrics__header">
        <div className="trace__analytics__save-metrics__header__title">
          Save metric
        </div>
        <div
          className="trace__analytics__save-metrics__header__close"
          onClick={closeModal}
        >
          <X />
        </div>
      </div>
      <Loader isLoading={getSavedTraceMetricsRequest.isLoading}>
        <form onSubmit={handleSubmit(onSaveTraceAnalyticsMetrics)}>
          <div className="trace__analytics__save-metrics__name">
            <label>Give metric a name</label>
            <InputWithValidatorV2
              {...register('name')}
              name="name"
              placeholder="Give metric a name"
              title="Give metric a name"
              type="text"
              errorText={errors.name?.message}
            />
          </div>
          <div className="trace__analytics__save-metrics--save-button">
            <Button variant="default" size="sm" type="submit">
              Save Metric
            </Button>
          </div>
        </form>
      </Loader>
    </div>
  );
};

export default TracesSaveMetricsModal;
