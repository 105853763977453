import { getBasicAnomaliesPromQL } from 'utils';

import { AnomalyConditionProps } from '../../AlertsCreateCondition';

export const getBasicAnomalyAlertPromql = (
  anomalyCondition: AnomalyConditionProps,
  promql: string,
): string => {
  const { bound, band, window } = anomalyCondition;
  const [upperBandPromql, lowerBandPromql] = getBasicAnomaliesPromQL({
    bound,
    query: promql,
    window,
  });

  if (band === 'upper') {
    return `(${promql} - ${upperBandPromql}) > bool 0`;
  } else if (band === 'lower') {
    return `(${promql} - ${lowerBandPromql}) < bool 0`;
  } else {
    return `(${promql} - ${upperBandPromql}) > bool 0 or (${promql} - ${lowerBandPromql}) < bool 0`;
  }
};
