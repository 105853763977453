import { SunburstGraph, Loader, useThemeContext } from 'components';
import React, { ReactElement, useMemo } from 'react';
import { DashboardPanelComponentProps, DashboardPanelType } from 'types';
import { queryDataFormattingByGraphType } from 'utils';

import { useDashboardDataLoader } from '../hooks';
import { getPanelWidthHeight } from '../utils';

const DashboardPanelPieChart = ({
  baseWidth,
  dashboardState,
  dashboardTemplateState,
  isInView,
  nestedIndex,
  panel,
  panelIndex,
}: DashboardPanelComponentProps): ReactElement => {
  const { templateValues } = dashboardTemplateState;
  const { darkModeEnabled } = useThemeContext();

  const dashboardDataLoader = useDashboardDataLoader({
    baseWidth,
    dashboardState,
    isInView,
    nestedIndex,
    panelIndex,
    templateValues,
    type: DashboardPanelType.PIECHART,
  });

  const pieChartQueryData = useMemo(
    () =>
      queryDataFormattingByGraphType({
        darkModeEnabled,
        graphType: DashboardPanelType.PIECHART,
        queries: [{ queryKey: 'a', isActive: true }],
        queryData: { query_a: dashboardDataLoader.result },
        formulas: [],
      }),
    [darkModeEnabled, dashboardDataLoader.result],
  );
  const panelSize = getPanelWidthHeight(panel.gridPos, baseWidth, panel.title);

  return (
    <Loader isLoading={dashboardDataLoader.isLoading}>
      <div
        className="mt-3 mb-3"
        style={{ maxHeight: panelSize.heightContainer, overflow: 'auto' }}
      >
        {pieChartQueryData?.data ? (
          <SunburstGraph
            data={pieChartQueryData.data || {}}
            height={panelSize.heightContainer - 24}
            width={panelSize.width - 2}
            unit={panel.fieldConfig?.defaults?.unit || 'number'}
          />
        ) : null}
      </div>
    </Loader>
  );
};

export default DashboardPanelPieChart;
