import delimiter from 'kfuse-constants/delimiter';
import { FilterProps } from 'types';
import { escapePeriod } from 'utils/escapePeriod';

import { buildFacetQuery } from './buildFacetQuery';
import buildFingerprintsLogQuery from './buildFingerprintsLogQuery';
import buildSelectedFacetRange from './buildSelectedFacetRange';
import buildSelectedFacetsQuery, {
  buildFacetsQueryWithOr,
} from './buildSelectedFacetsQuery';
import buildSearchTermsFilter from './buildSearchTermsFilter';
import formatFacetName from './formatFacetName';

type Args = FilterProps & {
  source?: string;
};

const buildQuery = ({
  facetName,
  filterByFacets = [],
  filterOrExcludeByFingerprint = {},
  keyExists = {},
  logLevel,
  searchTerms = [],
  selectedFacetRanges = {},
  selectedFacetValues = {},
  selectedFacetValuesWithOr = {},
  source = null,
}: Args): string => {
  let result = '{';
  result += 'and: [';
  result += buildFingerprintsLogQuery(filterOrExcludeByFingerprint);

  result += buildSearchTermsFilter(searchTerms);

  if (logLevel) {
    result += `{ eq: { facetName: "level", value: "${logLevel}" } }`;
  }

  if (facetName) {
    result += `{ startsWith: { facetName: "${facetName}", value: "" } }`;
  }

  if (source) {
    result += `{ eq: { facetName: "source", value: "${source}" } }`;
  }

  Object.keys(keyExists).forEach((facetKeyWithType) => {
    const [component, name, type] = facetKeyWithType.split(delimiter);

    const nameParts = name.split(' OR ');

    const clauses = nameParts.map((name) =>
      component
        ? `{ keyExists: "${formatFacetName(component, name, type)}" }`
        : `{ keyExists: "@:${type}.${escapePeriod(name)}" }`,
    );

    const clause =
      clauses.length > 1 ? `{ or: [${clauses.join('')}]}` : clauses[0];

    const neq = !keyExists[facetKeyWithType];
    if (neq) {
      result += `{ not: ${clause} }`;
    } else {
      result += clause;
    }
  });

  result += buildSelectedFacetRange(selectedFacetRanges);

  result += buildSelectedFacetsQuery(selectedFacetValues);
  if (
    selectedFacetValuesWithOr &&
    Object.keys(selectedFacetValuesWithOr).length > 0
  ) {
    result += buildFacetsQueryWithOr(selectedFacetValuesWithOr);
  }

  result += buildFacetQuery(filterByFacets);
  result += ']';

  result += '}';
  return result;
};

export default buildQuery;
