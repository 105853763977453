import { TimeseriesRenderer, SizeObserver } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useMemo } from 'react';
import { eventStackedCounts } from 'requests';
import {
  convertArrayToObject,
  getRollupByVisualization,
  setDateRangeOnChartZoom,
} from 'utils';
import useDebouncedEffect from 'use-debounced-effect';

import { EventPageProps } from '../types';
import { UplotExtended } from 'types/Timeseries';

const defaultChartTypes = ['Stacked Bar'];

const EventsExplorerChart = ({ eventsState }: EventPageProps): ReactElement => {
  const eventStackedCountsRequest = useRequest(eventStackedCounts);
  const { date, filterState, setDate } = eventsState;

  const { filter } = filterState;
  useDebouncedEffect(
    () => {
      const rollUp = getRollupByVisualization(date, 'bar');
      const { filterByFacets, searchTerms } = filter;

      eventStackedCountsRequest.call({
        date,
        filterByFacets: convertArrayToObject(filterByFacets),
        groupBys: ['alert_type'],
        rollUp: `${rollUp}s`,
        searchTerms,
        selectedFacetValuesByName: filter['sidebarFilters'],
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      timeout: 100,
      ignoreInitialCall: false,
    },
    [date, filter],
  );

  const hooks = useMemo(() => {
    return [
      {
        hook: (u: UplotExtended) => setDateRangeOnChartZoom(u, setDate),
        type: 'setSelect',
      },
    ];
  }, [setDate]);

  return (
    <SizeObserver>
      {({ width }) => (
        <TimeseriesRenderer
          chartTypes={defaultChartTypes}
          date={eventsState.date}
          hooks={hooks}
          chartData={
            eventStackedCountsRequest.result || { data: [], series: [] }
          }
          isLoading={eventStackedCountsRequest.isLoading}
          tooltipType="compact"
          size={{ width: width - 16, height: 140 }}
          unit="number"
          chartKey="events-chart"
        />
      )}
    </SizeObserver>
  );
};

export default EventsExplorerChart;
