import { BasicLogsSheet, SelectV2 } from 'components';
import { delimiter } from 'kfuse-constants';
import React, { useMemo } from 'react';
import { Span } from 'types';
import { logFacetsBitmap } from './constants';
import { LogsAttribute } from './types';

type Props = {
  logsAttribute: LogsAttribute;
  setLogsAttribute: (logsAttribute: LogsAttribute) => void;
  span: Span;
};

const TraceSidebarActiveSpanLogs = ({
  logsAttribute,
  setLogsAttribute,
  span,
}: Props) => {
  const date = useMemo(() => {
    const { endTimeNs, startTimeNs } = span;
    const startTimeUnix = Math.floor(startTimeNs / 1000000000) - 60;
    const endTimeUnix = Math.ceil(endTimeNs / 1000000000) + 60;
    return { startTimeUnix, endTimeUnix };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logsState = useMemo(() => {
    const { key, value } = logsAttribute;
    return {
      date,
      ...(logFacetsBitmap[key]
        ? {
            selectedFacetValues: {
              [`Kubernetes${delimiter}${key}${delimiter}string${delimiter}${value}`]: 1,
            },
          }
        : { searchTerms: [`${value}`] }),
    };
  }, [date, logsAttribute]);

  const options = useMemo(() => {
    const defaultOptions = Object.keys(logFacetsBitmap)
      .sort()
      .map((key) => ({
        label: key,
        value: { key, value: span.attributes[key] },
      }));

    const options = [
      { label: logsAttribute.key, value: logsAttribute },
      ...defaultOptions.filter(
        (defaultOption) =>
          defaultOption.value.key !== logsAttribute.key &&
          defaultOption.value.value,
      ),
    ];

    const doesOptionsHaveTraceId = options.some(
      (option) => option.label === 'traceId',
    );

    if (!doesOptionsHaveTraceId) {
      options.push({
        label: 'traceId',
        value: { key: 'traceId', value: span?.traceId },
      });
    }

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logsAttribute]);

  return (
    <div className="trace-sidebar__active-span__logs">
      <BasicLogsSheet
        disableToolbar={false}
        logsState={logsState}
        renderToolbarLeft={() => (
          <div className="button-group button-group--short logs__table__toolbar__item">
            <div className="button-group__item button-group__item--unpadded">
              <SelectV2.Select
                className="select--small"
                options={options}
                onChange={setLogsAttribute}
                value={logsAttribute}
              />
            </div>
            <div className="button-group__item bg--02 text--nowrap">
              {logsAttribute.value}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default TraceSidebarActiveSpanLogs;
