import { getRollupToSecond } from 'utils';
import { RuleProps } from '../types';

/**
 * Get the frequency for alert to run
 * Window will be convert to second and
 * When it is less than 24 hours then return 1m
 * When it is between 24 hours and 48 hours then return 10m
 * When it is greater than 48 hours then return 30m
 * @param forWindow
 * @returns string
 */
const getAlertFrequency = (forWindow: string): string => {
  const from = getRollupToSecond(forWindow);
  if (from < 86400) {
    return '1m';
  }
  if (from <= 172800) {
    return '10m';
  }
  return '30m';
};

/**
 * Naming convention for alert group
 * folderName_group_frequency
 * @param folderName
 * @param forWindow
 */
export const getAlertGroupName = (
  folderName: string,
  forWindow: string,
): string => {
  const frequency = getAlertFrequency(forWindow);
  return `${folderName}_group_${frequency}`;
};

/**
 * Check group name follows the naming convention
 */
export const isAlertGroupNameManaged = (
  folderName: string,
  groupName: string,
): boolean => {
  return !groupName.startsWith(`${folderName}_group_`);
};

/**
 * Get the group name and frequency
 */
export const getGroupNameAndFrequency = ({
  folderName,
  forWindow,
  prevGroupName,
  rulesByGroup,
}: {
  folderName: string;
  forWindow: string;
  prevGroupName?: string;
  rulesByGroup: {
    [key: string]: Array<{ interval: string; name: string; rules: Array<any> }>;
  };
}): { name: string; interval: string; rules: Array<any> } => {
  if (prevGroupName && isAlertGroupNameManaged(folderName, prevGroupName)) {
    const managedGroup = rulesByGroup[folderName]?.find(
      (group) => group.name === prevGroupName,
    );
    if (managedGroup) {
      return {
        name: managedGroup.name,
        interval: managedGroup.interval,
        rules: managedGroup.rules,
      };
    }
  }

  const interval = getAlertFrequency(forWindow);
  const groupNameN = `${folderName}_group_${interval}`;
  const hostname = window.location.hostname;
  const MAX_LENGTH = hostname.includes('pisco.kloudfuse.io') ? 10 : 50;

  // Get all groups that start with the group name
  const releventGroups = rulesByGroup[folderName]
    ?.filter((group) => group.name.startsWith(groupNameN))
    .sort((a, b) => a.name.localeCompare(b.name));

  // If there are no relevent groups, return the new group name with 1
  if (!releventGroups || !releventGroups.length) {
    return { name: `${groupNameN}_1`, interval, rules: [] };
  }

  // Look through all relevent groups and find the first one that has less than Max length rules
  const lastEmptyGroup = releventGroups.find(
    (group) => group.rules.length < MAX_LENGTH,
  );
  // If there is an empty group, return it
  if (lastEmptyGroup) {
    return { name: lastEmptyGroup.name, interval, rules: lastEmptyGroup.rules };
  }

  // If there are no empty groups, return the last group with the next number
  const lastGroup = releventGroups[releventGroups.length - 1];
  const lastGroupNumber = lastGroup.name.split('_').pop();
  const nextGroupNumber = parseInt(lastGroupNumber) + 1;
  return { name: `${groupNameN}_${nextGroupNumber}`, interval, rules: [] };
};

export const getTransferRuleFolderGroupUid = ({
  folderName,
  groupName,
  rule,
}: {
  folderName: string;
  groupName: string;
  rule: RuleProps;
}): { folder: string; group: string; uid: string } => {
  if (!rule) return;
  const { groupFile: folder, group, uid } = rule;

  if (folderName !== folder) {
    return { folder, group, uid };
  }

  if (groupName !== group) {
    return { folder, group, uid };
  }
};
