import classnames from 'classnames';
import dayjs from 'dayjs';
import { Loader, TooltipTrigger } from 'components';
import {
  FilterType,
  Operator,
  useRequest,
  useSearch,
  useTracesState,
  useUrlState,
} from 'hooks';
import { timeFormats } from 'kfuse-constants';
import React, { useEffect } from 'react';
import { ExternalLink } from 'react-feather';
import { aggregateTraceErrors, traceErrorGroups } from 'requests';
import { ChartGridItem, Service, TraceErrorGroup } from 'types';
import {
  calcAutoRollUpInSeconds,
  formatNumber,
  NANO_TO_MILLI_SECONDS,
} from 'utils';
import ApmErrorsGroupTableChart from './ApmErrorsGroupTableChart';
import ApmErrorsGroupTableSidebar from './ApmErrorsGroupTableSidebar';
import { TableColumnType, Table } from '../Table';

const columns = ({
  chartGridItemByGroupingKey,
  isLoading,
  onOpenInErrorPage,
}: {
  chartGridItemByGroupingKey: {
    [key: string]: ChartGridItem;
  };
  isLoading: boolean;
  onOpenInErrorPage: (row: TraceErrorGroup) => void;
}) => [
  { key: 'errorGroupingKey', label: 'Group ID' },
  { key: 'errorType', label: 'Type' },
  {
    key: 'message',
    label: 'Error Message and Culprit',
    renderCell: ({ row }: { row: TraceErrorGroup }) => (
      <div>
        <div>{row.message}</div>
        <div className="text--monospace">{row.culprit}</div>
      </div>
    ),
  },
  {
    key: 'numOccurrences',
    label: 'Occurrences',
    renderCell: ({ value }: { value: number }) => formatNumber(value),
    type: TableColumnType.NUMBER,
  },
  {
    key: 'lastOccurrenceNs',
    label: 'Last Occurrences',
    renderCell: ({ value }: { value: number }) =>
      dayjs(value / NANO_TO_MILLI_SECONDS).format(timeFormats.dateAtTime),
  },
  {
    key: 'chart',
    label: 'Chart',
    renderCell: ({ row }: { row: TraceErrorGroup }) => (
      <ApmErrorsGroupTableChart
        chartGridItem={chartGridItemByGroupingKey[row.errorGroupingKey]}
        isLoading={isLoading}
      />
    ),
  },
  {
    key: 'Actions',
    label: 'Actions',
    renderCell: ({ row }: { row: TraceErrorGroup }) => {
      return (
        <div className="flex--justify-content-center flex">
          <button
            className="link"
            onClick={(e) => {
              onOpenInErrorPage(row);
              e.stopPropagation();
            }}
          >
            <TooltipTrigger tooltip="Open in error page">
              <ExternalLink size={16} />
            </TooltipTrigger>
          </button>
        </div>
      );
    },
  },
];

type Props = {
  className?: string;
  colorsByServiceHash: Record<string, string>;
  isServiceFromDatabasesList?: boolean;
  serviceHash?: string;
  serviceByHash: Record<string, Service>;
  serviceName?: string;
  tracesState: ReturnType<typeof useTracesState>;
};

const ApmErrorsGroupTable = ({
  className,
  colorsByServiceHash,
  isServiceFromDatabasesList,
  serviceHash,
  serviceByHash,
  serviceName,
  tracesState,
}: Props) => {
  const [traceErrorGroupId, setTraceErrorGroupId] = useUrlState(
    'traceErrorGroupId',
    null,
  );
  const aggregateTraceErrorsRequest = useRequest((args) =>
    aggregateTraceErrors(args).then((chartGridItem: ChartGridItem) => {
      return chartGridItem.keys.reduce((obj, rawKey) => {
        const key = rawKey.split('=')[1];
        return {
          ...obj,
          [key]: {
            keys: [rawKey],
            data: chartGridItem.data,
            timestamps: chartGridItem.timestamps,
          },
        };
      }, {});
    }),
  );
  const traceErrorGroupsRequest = useRequest(traceErrorGroups);
  const rows = traceErrorGroupsRequest.result || [];
  const search = useSearch();
  const { measure, operation, rollUpInSeconds } = search.state;

  useEffect(() => {
    aggregateTraceErrorsRequest.call({
      aggregation: operation,
      aggregationField: measure,
      isServiceFromDatabasesList,
      groupBys: ['error_grouping_key'],
      serviceHash,
      rollUpSeconds:
        rollUpInSeconds || calcAutoRollUpInSeconds(tracesState.dateState[0]),
      tracesState,
    });

    traceErrorGroupsRequest.call({
      isServiceFromDatabasesList,
      serviceHash,
      tracesState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracesState.state]);

  const close = () => {
    setTraceErrorGroupId(null);
  };

  const onRowClick = ({ row }) => {
    setTraceErrorGroupId(row.errorGroupingKey);
  };

  const onOpenInErrorPage = (row: TraceErrorGroup) => {
    const filters = [
      ...(serviceHash
        ? [
            {
              type: FilterType.selectedFacetValue,
              value: {
                facet: isServiceFromDatabasesList
                  ? 'kf_database_service_hash'
                  : 'service_hash',
                operator: Operator.equal,
                values: {
                  [serviceHash]: 1,
                },
              },
            },
          ]
        : []),
      {
        type: FilterType.selectedFacetValue,
        value: {
          facet: 'error_grouping_key',
          operator: Operator.equal,
          values: {
            [row.errorGroupingKey]: 1,
          },
        },
      },
    ];
    window.open(
      `#/apm/errors/all?apmErrorsFilters=${JSON.stringify(
        filters,
      )}&apmDate=${JSON.stringify(tracesState.dateState[0])}`,
    );
  };

  const chartGridItemByGroupingKey = aggregateTraceErrorsRequest.result || {};

  return (
    <>
      <div className={classnames({ [className]: className })}>
        <Loader isLoading={traceErrorGroupsRequest.isLoading}>
          {!traceErrorGroupsRequest.isLoading &&
          traceErrorGroupsRequest.result &&
          !traceErrorGroupsRequest.result.length ? (
            <div className="placeholder">{`No error groups${
              serviceName ? ` for ${serviceName}` : ''
            }`}</div>
          ) : (
            <Table
              className="table--padded table--bordered table--bordered-cells"
              columns={columns({
                chartGridItemByGroupingKey,
                isLoading: aggregateTraceErrorsRequest.isLoading,
                onOpenInErrorPage,
              })}
              onRowClick={onRowClick}
              rows={rows}
            />
          )}
        </Loader>
      </div>
      {traceErrorGroupId ? (
        <ApmErrorsGroupTableSidebar
          close={close}
          colorsByServiceHash={colorsByServiceHash}
          isServiceFromDatabasesList={isServiceFromDatabasesList}
          serviceByHash={serviceByHash}
          errorGroupingKey={traceErrorGroupId}
          serviceHash={serviceHash}
          tracesState={tracesState}
        />
      ) : null}
    </>
  );
};

export default ApmErrorsGroupTable;
