import dayjs from 'dayjs';
import { SearchState } from 'hooks/useSearch';
import {
  DateSelection,
  FacetRegexTerm,
  KeyExists,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
  TimeSeries,
} from 'types';
import {
  convertAggregateTimeSeriesFromFloatToSeconds,
  getRollupByVisualization,
  onPromiseError,
} from 'utils';
import queryTraceService from './queryTraceService';
import {
  buildAggregateTimeSeriesFormula,
  buildTracesFilter,
  transformTraceTimeseries,
} from './utils';

type Args = {
  date: DateSelection;
  facetRegex: FacetRegexTerm[];
  formula: {
    expression: string;
    isValid: string | boolean;
    isActive: boolean;
    queryKey: string;
  };
  instant?: boolean;
  keyExists: KeyExists;
  queries: Array<SearchState>;
  resultFormat: 'timeseries' | 'none';
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  selectedHcFacetValuesByName: SelectedFacetValuesByName;
  spanFilter: SpanFilter;
  traceIdSearch?: string;
};

const aggregateTimeSeriesFormula = async ({
  date,
  resultFormat = 'none',
  formula,
  instant = false,
  keyExists,
  queries,
  facetRegex = [],
  selectedFacetRangeByName = {},
  selectedFacetValuesByName = {},
  selectedHcFacetValuesByName = {},
  spanFilter,
  traceIdSearch,
}: Args): Promise<
  TimeSeries[] | ReturnType<typeof transformTraceTimeseries>
> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const { expression, isActive, isValid } = formula;
  if (!isActive || isValid !== true)
    return Promise.resolve({
      data: [],
      series: [],
      minValue: 0,
      maxValue: 0,
    });

  const { groupBys } = queries[0];
  const filter = buildTracesFilter({
    facetRegex,
    keyExists,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
    selectedHcFacetValuesByName,
    spanFilter,
    traceIdSearch,
  });
  const formulaExpression = buildAggregateTimeSeriesFormula(
    expression,
    filter,
    queries,
    spanFilter,
  );

  if (formulaExpression instanceof Error) {
    return Promise.reject({
      message: 'Invalid formula',
      error: formulaExpression,
    });
  }

  return queryTraceService<TimeSeries[], 'aggregateTimeSeries'>(`
    {
      aggregateTimeSeries(
        durationSecs: ${durationSecs}
        formulaExpression: ${formulaExpression}
        groupBy: [${groupBys.map((groupBy) => `"${groupBy}"`).join(',')}]
        rollUpSeconds: ${getRollupByVisualization(date, 'bar')}
        timestamp: "${endTime.format()}"
      ) {
        BucketStartSecs
        GroupVal
        Value
      }
    }
  `)
    .then((data) =>
      (data?.aggregateTimeSeries || []).map(
        convertAggregateTimeSeriesFromFloatToSeconds,
      ),
    )
    .then((formattedData) => {
      if (resultFormat === 'none') {
        return formattedData;
      }

      if (resultFormat === 'timeseries') {
        return transformTraceTimeseries({
          date,
          dataset: formattedData,
          query: { queryKey: `formula_${formula.queryKey}`, limitTo: 'top' },
          step: getRollupByVisualization(date, 'bar'),
        });
      }

      return formattedData;
    }, onPromiseError);
};

export default aggregateTimeSeriesFormula;
