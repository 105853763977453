import { FacetType } from 'types';

const rangeFacetTypeBitmap: { [key: string]: number } = {
  [FacetType.duration]: 1,
  [FacetType.number]: 1,
  [FacetType.size]: 1,
};

const getIsLogRangeFacet = (type: string): boolean => {
  if (typeof type === 'string') {
    return Boolean(rangeFacetTypeBitmap[type.toLowerCase()]);
  }

  return false;
};

export default getIsLogRangeFacet;
