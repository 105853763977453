import {
  ConfirmationModal,
  Stepper,
  useModalsContext,
  useToaster,
} from 'components';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { combineLogsChartData, getRollupByVisualization } from 'utils';

import { AlertsChart } from '../AlertsChart';
import {
  AlertsCreateCondition,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import AlertsCreateLogsQueryBuilder from './AlertsCreateLogsQueryBuilder';
import {
  AlertsConfigureNoData,
  AlertsCreateContacts,
  AlertsCreateDetails,
  CreateRuleButton,
} from '../components';
import { useAlertsCreate } from '../hooks';
import { useAlertsCreateLogs } from './hooks';
import { RuleProps } from '../types';
import {
  confirmSelectedLogsAlertQueryKey,
  parseAlertsLogsQueryAndConditions,
} from './utils';
import { getQueryAndFormulaKeysLabel } from '../utils';

const AlertsCreateLogs = ({
  baseWidth,
  alertsCreateState,
  conditionState,
}: {
  baseWidth: number;
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
}): ReactElement => {
  const modal = useModalsContext();
  const { addToast } = useToaster();
  const location = useLocation();
  const rule = location.state as RuleProps;
  const { date, setUpdateAlertsRuleState } = alertsCreateState;

  const alertsCreateLogsState = useAlertsCreateLogs(
    alertsCreateState,
    baseWidth,
  );
  const { createLogQLAlerts, logsMetricsQueryState } = alertsCreateLogsState;
  const { formulas, queries, logsChartData, loadMultipleLogsChartData } =
    logsMetricsQueryState;
  const { condition, setUpdateConditionState, setCondition } = conditionState;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (rule) {
      const { condition } = parseAlertsLogsQueryAndConditions(
        rule.ruleData,
        rule.annotations,
      );
      if (rule.noData && condition) {
        condition.noData = rule.noData;
      }

      if (rule.executionError && condition) {
        condition.executionError = rule.executionError;
      }
      setUpdateAlertsRuleState(rule);
      setUpdateConditionState(condition);
    }
  }, [rule]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (formulas.length > 0) {
      loadMultipleLogsChartData({ queries, formulas, chartWidth: baseWidth });
    }
  }, [formulas]);

  const combinedChartData = useMemo(
    () => combineLogsChartData(logsChartData, queries, formulas),
    [logsChartData, queries, formulas],
  );

  const queryAndFormulaKeysLabel = useMemo(
    () => getQueryAndFormulaKeysLabel(queries, formulas),
    [queries, formulas],
  );

  const unit = queries.reduce((acc, query) => {
    if (acc === null) {
      return query.normalizeFunction;
    }
    if (acc !== query.normalizeFunction) {
      return 'number';
    }
    return acc;
  }, null);

  const onConfirmLogsAlertCreate = () => {
    const { queryKey } = condition;
    const confirmSelected = confirmSelectedLogsAlertQueryKey(
      formulas.length,
      queries.length,
      queryKey,
    );
    if (confirmSelected) {
      modal.push(
        <ConfirmationModal
          description={confirmSelected}
          onCancel={() => modal.pop()}
          onConfirm={() => {
            createLogQLAlerts(condition);
            modal.pop();
          }}
          submitText="Confirm and Create"
          title="Confirm Query Selection"
        />,
      );
    } else {
      createLogQLAlerts(condition);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.hash.substring(10, url.hash.length));
    const hasLogsMetricsQueries = params.has('LogsMetricsQueries');
    const metricsFormulas = params.get('LogsMetricsFormulas');

    if (hasLogsMetricsQueries && !rule) {
      setTimeout(() => {
        addToast({
          text: 'Minimum rollup for logs alerts is 15s. Chart may look different from logs analytics.',
          status: 'info',
          timeout: 3500,
        });
      }, 1000);
    }

    try {
      const metricsFormulasJSON = JSON.parse(metricsFormulas || '[]');
      if (metricsFormulasJSON.length > 0) {
        setCondition((prev) => {
          const newCondition = { ...prev };
          newCondition.queryKey = `Formula (${metricsFormulasJSON[0].queryKey})`;
          return newCondition;
        });
      }
    } catch (e) {}
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const updateQueriesWithRollup = queries.map((query) => ({
      ...query,
      step: `${getRollupByVisualization(date)}s`,
    }));
    loadMultipleLogsChartData({
      queries: updateQueriesWithRollup,
      formulas,
      chartWidth: baseWidth,
    });
  }, [date]);

  return (
    <div className="alerts__create__logs">
      <div className="alerts__create__logs__chart">
        <AlertsChart
          conditionOperator={condition.of}
          conditionValue={condition.value ? Number(condition.value) : undefined}
          date={date}
          isLoading={combinedChartData.isLoading}
          queryData={combinedChartData}
          unit={unit === 'duration' ? 's' : unit}
        />
      </div>
      <Stepper
        steps={[
          {
            title: 'Pick log metric',
            component: (
              <AlertsCreateLogsQueryBuilder
                date={date}
                logsMetricsQueryState={logsMetricsQueryState}
              />
            ),
          },
          {
            title: 'Set Condition',
            component: (
              <>
                <AlertsCreateCondition
                  alertsCreateState={alertsCreateState}
                  conditionState={conditionState}
                  queryAndFormulaKeysLabel={queryAndFormulaKeysLabel}
                  unit={unit === 'duration' ? 's' : unit}
                />
                <AlertsConfigureNoData conditionState={conditionState} />
              </>
            ),
          },
          {
            title: 'Add Details',
            component: (
              <AlertsCreateDetails alertsCreateState={alertsCreateState} />
            ),
          },
          {
            title: 'Add Contacts',
            component: (
              <AlertsCreateContacts alertsCreateState={alertsCreateState} />
            ),
          },
        ]}
      />
      <CreateRuleButton
        isEditing={alertsCreateState.isEditing}
        onClick={onConfirmLogsAlertCreate}
      />
    </div>
  );
};

export default AlertsCreateLogs;
