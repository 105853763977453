import {
  DownloadPopover,
  Loader,
  Table,
  TableHeader,
  useColumnsState,
  useModalsContext,
  useTableOptions,
  useTableBESort,
} from 'components';
import {
  tracesTableColumns,
  TracesTableColumnKey,
  tracesTableLiveTailColumns,
  TracesTableLiveTailColumnKey,
  isSortingDisabledForTraceColumn,
} from 'kfuse-constants';
import { useLiveTail, useTracesState } from 'hooks';
import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DownloadType, SelectedFacetValuesByName, Trace } from 'types';
import { getUrlParamByKey, pickUrlSearchParamsByKeys } from 'utils';
import TracesDownloadModal from './TracesDownloadModal';
import TracesTableDashboardExport from './TracesTableDashboardExport';
import useTracesRequest from './useTracesRequest';

type Props = {
  additionalFilterByFacets?: SelectedFacetValuesByName;
  colorsByServiceHash?: { [key: string]: string };
  colorsByServiceName: { [key: string]: string };
  liveTail: ReturnType<typeof useLiveTail>;
  setActiveTrace: (trace: Trace) => void;
  tracesState: ReturnType<typeof useTracesState>;
};

const TracesTable = ({
  additionalFilterByFacets,
  colorsByServiceHash = {},
  colorsByServiceName,
  liveTail,
  setActiveTrace,
  tracesState,
}: Props) => {
  const modals = useModalsContext();
  const [URLSearchParams] = useSearchParams();
  const { service } = useParams();
  const traceId = getUrlParamByKey('traceId');

  const {
    dateState,
    facetRegexState,
    keyExistsState,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    selectedHcFacetValuesByNameState,
    spanFilters,
    traceIdSearch,
    tracesSortState,
  } = tracesState;

  const [date] = dateState;
  const { spanFilter } = spanFilters;
  const tracesRequest = useTracesRequest({
    additionalFilterByFacets,
    date,
    facetRegexState,
    keyExistsState,
    service,
    selectedFacetRangeByNameState,
    selectedFacetValuesByNameState,
    selectedHcFacetValuesByNameState,
    spanFilter,
    traceIdSearch,
    tracesSortState,
  });

  useEffect(() => {
    if (traceId && tracesRequest.result) {
      const trace = tracesRequest.result.find(
        (trace) => trace.traceId === traceId,
      );
      setActiveTrace(trace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traceId, tracesRequest.result]);

  const onRowClick = ({ row }: { row: Trace }) => {
    setActiveTrace(row);
  };

  const regularColumns = tracesTableColumns({
    colorsByServiceName,
  });
  const regularColumnsState = useColumnsState({
    columns: regularColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        [TracesTableColumnKey.spanStartTimeNs]: 1,
        [TracesTableColumnKey.spanAttributesServiceName]: 1,
        [TracesTableColumnKey.spanName]: 1,
        [TracesTableColumnKey.duration]: 1,
        [TracesTableColumnKey.spanMethod]: 1,
        [TracesTableColumnKey.spanAttributesStatusCode]: 1,
        [TracesTableColumnKey.spanEndpoint]: 1,
        [TracesTableColumnKey.spanCount]: 1,
        [TracesTableColumnKey.traceMetrics]: 1,
      },
    },
    key: 'traces-live-table',
  });

  const liveColumns = tracesTableLiveTailColumns({
    colorsByServiceName,
  });
  const liveColumnsState = useColumnsState({
    columns: liveColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        [TracesTableLiveTailColumnKey.startTimeNs]: 1,
        [TracesTableLiveTailColumnKey.serviceName]: 1,
        [TracesTableLiveTailColumnKey.name]: 1,
        [TracesTableLiveTailColumnKey.duration]: 1,
        [TracesTableLiveTailColumnKey.method]: 1,
        [TracesTableLiveTailColumnKey.attributesStatusCode]: 1,
        [TracesTableLiveTailColumnKey.endpoint]: 1,
      },
    },
    key: 'traces-live-table',
  });

  const columnsState = liveTail.isEnabled
    ? liveColumnsState
    : regularColumnsState;

  const columns = liveTail.isEnabled ? liveColumns : regularColumns;
  const rows = liveTail.isEnabled ? liveTail.items : tracesRequest.result || [];

  const tableOptions = useTableOptions();
  const tableSort = useTableBESort({
    columns,
    initialKey: TracesTableColumnKey.spanStartTimeNs,
    rows: rows,
    onSortChange: ({ sortBy, sortOrder }) => {
      tracesSortState?.sortBy({ sortBy, sortOrder });
    },
  });

  const openTracesDownloadModal = (downloadType) => {
    modals.push(
      <TracesDownloadModal
        columns={columns}
        date={date}
        downloadType={downloadType}
        selectedFacetRangeByNameState={selectedFacetRangeByNameState}
        selectedFacetValuesByNameState={selectedFacetValuesByNameState}
        selectedHcFacetValuesByNameState={selectedHcFacetValuesByNameState}
        service={service}
        spanFilter={spanFilter}
        traceIdSearch={traceIdSearch}
      />,
      true,
    );
  };

  return (
    <>
      <TableHeader columnsState={columnsState} tableOptions={tableOptions}>
        <div className="flex">
          <TracesTableDashboardExport tracesState={tracesState} />
          <div className="ml-3">
            <DownloadPopover
              downloadTypes={[DownloadType.csv, DownloadType.json]}
              openModal={openTracesDownloadModal}
            />
          </div>
        </div>
      </TableHeader>
      <Loader
        className="traces__table"
        isLoading={tracesRequest.isLoading}
        dataTestId="traces-table"
      >
        {liveTail.isEnabled ? (
          <Table
            className="table--padded table--bordered-cells font-robotoMono"
            columns={columnsState.renderedColumns}
            rows={liveTail.items}
          />
        ) : (
          <Table
            className="table--padded table--bordered-cells font-robotoMono"
            columns={regularColumnsState.renderedColumns}
            externalTableSort={tableSort}
            isResizingEnabled
            isSortingEnabled
            isSortingDisabledForColumn={isSortingDisabledForTraceColumn}
            onRowClick={onRowClick}
            onScrollEnd={tracesRequest.onScrollEnd}
            rows={tableSort.sortedRows}
            tableKey="traces-table"
          />
        )}
      </Loader>
    </>
  );
};

export default TracesTable;
