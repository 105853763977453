import {
  Button,
  ConfirmationModal,
  Loader,
  Paginator,
  Table,
  TableSearch,
  TooltipTrigger,
  useModalsContext,
  usePaginator,
  useTableSearch,
  useTableSort,
  useToaster,
} from 'components';
import { useRequest } from 'hooks';
import React, { useEffect, useState } from 'react';
import {
  MdDelete as DeleteIcon,
  MdModeEdit as EditIcon,
  MdSettings as SettingsIcon,
} from 'react-icons/md';
import { getGrafanaFolders } from 'requests';

import FolderManagementSettings from './FolderManagementSettings';
import { CreateNewFolderModal } from '../../NewAlerts/components';
import { deleteFolderById, getFoldersItemsCount } from './requests';
import { FolderProps } from './types';

const folderColumns = ({
  onDeleteFolder,
  onEditFolder,
  onEditFolderSettings,
}: {
  onDeleteFolder: (folder: FolderProps) => void;
  onEditFolder: (folder: FolderProps) => void;
  onEditFolderSettings: (folder: FolderProps) => void;
}) => [
  { label: 'Folder Name', key: 'title' },
  {
    label: '',
    key: 'actions',
    renderCell: ({ row }: { row: FolderProps }) => {
      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': '109px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider">
              <div className="alerts__contacts__table__actions">
                <div
                  className="table__cell__actions__item--blue"
                  onClick={() => onEditFolderSettings(row)}
                >
                  <TooltipTrigger tooltip="Permissions Settings">
                    <SettingsIcon
                      className="alerts__contacts__table__actions__icon--blue"
                      size={18}
                    />
                  </TooltipTrigger>
                </div>
                <div
                  className="table__cell__actions__item--blue"
                  onClick={() => onEditFolder(row)}
                >
                  <TooltipTrigger tooltip="Edit">
                    <EditIcon
                      className="alerts__contacts__table__actions__icon--blue"
                      size={18}
                    />
                  </TooltipTrigger>
                </div>
                <div
                  className="table__cell__actions__item--red"
                  onClick={() => onDeleteFolder(row)}
                >
                  <TooltipTrigger tooltip="Delete">
                    <DeleteIcon
                      className="alerts__contacts__table__actions__icon--delete"
                      size={18}
                    />
                  </TooltipTrigger>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

const getDeleteFolderErrorMessage = (folderItemsCount: {
  alertrule: number;
  dashboard: number;
  librarypanel: number;
}) => {
  const nonEmptyItems = [];
  if (folderItemsCount.alertrule > 0)
    nonEmptyItems.push(
      `${folderItemsCount.alertrule} alert${folderItemsCount.alertrule > 1 ? 's' : ''}`,
    );
  if (folderItemsCount.dashboard > 0)
    nonEmptyItems.push(
      `${folderItemsCount.dashboard} dashboard${folderItemsCount.dashboard > 1 ? 's' : ''}`,
    );
  if (folderItemsCount.librarypanel > 0)
    nonEmptyItems.push(
      `${folderItemsCount.librarypanel} library panel${folderItemsCount.librarypanel > 1 ? 's' : ''}`,
    );

  return nonEmptyItems.join(', ');
};

const emptyArr = [];
const FolderManagement = () => {
  const { addToast } = useToaster();
  const modal = useModalsContext();
  const [folderSettings, setFolderSettings] = useState<FolderProps | null>(
    null,
  );

  const grafanaFoldersRequest = useRequest(getGrafanaFolders);
  const getFoldersItemsCountRequest = useRequest(getFoldersItemsCount);
  const deleteFolderByIdRequest = useRequest(deleteFolderById);

  const onCreateNewFolder = () => {
    modal.push(
      <div className="alerts__create__details__new-folder-modal">
        <CreateNewFolderModal
          closeModal={modal.pop}
          updateFolder={() => {
            modal.pop();
            grafanaFoldersRequest.call();
            addToast({
              text: 'Folder created successfully',
              status: 'success',
            });
          }}
        />
      </div>,
    );
  };

  const onDeleteFolder = async (folder: FolderProps) => {
    const folderItemsCount = await getFoldersItemsCountRequest.call({
      uid: folder.uid,
    });

    const errorMessage = getDeleteFolderErrorMessage(folderItemsCount);
    if (errorMessage) {
      addToast({
        text: `Cannot delete folder. It contains ${errorMessage}. Folder must be empty to be deleted.`,
        status: 'error',
      });
      return;
    }

    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={
          <span>
            Are you sure you want to delete{' '}
            <span className="font-bold">{folder.title}</span> folder? This
            action cannot be undone.
          </span>
        }
        dataTestId="delete-group-confirmation-modal"
        onCancel={() => modal.pop()}
        onConfirm={() => {
          deleteFolderByIdRequest.call({ uid: folder.uid }).then(() => {
            grafanaFoldersRequest.call();
            modal.pop();
          });
        }}
        title="Delete Folder"
      />,
    );
  };

  const onEditFolder = (folder: FolderProps) => {
    modal.push(
      <div className="alerts__create__details__new-folder-modal">
        <CreateNewFolderModal
          closeModal={modal.pop}
          updateFolder={() => {
            modal.pop();
            grafanaFoldersRequest.call();
            addToast({
              text: 'Folder updated successfully',
              status: 'success',
            });
          }}
          editFolderName={folder.title}
          editFolderUid={folder.uid}
        />
      </div>,
    );
  };

  const onEditFolderSettings = (folder: FolderProps) => {
    setFolderSettings(folder);
  };

  useEffect(() => {
    grafanaFoldersRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = folderColumns({
    onDeleteFolder,
    onEditFolder,
    onEditFolderSettings,
  });
  const folders = grafanaFoldersRequest.result || emptyArr;
  const tableSearch = useTableSearch({ rows: folders });
  const tableSort = useTableSort({ columns, rows: tableSearch.searchedRows });
  const paginator = usePaginator({
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  return (
    <div className="mx-auto max-w-[1200px] py-4">
      <div className="flex items-center justify-between pb-2">
        <h2 className="text-lg font-semibold">Folder Management</h2>
        <Button variant="default" size="sm" onClick={onCreateNewFolder}>
          Add New Folder
        </Button>
      </div>
      <Loader
        className="min-h-[300px]"
        isLoading={
          grafanaFoldersRequest.isLoading ||
          getFoldersItemsCountRequest.isLoading
        }
      >
        <TableSearch
          className="dashboard__list__search"
          placeholder="Search Folders..."
          tableSearch={tableSearch}
          dataTestId="dashboard-list-search"
        />
        <Table
          className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
          columns={columns}
          dataTestId="alerts-list-table"
          externalTableSort={tableSort}
          isSortingEnabled
          rows={paginator.paginatedRows}
        />
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
      </Loader>
      {folderSettings && (
        <FolderManagementSettings
          folder={folderSettings}
          close={() => setFolderSettings(null)}
        />
      )}
    </div>
  );
};

export default FolderManagement;
