import dayjs from 'dayjs';
import { useDateState, useUrlState, useFilterState, useRequest } from 'hooks';
import { useEffect } from 'react';
import { convertTimeStringToUnix } from 'utils';

import { AutocompleteOption } from 'components/Autocomplete';
import serverlessLabelValues from 'screens/Serverless/requests/serverlesslabelValues';
import serverlessLabels from 'screens/Serverless/requests/serverlessLabels';
import getServerlessMetrics from 'requests/getServerlessMetrics';

const useServerlessState = ({
  shouldWriteToUrl,
}: {
  shouldWriteToUrl?: boolean;
}) => {
  const [filterByFacets, setFilterByFacets] = useUrlState('filterByFacets', {});

  const [date, setDate] = useDateState();
  const filterState = useFilterState({ shouldWriteToUrl });

  const { filter } = filterState;

  const getServerlessLabelValuesOptions = (
    option: AutocompleteOption,
  ): Promise<AutocompleteOption[]> => {
    return new Promise((resolve) => {
      serverlessLabels({
        date,
      })
        .then((labelValues) => {
          const options: AutocompleteOption[] = labelValues.map(
            ({ count, value }) => ({
              label: value,
              value: value,
              count,
              optionType: 'value',
            }),
          );
          resolve(options);
        })
        .catch(() => {
          resolve([]);
        });
    });
  };

  const getServerlessFacetValuesOptions = (
    option: AutocompleteOption,
  ): Promise<AutocompleteOption[]> => {
    return new Promise((resolve) => {
      serverlessLabelValues({
        name: option.value,
        date,
      })
        .then((facetValues) => {
          const options: AutocompleteOption[] = facetValues.map(
            ({ aggrVal, groupVal }) => {
              const value = groupVal[option.value];
              return {
                label: value,
                value: value,
                count: aggrVal.function_arn,
                optionType: 'value',
              };
            },
          );
          resolve(options);
        })
        .catch(() => {
          resolve([]);
        });
    });
  };

  const refreshDate = (): void => {
    if (date.startLabel && date.endLabel) {
      const endTimeUnix = convertTimeStringToUnix(date.endLabel);
      const startTimeUnix = convertTimeStringToUnix(date.startLabel);
      if (endTimeUnix && startTimeUnix) {
        setDate({ ...date, endTimeUnix, startTimeUnix });
      }
    } else {
      setDate({ ...date, endTimeUnix: dayjs().unix() });
    }
  };

  const requestByLabelName = (name: string) => {
    return () =>
      serverlessLabelValues({ name, date }).then((result) =>
        result.map(({ aggrVal, groupVal }) => {
          const value = groupVal[name];
          const returnVal = {
            count: aggrVal.function_arn,
            value: value || '',
          };
          return returnVal;
        }),
      );
  };

  const getFacetValueCountsRequest = useRequest(serverlessLabels);
  const getServerlessMetricsRequest = useRequest(getServerlessMetrics);

  useEffect(() => {
    if (date) {
      getFacetValueCountsRequest.call({ date });
      getServerlessMetricsRequest.call(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return {
    date,
    getServerlessLabelValuesOptions,
    getFacetValueCountsRequest,
    getServerlessMetricsRequest,
    getServerlessFacetValuesOptions,
    filterByFacets: filter.filterByFacets,
    refreshDate,
    requestByLabelName,
    searchTerms: filterByFacets.searchItems,
    setDate,
    setFilterByFacets,
    filterState,
  };
};

export default useServerlessState;
