import { useToggle } from 'hooks';
import { useRef, useState } from 'react';
import { promqlQueryRangeV3 } from 'requests';
import {
  PromqlWithMetaProps,
  QueryDataProps,
  QueryDataPropsRange,
} from 'types';
import {
  adjustForecastTimeseriesTimestamp,
  DataFrame,
  metricsDataTransformer,
  mergeTwoTimeSeries,
  getRollupByVisualization,
  getForecastLookbackAndPredictDate,
  forecastDataTransformer,
  shiftForecastBandTimeseriesTimestamps,
} from 'utils';

import {
  ConditionProps,
  ForecastConditionProps,
} from '../AlertsCreateCondition';
import {
  buildForecastLinearPromql,
  buildForecastSeasonalPromql,
} from '../AlertsCreateMetrics/utils';

const useAlertsForecastDataLoader = ({
  condition,
  forecastCondition,
}: {
  condition: ConditionProps;
  forecastCondition: ForecastConditionProps;
}) => {
  const userActions = useRef({ initLoaded: false });
  const histLegendToggle = useToggle(true);
  const evalLegendToggle = useToggle(true);

  const [historicalData, setHistoricalData] = useState<QueryDataProps>({});
  const [evaluationData, setEvaluationData] = useState<QueryDataProps>({});
  const [breakpoint, setBreakpoint] = useState(0);
  const { forecastAlgorithm } = forecastCondition;

  const handleLoading = (
    queryId: string,
    type: 'historical' | 'evaluation',
  ) => {
    if (type === 'historical') {
      setHistoricalData((prevState) => ({
        ...prevState,
        [queryId]: { range: null, isLoading: true, meta: undefined },
      }));
    } else {
      setEvaluationData((prevState) => ({
        ...prevState,
        [queryId]: { range: null, isLoading: true, meta: undefined },
      }));
    }
  };

  const loadForecastData = async ({
    forecastAlgorithm,
    dateForChart,
    promqlWithMeta,
  }: {
    forecastAlgorithm: ForecastConditionProps['forecastAlgorithm'];
    dateForChart: ReturnType<typeof getForecastLookbackAndPredictDate>;
    promqlWithMeta: PromqlWithMetaProps;
  }) => {
    const { promql } = promqlWithMeta;
    if (!promql || typeof promql !== 'string') {
      return;
    }

    const forecastPromql =
      forecastAlgorithm === 'linear'
        ? buildForecastLinearPromql(promql, forecastCondition)
        : buildForecastSeasonalPromql({
            condition,
            forecastCondition,
            promql,
            type: 'load',
          });
    if (!forecastPromql) return;

    loadEvaluationData({ promqlWithMeta, forecastPromql, dateForChart });
  };

  const loadEvaluationData = async ({
    promqlWithMeta,
    forecastPromql,
    dateForChart,
  }: {
    forecastPromql: string;
    promqlWithMeta: PromqlWithMetaProps;
    dateForChart?: ReturnType<typeof getForecastLookbackAndPredictDate>;
  }) => {
    const { promql, meta, queryType } = promqlWithMeta;
    const queryId = `${queryType}_${meta.refId}_forecast`;

    handleLoading(queryId, 'evaluation');
    const promqls = [promql, forecastPromql];
    const { lookbackDate, predictedDate } = dateForChart;
    const forecastStep =
      meta.customStep ||
      (forecastAlgorithm === 'seasonal' ? dateForChart.step : meta.step);

    const [lookbackRes, predictedRes] = await Promise.all(
      promqls.map((promql, idx) => {
        const date = idx === 0 ? lookbackDate : predictedDate;
        const baseTransformer = metricsDataTransformer();
        if (idx === 1) {
          baseTransformer.splice(2, 0, {
            id: 'adjustForecastTimeseriesTimestamp',
            func: (dataFrame: DataFrame) =>
              adjustForecastTimeseriesTimestamp({
                dataFrame,
                startTimeUnix: lookbackDate.endTimeUnix + forecastStep,
                step: forecastStep,
              }),
          });

          if (forecastAlgorithm === 'seasonal') {
            baseTransformer.pop();
            baseTransformer.push({
              id: 'forecastDataTransformer',
              func: forecastDataTransformer,
            });
          }
        }

        return promqlQueryRangeV3({
          allowFutureTime: true,
          date,
          promqlQuery: promql as string,
          meta: { ...meta, step: forecastStep },
          transformer: baseTransformer,
        });
      }),
    ).catch(() => {
      setEvaluationData({ [queryId]: { range: null, isLoading: false, meta } });
      return [undefined, undefined];
    });

    if (!lookbackRes || !predictedRes) return;
    setBreakpoint(lookbackRes?.data[0]?.length);

    if (forecastAlgorithm === 'linear') {
      const mergedData = mergeTwoTimeSeries({
        timeSeries1: lookbackRes as unknown as QueryDataProps['k']['range'],
        timeSeries2: predictedRes as unknown as QueryDataProps['k']['range'],
      });

      setEvaluationData({
        [queryId]: { range: mergedData, isLoading: false, meta },
      });
      return;
    }

    const [upper, lower, mean] =
      predictedRes as unknown as QueryDataPropsRange[];
    const { lowerShifted, upperShifted } =
      shiftForecastBandTimeseriesTimestamps({
        lower,
        upper,
        shiftByTimestamps: lookbackRes.data[0],
      });

    const mergedData = mergeTwoTimeSeries({
      timeSeries1: lookbackRes as unknown as QueryDataProps['k']['range'],
      timeSeries2: mean as unknown as QueryDataProps['k']['range'],
    });

    const queryKeys = ['_upper', '_lower'];
    const newQueryDataForecast: QueryDataProps = {};
    queryKeys.forEach((key, idx) => {
      newQueryDataForecast[`${queryId}${key}`] = {
        range: idx === 0 ? upperShifted : lowerShifted,
        isLoading: false,
        meta,
      };
    });
    newQueryDataForecast[`${queryId}`] = {
      range: mergedData,
      isLoading: false,
      meta,
    };

    setEvaluationData(newQueryDataForecast);
  };

  const loadHistoricalData = async ({
    dateForChart,
    promqlWithMeta,
  }: {
    dateForChart: ReturnType<typeof getForecastLookbackAndPredictDate>;
    promqlWithMeta: PromqlWithMetaProps;
  }): Promise<QueryDataProps['k']['range']> => {
    return new Promise((resolve) => {
      const baseTransformer = metricsDataTransformer();
      const { promql, meta, queryType } = promqlWithMeta;
      const queryId = `${queryType}_${meta.refId}`;

      handleLoading(queryId, 'historical');
      promqlQueryRangeV3({
        date: dateForChart.historyDate,
        promqlQuery: promql as string,
        meta: {
          ...meta,
          step: getRollupByVisualization(dateForChart.historyDate),
        },
        transformer: baseTransformer,
      })
        .then((historicalRes: unknown) => {
          if (!historicalRes) return;

          setHistoricalData({
            [queryId]: {
              range: historicalRes as QueryDataProps['k']['range'],
              isLoading: false,
              meta,
            },
          });
          resolve(historicalRes as QueryDataProps['k']['range']);
        })
        .catch(() => handleLoading(queryId, 'historical'));
    });
  };

  return {
    breakpoint,
    evalLegendToggle,
    evaluationData,
    histLegendToggle,
    historicalData,
    loadForecastData,
    loadHistoricalData,
    userActions,
  };
};

export default useAlertsForecastDataLoader;
