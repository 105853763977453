import {
  FiltersOnEnterArgs,
  Search,
  ShowSidebarTooltipButton,
  useLeftSidebarState,
} from 'components';
import {
  BitmapFilter,
  FacetRegexFilter,
  Filter,
  FilterType,
  Operator,
  SelectedRangeFilter,
  SelectedFacetValueFilter,
  getExistingFilterWithSameFacet,
  useRequest,
  useSearches,
  useSearchFormulas,
  useTracesState,
} from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { Service, TracesTab } from 'types';
import { getRange, parsePartialSearchQuery } from 'utils';
import { numericOperatorBitmap } from 'kfuse-constants';
import getTracesTags from './getTracesTags';
import TracesQuerySearch from './TracesQuerySearch';
import TracesSearchQueryButtons from './TracesSearchQueryButtons';
import TracesSearchGrouperFormula from './TracesSearchGrouperFormula';
import TracesSearchInputPanel from './TracesSearchInputPanel';
import TracesSearchVisualize from './TracesSearchVisualize';

type Props = {
  colorsByServiceHash: Record<string, string>;
  isErrorScreen?: boolean;
  isLiveTailEnabled?: boolean;
  leftSidebarState: ReturnType<typeof useLeftSidebarState>;
  placeholder: string;
  searches: ReturnType<typeof useSearches>;
  searchesFormulas?: ReturnType<typeof useSearchFormulas>;
  serviceByHash: Record<string, Service>;
  traceLabelNamesRequest: ReturnType<typeof useRequest>;
  tracesState: ReturnType<typeof useTracesState>;
  tracesTab: TracesTab;
};

const getLabelOptionLabel = (label: string) => {
  if (label === 'duration_ns') {
    return 'duration (ns)';
  }

  if (label === 'service_hash') {
    return 'service';
  }

  return label;
};

const getOptions = (traceLabelNamesRequest: ReturnType<typeof useRequest>) => {
  if (!traceLabelNamesRequest.result) {
    return { groupByOptions: [], measureOptions: [] };
  }

  const labelOptions = ['duration_ns', ...(traceLabelNamesRequest.result || [])]
    .sort()
    .map((label) => ({
      label: getLabelOptionLabel(label),
      value: label,
    }));

  const groupByOptions = [
    { label: 'Everything', value: '*' },
    ...labelOptions.filter((item) => item.value !== 'duration_ns'),
  ];
  const measureOptions = [{ label: 'All spans', value: null }, ...labelOptions];

  return {
    groupByOptions,
    measureOptions,
  };
};

const TracesSearch = ({
  colorsByServiceHash,
  isErrorScreen,
  leftSidebarState,
  placeholder,
  searches,
  searchesFormulas,
  serviceByHash,
  traceLabelNamesRequest,
  tracesState,
  tracesTab,
  isLiveTailEnabled,
}: Props) => {
  const labelNames: string[] = useMemo(
    () => (traceLabelNamesRequest.result || []).sort(),
    [traceLabelNamesRequest.result],
  );

  const {
    dateState,
    filtersState,
    keyExistsState,
    selectedFacetValuesByNameState,
    setTraceIdSearch,
    spanFilters,
    traceIdSearch,
  } = tracesState;

  const [date] = dateState;
  const { spanFilter } = spanFilters;

  const { groupByOptions, measureOptions } = useMemo(
    () => getOptions(traceLabelNamesRequest),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [traceLabelNamesRequest.result],
  );

  const tags = useMemo(
    () =>
      getTracesTags({
        colorsByServiceHash,
        filtersState,
        serviceByHash,
        setTraceIdSearch,
        traceIdSearch,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colorsByServiceHash, serviceByHash, tracesState.state],
  );

  const onEnter = ({ index, replace, typed }: FiltersOnEnterArgs) => {
    const parsedSearch = parsePartialSearchQuery(typed, false);
    if (parsedSearch) {
      const {
        facetName: originalFacetName,
        operator,
        operatorIndex,
        value,
      } = parsedSearch;

      const shouldReplace = replace && typeof index === 'number';
      const replaceFilter = (filter: Filter) => {
        filtersState.replaceByIndex(filter, index);
      };
      const applyFilter = shouldReplace ? replaceFilter : filtersState.add;

      const facetName =
        originalFacetName === 'service' ? 'service_hash' : originalFacetName;
      if (facetName && operator) {
        if (operator === Operator.equal || operator === Operator.notEqual) {
          const facetValues = value
            .split(' OR ')
            .map((v) => v.trim())
            .filter((v) => v);

          const values = facetValues.reduce(
            (obj, facetValue) => ({
              ...obj,
              [facetValue]: 1,
            }),
            {},
          );

          const {
            existingFilterWithSameFacetIndex,
            existingFilterValues,
            shouldReplaceExistingFilter,
          } = getExistingFilterWithSameFacet({
            facetName,
            filters: filtersState.state,
            operator,
            replace,
          });

          const filter: SelectedFacetValueFilter = {
            type: FilterType.selectedFacetValue,
            value: {
              facet: facetName,
              operator,
              values: {
                ...existingFilterValues,
                ...values,
              },
            },
          };

          if (shouldReplaceExistingFilter) {
            filtersState.replaceByIndex(
              filter,
              existingFilterWithSameFacetIndex,
            );
          } else {
            applyFilter(filter);
          }

          return;
        }

        if (numericOperatorBitmap[operator]) {
          const { facetName: rangeFacetName, ...range } = getRange({
            firstOperator: operator,
            firstOperatorIndex: operatorIndex,
            typed,
            useNs: false,
          });

          const filter: SelectedRangeFilter = {
            type: FilterType.selectedRange,
            value: {
              facet: rangeFacetName,
              ...range,
            },
          };

          applyFilter(filter);
          return;
        }

        if (operator === Operator.regex || operator === Operator.notRegex) {
          const filter: FacetRegexFilter = {
            type: FilterType.facetRegex,
            value: {
              isEqual: operator === Operator.regex,
              name: facetName,
              value,
            },
          };
          applyFilter(filter);
          return;
        }
      }
    }

    setTraceIdSearch(typed);
  };

  useEffect(() => {
    const activeFormulas = searchesFormulas?.formulas?.filter(
      (formula) => formula.isActive,
    );
    const activeQueries = searches
      ?.map((query) => query.state)
      ?.filter((query) => query.isActive);
    const countOfActiveQueriesOrFormulas =
      activeFormulas?.length + activeQueries?.length;

    if (countOfActiveQueriesOrFormulas < 1) {
      searches[0].selectOnlySingeQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searches, searchesFormulas]);

  return (
    <div className="traces-search">
      <div className="traces-search__top">
        {leftSidebarState.width === 0 ? (
          <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
        ) : null}
        {tracesTab === TracesTab.list && (
          <Search
            onEnter={onEnter}
            placeholder={placeholder}
            renderTypedPanel={({
              close,
              editIndex,
              onValueSelect,
              setTyped,
              typed,
            }) => {
              return (
                <TracesSearchInputPanel
                  close={close}
                  colorsByServiceHash={colorsByServiceHash}
                  date={date}
                  editIndex={editIndex}
                  keyExistsState={keyExistsState}
                  labelNames={labelNames}
                  onEnter={onEnter}
                  onValueSelect={onValueSelect}
                  serviceByHash={serviceByHash}
                  selectedFacetValuesByNameState={
                    selectedFacetValuesByNameState
                  }
                  setTyped={setTyped}
                  spanFilter={spanFilter}
                  tags={tags}
                  typed={typed}
                />
              );
            }}
            shouldUseReplace
            tags={tags}
          />
        )}
        {tracesTab !== TracesTab.list && (
          <div className="max-height-50-vh traces-search__query-builder overflow-auto">
            {searches.map((search, i) => (
              <TracesQuerySearch
                colorsByServiceHash={colorsByServiceHash}
                getRange={getRange}
                groupByOptions={groupByOptions}
                index={i}
                key={i}
                labelNames={labelNames}
                measureOptions={measureOptions}
                placeholder={placeholder}
                search={search}
                searches={searches}
                serviceByHash={serviceByHash}
                tracesTab={tracesTab}
                tracesState={tracesState}
                searchesFormulas={searchesFormulas}
              />
            ))}
            {searchesFormulas &&
              searchesFormulas.formulas.map((formula, i) => (
                <TracesSearchGrouperFormula
                  key={i}
                  date={date}
                  formulaState={formula}
                  searches={searches}
                  tracesTab={tracesTab}
                />
              ))}
            <TracesSearchQueryButtons
              searches={searches}
              searchesFormulas={searchesFormulas}
              tracesTab={tracesTab}
            />
          </div>
        )}
      </div>
      {!isErrorScreen && (
        <TracesSearchVisualize isLiveTailEnabled={isLiveTailEnabled} />
      )}
    </div>
  );
};

export default TracesSearch;
