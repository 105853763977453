import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getDateFromRange } from 'screens/Dashboard/utils';
import { DateSelection } from 'types/index';
import { extractPathNameFromURL, parseUrlParamByKey } from 'utils';

const useHydrationPageState = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const hydrationDateState = useState<DateSelection>(
    parseUrlParamByKey('hydrationDate') || getDateFromRange('now-1M', 'now'),
  );

  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(
    () => [hydrationDateState[0]],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hydrationDateState[0]],
  );

  const writeStateToUrl = () => {
    params.set('hydrationDate', JSON.stringify(hydrationDateState[0]));
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    dateState: hydrationDateState,
    dependenciesForWriteStateToUrl,
    writeStateToUrl,
  };
};

export default useHydrationPageState;
