import { chartingPalette, serviceTableKpis } from 'kfuse-constants';
import { ServiceTableKpiKeys } from 'kfuse-constants/serviceTableKpis';
import { useToggle } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { queryRange } from 'requests';
import {
  DateSelection,
  EventListProps,
  SelectedFacetValuesByName,
} from 'types';
import { getInstantRateIntervalAndStep } from 'utils';
import { formatDatasets } from './utils';

type GetQueriesArgs = {
  isDownStream: boolean;
  isLatencyBoundedToMinMax?: boolean;
  property: string;
  rateInterval: string;
  selectedColumns: Record<string, number>;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceHash: string;
  stepInMs: number;
};

type FetchQueriesArgs = {
  date: DateSelection;
  eventList?: EventListProps[];
  isDownStream: boolean;
  isLatencyBoundedToMinMax?: boolean;
  property: string;
  selectedColumns: Record<string, number>;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceHash: string;
};

const getQueries = ({
  isDownStream,
  isLatencyBoundedToMinMax,
  property,
  rateInterval,
  selectedColumns,
  serviceHash,
  selectedFacetValuesByName,
  stepInMs,
}: GetQueriesArgs) => {
  const selectedColumnsToUse = selectedColumns;

  if (isLatencyBoundedToMinMax) {
    selectedColumnsToUse[ServiceTableKpiKeys.maxLatency] = 1;
    selectedColumnsToUse[ServiceTableKpiKeys.minLatency] = 1;
  }

  return serviceTableKpis
    .filter((kpi) => selectedColumnsToUse[kpi.key])
    .map((kpi) => ({
      key: kpi.key,
      query: kpi.serviceQuery({
        isDownStream,
        property,
        rateInterval,
        selectedFacetValuesByName,
        serviceHash,
        stepInMs,
      }),
    }));
};

type Options = {
  isDownStream?: boolean;
};

const useKpisBySpanNameRequest = (options?: Options) => {
  const isDownStream =
    typeof options?.isDownStream === 'boolean' ? options.isDownStream : false;
  const [state, setState] = useState<{
    [key: string]: { [key: string]: number | string | undefined };
  }>({});

  const isLoadingToggle = useToggle();
  const isReadyToggle = useToggle();

  useEffect(() => {
    setState({});
  }, [isDownStream]);

  const fetchKpisBySpanName = ({
    date,
    eventList,
    isDownStream,
    isLatencyBoundedToMinMax,
    property,
    selectedColumns,
    serviceHash,
    selectedFacetValuesByName,
  }: FetchQueriesArgs) => {
    const { rateInterval, stepInMs } = getInstantRateIntervalAndStep({ date });
    const queries = getQueries({
      isDownStream,
      isLatencyBoundedToMinMax,
      property,
      rateInterval,
      selectedColumns,
      selectedFacetValuesByName,
      serviceHash,
      stepInMs,
    });
    isLoadingToggle.on();
    return Promise.all(
      queries.map(({ key, query }) =>
        queryRange({ date, instant: true, query }).then((res) => {
          return formatDatasets({
            eventList,
            key,
            property,
            setState,
          })(res);
        }),
      ),
    ).then(() => {
      isLoadingToggle.off();
      isReadyToggle.on();
    });
  };

  const colorMap = useMemo(
    () =>
      Object.keys(state).reduce(
        (obj, spanName, i) => ({
          ...obj,
          [spanName]: chartingPalette[i % chartingPalette.length],
        }),
        {},
      ),
    [state],
  );

  return {
    colorMap,
    fetch: ({
      date,
      eventList,
      isDownStream,
      isLatencyBoundedToMinMax,
      property,
      selectedFacetValuesByName,
      selectedColumns,
      serviceHash,
    }: FetchQueriesArgs) => {
      fetchKpisBySpanName({
        date,
        eventList,
        isDownStream,
        isLatencyBoundedToMinMax,
        property,
        selectedColumns,
        serviceHash,
        selectedFacetValuesByName,
      });
    },
    fetchSingleColumn: ({
      date,
      eventList,
      isDownStream,
      key,
      property,
      serviceHash,
      selectedFacetValuesByName,
    }: FetchQueriesArgs & { key: string }) => {
      const kpi = serviceTableKpis.find((kpi) => kpi.key === key);
      if (kpi) {
        const { rateInterval, stepInMs } = getInstantRateIntervalAndStep({
          date,
        });
        const query = kpi.serviceQuery({
          isDownStream,
          property,
          rateInterval,
          serviceHash,
          stepInMs,
          selectedFacetValuesByName,
        });
        queryRange({ date, instant: true, query }).then(
          formatDatasets({
            key,
            eventList: eventList,
            property: property,
            setState,
          }),
        );
      }
    },
    isLoading: isLoadingToggle.value,
    isReady: isReadyToggle.value,
    kpisBySpanName: state || {},
  };
};

export default useKpisBySpanNameRequest;
