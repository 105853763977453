import {
  useFacetRegexState,
  useKeyExistsState,
  useRequest,
  useSelectedFacetRangeByNameState,
  useSelectedFacetValuesByNameState,
  useSortState,
} from 'hooks';
import { mapClientKeyToServerKey } from 'kfuse-constants';
import { useEffect, useRef, useState } from 'react';
import { getTraces } from 'requests';
import {
  DateSelection,
  SelectedFacetValuesByName,
  SpanFilter,
  Trace,
} from 'types';
import { getTimeMs } from './utils';

const limit = 100;

type Args = {
  additionalFilterByFacets?: SelectedFacetValuesByName;
  date: DateSelection;
  service: string;
  facetRegexState: ReturnType<typeof useFacetRegexState>;
  keyExistsState: ReturnType<typeof useKeyExistsState>;
  selectedFacetRangeByNameState: ReturnType<
    typeof useSelectedFacetRangeByNameState
  >;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  selectedHcFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  spanFilter: SpanFilter;
  traceIdSearch: string;
  tracesSortState?: ReturnType<typeof useSortState>;
};

const useTracesRequest = ({
  additionalFilterByFacets,
  date,
  facetRegexState,
  keyExistsState,
  service,
  selectedFacetRangeByNameState,
  selectedFacetValuesByNameState,
  selectedHcFacetValuesByNameState,
  spanFilter,
  traceIdSearch,
  tracesSortState,
}: Args) => {
  const pageNumRef = useRef<number>(1);
  const request = useRequest(getTraces);
  const [result, setResult] = useState([]);

  const call = () => {
    const pageNum = pageNumRef.current || 1;
    const { endTimeMs, startTimeMs } = getTimeMs({ date });
    request
      .call({
        additionalFilterByFacets,
        endTimeMs,
        startTimeMs,
        facetRegex: facetRegexState.state,
        keyExists: keyExistsState.state,
        limit,
        service,
        sortBy: mapClientKeyToServerKey(tracesSortState?.state?.key),
        sortOrder: tracesSortState?.state?.isAsc ? 'Asc' : 'Desc',
        selectedFacetRangeByName: selectedFacetRangeByNameState.state,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
        selectedHcFacetValuesByName: selectedHcFacetValuesByNameState.state,
        spanFilter,
        traceIdSearch,
        pageNum,
      })
      .then((nextResult) => {
        if (nextResult && nextResult.length) {
          pageNumRef.current = pageNum + 1;
          setResult((prevResult) => [...prevResult, ...nextResult]);
        }
      });
  };

  const onScrollEnd = () => {
    call();
  };

  useEffect(
    () => {
      pageNumRef.current = null;
      setResult([]);
      call();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      additionalFilterByFacets,
      date,
      facetRegexState.state,
      keyExistsState.state,
      selectedFacetRangeByNameState.state,
      selectedFacetValuesByNameState.state,
      selectedHcFacetValuesByNameState.state,
      spanFilter,
      traceIdSearch,
      tracesSortState?.state,
    ],
  );

  return {
    call,
    isLoading: request.isLoading,
    onScrollEnd,
    result,
  };
};

export default useTracesRequest;
