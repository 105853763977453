import { delimiter } from 'kfuse-constants';
import { parseFilterByFacet } from 'requests/utils';
import { getFacetKey } from 'utils/facets';
import { Filter, FilterType, Operator } from './types';
// {
//   key: 'keyExists',
//   isNonEmpty: (keyExists) => Object.keys(keyExists).length,
// },
// {
//   key: 'filterByFacets',
//   isNonEmpty: (filterByFacets) => filterByFacets.length,
// },
// {
//   key: 'filterOrExcludeByFingerprint',
//   isNonEmpty: (filterOrExcludeByFingerprint) =>
//     Object.keys(filterOrExcludeByFingerprint).length,
// },
// { key: 'searchTerms', isNonEmpty: (searchTerms) => searchTerms.length },
// {
//   key: 'selectedFacetValues',
//   isNonEmpty: (selectedFacetValues) =>
//     Object.keys(selectedFacetValues).length,
// },
// {
//   key: 'selectedFacetRanges',
//   isNonEmpty: (selectedFacetRanges) =>
//     Object.keys(selectedFacetRanges).length,
// },

// For backwards compatability
const parseFiltersFromLogsState = (logsState): Filter[] => {
  if (!logsState) {
    return [];
  }

  const filters = logsState.filters;
  if (filters && Array.isArray(filters)) {
    return filters;
  }

  const keyExists = logsState.keyExists || {};
  const filterByFacets = logsState.filterByFacets || [];
  const filterOrExcludeByFingerprint =
    logsState.filterOrExcludeByFingerprint || {};
  const searchTerms = logsState.searchTerms || [];
  const selectedFacetValues = logsState.selectedFacetValues || {};
  const selectedFacetRanges = logsState.selectedFacetRanges || {};

  const result: Filter[] = [];

  Object.keys(keyExists).forEach((facet) => {
    const operator = keyExists[facet] ? Operator.equal : Operator.notEqual;
    result.push({
      type: FilterType.keyExists,
      value: {
        facet,
        operator,
      },
    });
  });

  Object.keys(filterOrExcludeByFingerprint).forEach((facet) => {
    const operator = filterOrExcludeByFingerprint[facet]
      ? Operator.equal
      : Operator.notEqual;
    result.push({
      type: FilterType.filterOrExcludeByFingerprint,
      value: {
        facet,
        operator,
      },
    });
  });

  searchTerms.forEach((value) => {
    result.push({
      type: FilterType.searchTerms,
      value,
    });
  });

  const valuesBitmapByOperatorByFacetKey: Record<
    string,
    Record<Operator, Record<string, 1>>
  > = {};

  Object.keys(selectedFacetValues).forEach((facetCompositeKey) => {
    const operator = selectedFacetValues[facetCompositeKey]
      ? Operator.equal
      : Operator.notEqual;
    const [component, name, type, value] = facetCompositeKey.split(delimiter);

    const facetKey = getFacetKey({
      component,
      name,
      type,
    });

    if (!valuesBitmapByOperatorByFacetKey[facetKey]) {
      valuesBitmapByOperatorByFacetKey[facetKey] = {} as Record<
        Operator,
        Record<string, 1>
      >;
    }

    if (!valuesBitmapByOperatorByFacetKey[facetKey][operator]) {
      valuesBitmapByOperatorByFacetKey[facetKey][operator] = {};
    }

    valuesBitmapByOperatorByFacetKey[facetKey][operator][value] = 1;
  });

  Object.keys(valuesBitmapByOperatorByFacetKey).forEach((facet) => {
    Object.keys(valuesBitmapByOperatorByFacetKey[facet]).forEach(
      (operator: Operator) => {
        const values = valuesBitmapByOperatorByFacetKey[facet][operator];
        result.push({
          type: FilterType.selectedFacetValue,
          value: {
            facet,
            operator,
            values,
          },
        });
      },
    );
  });

  Object.keys(selectedFacetRanges).forEach((facet) => {
    const value = selectedFacetRanges[facet];
    result.push({
      type: FilterType.selectedRange,
      value: {
        facet,
        ...value,
      },
    });
  });

  filterByFacets.forEach((filter) => {
    const parsedFilterByFacet = parseFilterByFacet(filter);
    if (parsedFilterByFacet) {
      const { component, dataType, facetName, operator, value } =
        parsedFilterByFacet;
      const facet = getFacetKey({
        component,
        name: facetName,
        type: dataType,
      });

      const values = value
        .split(' OR ')
        .reduce((obj, v) => ({ ...obj, [v]: 1 }), {});

      result.push({
        type: FilterType.selectedFacetValue,
        value: {
          facet,
          operator,
          values,
        },
      });
    }
  });

  return result;
};

export default parseFiltersFromLogsState;
