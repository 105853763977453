import { useLogsMetricsQueryState } from 'hooks';
import { useState } from 'react';
import { LogsMetricQueryProps } from 'types';
import { defaultLogsQuery, parseUrlParamByKey } from 'utils';

import { ConditionProps } from '../../AlertsCreateCondition';
import { logsAlertValidatorForCreate } from '../../AlertsCreateValidators';
import { useAlertsCreate } from '../../hooks';
import { RuleType } from '../../types';
import { getSelectedLogQueryLogql } from '../utils';

const useAlertsCreateLogs = (
  alertsCreateState: ReturnType<typeof useAlertsCreate>,
  baseWidth: number,
) => {
  const {
    alertsDetails,
    addToast,
    date,
    mutateAlertsRule,
    setIsSaving,
    setErrorHandling,
  } = alertsCreateState;
  const parsedQueries = parseUrlParamByKey('LogsMetricsQueries');
  const [logsExplorerType, setLogsExplorerType] = useState('builder');
  const [logQLText, setLogQLText] = useState('');

  const queriesState = useState<LogsMetricQueryProps[]>(
    parsedQueries || [defaultLogsQuery],
  );

  const logsMetricsQueryState = useLogsMetricsQueryState({
    chartWidth: baseWidth,
    date,
    onChangeLoad: true,
    isRange: true,
    queriesState,
  });
  const { queries, formulas } = logsMetricsQueryState;

  const createLogQLAlerts = (condition: ConditionProps) => {
    const { queryKey } = condition;
    const selectedQuery = getSelectedLogQueryLogql(formulas, queries, queryKey);

    if (typeof selectedQuery === 'string') {
      addToast({ text: selectedQuery, status: 'error' });
      return;
    }

    const validation = logsAlertValidatorForCreate({
      alertsDetails,
      condition,
    });
    if (typeof validation === 'string') {
      addToast({ text: validation, status: 'error' });
      return;
    }

    const validationKeys = Object.keys(validation);
    if (validationKeys.length) {
      setErrorHandling((prev) => ({ ...prev, ...validation }));
      validationKeys.forEach((key) => {
        addToast({ text: validation[key], status: 'error' });
      });
      return;
    }

    if (!selectedQuery) {
      addToast({
        text: 'Failed to create alerts invalid logql',
        status: 'error',
      });
      return;
    }
    const { logql, query } = selectedQuery;
    const queryType = queryKey.includes('Query') ? 'query' : 'formula';
    const extraData = {
      queryType,
      queries: queryType === 'query' ? [query] : queries,
      formulas: queryType === 'formula' ? formulas : [],
      queryKey,
    };

    setIsSaving(true);
    mutateAlertsRule({
      condition,
      datasourceType: 'loki',
      date,
      promqlQuery: logql,
      ruleAnnotations: {
        ruleType: RuleType.LOGS,
        extraData: JSON.stringify(extraData),
      },
    });
  };

  return {
    createLogQLAlerts,
    logsExplorerType,
    logsMetricsQueryState,
    logQLText,
    setLogsExplorerType,
    setLogQLText,
  };
};

export default useAlertsCreateLogs;
