import {
  useRequest,
  useSelectedFacetValuesByNameState,
  useSortState,
} from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { DateSelection } from 'types';
import getRumEvents from './requests/getRumEvents';
import { RumEventType } from './types';

const limit = 100;

type Args = {
  date: DateSelection;
  eventType: RumEventType;
  idSearch: string;
  rumTableSortState?: ReturnType<typeof useSortState>;
  selectedFacetValuesByNameState?: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
};

const useRumTableRequest = ({
  date,
  eventType,
  idSearch,
  rumTableSortState,
  selectedFacetValuesByNameState,
}: Args) => {
  const pageNumRef = useRef<number>(1);
  const request = useRequest(getRumEvents);
  const [result, setResult] = useState([]);

  const call = () => {
    const pageNum = pageNumRef.current || 1;
    const { startTimeUnix, endTimeUnix } = date;
    request
      .call({
        eventType,
        startTimeUnix,
        idSearch,
        endTimeUnix,
        selectedFacetValuesByName: selectedFacetValuesByNameState.state,
      })
      .then((nextResult) => {
        if (nextResult && nextResult.length) {
          pageNumRef.current = pageNum + 1;
          setResult((prevResult) => [...prevResult, ...nextResult]);
        }
      });
  };

  const onScrollEnd = () => {
    //TODO to Fix this
    // call();
  };

  useEffect(
    () => {
      pageNumRef.current = null;
      setResult([]);
      call();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      date,
      eventType,
      idSearch,
      selectedFacetValuesByNameState.state,
      rumTableSortState?.state,
    ],
  );

  return {
    call,
    isLoading: request.isLoading,
    onScrollEnd,
    result,
  };
};

export default useRumTableRequest;
