import { TableColumnType } from 'components';
import { serviceTableKpis, ServiceTableKpiKeys } from 'kfuse-constants';
import { useToggle } from 'hooks';
import { useRef, useState } from 'react';
import { queryRange } from 'requests';
import { DateSelection, SelectedFacetValuesByName } from 'types';
import { getInstantRateIntervalAndStep } from 'utils';
import { formatDataset, formatKpiAsEmpty, ServicesTab } from './utils';
import { v4 as uuidv4 } from 'uuid';

const serviceTableKpiByKey = serviceTableKpis.reduce(
  (obj, kpi) => ({ ...obj, [kpi.key]: kpi }),
  {},
);

const getQueries = (
  columns: TableColumnType[],
  date: DateSelection,
  selectedFacetValuesByName: SelectedFacetValuesByName,
  tableType: string,
) => {
  const { rateInterval, stepInMs } = getInstantRateIntervalAndStep({ date });
  return columns
    .map((column) => serviceTableKpiByKey[column.key])
    .map((kpi) => {
      if (tableType === ServicesTab.db) {
        return kpi.servicesClientDBQuery({
          rateInterval,
          selectedFacetValuesByName,
          stepInMs,
        });
      }
      return kpi.servicesQuery({
        rateInterval,
        selectedFacetValuesByName,
        stepInMs,
      });
    });
};

const useKpisByServiceNameRequest = ({
  isLatencyBoundedToMinMax,
}: {
  isLatencyBoundedToMinMax: boolean;
}) => {
  const lastPromises = useRef({});

  const [lastRefreshedAt, setLastRefreshedAt] = useState<number>();
  const [state, setState] = useState({});
  const stateCallerRef = useRef(null);
  const [isLoadingState, setIsLoadingState] = useState({});
  const isLoadingToggle = useToggle();

  const onDone = () => {
    setLastRefreshedAt(new Date().valueOf());
    isLoadingToggle.off();
  };

  const runQuery = ({
    date,
    key,
    query,
    currentCalerRef = stateCallerRef.current,
  }) => {
    setIsLoadingState((prevLoadingState) => ({
      ...prevLoadingState,
      [key]: 1,
    }));

    const currentPromise = queryRange({ date, instant: true, query });

    lastPromises.current[key] = currentPromise;

    return currentPromise
      .then(
        (result) => {
          return formatDataset(setState, key)(
            result,
            currentPromise !== lastPromises.current[key] ||
              currentCalerRef !== stateCallerRef.current,
          );
        },
        (result) =>
          formatKpiAsEmpty(
            setState,
            key,
          )(
            currentPromise !== lastPromises.current[key] ||
              currentCalerRef !== stateCallerRef.current,
          ),
      )
      .finally(() => {
        if (currentPromise === lastPromises.current[key]) {
          setIsLoadingState((prevLoadingState) => ({
            ...prevLoadingState,
            [key]: 0,
          }));
        }
      });
  };

  const call = async ({
    columns,
    date,
    selectedFacetValuesByName,
    selectedColumns,
    tableType,
  }) => {
    setState({});
    stateCallerRef.current = uuidv4();
    const columnsByKey = columns.reduce(
      (obj, column) => ({ ...obj, [column.key]: column }),
      {},
    );
    isLoadingToggle.on();

    const filteredColumns = [
      ServiceTableKpiKeys.requests,
      ServiceTableKpiKeys.requestsPerSecond,
      ServiceTableKpiKeys.apdex,
      ServiceTableKpiKeys.errorRate,
      ServiceTableKpiKeys.maxLatency,
      ServiceTableKpiKeys.minLatency,
      ServiceTableKpiKeys.averageLatency,
      ServiceTableKpiKeys.p50latency,
      ServiceTableKpiKeys.p75latency,
      ServiceTableKpiKeys.p90latency,
      ServiceTableKpiKeys.p95latency,
      ServiceTableKpiKeys.p99latency,
    ]
      .filter((key) => selectedColumns[key] && serviceTableKpiByKey[key])
      .map((key) => columnsByKey[key]);

    if (isLatencyBoundedToMinMax) {
      if (!selectedColumns[ServiceTableKpiKeys.maxLatency]) {
        filteredColumns.unshift(columnsByKey[ServiceTableKpiKeys.maxLatency]);
      }
      if (!selectedColumns[ServiceTableKpiKeys.minLatency]) {
        filteredColumns.unshift(columnsByKey[ServiceTableKpiKeys.minLatency]);
      }
    }

    const queries = getQueries(
      filteredColumns,
      date,
      selectedFacetValuesByName,
      tableType,
    );

    const currentCalerRef = stateCallerRef.current;
    await Promise.all([
      ...queries.map((query, i) =>
        runQuery({
          date,
          key: filteredColumns[i].key,
          query,
          currentCalerRef,
        }),
      ),
    ]);

    onDone();
  };

  const fetchSingleColumn = ({
    activeTab,
    date,
    key,
    selectedFacetValuesByName,
  }) => {
    const kpi = serviceTableKpis.find((kpi) => kpi.key === key);
    if (kpi) {
      const { rateInterval, stepInMs } = getInstantRateIntervalAndStep({
        date,
      });
      let query = null;
      if (activeTab === ServicesTab.db) {
        query = kpi.servicesClientDBQuery({
          rateInterval,
          selectedFacetValuesByName,
          stepInMs,
        });
      } else {
        query = kpi.servicesQuery({
          rateInterval,
          selectedFacetValuesByName,
          stepInMs,
        });
      }
      runQuery({
        date,
        key: kpi.key,
        query,
        currentCalerRef: stateCallerRef.current,
      });
    }
  };

  const isLoading = Boolean(
    Object.values(isLoadingState).filter((value) => value).length,
  );

  return {
    call,
    fetchSingleColumn,
    isLoading,
    isLoadingState,
    lastRefreshedAt,
    result: state,
  };
};

export default useKpisByServiceNameRequest;
