import { SelectedFacetValues } from 'types';

export const getSpanTypeFilterForServiceAPI = (
  spanType: SelectedFacetValues,
) => {
  const value = spanType?.['db'];
  switch (value) {
    case 1:
      return 'database';
    case 0:
      return 'service';
    default:
      return null;
  }
};
