import { useRequest, useUrlState } from 'hooks';
import { useEffect, useState } from 'react';
import { getDashboardFilterLabelsValues } from 'requests';
import {
  DateSelection,
  DashboardTemplateProps,
  DashboardTemplateValueProps,
} from 'types';

import {
  checkVariableUsedInQuery,
  formatIntialTemplateOptions,
  formatReloadTemplateOptions,
} from '../utils';

const useDashboardTemplateState = ({
  date,
  disableFilter = false,
  fetchLimitedTemplates = [],
  shouldWriteToUrl = true,
}: {
  date: DateSelection;
  disableFilter?: boolean;
  fetchLimitedTemplates?: { name: string; joinValues?: boolean }[];
  shouldWriteToUrl?: boolean;
}) => {
  const [regularState, setRegularState] = useState<DashboardTemplateValueProps>(
    {},
  );
  const [urlState, setUrlState] = useUrlState(
    'templateValues',
    {} as DashboardTemplateValueProps,
  );
  const templateValues = shouldWriteToUrl ? urlState : regularState;
  const setTemplateValues = shouldWriteToUrl ? setUrlState : setRegularState;
  const [templating, setTemplating] = useState<DashboardTemplateProps[]>([]);
  const dashboardFiltersRequest = useRequest(
    getDashboardFilterLabelsValues,
    false,
  );

  const initialTemplateSetup = async (
    initTemplating: DashboardTemplateProps[],
  ): Promise<string> => {
    if (!initTemplating || initTemplating.length === 0) {
      return;
    }

    const sanitizedTemplates: DashboardTemplateProps[] = initTemplating || [];
    if (disableFilter) {
      initialTemplateSetupDisabled(sanitizedTemplates);
      return new Promise((resolve) => resolve('done'));
    }

    if (fetchLimitedTemplates.length > 0) {
      await fetchLimitedTemplateOptions(sanitizedTemplates);
      return new Promise((resolve) => resolve('done'));
    } else {
      await dashboardFiltersRequest
        .call({
          date,
          formatTemplateOptions: formatIntialTemplateOptions,
          templates: sanitizedTemplates,
          templateValues,
        })
        .then(
          ({
            templateValues: newTemplateValues,
          }: {
            templateValues: DashboardTemplateValueProps;
          }) => {
            setTemplating(sanitizedTemplates);
            setTemplateValues(newTemplateValues);
          },
        );

      return new Promise((resolve) => resolve('done'));
    }
  };

  const initialTemplateSetupDisabled = (
    sanitizedTemplates: DashboardTemplateProps[],
  ) => {
    const predefinedValues = sanitizedTemplates.map(({ name, current }) => ({
      [name]: current.value,
    }));
    setTemplateValues(Object.assign({}, ...predefinedValues));
  };

  const fetchLimitedTemplateOptions = async (
    sanitizedTemplates: DashboardTemplateProps[],
  ) => {
    return new Promise((resolve) => {
      const predefinedValues = sanitizedTemplates.map((tem) => {
        const { allValue, includeAll, name, current } = tem;

        if (includeAll) {
          const newAllValue = allValue || '.*';
          return {
            [name]: tem.multi ? [newAllValue] : newAllValue,
          };
        }
        return {
          [name]: current.value,
        };
      });
      const limitedTemplates = fetchLimitedTemplates
        .map((d, idx) => {
          const templateIndex = sanitizedTemplates.findIndex(
            ({ name }) => name === d.name,
          );
          if (templateIndex === -1) return;
          const template = sanitizedTemplates[templateIndex];
          return { index: templateIndex, template };
        })
        .filter(Boolean);

      const emptyOptions = sanitizedTemplates.map(() => []);
      const newTemplateValues = Object.assign({}, ...predefinedValues);
      dashboardFiltersRequest
        .call({
          date,
          formatTemplateOptions: ({ templateOptions }: any) =>
            formatReloadTemplateOptions({
              preTemplateOptions: emptyOptions,
              templateOptions,
              usedTemplates: limitedTemplates,
            }),
          templates: limitedTemplates.map(({ template }) => template),
          templateValues: newTemplateValues,
        })
        .then(({ templateValues, templateOptions }) => {
          const newTemplateValues = { ...templateValues };

          fetchLimitedTemplates.map((d, idx) => {
            const { name, joinValues } = d;
            if (joinValues) {
              const templateIndex = sanitizedTemplates.findIndex(
                ({ name: templateName }) => templateName === name,
              );
              if (templateIndex === -1) return;
              const tOptions = templateOptions[templateIndex];
              const selectedOptions = tOptions
                .filter(({ value }) => value !== '.*')
                .map(({ value }) => value);

              newTemplateValues[name] = selectedOptions;
            }
          });
          setTemplating(sanitizedTemplates);
          setTemplateValues(newTemplateValues);
          resolve('done');
        });
    });
  };

  const onTemplateChangeReload = async (
    name: string,
    newTemplateValues: DashboardTemplateValueProps,
  ) => {
    const usedTemplates = checkVariableUsedInQuery(name, templating);
    if (usedTemplates.length === 0) {
      return;
    }

    dashboardFiltersRequest.call({
      date,
      formatTemplateOptions: ({ templateOptions }: any) =>
        formatReloadTemplateOptions({
          preTemplateOptions:
            dashboardFiltersRequest?.result?.templateOptions || [],
          templateOptions,
          usedTemplates,
        }),
      templates: usedTemplates.map(({ template }) => template),
      templateValues: newTemplateValues,
    });
  };

  useEffect(() => {
    if (!dashboardFiltersRequest.isLoading && date && templating.length > 0) {
      initialTemplateSetup(templating);
    }

    if (templating.length === 0) {
      setTemplateValues({ ...templateValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return {
    initialTemplateSetup,
    isLoading: dashboardFiltersRequest.isLoading,
    onTemplateChangeReload,
    setTemplating,
    setTemplateValues,
    templating,
    templateOptions: dashboardFiltersRequest?.result?.templateOptions || [],
    templateValues,
  };
};

export default useDashboardTemplateState;
