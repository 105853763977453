import { AutocompleteOption } from 'components';
import {
  DashboardTemplateProps,
  DashboardTemplateValueProps,
  PrometheusDataset,
} from 'types';

import { promqlVariableReplacer } from './grafanaVariable';

/**
 * get template variables from query
 * @param query string
 * @returns
 * query string may contain $variable or ${variable}
 * retrun variable list
 * example: error{kube_namespace=~"${namespace}",kube_service=~"${service}"}
 * return ['namespace', 'service']
 * example: error{kube_namespace=~"$namespace",kube_service=~"$service"}
 * return ['namespace', 'service']
 */
export const getTemplateVariablesFromQuery = (query: string) => {
  if (!query) return [];

  const variables = query.match(/\$\{?(\w+)\}?/g);
  if (variables) {
    return variables.map((variable) => variable.replace(/\$|\{|\}/g, ''));
  }
  return [];
};

/**
 * Check variable is used in query
 */
export const checkVariableUsedInQuery = (
  variable: string,
  templating: DashboardTemplateProps[],
): Array<{
  index: number;
  template: DashboardTemplateProps;
}> => {
  const result: Array<{
    index: number;
    template: DashboardTemplateProps;
  }> = [];

  templating.forEach((template, index) => {
    const { query } = template;
    const variables = getTemplateVariablesFromQuery(query.query);
    if (variables.includes(variable)) {
      result.push({ index, template });
    }
  });

  return result;
};

/**
 * Get template dependencies
 * @param newTemplates
 * @returns
 */
export const getTemplateDependencies = (
  newTemplates: DashboardTemplateProps[],
): { [key: number]: number[] } => {
  const dependencies: { [key: number]: number[] } = {};
  newTemplates.forEach((template, index) => {
    const { name } = template;
    const usedTemplates = checkVariableUsedInQuery(name, newTemplates);
    const usedTemplatesIndexs = usedTemplates.map((t) => t.index);

    if (!dependencies[index]) {
      dependencies[index] = [];
    }

    usedTemplatesIndexs.forEach((usedTemplateIndex) => {
      if (dependencies[usedTemplateIndex]) {
        dependencies[usedTemplateIndex].push(index);
      } else {
        dependencies[usedTemplateIndex] = [index];
      }
    });
  });

  return dependencies;
};

/**
 * parse template query
 * @param query string
 * start with
 * 1. label_names()
 * 2. label_values(label)
 * 3. label_values(metric, label)
 * 4. metrics(metric)
 * 5. query_result(query)
 */
const parseTemplateQuery = (query: string) => {
  if (!query) return null;

  const queryResult = query.match(/query_result\((.*)\)/);
  if (queryResult) {
    return {
      type: 'query_result',
      query: queryResult[1],
    };
  }
  const labelNames = query.match(/label_names\((.*)\)/);
  if (labelNames) {
    return {
      type: 'label_names',
      query: labelNames[1],
    };
  }
  const labelValues = query.match(/label_values\((.*),(.*)\)/);
  if (labelValues) {
    return {
      type: 'series',
      query: labelValues[1],
      label: labelValues[2].trim(),
    };
  }
  const metrics = query.match(/metrics\((.*)\)/);
  if (metrics) {
    return {
      type: 'metrics',
      query: metrics[1],
    };
  }

  const labelValues2 = query.match(/label_values\((.*)\)/);
  if (labelValues2) {
    return {
      type: 'label_values',
      query: '',
      label: labelValues2[1].trim(),
    };
  }

  return null;
};

export const transformTemplateQueryResultValue = (
  data: PrometheusDataset[],
  template: DashboardTemplateProps,
): AutocompleteOption[] => {
  const labelBitmap: { [key: string]: boolean } = {};

  const { regex } = template;
  data.forEach((dataset) => {
    if (dataset.metric) {
      Object.keys(dataset.metric).forEach((key) => {
        const labelValue = dataset.metric[key];
        if (regex) {
          const regexLabel = labelValue.replace(regex, '');
          labelBitmap[regexLabel] = true;
        }
        labelBitmap[labelValue] = true;
      });
    }
  });

  return Object.keys(labelBitmap).map((label) => ({ label, value: label }));
};

/**
 * Transform template query with template variables
 */
export const transformTemplateQuery = (
  query: string,
  newTemplateValues: DashboardTemplateValueProps,
): {
  type: string;
  query: string;
  label?: string;
} => {
  const variables = getTemplateVariablesFromQuery(query);
  const templateQuery = parseTemplateQuery(query);

  if (!templateQuery) return { type: '', query };
  const { type } = templateQuery;

  if (variables.length > 0) {
    variables.forEach((variable) => {
      if (newTemplateValues.hasOwnProperty(variable)) {
        const value = newTemplateValues[variable];
        const replaceValue =
          typeof value === 'string' ? value : value.join('|');

        if (type === 'series' || type === 'query_result') {
          templateQuery.query = promqlVariableReplacer(
            templateQuery.query,
            variable,
            replaceValue,
          );
        }
      }
    });
  }

  return templateQuery;
};

export const firstNonEmptyValue = (arr: AutocompleteOption[]) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].value) {
      return arr[i].value;
    }
  }
  return '';
};

export const checkAndAssignDefaultValue = ({
  templateIndex,
  options,
  templates,
  newTemplateValues,
  templateValues,
}: {
  templateIndex: number;
  options: AutocompleteOption[];
  templates: DashboardTemplateProps[];
  newTemplateValues: DashboardTemplateValueProps;
  templateValues: DashboardTemplateValueProps;
}) => {
  const firstValue = firstNonEmptyValue(options);
  const {
    allValue,
    current,
    includeAll,
    multi,
    name,
    type,
    options: predefinedOptions,
  } = templates[templateIndex];

  if (templateValues[name]) {
    newTemplateValues[name] = templateValues[name];
    return;
  }

  if (type === 'textbox') {
    newTemplateValues[name] = current.value || '';
    return;
  }

  if (predefinedOptions && predefinedOptions.length > 0) {
    newTemplateValues[name] =
      predefinedOptions.find(({ selected }) => selected).value || current.value;
    return;
  }

  if (!allValue) {
    templates[templateIndex].allValue = '.*';
  }

  if (includeAll) {
    const newAllValue = allValue ? allValue : '.*';
    newTemplateValues[name] = multi ? [newAllValue] : newAllValue;
    return;
  }

  const defaultValue = current.value;
  let found = false;
  if (Array.isArray(defaultValue)) {
    const filterMultiOption = defaultValue.filter((val: string) =>
      options.find((option) => (option.value === val ? val : null)),
    );
    found = filterMultiOption.length > 0;
  } else {
    const filterOneOption = options.filter((option) =>
      option.value === defaultValue ? defaultValue : null,
    );
    found = filterOneOption.length > 0;
  }

  if (current.selected && found) {
    newTemplateValues[name] = current.value;
    return;
  }

  if (!multi) {
    newTemplateValues[name] = firstValue;
    return;
  }

  if (options.length > 0) {
    newTemplateValues[name] = multi ? [firstValue] : firstValue;
    return;
  }

  if (templates[templateIndex].allValue) {
    newTemplateValues[name] = multi
      ? [templates[templateIndex].allValue]
      : templates[templateIndex].allValue;
  }

  return;
};
