import {
  ConfirmationModal,
  Loader,
  Table,
  TableSearch,
  TooltipTrigger,
  useModalsContext,
  usePaginator,
  useTableSearch,
  useTableSort,
  useToaster,
} from 'components';
import { useRequest } from 'hooks';
import React, { useEffect } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { deleteTraceMetric } from 'requests';
import { getMetricsExplorerDefaultQuery } from 'utils';

const getColumns = ({ onDelete, onOpen }: any) => [
  { key: 'name', label: 'Name' },
  { key: 'labels', label: 'Labels' },
  {
    key: 'filter',
    label: 'Filter',
    renderCell: ({ value }: { value: string }) => {
      if (!value) return '';

      return <span className="text--pre">{value}</span>;
    },
  },
  { label: 'Range Aggregate', key: 'range_aggregate' },
  { label: 'Facet', key: 'unwrap_facet' },
  {
    label: 'Actions',
    key: 'actions',
    renderCell: ({ row }: { row: { name: string } }) => {
      return (
        <div className="alerts__contacts__table__actions">
          <TooltipTrigger
            className="table__cell__actions__item--blue"
            tooltip="Open in metric explorer"
          >
            <BiLinkExternal
              className="alerts__contacts__table__actions__icon--edit"
              onClick={() => onOpen(row.name)}
              size={18}
            />
          </TooltipTrigger>
          <TooltipTrigger
            className="table__cell__actions__item--red"
            tooltip="Delete"
          >
            <MdDelete
              className="alerts__contacts__table__actions__icon--delete"
              onClick={() => onDelete(row.name)}
              size={18}
            />
          </TooltipTrigger>
        </div>
      );
    },
  },
];

type Props = {
  getSavedTraceMetricsRequest: ReturnType<typeof useRequest>;
};

const TracesTimeseriesSavedMetrics = ({
  getSavedTraceMetricsRequest,
}: Props) => {
  const modals = useModalsContext();
  const toastmaster = useToaster();
  const deleteTraceMetricRequest = useRequest(deleteTraceMetric);

  const onDelete = (name: string) => {
    modals.push(
      <ConfirmationModal
        className="logs__analytics__saved-metrics__delete-modal"
        description={`Are you sure you want to delete saved metric?`}
        onCancel={() => modals.pop()}
        onConfirm={() => {
          deleteTraceMetricRequest
            .call(name)
            .then((deleteMetricResponse: any) => {
              if (deleteMetricResponse) {
                toastmaster.addToast({
                  status: 'success',
                  text: 'Metric deleted successfully.',
                });
                getSavedTraceMetricsRequest.call();
              }
            });
          modals.pop();
        }}
        title="Delete Metric"
      />,
    );
  };

  const onOpen = (metricName: string) => {
    const defaultQuery = getMetricsExplorerDefaultQuery(metricName);
    const defaultQueryStr = encodeURIComponent(JSON.stringify([defaultQuery]));
    window.open(
      `${window.location.origin}/#/metrics?metricsQueries=${defaultQueryStr}`,
      '_blank',
    );
  };

  useEffect(() => {
    getSavedTraceMetricsRequest.call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableSearch = useTableSearch({
    rows: getSavedTraceMetricsRequest.result || [],
  });

  const tableSort = useTableSort({
    columns: getColumns({ onDelete, onOpen }),
    rows: tableSearch.searchedRows,
  });
  const paginator = usePaginator({
    rows: tableSort.sortedRows,
  });

  const rows = getSavedTraceMetricsRequest.result || [];

  return (
    <div className="apm__derived-metrics">
      <div className="apm__derived-metrics__header">Derived Metrics</div>
      <TableSearch
        className="mb-2 apm__derived-metrics__search"
        placeholder="Search APM Derived"
        tableSearch={tableSearch}
        dataTestId="derived_metrics_search"
      />
      <Loader
        isLoading={
          getSavedTraceMetricsRequest.isLoading ||
          deleteTraceMetricRequest.isLoading
        }
        className="overflow-x-auto"
      >
        <Table
          className="table--bordered table--bordered-cells apm__derived-metrics__table"
          columns={getColumns({ onDelete, onOpen })}
          rows={paginator.paginatedRows}
          externalTableSort={tableSort}
          isSortingEnabled
          dataTestId="derived_metrics_table"
        />
      </Loader>
      {rows.length === 0 && (
        <div className="apm__derived-metrics-empty">
          <h2>No Saved Metrics</h2>
        </div>
      )}
    </div>
  );
};

export default TracesTimeseriesSavedMetrics;
