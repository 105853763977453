import { useThemeContext } from 'components';
import { useUrlState } from 'hooks';
import { MouseEvent, useState, useEffect, useRef } from 'react';
import {
  ChartType,
  StrokeType,
  TimeseriesRenderProps,
  UplotExtended,
} from 'types';
import {
  getAnomalyBandOnSelect,
  getAnomalyBandIndexes,
} from 'utils/Timeseries';

import { UPlotConfig } from './types';
import { getConfig } from './ChartConfigBuilder';
import {
  getNoDataPlaceholder,
  getStrokeLineConfig,
  mapMouseEventToMode,
} from './utils';

const checkAllSelected = (series: UPlotConfig['series']): boolean => {
  return series.every(({ show }) => show);
};

export const SERIES_LIMIT = 50;

const useTimeseries = ({
  bands,
  date,
  chartData,
  chartKey,
  chartTypes,
  cursorState,
  defaultActiveChart,
  hooks,
  kfuseHook,
  layoutType = 'explore',
  onCursorStateChange,
  onSeriesShowHide,
  plugins,
  onChartTypeChange,
  seriesLimit = SERIES_LIMIT,
  size: { width, height },
  strokeType = 'normal',
  styles = {},
  unit = 'number',
}: TimeseriesRenderProps) => {
  const userActions = useRef({ init: false });
  const [config, setConfig] = useState<UPlotConfig>(null);
  const [data, setData] = useState<any>([]);
  const [activeChart, setActiveChart] = useUrlState<ChartType>(
    chartKey ? `activeChart_${chartKey}` : 'activeChart',
    chartTypes ? chartTypes[0] : defaultActiveChart || 'Line',
  );
  const [activeStroke, setActiveStroke] = useUrlState<StrokeType>(
    chartKey ? `activeStroke_${chartKey}` : 'activeStroke',
    strokeType,
  );

  const [showAll, setShowAll] = useState<boolean>(false);
  const { darkModeEnabled, utcTimeEnabled } = useThemeContext();

  const chartRef = useRef<UplotExtended | null>(null);

  const onLegendItemClick = (e: MouseEvent<HTMLLIElement>, idx: number) => {
    const mode = mapMouseEventToMode(e);
    const newConfig = { ...config };
    const label = newConfig.series[idx].label;
    const anomalyBandIndexes = getAnomalyBandIndexes(label, newConfig.series);

    if (mode === 'append') {
      const appendBoolean = !newConfig.series[idx].show;
      newConfig.series[idx].show = appendBoolean;
      if (anomalyBandIndexes) {
        newConfig.series[anomalyBandIndexes.lowerBandIndex].show =
          appendBoolean;
        newConfig.series[anomalyBandIndexes.upperBandIndex].show =
          appendBoolean;
      }
    } else if (config.series[idx].show && !checkAllSelected(config.series)) {
      config.series.forEach((_, i) => {
        newConfig.series[i].show = true;
      });
      if (anomalyBandIndexes) {
        newConfig.series[anomalyBandIndexes.lowerBandIndex].show = true;
        newConfig.series[anomalyBandIndexes.upperBandIndex].show = true;
      }
    } else {
      newConfig.series.forEach((_, i) => {
        config.series[i].show = i === idx;
      });
      if (anomalyBandIndexes) {
        newConfig.series[anomalyBandIndexes.lowerBandIndex].show = true;
        newConfig.series[anomalyBandIndexes.upperBandIndex].show = true;
      }
    }

    const updatedBand = getAnomalyBandOnSelect({
      prevBands: config.bands,
      mode,
      anomalyBandIndexes,
      darkModeEnabled,
    });
    newConfig.bands = updatedBand;
    setConfig(newConfig);
    onSeriesShowHide && onSeriesShowHide(idx, config.series);
  };

  const getConfigAndData = (isShowAll: boolean) => {
    const { data, maxValue, minValue, series } = chartData;

    let newData = [];
    let newSeries = [];
    if (!isShowAll && layoutType === 'explore' && seriesLimit) {
      newData = [...data].slice(0, seriesLimit + 1);
      newSeries = [...series].slice(0, seriesLimit);
    } else {
      newData = [...data];
      newSeries = [...series];
    }

    const newChartStyles = styles || {};
    const strokeConfig = getStrokeLineConfig(
      activeChart === 'Points' ? 'none' : activeStroke,
    );
    const chartType = activeChart === 'Points' ? 'Line' : activeChart;
    const newConfig = getConfig({
      bands,
      unit,
      chartStyles: { ...newChartStyles, ...strokeConfig },
      darkModeEnabled,
      data: newData,
      hooks,
      kfuseHook,
      layoutType,
      maxValue,
      minValue,
      plugins,
      series: newSeries,
      size: { width, height: height || 340 },
      type: chartType,
      utcTimeEnabled,
    });

    return { data: newData, newConfig };
  };

  const updateChartSize = (height: number) => {
    const newConfig = { height };
    setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }));
  };

  useEffect(() => {
    const isShowAll = chartData.data.length < SERIES_LIMIT;
    if (isShowAll) {
      setShowAll(false);
    }

    const { data, newConfig } = getConfigAndData(isShowAll);
    if ((data.length === 0 || data[0]?.length === 0) && date) {
      setData(getNoDataPlaceholder(date));
      setConfig(newConfig);
    } else {
      setData(data);
      setConfig(newConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartData,
    activeChart,
    activeStroke,
    darkModeEnabled,
    utcTimeEnabled,
    hooks,
    kfuseHook,
    styles?.scaleDistribution?.type,
  ]);

  useEffect(() => {
    if (config) {
      const { newConfig } = getConfigAndData(showAll);
      setConfig({
        ...newConfig,
        width: width ? width : config.width,
        height: height ? height : config.height,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  const showAllSeries = () => {
    setShowAll(true);
    const { data, newConfig } = getConfigAndData(true);
    setData([...data]);
    setConfig(newConfig);
  };

  useEffect(() => {
    if (!userActions.current.init) {
      userActions.current.init = true;
      return;
    }
    if (onChartTypeChange) {
      onChartTypeChange(activeChart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChart]);

  useEffect(() => {
    if (chartTypes && chartTypes?.[0]) {
      setActiveChart(chartTypes[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartTypes]);

  return {
    activeChart,
    activeStroke,
    chartRef,
    cursorState,
    config,
    data,
    darkModeEnabled,
    getRawData: () => chartData.data || [],
    onLegendItemClick,
    onCursorStateChange,
    setActiveChart,
    setActiveStroke,
    setConfig,
    setData,
    showAll,
    showAllSeries,
    styles,
    updateChartSize,
  };
};

export default useTimeseries;
