import { AnalyticsChart } from 'components';
import { useSearches } from 'hooks';
import React, { useMemo } from 'react';
import { formatDurationNs } from 'utils';
import { useAnalyticsChartOverlayMessage } from 'hooks';
import useRumState from '../hooks/useRumState';
import useRumTimeseries from './hooks/useRumTimeseries';
import { RumTab } from '../types';

type Props = {
  searches: ReturnType<typeof useSearches>['searches'];
  rumState: ReturnType<typeof useRumState>;
  rumTab: RumTab;
};

const RumTimeseries = ({ searches, rumState, rumTab }: Props) => {
  const rumTimeseries = useRumTimeseries({
    searches,
    rumState,
    rumTab,
  });
  const { analyticsChart, analyticsData, groupBysForTable, onViewFullscreen } =
    rumTimeseries;

  const { dateState } = rumState;
  const [date, setDate] = dateState;

  const overlayMessage = useAnalyticsChartOverlayMessage({
    chartData: analyticsData.chartData,
    activeVisualization: analyticsChart.activeVisualization,
  });

  const renderValue = useMemo(
    () =>
      searches[0].measure === 'duration_ns'
        ? (n: number) => formatDurationNs(n, 1, 2)
        : null,
    [searches],
  );

  return (
    <div className="rum__timeseries">
      <AnalyticsChart
        analyticsChart={analyticsChart}
        chartData={analyticsData.chartData}
        chartFormulas={analyticsData.formulaQueries}
        chartQueries={analyticsData.chartQueries}
        groupBy={groupBysForTable}
        overlayMessage={overlayMessage}
        renderValue={renderValue}
        rightToolbar={{
          onViewFullscreen,
        }}
        setDate={setDate}
        settingOptions={{
          legendType: 'values',
          toolbarChartShowType: 'dropdown',
        }}
      />
    </div>
  );
};

export default RumTimeseries;
