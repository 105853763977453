import {
  Badge,
  ConfirmationModal,
  Loader,
  Paginator,
  Table,
  TableSearch,
  TooltipTrigger,
  useModalsContext,
  usePaginator,
  useTableSearch,
  useTableSort,
  useToaster,
} from 'components';
import dayjs from 'dayjs';
import { FilterType, Operator, useRequest } from 'hooks';
import { dateTimeFormat, delimiter } from 'kfuse-constants';
import React, { useEffect } from 'react';
import { RiExternalLinkLine as ExternalLinkIcon } from 'react-icons/ri';
import { MdOutlineCancel as CancelIcon } from 'react-icons/md';
import { RiFileCopyLine as CopyIcon } from 'react-icons/ri';
import { FaInfoCircle as InfoIcon } from 'react-icons/fa';
import { DateSelection, LogsMetricQueryProps } from 'types';
import {
  convertSecondToReadablePrecision,
  getLogsExplorerDefaultQueryByFilter,
} from 'utils';

import LogsHydrationJobsListJobProgress from './LogsHydrationJobsListJobProgress';
import { cancelJob, getJobsList } from './requests';
import { HydrationJobProps, JobStatus } from './types';

const jobsColumns = ({
  onCopyJobId,
  onCancelJob,
  onViewLogs,
}: {
  onCopyJobId: (job: HydrationJobProps) => void;
  onCancelJob: (job: HydrationJobProps) => void;
  onViewLogs: (job: HydrationJobProps) => void;
}) => [
  { key: 'ArchivalName', label: 'Archival Name' },
  {
    key: 'ArchivalStartTsMs',
    label: 'Start Time and End Time (UTC)',
    renderCell: ({ row }: { row: HydrationJobProps }) => (
      <div>
        {dayjs(row.ArchivalStartTsMs).format(dateTimeFormat)} -{' '}
        {dayjs(row.ArchivalEndTsMs).format(dateTimeFormat)}
      </div>
    ),
  },
  {
    key: 'JobStatus',
    label: 'Status',
    renderCell: ({ row }: { row: HydrationJobProps }) => {
      return (
        <TooltipTrigger
          tooltip={row.JobStatus === JobStatus.FAILED ? row.ErrorMsg : ''}
        >
          <div className="flex items-center">
            {row.JobStatus}
            {row.JobStatus === JobStatus.FAILED && (
              <InfoIcon
                size={14}
                className="ml-1 cursor-pointer text-red-500"
              />
            )}
          </div>
        </TooltipTrigger>
      );
    },
  },
  {
    key: 'JobProgress',
    label: 'Progress',
    renderCell: ({ row }: { row: HydrationJobProps }) => (
      <LogsHydrationJobsListJobProgress job={row} />
    ),
  },
  {
    key: 'filter',
    label: 'Filters',
    renderCell: ({ row }: { row: HydrationJobProps }) => {
      if (!row.ArchivalFilters) return;
      const filtersJson = JSON.parse(row.ArchivalFilters);
      const filterKeys = Object.keys(filtersJson);
      return (
        <div>
          {filterKeys.map((key) => (
            <Badge
              key={key}
              variant="outline"
              className="py-0.25 mb-1 mr-1 truncate rounded-sm px-1 text-xs font-medium"
            >
              {key}:{filtersJson[key]}
            </Badge>
          ))}
        </div>
      );
    },
  },
  {
    key: 'tags',
    label: 'Tags',
    renderCell: ({ row }: { row: HydrationJobProps }) => {
      if (!row.ArchivalAddTags) return;
      const tagsJson = JSON.parse(row.ArchivalAddTags);
      const tagKeys = Object.keys(tagsJson);
      return (
        <div>
          {tagKeys.map((key) => (
            <Badge
              key={key}
              variant="outline"
              className="py-0.25 mb-1 mr-1 truncate rounded-sm px-1 text-xs font-medium"
            >
              {key}:{tagsJson[key]}
            </Badge>
          ))}
        </div>
      );
    },
  },
  {
    key: 'CreatedAtMs',
    label: 'Created At',
    renderCell: ({ row }: { row: HydrationJobProps }) => (
      <div>{dayjs(row.CreatedAtMs).format(dateTimeFormat)}</div>
    ),
  },
  {
    key: 'DurationMs',
    label: 'Retention',
    renderCell: ({ row }: { row: HydrationJobProps }) => {
      const durationSeconds = row.ArchivalDurationMs / 1000;
      return (
        <div>{convertSecondToReadablePrecision(durationSeconds, true)}</div>
      );
    },
  },
  {
    label: '',
    key: 'actions',
    renderCell: ({ row }: { row: HydrationJobProps }) => {
      const isRunning =
        row.JobStatus === JobStatus.HYDRATING ||
        row.JobStatus === JobStatus.INIT;
      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': isRunning ? '109px' : '73px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider">
              <div className="alerts__contacts__table__actions">
                <div
                  className="table__cell__actions__item--blue"
                  onClick={() => onCopyJobId(row)}
                >
                  <TooltipTrigger tooltip="Copy Job ID">
                    <CopyIcon
                      className="alerts__contacts__table__actions__icon--blue"
                      size={18}
                    />
                  </TooltipTrigger>
                </div>
                <div
                  className="table__cell__actions__item--blue"
                  onClick={() => onViewLogs(row)}
                >
                  <TooltipTrigger tooltip="View Logs">
                    <ExternalLinkIcon
                      className="alerts__contacts__table__actions__icon--blue"
                      size={18}
                    />
                  </TooltipTrigger>
                </div>
                {isRunning && (
                  <div
                    className="table__cell__actions__item--red"
                    onClick={() => onCancelJob(row)}
                  >
                    <TooltipTrigger tooltip="Cancel Job">
                      <CancelIcon
                        className="alerts__contacts__table__actions__icon--delete"
                        size={18}
                      />
                    </TooltipTrigger>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];

const emptyArr = [];
const LogsHydrationJobsList = ({ date }: { date: DateSelection }) => {
  const { addToast } = useToaster();
  const modal = useModalsContext();
  const getJobsListRequest = useRequest(getJobsList);
  const cancelJobRequest = useRequest(cancelJob);

  useEffect(() => {
    getJobsListRequest.call(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancelJob = (row: HydrationJobProps) => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={
          <span>
            Are you sure you want to cancel{' '}
            <span className="font-bold">{row.JobId}</span> job?
          </span>
        }
        dataTestId="delete-group-confirmation-modal"
        onCancel={() => modal.pop()}
        cancelText="Close"
        submitText="Cancel Job"
        onConfirm={() => {
          cancelJobRequest.call(row.JobId).then((res) => {
            modal.pop();
            addToast({ text: res.message, status: 'success' });

            getJobsListRequest.call(date);
          });
        }}
        title="Cancel Job"
      />,
    );
  };

  const onViewLogs = (row: HydrationJobProps) => {
    const facet = `Additional${delimiter}__kf_hydration_job${delimiter}string`;
    const filters: LogsMetricQueryProps['filters'] = [
      {
        type: FilterType.selectedFacetValue,
        value: {
          facet,
          operator: Operator.equal,
          values: { [row.JobId]: 1 },
        },
      },
    ];
    const defaultLogsQuery = getLogsExplorerDefaultQueryByFilter(filters);

    window.open(
      `#/logs?LogsMetricsQueries=${encodeURIComponent(
        JSON.stringify([defaultLogsQuery]),
      )}`,
      '_blank',
    );
  };

  const onCopyJobId = (row: HydrationJobProps) => {
    navigator.clipboard.writeText(row.JobId);
    addToast({ text: 'Job ID copied to clipboard', status: 'success' });
  };

  const columns = jobsColumns({ onCancelJob, onViewLogs, onCopyJobId });
  const jobs = getJobsListRequest.result || emptyArr;
  const tableSearch = useTableSearch({ rows: jobs });
  const tableSort = useTableSort({ columns, rows: tableSearch.searchedRows });
  const paginator = usePaginator({
    rows: tableSort.sortedRows,
    initialNumberOfRowsPerPage: 25,
  });

  return (
    <div className="pt-4">
      <TableSearch
        className="dashboard__list__search"
        placeholder="Search Jobs..."
        tableSearch={tableSearch}
        dataTestId="dashboard-list-search"
      />
      <Loader isLoading={getJobsListRequest.isLoading}>
        <Table
          columns={columns}
          rows={paginator.paginatedRows}
          className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
          dataTestId="alerts-list-table"
          externalTableSort={tableSort}
          isSortingEnabled
        />
        <div className="table-footer">
          <Paginator paginator={paginator} />
        </div>
      </Loader>
    </div>
  );
};

export default LogsHydrationJobsList;
