import { FunctionProps, FunctionParamsProps } from 'types/MetricsQueryBuilder';
import { getRollupByVisualization, getRollupToSecond } from 'utils/rollup';
import { DURATION_OPTIONS } from './functions-params-utils';
import { DateSelection } from 'types/DateSelection';

export const ANOMALIES_FUNCTIONS = ['anomalies'];

const AGILE_DEFAULT_VALUES = {
  p: '2',
  d: '1',
  q: '2',
  sp: '0',
  sd: '0',
  sq: '0',
  m: '0',
};

const ANAMOLY_OPTIONS = [
  { label: 'basic', value: 'basic' },
  { label: 'agile', value: 'agile' },
  { label: 'robust', value: 'robust' },
  { label: 'agile-robust', value: 'agile-robust' },
];

const BOUND_OPTIONS = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
];

const BOUND_VALUES_BASIC: {
  [key: string]: [number, number];
} = {
  '1': [0.84, 0.16],
  '2': [0.84, 0.16],
  '3': [0.84, 0.16],
};

const BOUND_VALUES_ROBUST: {
  [key: string]: [number, number];
} = {
  '1': [1, 1],
  '2': [2, 2],
  '3': [3, 3],
};

const BOUND_VALUES_AGILE: {
  [key: string]: [number, number];
} = {
  '1': [1, 1],
  '2': [2, 2],
  '3': [3, 3],
};

export const getBoundNumberByValue = (
  value: number,
  algorithm: string,
): string => {
  let boundNumber = '1';
  if (algorithm === 'basic') {
    Object.keys(BOUND_VALUES_BASIC).forEach((key) => {
      if (
        BOUND_VALUES_BASIC[key][0] === value ||
        BOUND_VALUES_BASIC[key][1] === value
      ) {
        boundNumber = key;
      }
    });
  }
  if (algorithm === 'agile') {
    Object.keys(BOUND_VALUES_AGILE).forEach((key) => {
      if (
        BOUND_VALUES_AGILE[key][0] === value ||
        BOUND_VALUES_AGILE[key][1] === value
      ) {
        boundNumber = key;
      }
    });
  }
  if (algorithm === 'robust') {
    Object.keys(BOUND_VALUES_ROBUST).forEach((key) => {
      if (
        BOUND_VALUES_ROBUST[key][0] === value ||
        BOUND_VALUES_ROBUST[key][1] === value
      ) {
        boundNumber = key;
      }
    });
  }
  return boundNumber;
};

export const anomalyRobustModelParams: FunctionParamsProps[] = [
  {
    name: 'model',
    default: '0',
    options: [
      { label: 'additive', value: '0' },
      { label: 'multiplicative', value: '1' },
    ],
    value: '0',
    type: 'select',
  },
];

export const anomalyProphetModelParams: FunctionParamsProps[] = [
  {
    name: 'seasonality',
    default: '0',
    options: [
      { label: 'none', value: '0' },
      { label: 'hourly', value: '1' },
      { label: 'monthly', value: '2' },
      { label: 'quarterly', value: '3' },
    ],
    value: '0',
    type: 'select',
  },
];

export const getAnomaliesParams = (
  functionName: string,
): FunctionParamsProps[] => {
  return [
    {
      name: 'anomaly',
      default: 'basic',
      options: ANAMOLY_OPTIONS,
      value: 'basic',
      type: 'select',
    },
    {
      name: 'window',
      default: '10m',
      options: DURATION_OPTIONS,
      value: '5m',
      type: 'select',
    },
    {
      name: 'bounds',
      default: 1,
      options: BOUND_OPTIONS,
      value: 1,
      type: 'select',
    },
  ];
};

export const getAnomaliesPromQL = (
  func: FunctionProps,
  isRangeVector: boolean,
  query: string,
  steps?: number,
  date?: DateSelection,
): string | string[] => {
  const { name, params } = func;
  const [anomaly, window, bounds, model] = params;
  const isSubquery = isRangeVector ? ':' : '';

  if (anomaly.value === 'basic') {
    const [upperBound, lowerBound] = BOUND_VALUES_BASIC[bounds.value];

    const upper = `avg_over_time((quantile_over_time(${upperBound}, ${query} [${window.value}${isSubquery}]) 
    %2B ${bounds.value} * stddev_over_time(${query} [${window.value}${isSubquery}]))[${window.value}:])`;

    const lower = `avg_over_time((quantile_over_time(${lowerBound}, ${query} [${window.value}${isSubquery}]) 
    - ${bounds.value} * stddev_over_time(${query} [${window.value}${isSubquery}]))[${window.value}:])`;

    return [upper, lower, query];
  }

  if (anomaly.value === 'robust') {
    const seconds = getRollupToSecond(window.value);

    const defaultStep = getRollupByVisualization(date);

    const newPeriod = steps
      ? Math.floor(3600 / steps)
      : Math.floor(3600 / defaultStep);

    const lowerAndUpper = `seasonal_decompose(${query}, ${newPeriod}, ${
      model?.value || 0
    }, ${seconds * 1000}, ${bounds.value}, 3)`;

    return [lowerAndUpper, query];
  }

  if (anomaly.value === 'RRCF') {
    const rrcf = `rrcf_anomalies(${query}, 10, 256, 15, 1, 1, 1, 1)`;
    return [rrcf, query];
  }

  if (anomaly.value === 'agile') {
    const boundsValue = bounds || window;
    const [upperBound] = BOUND_VALUES_AGILE[boundsValue.value];
    const upper = `sarima(${query}, 2,1,2,0,0,0,0, ${upperBound}, 3)`;
    return [upper, query];
  }

  if (anomaly.value === 'agile-robust') {
    const boundsValue = bounds;
    const seasonalityValue = window;
    const [upperBound] = BOUND_VALUES_AGILE[boundsValue.value];
    const upper = `prophet(${query}, ${seasonalityValue.value}, ${upperBound}, 3)`;
    return [upper, query];
  }
};

export const getBasicAnomaliesPromQL = ({
  bound,
  query,
  window,
}: {
  bound: string;
  query: string;
  window: string;
}): string[] => {
  const [upperBound, lowerBound] = BOUND_VALUES_BASIC[bound];

  const upper = `avg_over_time((quantile_over_time(${upperBound}, ${query} [${window}:]) 
  %2B ${bound} * stddev_over_time(${query} [${window}:]))[${window}:])`;

  const lower = `avg_over_time((quantile_over_time(${lowerBound}, ${query} [${window}:]) 
  - ${bound} * stddev_over_time(${query} [${window}:]))[${window}:])`;

  return [upper, lower, query];
};

export const getRobustAnomaliesPromQL = ({
  band,
  bound,
  model,
  query,
  seasonality,
  step,
  window,
}: {
  band: string;
  bound: string;
  model: string;
  query: string;
  seasonality: string;
  step?: number;
  window: string;
}): string[] => {
  const milisecond = getRollupToSecond(window) * 1000;
  const newPeriod = Math.floor((Number(seasonality) || 3600) / (step || 60));
  const parameters = `${newPeriod}, ${model || 0} , ${milisecond}, ${bound}`;

  const lower = `seasonal_decompose(${query}, ${parameters}, ${band})`;
  const upper = `seasonal_decompose(${query}, ${parameters}, ${band})`;
  return [upper, lower, query];
};

export const getAgileRobustAnomaliesPromQL = ({
  bound,
  query,
  seasonality,
  band,
}: {
  bound: string;
  query: string;
  seasonality: string;
  band: string;
}): string[] => {
  const upper = `prophet(${query}, ${seasonality}, ${bound}, ${band})`;
  return [upper, query];
};

export const getAgileAnomaliesPromQL = ({
  band,
  bound,
  query,
}: {
  band: string;
  bound: string;
  query: string;
}): string[] => {
  const { p, d, q, sp, sd, sq, m } = AGILE_DEFAULT_VALUES;
  const upper = `sarima(${query}, ${p}, ${d}, ${q}, ${sp}, ${sd}, ${sq}, ${m}, ${bound}, ${band})`;
  const lower = `sarima(${query}, ${p}, ${d}, ${q}, ${sp}, ${sd}, ${sq}, ${m}, ${bound}, ${band})`;
  return [upper, lower, query];
};

export const getAgileAnomaliesPromQLV2 = (
  bound: string,
  query: string,
): string[] => {
  const { p, d, q, sp, sd, sq, m } = AGILE_DEFAULT_VALUES;
  const upper = `sarima(${query}, ${p}, ${d}, ${q}, ${sp}, ${sd}, ${sq}, ${m}, ${bound}, 3)`;
  return [upper, query];
};
