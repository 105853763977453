import { useToaster } from 'components';
import { useEffect, useRef, useState } from 'react';
import { RequestState, RequestResult } from 'types';
import { parseError } from 'utils';
import { v4 } from 'uuid';

const useRequest = <T, A extends any[]>(
  callback: (...args: A) => Promise<T>,
  checkRequestId = true,
): RequestResult<T, A> => {
  const { addToast } = useToaster();

  const isMounted = useRef(true);
  const requestIdRef = useRef<string>(null);
  const [state, setState] = useState<RequestState<T>>({
    calledAtLeastOnce: false,
    error: null,
    isLoading: false,
    result: null,
  });

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    ...state,
    call: (...args: A) => {
      const requestId = v4();
      requestIdRef.current = requestId;

      setState((prevState) => ({
        ...prevState,
        error: null,
        calledAtLeastOnce: true,
        isLoading: true,
      }));

      const onSuccess = (result: T) => {
        if (checkRequestId && requestIdRef.current !== requestId) {
          Promise.reject(new Error('Request was replaced by another request'));
        }

        // Request was resolved after hook unmounted
        if (!isMounted.current) return result;

        setState((prevState) => ({ ...prevState, result, isLoading: false }));
        return result;
      };

      const onError = (error: Error) => {
        setState((prevState) => ({ ...prevState, error, isLoading: false }));
        if (error.message === 'Unauthorized') {
          return Promise.reject(error);
        }

        if (error.message === 'empty ResultTable') {
          return Promise.resolve([] as unknown as T);
        }

        const errorMessage = parseError(error);
        if (errorMessage && errorMessage !== 'empty ResultTable') {
          addToast({
            status: 'error',
            text: errorMessage,
            timeout: 4000,
          });
        }
        return Promise.reject(error);
      };

      return callback(...args).then(onSuccess, onError);
    },

    clear: () => {
      setState({
        calledAtLeastOnce: false,
        error: null,
        isLoading: false,
        result: null,
      });
    },

    clearError: () => {
      setState((prevState) => ({
        ...prevState,
        error: null,
      }));
    },
  };
};

export default useRequest;
