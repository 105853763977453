import {
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
} from 'types/LogsMetricsQueryBuilder';

import { buildLogql } from './LogqlBuilder';

const logqlLogicalOperators = ['and', 'or', 'unless'];

export const buildLogqlFormula = ({
  formulas,
  queries,
  step,
}: {
  formulas: LogsMetricForumlaProps[];
  queries: LogsMetricQueryProps[];
  step: string;
}): string[] => {
  const logqls = buildLogql({ queries, step });
  const queryKeys = queries.map((query) => query.queryKey);

  queries.forEach((query: LogsMetricQueryProps, idx: number) => {
    if (query.showInput) {
      logqls[idx] = query.logql;
    }
  });

  const logqlBitmap: { [key: string]: string } = {};
  logqls.forEach((logql, index) => {
    logqlBitmap[queryKeys[index]] = logql;
  });

  const formulaLogqls: string[] = [];
  formulas.forEach((formula: LogsMetricForumlaProps) => {
    if (formula.isValid) {
      let expression = '';
      const logicalOperatorMap = getLogqlLogicalOperatorMap(formula.expression);
      const formulaLength = formula.expression.length;
      for (let i = 0; i < formulaLength; i++) {
        if (logicalOperatorMap.length > 0) {
          const logicalOperator = logicalOperatorMap.some((operator) => {
            return i >= operator[0] && i <= operator[1];
          });

          if (logicalOperator) {
            expression += formula.expression.charAt(i);
            continue;
          }
        }

        const char = formula.expression.charAt(i);
        if (queryKeys.includes(char)) {
          expression += `( ${logqlBitmap[char]} )`;
        } else {
          expression += char;
        }
      }
      formulaLogqls.push(expression);
    }
  });

  return formulaLogqls;
};

/**
 * Not to modify the original logical operator retrun start and end index of logical operator
 * @param expression
 * example expression: "a and b or c"
 * return: [[2, 4], [6, 7]]
 * explanation: [[start, end], [start, end]]
 * expression: "a and b or c unless d and e"
 * return: [[2, 4], [6, 7], [14, 16], [18, 19]]
 */
const getLogqlLogicalOperatorMap = (expression: string): number[][] => {
  const logicalOperatorMap: number[][] = [];

  logqlLogicalOperators.forEach((operator) => {
    const regex = new RegExp(`\\s${operator}\\s`, 'g');
    let match;
    while ((match = regex.exec(expression)) != null) {
      logicalOperatorMap.push([
        match.index + 1,
        match.index + 1 + operator.length,
      ]);
    }
  });

  return logicalOperatorMap;
};
