import { DateSelection, FunctionParamsProps, FunctionProps } from 'types';

import { getForecastLookbackWindow } from './calculateForecastLookbackDuration';

export const FORECAST_FUNCTIONS = ['forecast'];

export const FORECAST_SEASONAL_OPTIONS = [
  { label: 'hourly', value: '0' },
  { label: 'daily', value: '1' },
  { label: 'weekly', value: '2' },
];

export const getForecastDefaultIntervalBySeasonality = (
  seasonality: string,
): number => {
  if (seasonality === '0') {
    return 10 * 60; // 10 minutes
  }
  if (seasonality === '1') {
    return 20 * 60; // 20 minutes
  }
  if (seasonality === '2') {
    return 60 * 60; // 1 hour
  }
};

export const getForecastSeasonalParams = (): FunctionParamsProps[] => {
  return [
    {
      name: 'seasonality',
      default: '1',
      value: '1',
      options: FORECAST_SEASONAL_OPTIONS,
      type: 'select',
    },
  ];
};

export const getForecastParams = (): FunctionParamsProps[] => {
  const algoOptions: FunctionParamsProps = {
    name: 'algorithm',
    default: 'linear',
    options: [
      { label: 'linear', value: 'linear' },
      { label: 'seasonal', value: 'seasonal' },
    ],
    value: 'linear',
    type: 'select',
  };
  return [algoOptions];
};

const MIN_FORECAST_DURATION = 3600 * 1; // 12 hours

export const getForecastPromQL = (
  func: FunctionProps,
  isRangeVector: boolean,
  query: string,
  steps: number,
  date: DateSelection,
): [string, string] => {
  const [algorithm, seasonality] = func.params;
  const { startTimeUnix, endTimeUnix } = date;
  const seconds = endTimeUnix - startTimeUnix;
  // Ensure we have at least 1 hour of forecast
  const forecastDurationSeconds = Math.max(MIN_FORECAST_DURATION, seconds);
  const forecastLookbackWindow = getForecastLookbackWindow(
    `${forecastDurationSeconds}s`,
  );

  let forecastQuery = '';
  if (algorithm.value === 'linear') {
    forecastQuery = `predict_linear(${query} [${forecastLookbackWindow}], ${forecastDurationSeconds})`;
  }

  if (algorithm.value === 'seasonal') {
    forecastQuery = `holtwinters_seasonal(${query}, ${seasonality.value}, 3, ${forecastDurationSeconds})`;
  }

  return [forecastQuery, query];
};
