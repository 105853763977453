import { SLOProps } from 'types';
import { onPromiseError } from 'utils';

import queryTraceService from './queryTraceService';

const getSloList = async (serviceHash?: string): Promise<SLOProps[]> => {
  return queryTraceService<SLOProps[], 'listSLOs'>(`
    {
      listSLOs(
        ${
          serviceHash
            ? `
          service: {
            hash: "${serviceHash}"
          }
        `
            : ''
        }
      ) {
        id
        name
        type
        service {
          name
          hash
          distinctLabels
          kfType
          labels
        }
        matchers
        latencyThreshold
        objective
        description
        timeWindow
        alertUid
        contactPoints
      }
    }
    `).then((data) => data.listSLOs || null, onPromiseError);
};

export default getSloList;
