import dayjs from 'dayjs';
import { FormulaState, SearchState } from 'hooks/useSearch';
import { DateSelection, SpanFilter, TimeSeries } from 'types';
import { getRollupByVisualization, onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';
import { buildAggregateTimeSeriesFormulaV2 } from './utils';

type Args = {
  date: DateSelection;
  formula: FormulaState;
  instant?: boolean;
  queries: SearchState[];
  spanFilter: SpanFilter;
};

const getGroupBy = (expression: string, queries: SearchState[]): string[] => {
  const queryKeys = queries.map((query) => query.queryKey);
  const usedQueryKey = expression
    .split('')
    .find((word) => queryKeys.includes(word));
  return (
    queries.find((query) => query.queryKey === usedQueryKey)?.groupBys || ['*']
  );
};

const aggregateTimeSeriesFormulaV2 = async ({
  date,
  formula,
  instant = false,
  queries,
  spanFilter,
}: Args): Promise<TimeSeries[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const { expression, isActive, isValid } = formula;
  if (!isActive || isValid !== true) return Promise.resolve([]);

  const usedGroupBys = getGroupBy(expression, queries);
  const formulaExpression = buildAggregateTimeSeriesFormulaV2({
    expression,
    queries,
    spanFilter,
  });

  if (formulaExpression instanceof Error) {
    return Promise.reject({
      message: 'Invalid formula',
      error: formulaExpression,
    });
  }

  return queryTraceService<TimeSeries[], 'aggregateTimeSeries'>(`
    {
      aggregateTimeSeries(
        durationSecs: ${durationSecs}
        formulaExpression: ${formulaExpression}
        groupBy: [${usedGroupBys.map((groupBy) => `"${groupBy}"`).join(',')}]
        rollUpSeconds: ${instant ? durationSecs : getRollupByVisualization(date, 'bar')}
        timestamp: "${endTime.format()}"
      ) {
        BucketStartSecs
        GroupVal
        Value
      }
    }
  `).then((data) => data.aggregateTimeSeries, onPromiseError);
};

export default aggregateTimeSeriesFormulaV2;
