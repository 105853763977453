import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { AiOutlineLineChart } from 'react-icons/ai';
import { ImTable } from 'react-icons/im';
import { DashboardPanelType } from 'types';
import { BsFilterLeft, BsPieChartFill } from 'react-icons/bs';
import { HiTableCells } from 'react-icons/hi2';
import { RumTab } from '../types';

const iconByVisualizeAs: { [key: string]: ReactNode } = {
  [RumTab.list]: <ImTable size={14} />,
  [DashboardPanelType.TIMESERIES]: <AiOutlineLineChart size={14} />,
  [DashboardPanelType.TOP_LIST]: <BsFilterLeft size={14} />,
  [DashboardPanelType.TABLE]: <HiTableCells size={14} />,
  [DashboardPanelType.PIECHART]: <BsPieChartFill size={14} />,
};

const labelByVisualizeAs: { [key: string]: string } = {
  [RumTab.list]: 'List',
  [DashboardPanelType.TIMESERIES]: 'Time Series',
  [DashboardPanelType.TOP_LIST]: 'Top List',
  [DashboardPanelType.TABLE]: 'Table',
  [DashboardPanelType.PIECHART]: 'Pie Chart',
};

const SUPPORTED_VISUALIZE_AS = [
  DashboardPanelType.TIMESERIES,
  DashboardPanelType.TOP_LIST,
  DashboardPanelType.TABLE,
  DashboardPanelType.PIECHART,
];

const RumSearchVisualize = () => {
  const [searchParams] = useSearchParams();
  const searchParamsString = searchParams.toString();

  return (
    <div className="rum-search__visualize button-group">
      {[RumTab.list, ...SUPPORTED_VISUALIZE_AS].map((rumTab) => (
        <NavLink
          className={({ isActive }) =>
            classnames({
              'button-group__item': true,
              'button-group__item--active': isActive,
            })
          }
          key={rumTab}
          to={`/rum/${rumTab}${
            searchParamsString ? `?${searchParamsString}` : ''
          }`}
        >
          <div className="button-group__item__icon">
            {iconByVisualizeAs[rumTab]}
          </div>
          {labelByVisualizeAs[rumTab]}
        </NavLink>
      ))}
    </div>
  );
};

export default RumSearchVisualize;
