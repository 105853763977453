import {
  Button,
  Loader,
  Paginator,
  Table,
  TooltipTrigger,
  useTableSearch,
  useTableSort,
  usePaginator,
  Badge,
  TableBulkActionsCheckbox,
  useTableBulkActions,
  TableBulkActions,
} from 'components';
import { ConfirmationModal, useModalsContext } from 'components/Modals';
import { useRequest } from 'hooks';
import React, { useEffect } from 'react';
import { MdDelete as DeleteIcon, MdModeEdit as EditIcon } from 'react-icons/md';
import { Plus } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { getGrafanaAlertManager } from 'requests';

import { useAlertsContactsDelete } from './hooks';

type ContactPointRowsProps = {
  name: string;
  type: string[];
  receiver: {
    grafana_managed_receiver_configs: Record<string, unknown>[];
  };
  uid: string;
};

const columns = ({
  onDeleteContactPoint,
  onEdit,
  tableBulkActions,
}: {
  onDeleteContactPoint: (name: string) => void;
  onEdit: (val: any) => void;
  tableBulkActions: ReturnType<typeof useTableBulkActions>;
}) => [
  {
    key: 'bulk-action-checkbox',
    label: (
      <TableBulkActionsCheckbox
        tableBulkActions={tableBulkActions}
        isSelectAll
      />
    ),
    renderCell: ({ row }: { row: ContactPointRowsProps }) => (
      <TableBulkActionsCheckbox
        tableBulkActions={tableBulkActions}
        uniqueId={row.name as string}
      />
    ),
  },
  {
    label: 'Contact point name',
    key: 'name',
    renderCell: ({ row }: { row: ContactPointRowsProps }) => {
      const isProvisioned =
        row.receiver?.grafana_managed_receiver_configs[0]?.provenance === 'api';
      return isProvisioned ? (
        <span className="text-gray-500">
          {row.name}{' '}
          <Badge
            variant="secondary"
            className="ml-1 px-1.5 py-0.5 text-2xs font-normal"
          >
            Provisioned
          </Badge>
        </span>
      ) : (
        row.name
      );
    },
  },
  {
    label: 'Type',
    key: 'type',
    renderCell: ({ row }: { row: ContactPointRowsProps }) => {
      return (
        <div className="flex items-center gap-1">
          {row.type.map((type: string) => (
            <Badge key={type} className="font-normal" variant="outline">
              {type}
            </Badge>
          ))}
        </div>
      );
    },
  },
  {
    label: '',
    key: 'actions',
    renderCell: ({ row }: { row: ContactPointRowsProps }) => {
      const provenance =
        row.receiver?.grafana_managed_receiver_configs[0]?.provenance;
      const isProvisioned = provenance === 'api';

      return (
        <div
          className="table__row__actions"
          style={{ '--table-actions-width': '74px' }}
        >
          <div className="table__row__actions--hidden">
            <div className="table__row__actions__slider">
              <div className="flex h-full items-center justify-end">
                {!isProvisioned || row.type !== 'slack' ? (
                  <TooltipTrigger
                    className="table__cell__actions__item--blue"
                    tooltip="Edit"
                  >
                    <EditIcon
                      className="alerts__contacts__table__actions__icon--edit"
                      onClick={() => onEdit(row)}
                      size={18}
                    />
                  </TooltipTrigger>
                ) : null}
                <TooltipTrigger
                  className="table__cell__actions__item--red"
                  tooltip="Delete"
                >
                  <DeleteIcon
                    className="alerts__contacts__table__actions__icon--delete"
                    onClick={() => onDeleteContactPoint(row.name)}
                    size={18}
                  />
                </TooltipTrigger>
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
];
const emptyArray = [];

const AlertsContacts = () => {
  const modal = useModalsContext();
  const navigate = useNavigate();
  const grafanaAlertManagerRequest = useRequest(getGrafanaAlertManager);
  const {
    bulkDeleteContactPoints,
    deleteContactPoint,
    isDeleting,
    mutateContactPointsRequest,
  } = useAlertsContactsDelete();

  useEffect(() => {
    grafanaAlertManagerRequest.call('contact-list');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateContactPointsRequest.result]);

  const contactList =
    (grafanaAlertManagerRequest.result as ContactPointRowsProps[]) ||
    emptyArray;
  const tableSearch = useTableSearch({ rows: contactList });
  const tableBulkActions = useTableBulkActions({
    rows: tableSearch.searchedRows,
    uniqueIdKey: 'name',
  });

  const onEdit = (row: any) => {
    navigate('/alerts/contacts/create', {
      state: row.receiver,
    });
  };

  const onDeleteContactPoint = (name: string) => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description="Are you sure you want to delete this contact point?"
        onCancel={() => modal.pop()}
        onConfirm={() => {
          deleteContactPoint(name);
          modal.pop();
        }}
        title="Delete Contact Point"
      />,
    );
  };

  const onBulkDeleteContactPoint = (uids: string[]) => {
    modal.push(
      <ConfirmationModal
        className="alerts__list__delete-alerts-rule"
        description={`Are you sure you want to delete ${uids.length} contact points? Once deleted, they cannot be recovered.`}
        onCancel={() => modal.pop()}
        onConfirm={() => {
          bulkDeleteContactPoints(uids).then(() => {
            tableBulkActions.clearSelectedRows();
          });
          modal.pop();
        }}
        title="Delete Contact Point"
      />,
    );
  };

  const tableSort = useTableSort({
    columns: columns({ onDeleteContactPoint, onEdit, tableBulkActions }),
    rows: tableSearch.searchedRows,
  });
  const paginator = usePaginator({ rows: tableSort.sortedRows });

  return (
    <div className="container-center">
      <div className="alerts__contacts">
        <div className="alerts__contacts__list__header">
          <h2 className="text-lg font-semibold">Contact Points</h2>
          <Button
            variant="default"
            size="sm"
            onClick={() => navigate('/alerts/contacts/create')}
          >
            <Plus />
            Add New Contact Point
          </Button>
        </div>
        <Loader isLoading={grafanaAlertManagerRequest.isLoading || isDeleting}>
          <div className="alerts__contacts__list">
            <TableBulkActions
              tableBulkActions={tableBulkActions}
              onBulkDelete={onBulkDeleteContactPoint}
              selectedLabel={`contact ${tableBulkActions.selectedRowsCount > 1 ? 'points' : 'point'} selected`}
            />
            <Table
              className="table--bordered table--bordered-cells table__actions--hidden alerts__contacts__table"
              columns={columns({
                onDeleteContactPoint,
                onEdit,
                tableBulkActions,
              })}
              externalTableSort={tableSort}
              isSortingEnabled
              rows={paginator.paginatedRows}
              dataTestId="alerts-contacts-list"
            />
            <div className="table-footer">
              <Paginator paginator={paginator} />
            </div>
            {grafanaAlertManagerRequest.result &&
              grafanaAlertManagerRequest.result.length === 0 && (
                <div className="alerts__contacts__list__empty">
                  <h3>No contact points found</h3>
                  <p>
                    You can create a new contact point by clicking on the button
                    above.
                  </p>
                </div>
              )}
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default AlertsContacts;
