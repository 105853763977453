import {
  CursorStateProvider,
  DateControls,
  SelectV2,
  ServiceWithLabels,
  ShowSidebarTooltipButton,
  Tab,
  Tabs,
  useLeftSidebarState,
} from 'components';
import { Datepicker } from 'composite';
import {
  useDateState,
  useRequest,
  useSelectedFacetValuesByNameState,
  useTracesState,
  useUrlState,
} from 'hooks';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ChevronRight } from 'react-feather';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import { eventsData, traceLabelValuesV2 } from 'requests';
import {
  ChartGridKeysState,
  EventListProps,
  KfPlatform,
  Service as ServiceType,
} from 'types';
import {
  buildPromQLClausesFromSelectedFacetValuesByName,
  getColorsByServiceHash,
  getColorsByServiceName,
} from 'utils';

import { ServicesTab } from 'screens/Services/utils';
import ServiceActiveLogs from './ServiceActiveLogs';
import { ServiceAlertsStatus } from './ServiceAlertsStatus';
import {
  ServiceInfraTabs,
  ServiceTabAnomaly,
  ServiceTabForecastV2,
  ServiceTabOutlier,
} from './ServiceInfraTabs';
import { ServiceReportTabs } from './ServiceReportTabs';
import ServiceRightSidebar from './ServiceRightSidebar';
import ServiceSLOInfo from './ServiceSLOInfo';
import ServiceSummaryGrid from './ServiceSummaryGrid';
import ServiceTab from './ServiceTab';
import ServiceTabErrors from './ServiceTabErrors';
import ServiceTabServiceMap from './ServiceTabServiceMap';
import ServiceTabsMenu from './ServiceTabsMenu';
import ServiceTraces from './ServiceTraces';
import { Property, SidebarState, TelemetrySdk } from './types';
import { asmTabs, getAllowedFacetsByName, getAPMTabs, tabs } from './utils';

const labelsBySubTabProperty: { [key: string]: Property } = tabs.reduce(
  (obj, tab) =>
    tab.subTabs
      ? tab.subTabs.reduce(
          (subObj, subTab) => ({
            ...subObj,
            [subTab.property]: `${subTab.label}`,
          }),
          obj,
        )
      : obj,
  {},
);

const labelsByProperty = tabs.reduce(
  (obj, tab) => ({ ...obj, [tab.property]: tab.label }),
  {},
);

type Props = {
  apmDateState: ReturnType<typeof useDateState>;
  chartGridKeysState: ChartGridKeysState;
  getServicesRequest: ReturnType<typeof useRequest>;
  isAsmChecked: boolean;
  isServiceFromDatabasesList: boolean;
  leftSidebarState: ReturnType<typeof useLeftSidebarState>;
  selectedFacetValuesByNameState: ReturnType<
    typeof useSelectedFacetValuesByNameState
  >;
  service: string;
  serviceDistinctLabels: Record<string, string>;
  serviceByHash: Record<string, ServiceType>;
  serviceHash: string;
  serviceLabels: Record<string, string>;
  telemetrySdkName: string;
};

const getServicesPathname = ({
  isAsmChecked,
  isServiceFromDatabasesList,
}: {
  isAsmChecked: boolean;
  isServiceFromDatabasesList: boolean;
}) => {
  if (isServiceFromDatabasesList) {
    return '/apm/services/databases';
  }

  if (isAsmChecked) {
    return '/advanced-service-monitoring';
  }

  return '/apm/services';
};

const Service = ({
  apmDateState,
  chartGridKeysState,
  getServicesRequest,
  isAsmChecked,
  isServiceFromDatabasesList,
  leftSidebarState,
  selectedFacetValuesByNameState,
  service,
  serviceDistinctLabels,
  serviceByHash,
  serviceHash,
  serviceLabels,
  telemetrySdkName,
}: Props) => {
  const navigate = useNavigate();
  const tabsState = useUrlState('tabs', 0);
  const [activeTabIndex, setActiveTabIndex] = tabsState;

  const colorsByServiceHash = useMemo(
    () =>
      getColorsByServiceHash(
        (getServicesRequest.result as ServiceType[]) || [],
      ),
    [getServicesRequest.result],
  );

  const colorsByServiceName = useMemo(
    () =>
      getColorsByServiceName(
        (getServicesRequest.result as ServiceType[]) || [],
      ),
    [getServicesRequest.result],
  );

  const versionEventsRequest = useRequest(eventsData);
  const telemetrySdkLanguageRequest = useRequest(traceLabelValuesV2);
  const [sidebar, setSidebar] = useState<SidebarState>();
  const [property, setProperty] = useUrlState<Property>(
    'property',
    isAsmChecked ? Property.serviceMap : Property.spanName,
  );

  const eventListRef = useRef<EventListProps[] | null>(null);
  const tracesState = useTracesState({
    dateState: apmDateState,
    shouldWriteToUrl: false,
  });
  const { dateState } = tracesState;
  const [date, setDate] = dateState;

  const kfSourceObj = selectedFacetValuesByNameState.state['kf_source'];
  const kfSource = Object.keys(kfSourceObj)[0];

  const nextSelectedFacetValues = { ...selectedFacetValuesByNameState.state };
  Object.keys(serviceDistinctLabels || {}).forEach((label) => {
    delete nextSelectedFacetValues[label];
  });

  const onChange = (service: ServiceType) => {
    const { hash } = service;

    navigate(
      `/apm/services/${hash}?${createSearchParams({
        date: JSON.stringify(date),
        ...(isServiceFromDatabasesList ? { activeTab: ServicesTab.db } : {}),
      }).toString()}`,
    );
  };

  useEffect(() => {
    telemetrySdkLanguageRequest.call({
      date,
      name: 'telemetry_sdk_language',
      selectedFacetValuesByName: selectedFacetValuesByNameState.state,
      serviceHash,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, selectedFacetValuesByNameState.state, service, serviceHash]);

  useEffect(() => {
    if (!isAsmChecked) return;

    const isPropertyFromAsmTabs = asmTabs.some(
      (tab) => tab.property === property,
    );
    if (!isPropertyFromAsmTabs) {
      setProperty(Property.serviceMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAsmChecked]);

  const asmMatcher = useMemo(() => {
    const matcherObj = {
      ...selectedFacetValuesByNameState.state,
    };

    Object.keys(serviceLabels).forEach((label) => {
      matcherObj[label] = { [serviceLabels[label]]: 1 };
    });

    if (kfSource === 'apm' || kfSource === 'knight') {
      matcherObj['service_name'] = { [service]: 1 };
    }
    delete matcherObj['kf_source'];
    const clauses = buildPromQLClausesFromSelectedFacetValuesByName({
      selectedFacetValuesByName: matcherObj,
    });

    return clauses.length > 0 ? `${clauses.join(',')}` : '';
  }, [kfSource, selectedFacetValuesByNameState.state, service, serviceLabels]);

  const runtimeMatcher = useMemo(() => {
    const matcherObj = {
      ...selectedFacetValuesByNameState.state,
    };

    Object.keys(serviceLabels).forEach((label) => {
      matcherObj[label] = { [serviceLabels[label]]: 1 };
    });

    if (kfSource === 'apm' || kfSource === 'knight') {
      if (telemetrySdkName === TelemetrySdk.datadog) {
        matcherObj['service'] = { [service]: 1 };
        delete matcherObj['cloud_account_id'];
      } else {
        matcherObj['service_name'] = { [service]: 1 };
      }
    }
    delete matcherObj['kf_source'];

    const clauses = buildPromQLClausesFromSelectedFacetValuesByName({
      selectedFacetValuesByName: matcherObj,
    });

    return clauses.length > 0 ? `${clauses.join(',')}` : '';
  }, [
    kfSource,
    selectedFacetValuesByNameState.state,
    service,
    serviceLabels,
    telemetrySdkName,
  ]);

  const filteredTabs = useMemo(() => {
    if (kfSource !== 'apm') return asmTabs;
    const language = telemetrySdkLanguageRequest.result?.[0]?.groupVal;
    const telemetrySdkLanguage = language?.telemetry_sdk_language;
    const resApmTabs = getAPMTabs({
      language: telemetrySdkLanguage,
      sdk: telemetrySdkName,
      isServiceFromDatabasesList,
      kfPlatform: serviceLabels.kf_platform as KfPlatform,
    });
    return resApmTabs;
  }, [
    kfSource,
    telemetrySdkLanguageRequest.result,
    telemetrySdkName,
    isServiceFromDatabasesList,
    serviceLabels.kf_platform,
  ]);

  const onTabHeaderClick = useCallback(
    (i: number) => {
      const tab = filteredTabs[i];
      if (!tab.property) return;
      setProperty(tab.property);
      setActiveTabIndex(i);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredTabs, tabsState],
  );

  useEffect(() => {
    if (isAsmChecked) {
      return;
    }
    const allowedFacetsFromSelectedFacets = getAllowedFacetsByName(
      selectedFacetValuesByNameState?.state,
    );
    versionEventsRequest
      .call({
        date,
        selectedFacetValuesByName: {
          ...allowedFacetsFromSelectedFacets,
          service_hash: { [serviceHash]: 1 },
          source_type_name: { kfuse_ingester: 1 },
        },
        searchTerms: [],
      })
      .then((data) => {
        eventListRef.current = data;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceHash, date, selectedFacetValuesByNameState?.state]);

  const getTab = (tab, key) => {
    switch (property) {
      case Property.traces:
        return (
          <ServiceTraces
            colorsByServiceHash={colorsByServiceHash}
            colorsByServiceName={colorsByServiceName}
            date={date}
            isServiceFromDatabasesList={isServiceFromDatabasesList}
            key={key}
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            serviceHash={serviceHash}
            setSidebar={setSidebar}
          />
        );
      case Property.errors:
        return (
          <ServiceTabErrors
            colorsByServiceHash={colorsByServiceHash}
            isServiceFromDatabasesList={isServiceFromDatabasesList}
            serviceName={service}
            serviceHash={serviceHash}
            serviceByHash={serviceByHash}
            tracesState={tracesState}
          />
        );
      case Property.anomaly:
        return (
          <ServiceTabAnomaly
            asmMatcher={asmMatcher}
            date={date}
            kfSource={kfSource}
          />
        );
      case Property.outlier:
        return (
          <ServiceTabOutlier
            asmMatcher={asmMatcher}
            date={date}
            kfSource={kfSource}
          />
        );
      case Property.forecast:
        return (
          <ServiceTabForecastV2
            asmMatcher={asmMatcher}
            date={date}
            kfSource={kfSource}
          />
        );
      case Property.serviceMap:
        return (
          <ServiceTabServiceMap
            colorsByServiceHash={colorsByServiceHash}
            date={date}
            serviceHash={serviceHash}
            serviceByHash={serviceByHash}
            selectedFacetValuesByName={selectedFacetValuesByNameState.state}
          />
        );
      case Property.downstreamDependencies: {
        if (!getServicesRequest.result) {
          return null;
        }

        return (
          <ServiceTab
            chartGridKeysState={chartGridKeysState}
            date={date}
            isDownStream
            key={`${key}-downstream`}
            label="Downstream Dependencies"
            property="service_hash"
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            service={service}
            serviceHash={serviceHash}
            setDate={setDate}
            setSidebar={setSidebar}
            serviceByHash={serviceByHash}
            setProperty={setProperty}
          />
        );
      }
      case Property.upstreamDependencies: {
        if (!getServicesRequest.result) {
          return null;
        }

        return (
          <ServiceTab
            chartGridKeysState={chartGridKeysState}
            date={date}
            key={`${key}-upstream`}
            label="Upstream Dependencies"
            property="client_service_hash"
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            service={service}
            serviceByHash={serviceByHash}
            serviceHash={serviceHash}
            setDate={setDate}
            setSidebar={setSidebar}
            setProperty={setProperty}
          />
        );
      }
      case Property.spanName:
      case Property.version: {
        if (!getServicesRequest.result) {
          return null;
        }

        return (
          <ServiceTab
            chartGridKeysState={chartGridKeysState}
            date={date}
            isServiceFromDatabasesList={isServiceFromDatabasesList}
            key={key}
            label={
              labelsByProperty[tab.property as keyof typeof labelsByProperty]
            }
            property={tab.property}
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            service={service}
            serviceByHash={serviceByHash}
            serviceHash={serviceHash}
            setDate={setDate}
            setSidebar={setSidebar}
            setProperty={setProperty}
          />
        );
      }
      case Property.infraPod:
      case Property.infraNode:
      case Property.infraVolume:
      case Property.infraDockerContainer:
      case Property.infraDockerNode:
        return (
          <ServiceInfraTabs
            asmMatcher={asmMatcher}
            date={date}
            kfSource={kfSource}
            kfPlatform={serviceLabels.kf_platform as KfPlatform}
            property={property}
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
          />
        );
      case Property.infraRuntimeGo:
      case Property.infraRuntimeJava:
      case Property.infraRuntimePython:
      case Property.infraRuntimeNodejs:
      case Property.infraRuntimeDatadogGo:
      case Property.infraRuntimeDatadogJava:
      case Property.infraRuntimeDatadogNodejs:
        return (
          <ServiceInfraTabs
            asmMatcher={runtimeMatcher}
            date={date}
            kfSource={kfSource}
            kfPlatform={serviceLabels.kf_platform as KfPlatform}
            property={property}
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
          />
        );
      case Property.reportPerformance:
      case Property.reportSLA:
        return (
          <ServiceReportTabs
            asmMatcher={asmMatcher}
            date={date}
            kfSource={kfSource}
            property={property}
          />
        );
      case Property.logs:
        return (
          <ServiceActiveLogs
            selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            date={date}
            service={service}
            serviceLabels={serviceLabels}
            kfPlatform={serviceLabels?.kf_platform as KfPlatform}
            serviceHash={serviceHash}
          />
        );
      default:
        return null;
    }
  };

  const isKFSourceKnight = useMemo(() => {
    return kfSource === 'knight';
  }, [kfSource]);

  return (
    <div className="service">
      <div className="service__header">
        <div className="service__header__top">
          <div className="service__header__left">
            {leftSidebarState.width === 0 ? (
              <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
            ) : null}
            <div className="breadcrumbs">
              <div className="breadcrumbs__item" data-testid="services-link">
                <Link
                  to={{
                    pathname: getServicesPathname({
                      isAsmChecked,
                      isServiceFromDatabasesList,
                    }),
                    search: createSearchParams({
                      date: JSON.stringify(date),
                      selectedFacetValues: JSON.stringify(
                        nextSelectedFacetValues,
                      ),
                    }).toString(),
                  }}
                >
                  {isServiceFromDatabasesList ? 'Databases' : 'Services'}
                </Link>
              </div>
              <div className="breadcrumbs__chevron">
                <ChevronRight size={18} />
              </div>
              <div
                className="breadcrumbs__item breadcrumbs__item--active"
                data-testid="service-name"
              >
                <SelectV2.Select
                  className="service__header__title select--naked"
                  getSearchedValue={(value: ServiceType) =>
                    `${value.name} ${Object.values(
                      value.distinctLabels || {},
                    ).join(' ')}`
                  }
                  isAutocompleteEnabled
                  onChange={onChange}
                  options={(getServicesRequest.result || [])
                    .filter((service: ServiceType) =>
                      isServiceFromDatabasesList
                        ? service.kfType == 'database'
                        : service.kfType == 'service',
                    )
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((service: ServiceType) => ({
                      label: (
                        <ServiceWithLabels
                          color={colorsByServiceHash[service.hash]}
                          name={service.name}
                          distinctLabels={service.distinctLabels || {}}
                          labels={service.labels}
                        />
                      ),
                      value: service,
                    }))}
                  renderValue={() => (
                    <ServiceWithLabels
                      color={colorsByServiceHash[serviceHash]}
                      name={service}
                      distinctLabels={serviceDistinctLabels || {}}
                      labels={serviceLabels}
                    />
                  )}
                  value={service}
                  dataTestId="service-name"
                />
              </div>
              {!isServiceFromDatabasesList && (
                <>
                  {!isKFSourceKnight && (
                    <ServiceSLOInfo
                      isServiceUnique={true}
                      kfSource={kfSource}
                      serviceName={service}
                      serviceHash={serviceHash}
                    />
                  )}
                  <ServiceAlertsStatus
                    asmMatcher={asmMatcher}
                    date={date}
                    kfSource={kfSource}
                    serviceName={service}
                    serviceDistinctLabels={serviceDistinctLabels}
                    serviceHash={serviceHash}
                    serviceLabels={serviceLabels}
                  />
                </>
              )}
            </div>
          </div>
          <div className="service__header__right">
            <Datepicker onChange={setDate} value={date} />
            <DateControls date={date} setDate={setDate} />
          </div>
        </div>
      </div>
      <CursorStateProvider>
        <div className="service__main">
          <div className="service__section">
            <ServiceSummaryGrid
              chartGridKeysState={chartGridKeysState}
              date={date}
              eventListRef={eventListRef}
              hideExecutionBreakDown={isServiceFromDatabasesList}
              isServiceFromDatabasesList={isServiceFromDatabasesList}
              kfSource={kfSource}
              service={service}
              serviceDistinctLabels={serviceDistinctLabels}
              serviceHash={serviceHash}
              selectedFacetValuesByName={selectedFacetValuesByNameState.state}
              setDate={setDate}
              setSidebar={setSidebar}
            />
          </div>
          <Tabs
            className="tabs--underline"
            tabs={{
              activeIndex: activeTabIndex,
              setActiveIndex: setActiveTabIndex,
            }}
            onClick={onTabHeaderClick}
            dataTestId="service-subtabs"
          >
            {filteredTabs.map((tab, i) => (
              <Tab
                key={i}
                label={
                  <div className="header__nav__item">
                    <div>{tab.label}</div>
                    {!tab.property && tab.subTabs && (
                      <ServiceTabsMenu
                        subTabs={tab.subTabs || []}
                        onClick={(pr) => {
                          setProperty(pr);
                          setActiveTabIndex(i);
                        }}
                      />
                    )}
                  </div>
                }
              >
                <>
                  {Boolean(
                    labelsBySubTabProperty && labelsBySubTabProperty[property],
                  ) && (
                    <div className="text-base">
                      <div className="py-1">
                        {labelsBySubTabProperty[property]}
                      </div>
                    </div>
                  )}
                  {getServicesRequest.calledAtLeastOnce ? getTab(tab, i) : null}
                </>
              </Tab>
            ))}
          </Tabs>
        </div>
      </CursorStateProvider>
      <ServiceRightSidebar
        chartGridKeysState={chartGridKeysState}
        colorsByServiceHash={colorsByServiceHash}
        colorsByServiceName={colorsByServiceName}
        sidebar={sidebar}
        setSidebar={setSidebar}
        tracesState={{ ...tracesState, selectedFacetValuesByNameState }}
      />
    </div>
  );
};

export default Service;
