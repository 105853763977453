import { AutocompleteV2 } from 'components';
import React from 'react';
import useRumState from './hooks/useRumState';
import { RumEventType } from './types';

type Props = {
  rumState: ReturnType<typeof useRumState>;
};

const readableEventType = (eventType: RumEventType) => {
  switch (eventType) {
    case RumEventType.ACTION:
      return 'Actions';
    case RumEventType.VIEW:
      return 'Views';
    default:
      return eventType;
  }
};

const EventTypeFilter = ({ rumState }: Props) => {
  const { eventType, setEventType } = rumState;
  return (
    <div className="flex flex-row items-center">
      <div className="event-filter__item">
        <AutocompleteV2
          onChange={(val: string) => setEventType(val as RumEventType)}
          options={Object.values(RumEventType).map((filter) => ({
            label: readableEventType(filter),
            value: filter,
          }))}
          value={eventType}
        />
      </div>
    </div>
  );
};

export default EventTypeFilter;
