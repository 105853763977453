import { Button, TooltipTrigger } from 'components';
import { useRequest } from 'hooks';
import React, { ReactElement, useEffect } from 'react';
import { RefreshCcw as RefreshIcon } from 'react-feather';

import { HydrationJobProps, JobStatus } from './types';
import { jobProgress } from './requests';

const LogsHydrationJobsListJobProgress = ({
  job,
}: {
  job: HydrationJobProps;
}): ReactElement => {
  const jobProgressRequest = useRequest(jobProgress);

  useEffect(() => {
    if (
      job.JobStatus === JobStatus.HYDRATING ||
      job.JobStatus === JobStatus.INIT
    ) {
      jobProgressRequest.call(job.JobId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job.JobId, job.JobStatus]);

  if (
    job.JobStatus !== JobStatus.HYDRATING &&
    job.JobStatus !== JobStatus.INIT
  ) {
    return null;
  }

  return (
    <div>
      {jobProgressRequest.isLoading ? (
        <div className="flex w-full items-center justify-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="flex items-center justify-between">
          <TooltipTrigger
            tooltip={
              <div className="flex flex-col p-1">
                <div className="text-sm">
                  Total objects: {jobProgressRequest.result?.totalObjects}
                </div>
                <div className="text-sm">
                  Total objects processed:{' '}
                  {jobProgressRequest.result?.TotalObjectsProcessed}
                </div>
                <div className="text-sm">
                  Total records filtered:{' '}
                  {jobProgressRequest.result?.TotalRecordsFiltered}
                </div>
                <div className="text-sm">
                  Total records processed:{' '}
                  {jobProgressRequest.result?.TotalRecordsProcessed}
                </div>
              </div>
            }
          >
            <div className="text-sm">
              {jobProgressRequest.result ? (
                <>
                  Proccessed {jobProgressRequest.result?.TotalObjectsProcessed}{' '}
                  / {jobProgressRequest.result?.totalObjects}
                </>
              ) : null}
            </div>
          </TooltipTrigger>
          <div className="flex justify-end">
            <TooltipTrigger tooltip="Refresh">
              <Button
                className="h-7 p-1"
                variant="link"
                onClick={() => jobProgressRequest.call(job.JobId)}
              >
                <RefreshIcon size={14} />
              </Button>
            </TooltipTrigger>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogsHydrationJobsListJobProgress;
