import classnames from 'classnames';
import React, { ReactNode } from 'react';
import * as Shadcn from '../shadcn';
import useTabs from './useTabs';

type Props = {
  children?: ReactNode;
  className?: string;
  onChange?: (i: number) => void;
  onClick?: (i: number) => void;
  tabs: ReturnType<typeof useTabs>;
  tabsContentClassName?: string;
  tabsTriggerClassName?: string;
  dataTestId?: string;
};

const Tabs = ({
  children,
  className,
  onChange,
  onClick,
  tabs,
  tabsContentClassName,
  tabsTriggerClassName,
  dataTestId,
}: Props) => {
  const { activeIndex, setActiveIndex } = tabs;

  const onClickHandler = (i: string) => {
    if (onClick) {
      onClick(i as unknown as number);
      return;
    }
    if (onChange) {
      onChange(i as unknown as number);
    }

    setActiveIndex(i as unknown as number);
  };

  return (
    <>
      <Shadcn.Tabs
        value={`${activeIndex}`}
        onValueChange={onClickHandler}
        className={classnames({ tabs: true, [className]: className })}
      >
        <Shadcn.TabsList className="tabs__buttons" data-testid={dataTestId}>
          {React.Children.map(children, (child, index: number) => {
            if (child) {
              return (
                <Shadcn.TabsTrigger
                  key={child.props.label}
                  value={`${index}`}
                  disabled={child.props.disabled}
                  className={tabsTriggerClassName}
                >
                  {child.props.label}
                </Shadcn.TabsTrigger>
              );
            }
            return null;
          })}
        </Shadcn.TabsList>
        <Shadcn.TabsContent
          value={`${activeIndex}`}
          className={classnames({
            tabs__panel: true,
            [tabsContentClassName]: tabsContentClassName,
          })}
          data-testid={dataTestId + '-content'}
        >
          {React.Children.toArray(children)[activeIndex] || null}
        </Shadcn.TabsContent>
      </Shadcn.Tabs>
    </>
  );
};

export default Tabs;
