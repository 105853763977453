import {
  ChartToolbar,
  TimeseriesRenderer,
  TooltipTrigger,
  useCursorContextState,
  useThemeContext,
} from 'components';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { MdLegendToggle } from 'react-icons/md';
import {
  DateSelection,
  ExplorerQueryProps,
  FormulaProps,
  LegendTypes,
} from 'types';
import {
  combineRangeQueryData,
  calculateForecastLookbackDuration,
  drawForecastVerticalLine,
  drawForecastSeriesByIndex,
  getForecastLookbackAndPredictDate,
} from 'utils';

import {
  ConditionProps,
  ForecastConditionProps,
} from '../AlertsCreateCondition';
import { getPromQlQuery } from '../utils';
import useAlertsForecastDataLoader from './useAlertsForecastDataLoader';

const AlertChartRightToolbar = ({
  isActive,
  onClick,
}: {
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <div className="new-metrics__chart__right-toolbar__icon" onClick={onClick}>
      <TooltipTrigger tooltip={`${isActive ? 'Hide' : 'Show'} Legends`}>
        <MdLegendToggle />
      </TooltipTrigger>
    </div>
  );
};

const defaultChartTypes = ['Line'];

const AlertsChartForecast = ({
  baseWidth,
  condition,
  date,
  forecastCondition,
  formulas,
  queries,
  queryKey,
}: {
  baseWidth: number;
  condition: ConditionProps;
  date: DateSelection;
  forecastCondition: ForecastConditionProps;
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
  queryKey: string;
}): ReactElement => {
  const { cursorState, setCursorState } = useCursorContextState();
  const forecastDataLoader = useAlertsForecastDataLoader({
    condition,
    forecastCondition,
  });
  const {
    breakpoint,
    evalLegendToggle,
    evaluationData,
    histLegendToggle,
    historicalData,
    loadForecastData,
    loadHistoricalData,
    userActions,
  } = forecastDataLoader;
  const { darkModeEnabled } = useThemeContext();

  const { forecastAlgorithm, interval, forecastDuration } = forecastCondition;
  const dateForChart = useMemo(() => {
    const lookbackDuration =
      calculateForecastLookbackDuration(forecastDuration);
    return getForecastLookbackAndPredictDate({
      forecastDuration,
      lookbackDuration,
      forecastInterval: Number(interval),
    });
  }, [forecastDuration, interval]);

  const promqlWithMeta = useMemo(
    () =>
      dateForChart &&
      getPromQlQuery({
        formulas,
        queryKey,
        queries,
        date: dateForChart.lookbackDate,
      }),
    [formulas, queries, queryKey, dateForChart],
  );

  useEffect(() => {
    if (!promqlWithMeta) return;
    const { promql } = promqlWithMeta;
    if (!promql || typeof promql !== 'string') return;
    loadHistoricalData({ dateForChart, promqlWithMeta }).then((data) => {
      userActions.current.initLoaded = true;
    });
    loadForecastData({ dateForChart, forecastAlgorithm, promqlWithMeta });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promqlWithMeta]);

  useEffect(() => {
    if (!userActions.current.initLoaded) return;
    if (!promqlWithMeta) return;
    const { promql } = promqlWithMeta;
    if (!promql || typeof promql !== 'string') return;

    loadForecastData({ forecastAlgorithm, dateForChart, promqlWithMeta });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastAlgorithm]);

  const histCombinedData = useMemo(() => {
    return combineRangeQueryData({
      formulas: formulas.map((formula) => ({
        queryKey: formula.queryKey,
        isActive: formula.isActive,
      })),
      queries: queries.map((query) => ({
        queryKey: query.queryKey,
        isActive: query.isActive,
      })),
      queryData: historicalData,
      darkModeEnabled,
    });
  }, [historicalData, darkModeEnabled, formulas, queries]);

  const evalCombinedData = useMemo(() => {
    return combineRangeQueryData({
      formulas: formulas.map((formula) => ({
        queryKey: formula.queryKey,
        isActive: formula.isActive,
      })),
      queries: queries.map((query) => ({
        queryKey: query.queryKey,
        isActive: query.isActive,
      })),
      queryData: evaluationData,
      darkModeEnabled,
    });
  }, [evaluationData, darkModeEnabled, formulas, queries]);

  const forecastHooks = useMemo(() => {
    if (!evaluationData || evalCombinedData?.series?.length === 0) {
      return [];
    }

    const hooks = [
      {
        type: 'draw',
        hook: (u: uPlot) => {
          drawForecastVerticalLine({
            u,
            darkModeEnabled,
            forecastDuration,
            breakPoint: breakpoint,
          });
        },
      },
      {
        type: 'drawSeries',
        hook: (u: uPlot, seriesIndex: number) => {
          drawForecastSeriesByIndex({
            u,
            seriesIndex,
            breakPoint: breakpoint,
          });
        },
      },
    ];

    if (evalCombinedData?.hooks[2]) {
      hooks.push(evalCombinedData.hooks[2]);
    }
    return hooks;
  }, [
    evaluationData,
    evalCombinedData,
    darkModeEnabled,
    forecastDuration,
    breakpoint,
  ]);

  return (
    <div className="alerts__chart__forecast">
      <TimeseriesRenderer
        chartData={histCombinedData}
        chartTypes={defaultChartTypes}
        cursorState={cursorState}
        date={date || null}
        isLoading={histCombinedData.isLoading}
        chartKey="alerts-chart"
        legend={{
          legendType: histLegendToggle.value
            ? LegendTypes.COMPACT_ONE_LINE
            : 'none',
          legendHeight: 120,
        }}
        onCursorStateChange={setCursorState}
        size={{ height: 280, width: baseWidth / 2 - 32 }}
        renderToolbar={({ activeChart, setActiveChart }) => (
          <ChartToolbar
            activeChart={activeChart}
            chartTypes={['Line']}
            setActiveChart={setActiveChart}
            toolbar={{
              rightToolbar: (
                <AlertChartRightToolbar
                  isActive={histLegendToggle.value}
                  onClick={() => histLegendToggle.toggle()}
                />
              ),
              leftToolbar: <div className="text--h3">Historical View</div>,
            }}
          />
        )}
        tooltipType="compact"
        unit="number"
      />
      <TimeseriesRenderer
        bands={evalCombinedData.bands || []}
        chartData={evalCombinedData}
        chartTypes={defaultChartTypes}
        cursorState={cursorState}
        date={date || null}
        isLoading={evalCombinedData.isLoading}
        chartKey="forecast-chart"
        legend={{
          legendType: evalLegendToggle.value
            ? LegendTypes.COMPACT_ONE_LINE
            : 'none',
          legendHeight: 120,
        }}
        onCursorStateChange={setCursorState}
        hooks={forecastHooks}
        size={{ height: 280, width: baseWidth / 2 - 32 }}
        renderToolbar={({ activeChart, setActiveChart }) => (
          <ChartToolbar
            activeChart={activeChart}
            chartTypes={['Line']}
            setActiveChart={setActiveChart}
            toolbar={{
              rightToolbar: (
                <AlertChartRightToolbar
                  isActive={evalLegendToggle.value}
                  onClick={() => evalLegendToggle.toggle()}
                />
              ),
              leftToolbar: <div className="text--h3">Forecast View</div>,
            }}
          />
        )}
        tooltipType="compact"
        unit="number"
      />
    </div>
  );
};

export default AlertsChartForecast;
