import { Dispatch, SetStateAction } from 'react';
import {
  ExplorerQueryProps,
  FormulaProps,
  FunctionParamsProps,
  VectorTypes,
} from 'types/MetricsQueryBuilder';
import { DateSelection } from 'types/DateSelection';
import {
  AGGREGATE_FUNCTIONS,
  checkIfQueryHasAnomaly,
  getFunctionParams,
  anomalyProphetModelParams,
  getForecastSeasonalParams,
} from 'utils';

import useMetricsDataState from './useMetricsDataState';

const useMetricsFunctionState = ({
  checkAndLoadedAffectedFormulas,
  formulas,
  queries,
  metricsDataState,
  setQueries,
  date,
}: {
  checkAndLoadedAffectedFormulas: (
    queryIndex: number,
    newQueries: ExplorerQueryProps[],
    callType: 'normal' | 'debounce',
  ) => void;
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
  metricsDataState: ReturnType<typeof useMetricsDataState>;
  setQueries: Dispatch<SetStateAction<ExplorerQueryProps[]>>;
  date: DateSelection;
}) => {
  const { callOnePromqlQuery } = metricsDataState;

  const addFunction = (
    queryIndex: number,
    functionName: string,
    vectorType: VectorTypes,
  ) => {
    setQueries((prevQueries: ExplorerQueryProps[]) => {
      const newQueries = [...prevQueries];
      const { metric, functions } = newQueries[queryIndex];
      const functionParams = getFunctionParams(functionName);

      const funcObj = {
        name: functionName,
        params: functionParams,
        vectorType,
      };
      const newFunctions = [...functions];
      if (checkIfQueryHasAnomaly(newQueries[queryIndex])) {
        newFunctions.splice(newFunctions.length - 1, 0, funcObj);
      } else {
        newFunctions.push(funcObj);
      }
      newQueries[queryIndex].functions = newFunctions;

      if (metric !== '') {
        if (newQueries[queryIndex].isActive) {
          callOnePromqlQuery({
            callType: null,
            date,
            formulas,
            queryIndex,
            queries,
            type: 'query',
          });
        }
      }
      return newQueries;
    });
  };

  const updateFunction = (
    queryIndex: number,
    fnIndex: number,
    paramIndex: number,
    value: any,
  ) => {
    setQueries((prevQueries: ExplorerQueryProps[]) => {
      const newQueries = [...prevQueries];
      const { metric, functions } = newQueries[queryIndex];
      const newFunctions = [...functions];

      const newParams = [...newFunctions[fnIndex].params];
      if (
        AGGREGATE_FUNCTIONS.includes(newFunctions[fnIndex].name) &&
        paramIndex === 0
      ) {
        const aggregationParams = updatedParamsOnAggregateChange(
          newParams,
          value,
        );
        newFunctions[fnIndex].params = aggregationParams;
      }

      if (newFunctions[fnIndex].name === 'anomalies' && paramIndex === 0) {
        const anomalyParams = updatedParamsOnAnomalyChange(newParams, value);
        newFunctions[fnIndex].params = anomalyParams;
      }

      if (newFunctions[fnIndex].name === 'forecast') {
        const forecastParams = updatedParamsOnForecastChange(newParams, value);
        newFunctions[fnIndex].params = forecastParams;
      }

      newFunctions[fnIndex].params[paramIndex].value = value;
      newQueries[queryIndex].functions = newFunctions;

      if (metric === '') {
        return newQueries;
      }

      if (newQueries[queryIndex].isActive) {
        if (newFunctions[fnIndex].params[paramIndex].type === 'text') {
          callOnePromqlQuery({
            callType: 'debounce',
            date,
            formulas,
            queryIndex,
            queries: newQueries,
            type: 'query',
          });
        } else {
          callOnePromqlQuery({
            callType: null,
            date,
            formulas,
            queryIndex,
            queries: newQueries,
            type: 'query',
          });
        }
      }
      checkAndLoadedAffectedFormulas(queryIndex, newQueries, 'normal');
      return newQueries;
    });
  };

  const updatedParamsOnAnomalyChange = (
    newParams: FunctionParamsProps[],
    value: any,
  ): FunctionParamsProps[] => {
    if (value === 'agile') {
      newParams = newParams.filter((item) => item.name !== 'window');
    }

    if (value === 'agile-robust') {
      newParams = newParams.filter((item) => item.name !== 'window');
      newParams.splice(1, 0, ...anomalyProphetModelParams);
    }

    if (value === 'basic' || value === 'robust') {
      newParams = newParams.filter((item) => item.name !== 'seasonality');
    }

    const isWindowParamExist = newParams.find(
      (param) => param.name === 'window',
    );

    if (!isWindowParamExist && value !== 'agile' && value !== 'agile-robust') {
      const anomalyParam = getFunctionParams('anomalies');
      const window = anomalyParam.find((param) => param.name === 'window');
      // insert window param at index 1
      newParams.splice(1, 0, window);
    }

    if (value === 'robust') {
      return newParams;
    }

    return newParams.filter((param) => param.name !== 'model');
  };

  const updatedParamsOnAggregateChange = (
    newParams: FunctionParamsProps[],
    value: any,
  ) => {
    if (value === 'quantile') {
      newParams.push({
        name: 'quantile',
        default: 0.99,
        value: 0.99,
        type: 'text',
      });
    } else {
      newParams = newParams.filter((param) => param.name !== 'quantile');
    }
    return newParams;
  };

  const updatedParamsOnForecastChange = (
    newParams: FunctionParamsProps[],
    value: string,
  ) => {
    if (value === 'seasonal') {
      newParams.push(...getForecastSeasonalParams());
    }

    if (value === 'linear') {
      newParams = newParams.filter((param) => param.name !== 'seasonality');
    }
    return newParams;
  };

  const removeFunction = (queryIndex: number, functionIndex: number) => {
    setQueries((prevQueries: ExplorerQueryProps[]) => {
      const newQueries = [...prevQueries];
      const { metric, functions } = newQueries[queryIndex];
      const newFunctions = [...functions];
      newFunctions.splice(functionIndex, 1);
      newQueries[queryIndex].functions = newFunctions;

      if (metric !== '' && newQueries[queryIndex].isActive) {
        callOnePromqlQuery({
          callType: null,
          date,
          formulas,
          queryIndex,
          queries: newQueries,
          type: 'query',
        });
      }
      return newQueries;
    });
  };

  return { addFunction, removeFunction, updateFunction };
};

export default useMetricsFunctionState;
