import { useRequest } from 'hooks';
import React, { ReactElement } from 'react';
import { getFacetNamesByCursor } from 'requests';
import { DateSelection, LogsMetricQueryProps } from 'types';
import LogsMetricsQueryBuilderSearchInner from './LogsMetricsQueryBuilderSearchInner';
import useDebouncedEffect from 'use-debounced-effect';

const LogsMetricsQueryBuilderSearch = ({
  date,
  handleFilterChange,
  query,
}: {
  date: DateSelection;
  handleFilterChange: VoidFunction;
  query: LogsMetricQueryProps;
}): ReactElement => {
  const getFacetNamesRequest = useRequest(getFacetNamesByCursor);

  useDebouncedEffect(
    () => {
      handleFilterChange();
    },
    300,
    [query.filtersState.state],
  );

  return (
    <LogsMetricsQueryBuilderSearchInner
      date={date}
      filtersState={query.filtersState}
      getFacetNamesRequest={getFacetNamesRequest}
    />
  );
};

export default LogsMetricsQueryBuilderSearch;
