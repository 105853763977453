import dayjs from 'dayjs';
import { DateSelection, ValueCount, SelectedFacetValuesByName } from 'types';
import { onPromiseError } from 'utils';
import queryRumService from './queryRumService';
import { buildRumFilter } from './utils';
import { RumEventType } from '../types';

type Args = {
  date: DateSelection;
  eventType: RumEventType;
  idSearch?: string;
  labelName: string;
  selectedFacetValuesByName?: SelectedFacetValuesByName;
};

const rumLabelValues = async ({
  date,
  eventType,
  idSearch,
  labelName,
  selectedFacetValuesByName,
}: Args): Promise<ValueCount[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  return queryRumService<ValueCount[], 'attributeValues'>(`
  query {
    attributeValues(
      eventType: ${eventType},
      timestamp: "${endTime.format()}",
      durationSecs: ${durationSecs},
      filter: ${buildRumFilter({ idSearch, selectedFacetValuesByName })},
      field: "${labelName}"
    ) {
      value,
      count,
    }
  }
  `).then((data) => data.attributeValues || [], onPromiseError);
};

export default rumLabelValues;
