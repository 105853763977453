import { ChartGridV2 } from 'components';
import React, { useMemo } from 'react';
import { DateSelection, SelectedFacetValuesByName, Service } from 'types';
import ServiceDependencyGraph from './ServiceDependencyGraph';

type GetRowsArgs = {
  colorsByServiceHash: { [key: string]: string };
  date: DateSelection;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceHash: string;
  serviceByHash: Record<string, Service>;
};

const getRows = ({
  colorsByServiceHash,
  date,
  selectedFacetValuesByName,
  serviceHash,
  serviceByHash,
}: GetRowsArgs) => {
  return [
    [
      {
        charts: [
          {
            key: 'dependencyGraph',
            disableLogScale: true,
            label: 'Dependency Graph',
            render: () => (
              <div className="chart-grid__item__visualization">
                <ServiceDependencyGraph
                  colorsByServiceHash={colorsByServiceHash}
                  date={date}
                  key={serviceHash}
                  selectedFacetValuesByName={selectedFacetValuesByName}
                  serviceHash={serviceHash}
                  serviceByHash={serviceByHash}
                />
              </div>
            ),
          },
        ],
      },
    ],
  ];
};

type Props = {
  colorsByServiceHash: { [key: string]: string };
  date: DateSelection;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceHash: string;
  serviceByHash: Record<string, Service>;
};

const ServiceTabServiceMap = ({
  colorsByServiceHash,
  date,
  selectedFacetValuesByName,
  serviceHash,
  serviceByHash,
}: Props) => {
  const rows = useMemo(
    () =>
      getRows({
        colorsByServiceHash,
        date,
        selectedFacetValuesByName,
        serviceHash,
        serviceByHash,
      }),
    [
      colorsByServiceHash,
      date,
      selectedFacetValuesByName,
      serviceHash,
      serviceByHash,
    ],
  );
  return <ChartGridV2.ChartGrid date={date} rows={rows} />;
};

export default ServiceTabServiceMap;
