import {
  ChartToolbar,
  SizeObserver,
  TimeseriesRenderer,
  TimeseriesExplorer,
  useModalsContext,
  useThemeContext,
} from 'components';
import React, { ReactElement, useMemo } from 'react';
import {
  DateSelection,
  ExplorerQueryProps,
  FormulaProps,
  LegendTypes,
  QueryDataProps,
  UplotExtended,
} from 'types';
import { combineRangeQueryData, getPromqlQueryByIndex } from 'utils';
import { setDateRangeOnChartZoom } from 'utils';

import MetricsChartsRightToolbar from './MetricsChartsRightToolbar';

const MetricsChartsCombined = ({
  date,
  setDate,
  formulas,
  queries,
  queryData,
}: {
  date: DateSelection;
  setDate: (date: DateSelection) => void;
  formulas: FormulaProps[];
  queries: ExplorerQueryProps[];
  queryData: QueryDataProps;
}): ReactElement => {
  const { darkModeEnabled } = useThemeContext();
  const chartModal = useModalsContext();
  const combinedData = useMemo(
    () =>
      combineRangeQueryData({
        formulas,
        queries,
        queryData,
        darkModeEnabled,
        combineTimestamp: true,
        combineLabelWithQueryKey: true,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryData, darkModeEnabled],
  );

  const onViewFullscreen = (activeChart: string, unit: string) => {
    const allPromqlQueries = [];

    queries.forEach(({ metric, steps, isActive }, index) => {
      if (!isActive) return;
      const promql = getPromqlQueryByIndex(
        {
          formulas,
          queries,
          queryIndex: index,
          type: 'query',
          returnType: 'array',
        },
        date,
      );
      allPromqlQueries.push({ promql, metricName: metric, steps });
    });

    formulas.forEach(({ isActive }, index) => {
      if (!isActive) return;
      const promql = getPromqlQueryByIndex(
        { formulas, queries, queryIndex: index, type: 'formula' },
        date,
      );
      allPromqlQueries.push({ promql, metricName: promql });
    });

    chartModal.push(
      <TimeseriesExplorer
        activeQueries={allPromqlQueries}
        activeChartType={activeChart}
        chartData={combinedData}
        date={date}
        onClose={() => chartModal.pop()}
        unit={unit}
      />,
    );
  };

  const defaultChartType = useMemo(() => {
    const length = combinedData?.data[1]?.filter(Boolean)?.length;
    return length < 5 ? ['Points'] : undefined;
  }, [combinedData?.data]);

  const hooks = useMemo(() => {
    return [
      ...(combinedData.hooks || []),
      {
        hook: (u: UplotExtended) => setDateRangeOnChartZoom(u, setDate),
        type: 'setSelect',
      },
    ];
  }, [combinedData.hooks, setDate]);

  return (
    <SizeObserver>
      {({ width: chartWidth }) => (
        <TimeseriesRenderer
          bands={combinedData.bands || []}
          chartData={combinedData}
          chartTypes={defaultChartType}
          date={date}
          hooks={hooks}
          isLoading={combinedData.isLoading}
          legend={{
            legendType: LegendTypes.COMPACT_ONE_LINE,
            legendHeight: 150,
          }}
          renderToolbar={({
            activeChart,
            activeStroke,
            setActiveChart,
            setActiveStroke,
            unit,
          }) => (
            <ChartToolbar
              chartTypes={['Line', 'Area', 'Stacked Bar', 'Points']}
              activeChart={activeChart}
              activeStroke={activeStroke}
              setActiveChart={setActiveChart}
              setActiveStroke={setActiveStroke}
              toolbar={{
                rightToolbar: (
                  <MetricsChartsRightToolbar
                    onViewFullscreen={() => onViewFullscreen(activeChart, unit)}
                  />
                ),
                toolbarMenuType: 'dropdown',
              }}
            />
          )}
          size={{ width: chartWidth, height: 360 }}
          tooltipType="compact"
          unit="number"
        />
      )}
    </SizeObserver>
  );
};

export default MetricsChartsCombined;
