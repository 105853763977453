export enum DownloadType {
  csv = 'CSV',
  json = 'JSON',
  txt = 'TXT',
}

export type DownloaderState = {
  isDone: boolean;
  result: any[];
  size: number;
};

export interface Downloader {
  fetch: any;
  isLoading: boolean;
  state: DownloaderState;
  stopAndDownload: VoidFunction;
}
