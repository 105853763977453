import { AutocompleteOption } from 'components';
import { FacetBase } from 'types';
import delimiter from './delimiter';
export const CoreLevelLabel = {
  component: 'Core',
  name: 'level',
  type: 'string',
};

export const CoreSourceLabel = {
  component: 'Core',
  name: 'source',
  type: 'string',
};

export const LogLabels = {
  accountId: { component: 'Cloud', name: 'account_id', type: 'string' },
  agent: { component: 'Additional', name: 'agent', type: 'string' },
  availabilityZone: {
    component: 'Cloud',
    name: 'availability_zone',
    type: 'string',
  },
  cloud: { component: 'Cloud', name: 'cloud', type: 'string' },
  clusterName: {
    component: 'Additional',
    name: 'cluster_name',
    type: 'string',
  },
  containerName: {
    component: 'Additional',
    name: 'container_name',
    type: 'string',
  },
  host: { component: 'Cloud', name: 'host', type: 'string' },
  instanceId: { component: 'Cloud', name: 'instance_id', type: 'string' },
  instanceType: { component: 'Cloud', name: 'instance_type', type: 'string' },
  project: { component: 'Cloud', name: 'project', type: 'string' },
  region: { component: 'Cloud', name: 'region', type: 'string' },

  dockerImage: {
    component: 'Kubernetes',
    name: 'docker_image',
    type: 'string',
  },
  fileName: {
    component: 'Additional',
    name: 'filename',
    type: 'string',
  },
  kubeContainerName: {
    component: 'Kubernetes',
    name: 'kube_container_name',
    type: 'string',
  },
  kubeClusterName: {
    component: 'Cloud',
    name: 'kube_cluster_name',
    type: 'string',
  },
  kubeNamespace: {
    component: 'Kubernetes',
    name: 'kube_namespace',
    type: 'string',
  },
  kubeService: {
    component: 'Kubernetes',
    name: 'kube_service',
    type: 'string',
  },
  logStream: {
    component: 'Additional',
    name: 'logStream',
    type: 'string',
  },
  logGroup: {
    component: 'Additional',
    name: 'logGroup',
    type: 'string',
  },
  podName: { component: 'Kubernetes', name: 'pod_name', type: 'string' },
  service: { component: 'Additional', name: 'service', type: 'string' },
};

export const CoreLabels = [CoreLevelLabel, CoreSourceLabel];

export const CloudLabels = [
  LogLabels.accountId,
  LogLabels.availabilityZone,
  LogLabels.host,
  LogLabels.instanceId,
  LogLabels.instanceType,
  LogLabels.project,
];

export const KubernetesLabels = [
  LogLabels.dockerImage,
  LogLabels.kubeContainerName,
  LogLabels.kubeClusterName,
  LogLabels.kubeNamespace,
  LogLabels.kubeService,
  LogLabels.podName,
];

export const getCoreCloudKubernetesLabels = () => [
  ...CoreLabels,
  ...CloudLabels,
  ...KubernetesLabels,
];

export const getCoreCloudKubernetesOptions = (): AutocompleteOption[] => {
  const labels = getCoreCloudKubernetesLabels();

  return labels.map((label) => ({
    label: `${label.component}:${label.name}`,
    value: `${label.component}${delimiter}${label.name}${delimiter}${label.type}`,
  }));
};

const createBitmap = (labels) =>
  labels.reduce((obj, label) => ({ ...obj, [label.name]: label }), {});

export const CoreLabelsBitmap = createBitmap(CoreLabels);
export const CloudLabelsBitmap = createBitmap(CloudLabels);
export const KubernetesLabelsBitmap = createBitmap(KubernetesLabels);

export const eventsFacets = [
  'aggregation_key',
  'alert_type',
  'event_type',
  'host',
  'id',
  'priority',
  'source_type_name',
  'text',
  'title',
];

export const AlertsStatusLabels = [
  { label: 'Alerting', value: 'alerting' },
  { label: 'OK', value: 'ok' },
  { label: 'No Data', value: 'no_data' },
  { label: 'Pending', value: 'pending' },
  { label: 'Paused', value: 'paused' },
];

export const GCP = 'gcp';
export const KINESIS = 'kinesis';
