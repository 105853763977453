import { getLegacyFiltersFromFiltersState } from 'hooks/useFiltersState';
import flatten from 'lodash/flatten';
import {
  DateSelection,
  KeyExists,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
  SpanFilter,
} from 'types';
import {
  formatAggregateTimeSeriesData,
  getRollupByVisualization,
  onPromiseError,
} from 'utils';

import aggregateTimeSeries from './aggregateTimeSeries';
import aggregateTimeSeriesFormula from './aggregateTimeSeriesFormula';

import { transformTraceTimeseries, transformTraceInstantQuery } from './utils';

type Args = {
  date: DateSelection;
  formulas?: Array<{
    expression: string;
    isValid: string;
    isActive: boolean;
    queryKey: string;
  }>;
  instant?: boolean;
  keyExists: KeyExists;
  queries: Array<{
    operation: string;
    measure: string;
    groupBys: string[];
    rollUpInSeconds: number;
    queryKey: string;
  }>;
  resultFormat?: string;
  selectedFacetRangeByName: SelectedFacetRangeByName;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  selectedHcFacetValuesByName: SelectedFacetValuesByName;
  spanFilter: SpanFilter;
};

const aggregateTimeSeriesMultiple = async ({
  date,
  formulas = [],
  instant,
  keyExists,
  queries,
  resultFormat,
  selectedFacetRangeByName,
  selectedFacetValuesByName,
  selectedHcFacetValuesByName,
  spanFilter,
}: Args): Promise<any> => {
  const datasets = await Promise.all(
    queries.map((query) => {
      const searchBarState = query.searchBarState;

      if (searchBarState) {
        const { filters, traceIdSearch } = searchBarState;
        const {
          keyExists,
          facetRegex,
          selectedFacetRangeByName,
          selectedFacetValuesByName,
        } = getLegacyFiltersFromFiltersState(filters);
        return aggregateTimeSeries({
          ...query,
          aggregation: query.operation,
          aggregationField: query.measure,
          date,
          facetRegex,
          instant,
          keyExists,
          rollUpSeconds:
            query.rollUpInSeconds || getRollupByVisualization(date, 'bar'),
          selectedFacetRangeByName,
          selectedFacetValuesByName,
          spanFilter,
          traceIdSearch,
        }).catch(onPromiseError);
      }

      return aggregateTimeSeries({
        ...query,
        aggregation: query.operation,
        aggregationField: query.measure,
        instant,
        rollUpSeconds:
          query.rollUpInSeconds || getRollupByVisualization(date, 'bar'),
        date,
        keyExists,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        spanFilter,
      }).catch(onPromiseError);
    }),
  );

  const datasetsFormula = await Promise.all(
    formulas.map((formula) =>
      aggregateTimeSeriesFormula({
        date,
        formula,
        instant,
        keyExists,
        queries,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
        selectedHcFacetValuesByName,
        spanFilter,
      }).catch(onPromiseError),
    ),
  );

  if (instant) {
    const tableDatas = datasets.map((dataset, idx) =>
      transformTraceInstantQuery(dataset, queries[idx], resultFormat),
    );

    const tableDatasFormula = datasetsFormula.map((dataset, idx) =>
      transformTraceInstantQuery(dataset, formulas[idx], resultFormat),
    );

    return tableDatas.concat(tableDatasFormula);
  }

  if (resultFormat === 'timeseries') {
    const timeseriesDatasets = datasets.map((dataset, idx) =>
      transformTraceTimeseries({
        date,
        dataset,
        query: queries[idx],
        step:
          queries[idx].rollUpInSeconds || getRollupByVisualization(date, 'bar'),
      }),
    );

    const timeseriesDatasetsFormula = datasetsFormula.map((dataset, idx) =>
      transformTraceTimeseries({
        date,
        dataset,
        query: {
          queryKey: `formula_${formulas[idx].queryKey}`,
          limitTo: 'top',
        },
        step:
          queries[idx].rollUpInSeconds || getRollupByVisualization(date, 'bar'),
      }),
    );
    return timeseriesDatasets.concat(timeseriesDatasetsFormula);
  }
  datasets.forEach((dataset, idx) => {
    const { groupBys, queryKey } = queries[idx];
    if (groupBys[0] !== '*') return;
    dataset.forEach((data) => {
      data.GroupVal = { [`count_of_${queryKey}`]: '' };
    });
  });

  const flattenedDatasets = flatten(datasets);
  return formatAggregateTimeSeriesData({
    points: flattenedDatasets,
  });
};

export default aggregateTimeSeriesMultiple;
