import { AutocompleteOption } from 'components';
import {
  DashboardPanelType,
  DateSelection,
  ExplorerQueryProps,
  FormulaProps,
  PromqlWithMetaProps,
} from 'types';

import { getPromqlWithMetaToLoadByQueryIndex } from 'utils';
import { AlertsCreateDetailsProps, KeyPairProps } from '../types';

export const ReservedAnnotationKeys: string[] = [
  'ruleType',
  'alertType',
  'componentName',
  'description',
  'runbook_url',
  'summary',
  'kfuseTags',
  'logQL',
  'extraData',
  'rrcfInnerExpression',
  'forWindow',
  'kfuseASMFilter',
];

export const alertType = {
  Metric: 'threshold',
  Anomaly: 'anomaly',
  Change: 'change',
  Forecast: 'forecast',
  Outlier: 'outliers',
  Threshold: 'threshold',
};

export const alertsList = [
  'Metric',
  'Anomaly',
  'Change',
  'Forecast',
  'Outlier',
  'Threshold',
  'Logs',
  'APM',
  // TODO Pavan
  // 'APM SLO',
];

export const getQueryAndFormulaKeysLabel = (
  queries: ExplorerQueryProps[],
  formulas: FormulaProps[],
): AutocompleteOption[] => {
  const queryAndFormulaLabels: AutocompleteOption[] = [];
  queries.forEach((query) => {
    queryAndFormulaLabels.push({
      label: `Query (${query.queryKey})`,
      value: `Query (${query.queryKey})`,
    });
  });
  formulas.forEach((formula, index) => {
    queryAndFormulaLabels.push({
      label: `Formula (${index + 1})`,
      value: `Formula (${index + 1})`,
    });
  });
  return queryAndFormulaLabels;
};

export const getFolderOptions = (folders: string[]): AutocompleteOption[] => {
  const folderOptions: AutocompleteOption[] = [];
  folders.forEach((folder) => {
    folderOptions.push({ label: folder, value: folder });
  });
  return folderOptions;
};

export const getPromQlQuery = ({
  date,
  formulas,
  queryKey,
  queries,
}: {
  date: DateSelection;
  formulas: FormulaProps[];
  queryKey: string;
  queries: ExplorerQueryProps[];
}): PromqlWithMetaProps => {
  const defaultResponse: PromqlWithMetaProps = {
    promql: '',
    meta: undefined,
    queryType: 'query',
  };
  if (queryKey.includes('Query')) {
    const queryKeyParsed = queryKey.split('(')[1].split(')')[0];
    const queryIndex = queries.findIndex((q) => q.queryKey === queryKeyParsed);
    if (queryIndex === -1) return defaultResponse;

    const promqlWithMeta = getPromqlWithMetaToLoadByQueryIndex({
      dataFormat: DashboardPanelType.TIMESERIES,
      date,
      queryIndex,
      queries,
      formulas,
    });

    if (promqlWithMeta.length) {
      return promqlWithMeta[0];
    }
  }
  if (queryKey.includes('Formula')) {
    const formulaIndex = Number(queryKey.split('(')[1].split(')')[0]) - 1;

    const promqlWithMeta = getPromqlWithMetaToLoadByQueryIndex({
      dataFormat: DashboardPanelType.TIMESERIES,
      date,
      queryIndex: formulaIndex,
      queries,
      formulas,
      queryType: 'formula',
    });

    if (promqlWithMeta.length) {
      return promqlWithMeta[0];
    }
  }
  return defaultResponse;
};

export const getContactPointsForCreateAlert = (
  contactPoints: string[],
  customLabels: AlertsCreateDetailsProps['customLabels'],
): { [key: string]: string } => {
  const contactPointsObject: { [key: string]: string } = {};
  contactPoints.forEach((contactPoint) => {
    contactPointsObject[contactPoint] = 'true';
  });

  customLabels.map(({ label, value }) => {
    if (label && value) {
      contactPointsObject[label] = value;
    }
  });
  return contactPointsObject;
};

export const getCustomLabels = (tags: string[]): KeyPairProps[] => {
  const customLabels: KeyPairProps[] = [];
  tags.forEach((tag) => {
    const [key, value] = tag.split(':');
    customLabels.push({ label: key, value });
  });

  if (customLabels.length > 0) {
    return customLabels;
  }

  return [{ label: '', value: '' }];
};

export const getCustomAnnotations = (annotations: {
  [key: string]: string;
}): KeyPairProps[] => {
  const customAnnotations: KeyPairProps[] = [];
  Object.keys(annotations).forEach((key) => {
    // check reserved keywords ReservedAnnotationKeys
    if (!ReservedAnnotationKeys.includes(key)) {
      customAnnotations.push({ label: key, value: annotations[key] });
    }
  });

  if (customAnnotations.length > 0) {
    return customAnnotations;
  }

  return [{ label: '', value: '' }];
};
