import React from 'react';
import LogsTabs from './LogsTabs';
import { MdBarChart } from 'react-icons/md';
import { useLocalStorageToggle } from 'hooks';

const LogsTabsContainer = ({
  isAnalyticsView,
  showTimelineToggle,
}: {
  isAnalyticsView: boolean;
  showTimelineToggle: ReturnType<typeof useLocalStorageToggle>;
}) => {
  return (
    <div className="logs__search__bottom">
      <LogsTabs />
      <div className="logs__search__bottom__right">
        {!showTimelineToggle.value && !isAnalyticsView ? (
          <div className="logs__search__bottom__right__item">
            <button
              className="button button--short"
              onClick={showTimelineToggle.on}
            >
              <div className="button__icon">
                <MdBarChart size={16} />
              </div>
              <div className="button__text">Show Timeline</div>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LogsTabsContainer;
