import { useDateState } from 'hooks';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { extractPathNameFromURL, parseUrlParamByKey } from 'utils';
import { useSearches } from '../useSearch';
import useRumState from 'screens/Rum/hooks/useRumState';
import { RumEventType } from 'screens/Rum';

type Args = {
  rumDateState: ReturnType<typeof useDateState>;
};

const useRumPageState = ({ rumDateState }: Args) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { searches } = useSearches({});

  const rumState = useRumState({
    dateState: rumDateState,
    initialEventType: parseUrlParamByKey('rumEventType') || RumEventType.VIEW,
    initialFilters: parseUrlParamByKey('rumFilters'),
    shouldWriteToUrl: false,
    urlStateKey: 'rumFilters',
  });

  const date = rumState.dateState[0];

  // Get dependencies for determining when a url change should happen
  const dependenciesForWriteStateToUrl = useMemo(
    () => [date, rumState.eventType, searches, rumState.filtersState.state],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, searches, rumState.eventType, rumState.filtersState.state],
  );

  const writeStateToUrl = () => {
    const { filtersState } = rumState;
    params.set('rumDate', JSON.stringify(rumState.dateState[0]));
    params.set('rumEventType', JSON.stringify(rumState.eventType));
    params.set('rumFilters', JSON.stringify(filtersState.state));
    const navigateUrl = `${extractPathNameFromURL()}?${params.toString()}`;
    navigate(navigateUrl, { replace: true });
  };

  return {
    dependenciesForWriteStateToUrl,
    searches,
    rumState,
    writeStateToUrl,
  };
};

export default useRumPageState;
