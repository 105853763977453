import { Loader } from 'components';
import React, { forwardRef } from 'react';
import LogsSheetMain from './LogsSheetMain';
import LogsSheetToolbar from './LogsSheetToolbar';
import useSheetState from './useSheetState';

const LogsSheet = forwardRef(
  (
    {
      activeRowIndex,
      bindKeyHandlersRef,
      containerRef,
      columns,
      columnsState,
      customColumnsState,
      date,
      disableToolbar = false,
      getWorkbooksRequest,
      isLoading,
      logs,
      logsState,
      onClick,
      onEnter,
      onScrollEnd,
      renderToolbarLeft,
      setSelectedLog,
      sort,
      sortByColumn,
      tableOptions,
    },
    ref,
  ) => {
    const sheetState = useSheetState();
    return (
      <div className="logs__table__logs">
        {disableToolbar ? null : (
          <LogsSheetToolbar
            columnsState={columnsState}
            customColumnsState={customColumnsState}
            date={date}
            getWorkbooksRequest={getWorkbooksRequest}
            logsState={logsState}
            renderToolbarLeft={renderToolbarLeft}
            tableOptions={tableOptions}
          />
        )}
        <div
          className="logs__table__logs__main"
          data-testid="logs-main-table"
          onClick={onClick}
          ref={ref}
        >
          <LogsSheetMain
            activeRowIndex={activeRowIndex}
            bindKeyHandlersRef={bindKeyHandlersRef}
            columns={columns}
            columnsState={columnsState}
            rows={logs}
            onEnter={onEnter}
            onScrollEnd={onScrollEnd}
            setSelectedLog={setSelectedLog}
            sheetState={sheetState}
            sortByColumn={sortByColumn}
            sort={sort}
            tableOptions={tableOptions}
          />
          {isLoading ? (
            <div className="logs__table__logs__loader">
              <Loader isLoading />
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);

export default LogsSheet;
