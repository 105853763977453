import { DateSelection } from 'types/DateSelection';
import { Series } from 'uplot';
import { getRollupByVisualization } from 'utils';

/**
 * Return array of timestamps for the given date selection
 * @param date
 */
export const getNoDataPlaceholder = (date: DateSelection): number[][] => {
  const steps = getRollupByVisualization(date);

  const { startTimeUnix, endTimeUnix } = date;
  const timeGap = Math.floor((endTimeUnix - startTimeUnix) / steps);

  const timestamps = [];
  for (let i = 0; i < steps; i++) {
    timestamps.push(startTimeUnix + i * timeGap);
  }

  return [timestamps, timestamps.map(() => 0)];
};

/**
 * Return series for empty chart
 */
export const getNoDataSeries = (seriesCount: number): Series[] => {
  if (seriesCount === 0) {
    return [{ show: true }, { show: true, label: '', points: { show: false } }];
  }
  return [{ show: true }];
};
