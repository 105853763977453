import { Separator } from 'components/shadcn';
import React, { ReactElement } from 'react';

import {
  alertTypeAlgorithm,
  forecastSeasonalityReverseMapping,
} from '../AlertsCreateMetrics/utils';
import { alertDetailsTimeConversion } from '../utils';
import { AlertsMetricsParsedProps } from '../types';

const AlertsDetailsForecastParameters = ({
  parsed,
}: {
  parsed: AlertsMetricsParsedProps;
}): ReactElement => {
  const { forecastCondition } = parsed;
  return (
    <>
      <div className="alerts__details__properties__summary__item">
        <span className="text-sm font-bold text-text-secondary">
          Algorithm:{' '}
        </span>{' '}
        {alertTypeAlgorithm[forecastCondition.forecastAlgorithm]}
      </div>
      <Separator orientation="horizontal" />
      <div className="alerts__details__properties__summary__item">
        <span className="text-sm font-bold text-text-secondary">
          Forecast Duration:{' '}
        </span>{' '}
        {alertDetailsTimeConversion(forecastCondition.forecastDuration)}
      </div>
      <Separator orientation="horizontal" />
      {forecastCondition.forecastAlgorithm === 'seasonal' && (
        <>
          <div className="alerts__details__properties__summary__item">
            <span className="text-sm font-bold text-text-secondary">
              Seasonality:{' '}
            </span>{' '}
            {forecastSeasonalityReverseMapping[forecastCondition.seasonality]}
          </div>
          <Separator orientation="horizontal" />
          <div className="alerts__details__properties__summary__item">
            <span className="text-sm font-bold text-text-secondary">
              Interval:{' '}
            </span>{' '}
            {alertDetailsTimeConversion(`${forecastCondition.interval}s`)}
          </div>
        </>
      )}
    </>
  );
};

export default AlertsDetailsForecastParameters;
