import { useMergeState } from 'hooks';
import { Operation } from 'types';
import { SearchState } from './types';
import { getInitialState } from './utils';

const useSearch = () => {
  const [state, setState] = useMergeState<SearchState>(getInitialState());

  const addGroupBy = (group: string) => {
    setState((prevState) => {
      const nextGroupBys = [...prevState.groupBys, group];
      return {
        groupBys: nextGroupBys,
      };
    });
  };

  const removeGroupByByIndexHandler = (i: number) => () => {
    setState((prevState) => {
      const nextGroupBys = [...prevState.groupBys];
      nextGroupBys.slice(i, 1);
      return {
        groupBys: nextGroupBys,
      };
    });
  };

  const changeHandler =
    <T extends keyof SearchState>(key: T) =>
    (value: SearchState[T]) => {
      setState({ [key]: value });
    };

  const changeMeasure = (nextMeasure: string) => {
    setState({
      measure: nextMeasure,
      operation:
        nextMeasure === 'duration' ? Operation.avg : Operation.distinctcount,
    });
  };

  return {
    ...state,
    addGroupBy,
    changeActive: changeHandler('isActive'),
    changeGroupBys: changeHandler('groupBys'),
    changeLimitTo: changeHandler('limitTo'),
    changeLimitToValue: changeHandler('limitToValue'),
    changeMeasure: changeMeasure,
    changeOperation: changeHandler('operation'),
    changeRollUpInSeconds: changeHandler('rollUpInSeconds'),
    changeVisualizeAs: changeHandler('visualizeAs'),
    removeGroupByByIndexHandler,
    state,
  };
};

export default useSearch;
