import React from 'react';
import { formatDurationNs, isNullOrUndefined } from 'utils';

type Values = { [key: string]: number };

const valueitems = [
  {
    label: 'Requests',
    render: (values: Values) =>
      isNullOrUndefined(values.requests)
        ? '-'
        : `${values.requests?.toFixed(2)}/s`,
  },
  {
    label: 'Max Latency',
    render: (values: Values) =>
      isNullOrUndefined(values.latency)
        ? '-'
        : formatDurationNs(values.latency * 1000000, 1, 2),
  },
  {
    label: 'Errors',
    render: (values: Values) =>
      `${isNullOrUndefined(values.errors) ? '0' : values.errors.toFixed(2)}/s`,
  },
];

type Props = {
  isLoading: boolean;
  style?: Record<string, string>;
  title: string;
  values: {
    errors: number;
    latency: number;
    requests: number;
  };
};

const ApmServiceMapTooltip = ({ isLoading, style, title, values }: Props) => {
  return (
    <div
      className="traces__service-map__node__tooltip"
      data-testid="tooltip"
      style={style || {}}
    >
      <div
        className="traces__service-map__node__tooltip__name"
        data-testid="tooltip-name"
      >
        {title}
      </div>
      <div className="traces__service-map__node__tooltip__values">
        {valueitems.map((valueItem, i) => (
          <div
            className="traces__service-map__node__tooltip__values__item"
            data-testid="tooltip-item"
            key={i}
          >
            <div
              className="traces__service-map__node__tooltip__values__item__label"
              data-testid="tooltip-item-label"
            >
              {valueItem.label}
            </div>
            <div
              className="traces__service-map__node__tooltip__values__item__value"
              data-testid="tooltip-item-value"
            >
              {isLoading ? '-' : valueItem.render(values)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApmServiceMapTooltip;
