import {
  Filter,
  FilterType,
  SelectedRangeFilter,
  SelectedRangeFilterRange,
} from './types';
import useFiltersState from './useFiltersState';
import useSelectedFacetRangeByNameState from '../useSelectedFacetRangeByNameState';

const findSelectedRangeFilterByKey = (key: string) => (filter: Filter) =>
  filter.type === FilterType.selectedRange && filter.value.facet === key;

const getSelectedRangeFilter = (
  facet: string,
  range: SelectedRangeFilterRange,
): SelectedRangeFilter => ({
  disabled: false,
  type: FilterType.selectedRange,
  value: {
    facet,
    ...range,
  },
});

export const getLegacySelectedFacetRangeByNameState = (
  state: ReturnType<typeof useFiltersState>['state'],
) => {
  const selectedFacetRangeFilters = state.filter(
    (filter) => filter.type === FilterType.selectedRange,
  ) as SelectedRangeFilter[];

  return selectedFacetRangeFilters.reduce((obj, filter) => {
    const { facet, ...range } = filter.value;
    return {
      ...obj,
      [facet]: range,
    };
  }, {});
};

const getSelectedFacetRangeByNameState = (
  filtersState: ReturnType<typeof useFiltersState>,
): ReturnType<typeof useSelectedFacetRangeByNameState> => {
  const { state, setState } = filtersState;

  const changeFacetRange =
    ({ name }: { name: string }) =>
    (nextRange: SelectedRangeFilterRange) => {
      setState((prevState) => {
        const nextState = [...prevState];
        const filterIndex = prevState.findIndex(
          findSelectedRangeFilterByKey(name),
        );
        const nextIndex = filterIndex > -1 ? filterIndex : prevState.length;
        nextState[nextIndex] = getSelectedRangeFilter(name, nextRange);

        return nextState;
      });
    };

  const clearFacet = (name: string) => {
    setState((prevState) => {
      const nextState = { ...prevState };
      const filterIndex = prevState.findIndex(
        findSelectedRangeFilterByKey(name),
      );

      if (filterIndex > -1) {
        nextState.splice(filterIndex, 1);
      }

      return nextState;
    });
  };

  return {
    changeFacetRange,
    clearFacet,
    state: getLegacySelectedFacetRangeByNameState(state),
  };
};

export default getSelectedFacetRangeByNameState;
