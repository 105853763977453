import {
  BasicLogsTable,
  Stepper,
  useColumnsState,
  useTableOptions,
} from 'components';
import {
  getLogsStateFromFiltersState,
  parseFiltersFromLogsState,
  useFiltersState,
  useKeyExistsState,
  useRequest,
  useToggle,
} from 'hooks';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { getFacetNames } from 'requests';
import { DashboardPanelProps, LabelsProps } from 'types';

import {
  DashboardPanelModalFooter,
  DashboardPanelModalHeader,
} from '../components';

import DashboardEditLogEventColumns from './DashboardEditLogEventColumns';
import DashboardEditPanelName from './DashboardEditPanelName';
import { useDashboardEditState } from './hooks';
import LogsSearchBarV2 from 'screens/Logs/LogsSearchBarV2/LogsSearchBarV2';
import { MESSAGE, SOURCE, TIMESTAMP, getColumns } from 'screens/Logs/constants';
import tryJsonParse from 'utils/tryJsonParse';

const DashboardEditLogEvents = ({
  close,
  dashboardEditState,
  panel,
}: {
  close: () => void;
  dashboardEditState: ReturnType<typeof useDashboardEditState>;
  panel: DashboardPanelProps;
}): ReactElement => {
  const { date, editPanel, onSaveClickLogEvents, setDate } = dashboardEditState;
  const [labels, setLabels] = useState<LabelsProps>();
  const isReadyToggle = useToggle();
  const [lastRefreshedAt, setLastRefreshedAt] = useState<number>(null);
  const getFacetNamesRequest = useRequest(getFacetNames);

  const parsedExpr = useMemo(
    () => tryJsonParse(panel?.targets[0]?.expr || '{}'),
    [panel],
  );

  const filtersState = useFiltersState({
    initialState: parseFiltersFromLogsState(parsedExpr),
  });

  useEffect(() => {
    filtersState.setState(parseFiltersFromLogsState(parsedExpr));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedExpr]);

  const customColumnsState = useKeyExistsState({
    initalState: parsedExpr?.tableColumns?.customColumns,
    urlStateKey: 'customColumns',
    shouldWriteToUrl: false,
  });
  const tableOptions = useTableOptions(
    false,
    parsedExpr?.tableOptions || undefined,
  );

  const baseColumns = useMemo(
    () => getColumns({ customColumnsState, logsState: {} }),
    [customColumnsState],
  );

  const columnsState = useColumnsState({
    columns: baseColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: parsedExpr?.tableColumns?.selectedColumns || {
        [MESSAGE]: 1,
        [SOURCE]: 1,
        [TIMESTAMP]: 1,
      },
    },
    key: 'logs-table',
    shouldUseLocalStorage: false,
  });

  const setKeyExists = (key: string) => {
    customColumnsState.setKeyExists(key);
    columnsState.setSelectedColumnByKey(key);
  };
  const overriddenCustomColumnsState = {
    ...customColumnsState,
    setKeyExists,
  };

  const logsState = useMemo(
    () => ({
      ...getLogsStateFromFiltersState({ filtersState }),
      date,
      labels,
      removeFilterByFacetByIndex: () => {},
      setLabels,
      setDate,
      filtersState,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, filtersState.state],
  );

  const logsEventsFilter = {
    tableColumns: {
      customColumns: customColumnsState.state,
      selectedColumns: columnsState.state.selectedColumns,
    },
    filters: filtersState.state,
    tableOptions: tableOptions.state,
  };

  useEffect(() => {
    if (!panel) return;
    if (panel.targets.length) {
      const { expr } = panel.targets[0];

      const initialLogsState = { date, ...JSON.parse(expr) };
      if (initialLogsState.tableColumns) {
        const { customColumns, selectedColumns } =
          initialLogsState.tableColumns;
        if (!customColumns || !selectedColumns) return;

        customColumnsState.setState((prev) => ({ ...prev, ...customColumns }));
        columnsState.setState((prev) => ({
          ...prev,
          selectedColumns: { ...customColumns, ...selectedColumns },
        }));
      }
      if (initialLogsState.tableOptions) {
        tableOptions.setState((prev) => ({
          ...prev,
          ...initialLogsState.tableOptions,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isReadyToggle.on();
    setLastRefreshedAt(new Date().valueOf());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, filtersState.state]);

  return (
    <>
      <DashboardPanelModalHeader
        close={close}
        modalDate={date}
        setModalDate={setDate}
        title={!panel ? 'Add Panel' : editPanel.title || 'Edit Panel'}
      />
      <div className="dashboard-edit__metric__body">
        <div className="px-2 pt-2" style={{ height: 340 }}>
          {isReadyToggle.value ? (
            <BasicLogsTable
              columnsState={columnsState}
              customColumnsState={overriddenCustomColumnsState}
              key={lastRefreshedAt}
              logsState={logsState}
              tableOptions={tableOptions}
            />
          ) : null}
        </div>
        <Stepper
          steps={[
            {
              title: 'Graph your data',
              component: (
                <LogsSearchBarV2
                  date={date}
                  getFacetNamesRequest={getFacetNamesRequest}
                  filtersState={filtersState}
                />
              ),
            },
            {
              title: 'Columns & Line',
              component: (
                <DashboardEditLogEventColumns
                  columnsState={columnsState}
                  customColumnsState={overriddenCustomColumnsState}
                  tableOptions={tableOptions}
                />
              ),
            },
            {
              title: 'Enter chart title',
              component: (
                <DashboardEditPanelName
                  dashboardEditState={dashboardEditState}
                />
              ),
            },
          ]}
        />
      </div>
      <DashboardPanelModalFooter
        close={close}
        onSave={() => {
          const expr = JSON.stringify(logsEventsFilter);
          onSaveClickLogEvents(expr);
        }}
      />
    </>
  );
};

export default DashboardEditLogEvents;
