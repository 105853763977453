export type HydrationJobProps = {
  ArchivalAddTags: string;
  ArchivalDurationMs: number;
  ArchivalEndTsMs: number;
  ArchivalFilters: string;
  ArchivalName: string;
  ArchivalStartTsMs: number;
  CreatedAtMs: number;
  ErrorMsg: string | null;
  JobId: string;
  JobStatus: string;
  LastUpdatedAtMs: number;
  ShouldCancel: boolean;
};

export type HydrateFormProps = {
  archiveName: string;
  startTsMs: number;
  endTsMs: number;
  retentionDurationValue: number;
  retentionDurationUnit: string;
  filter: { key: string; value: string }[];
  addTags: { key: string; value: string }[];
};

export enum JobStatus {
  INIT = 'INIT',
  HYDRATING = 'HYDRATING',
  DONE = 'DONE',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}
