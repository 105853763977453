import React from 'react';
import { AiOutlineLineChart } from 'react-icons/ai';
import { BsFilterLeft, BsPieChartFill, BsTable } from 'react-icons/bs';
import { GiHoneycomb } from 'react-icons/gi';
import { RiLayout4Line } from 'react-icons/ri';
import { TbMist } from 'react-icons/tb';
import { TiSortNumerically } from 'react-icons/ti';

import { DashboardPanelType, WidgetItemProps } from 'types/Dashboard';

export const SupportedEditGraphList: WidgetItemProps[] = [
  {
    name: DashboardPanelType.TIMESERIES,
    label: 'Timeseries',
    icon: <AiOutlineLineChart />,
  },
  {
    name: DashboardPanelType.TOP_LIST,
    label: 'Top List',
    icon: <BsFilterLeft />,
  },
  { name: DashboardPanelType.TABLE, label: 'Table', icon: <BsTable /> },
  {
    name: DashboardPanelType.PIECHART,
    label: 'Pie Chart',
    icon: <BsPieChartFill />,
  },
  {
    name: DashboardPanelType.GRAFANA_POLYSTAT_PANEL,
    label: 'Host map',
    icon: <GiHoneycomb />,
  },
];

const UnsupportedEditGraphList: WidgetItemProps[] = [
  { name: DashboardPanelType.STAT, label: 'Stat', icon: <TiSortNumerically /> },
  { name: DashboardPanelType.HEATMAP, label: 'Heatmap', icon: <TbMist /> },
  {
    name: DashboardPanelType.TREEMAP,
    label: 'Treemap',
    icon: <RiLayout4Line />,
  },
];
