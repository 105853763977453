import { ApmErrorsGroupTable } from 'components';
import { useTracesState } from 'hooks';
import React from 'react';
import { Service } from 'types';

type Props = {
  colorsByServiceHash: Record<string, string>;
  isServiceFromDatabasesList?: boolean;
  serviceHash?: string;
  serviceByHash: Record<string, Service>;
  serviceName?: string;
  tracesState: ReturnType<typeof useTracesState>;
};

const ServiceTabErrors = ({
  colorsByServiceHash,
  isServiceFromDatabasesList,
  serviceName,
  serviceByHash,
  serviceHash,
  tracesState,
}: Props) => {
  return (
    <ApmErrorsGroupTable
      className="service__tab"
      colorsByServiceHash={colorsByServiceHash}
      isServiceFromDatabasesList={isServiceFromDatabasesList}
      serviceHash={serviceHash}
      serviceByHash={serviceByHash}
      serviceName={serviceName}
      tracesState={tracesState}
    />
  );
};

export default ServiceTabErrors;
