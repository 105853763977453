import { FlyoutCaret } from 'components';
import React, { ReactElement, useEffect, useState } from 'react';

import AlertsContactsCreateElementField from './AlertsContactsCreateElementField';
import { useAlertsContactsState } from './hooks';
import { ContactNotifierType, ContactNotifierTypeOptions } from './types';
import { isDisabledField } from './utils';

const AlertsContactsCreateElementLabel = ({
  description,
  label,
  required,
}: {
  description: string;
  label: string;
  required: boolean;
}): ReactElement => {
  return (
    <>
      <div className="alerts__contacts__create__element__label">
        {label} {required && <span className="text--red">*</span>}
      </div>
      {Boolean(description) && (
        <div className="alerts__contacts__create__element__description">
          {description}
        </div>
      )}
    </>
  );
};

const AlertsContactsCreateElement = ({
  alertsContactsState,
  dependencyRelation,
  secureFieldsArray,
  notifierData,
  notifierIndex,
  setSecureFieldsArray,
  selectedNotifierData,
}: {
  alertsContactsState: ReturnType<typeof useAlertsContactsState>;
  dependencyRelation: Record<string, string[]>;
  secureFieldsArray: Array<Record<string, boolean>>;
  notifierData: ContactNotifierType;
  notifierIndex: number;
  setSecureFieldsArray: (secureFields: Array<Record<string, boolean>>) => void;
  selectedNotifierData: { [key: string]: any };
}): ReactElement => {
  const [isOptionalOpen, setIsOptionalOpen] = useState(false);
  const { updateSelectedNotifierData } = alertsContactsState;
  const notifierDataRequird = notifierData.options.filter(
    (item: ContactNotifierTypeOptions) => item.required,
  );

  const notifierDataOptional = notifierData.options.filter(
    (item: ContactNotifierTypeOptions) => !item.required,
  );

  useEffect(() => {
    setIsOptionalOpen(false);
  }, [notifierData]);

  const handleClearSecureField = () => {
    const newSecureFields = [...secureFieldsArray];
    newSecureFields[notifierIndex] = {};
    setSecureFieldsArray(newSecureFields);
  };

  const secureFields = secureFieldsArray[notifierIndex] || {};

  return (
    <div className="alerts__contacts__create__element">
      <div className="alerts__contacts__create__element__heading">
        {notifierData.heading}
      </div>
      <div className="alerts__contacts__create__element__required">
        {notifierDataRequird.map(
          (field: ContactNotifierTypeOptions, index: number) => {
            const isDisabled = isDisabledField({
              secureFields,
              field: field.propertyName,
              fieldsToDisableForSecure: dependencyRelation,
            });

            return (
              <div key={index}>
                {field.element !== 'checkbox' && (
                  <AlertsContactsCreateElementLabel
                    description={field.description}
                    label={field.label}
                    required={!field.dependsOn || field.required}
                  />
                )}
                <AlertsContactsCreateElementField
                  disabled={isDisabled}
                  field={field}
                  onChange={(val) =>
                    updateSelectedNotifierData(
                      notifierIndex,
                      field.propertyName,
                      val,
                    )
                  }
                  onClear={handleClearSecureField}
                  showClear={Boolean(secureFields[field.propertyName])}
                  value={selectedNotifierData[field.propertyName]}
                />
              </div>
            );
          },
        )}
      </div>
      <div className="alerts__contacts__create__element__optional">
        <div
          className="alerts__contacts__create__element__optional__header"
          onClick={() => setIsOptionalOpen(!isOptionalOpen)}
        >
          <FlyoutCaret isOpen={isOptionalOpen} />
          <div>Optional {notifierData.name} settings</div>
        </div>
        {isOptionalOpen &&
          notifierDataOptional.map((field: any, index: number) => {
            return (
              <div key={index}>
                {field.element !== 'checkbox' && (
                  <AlertsContactsCreateElementLabel
                    description={field.description}
                    label={field.label}
                    required={false}
                  />
                )}
                <AlertsContactsCreateElementField
                  field={field}
                  onChange={(val) =>
                    updateSelectedNotifierData(
                      notifierIndex,
                      field.propertyName,
                      val,
                    )
                  }
                  value={selectedNotifierData[field.propertyName]}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AlertsContactsCreateElement;
