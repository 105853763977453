import classnames from 'classnames';
import {
  Accordion,
  ChipWithLabel,
  IconWithLabel,
  SearchInput,
  RadioGroup,
  Icon,
} from 'components';
import { useForm, useSortState } from 'hooks';
import { iconsBySpanType, TracesTableColumnKey } from 'kfuse-constants';
import React, { useMemo } from 'react';
import { Span, TraceMetrics } from 'types';
import { spanListColumns } from './constants';
import TraceSidebarMainSpanListRow from './TraceSidebarMainSpanListRow';

type SpansBySpanName = { [spanName: string]: Span[] };

type SpansByNamesByServiceName = {
  [serviceName: string]: SpansBySpanName;
};

const filterSpans = ({ search, spans, spanType }) => {
  let result = [...spans];

  if (search) {
    result = result.filter(
      (span) =>
        JSON.stringify(span).toLowerCase().indexOf(search.toLowerCase()) > -1,
    );
  }

  if (spanType) {
    result = result.filter((span) => span.attributes['span_type'] === spanType);
  }

  return result;
};

const getSpansByNamesByServiceName = (spans: Span[]) => {
  const result: SpansByNamesByServiceName = {};
  spans.forEach((span) => {
    const { name, service } = span;
    const serviceName = service.name;
    if (!result[serviceName]) {
      result[serviceName] = {};
    }

    if (!result[serviceName][name]) {
      result[serviceName][name] = [];
    }

    result[serviceName][name].push(span);
  });

  return result;
};

const getTotalDuraion = (spans: Span[]) => {
  return spans.reduce(
    (total, span) => total + span.endTimeNs - span.startTimeNs,
    0,
  );
};

const getServiceAverageDuration = (spansBySpanName: SpansBySpanName) => {
  const count = Object.keys(spansBySpanName).reduce(
    (count, spanName) => count + spansBySpanName[spanName].length,
    0,
  );

  const totalDuration = Object.keys(spansBySpanName).reduce(
    (total, spanName) => {
      const spans = spansBySpanName[spanName];
      const totalDuration = getTotalDuraion(spans);
      return total + totalDuration;
    },
    0,
  );

  return totalDuration / count;
};

const getServices = (
  spansByNamesByServiceName: SpansByNamesByServiceName,
  traceMetrics: TraceMetrics,
) => {
  const spanIdExecTimeNs: { [key: string]: number } =
    traceMetrics.spanIdExecTimeNs;

  const totalExecTime = Object.values(spanIdExecTimeNs).reduce(
    (sum: number, spanExecTime: number) => sum + spanExecTime,
    0,
  );

  return Object.keys(spansByNamesByServiceName).map((serviceName) => {
    const spansByNames = spansByNamesByServiceName[serviceName];
    const serviceExecTimeNs = Object.values(spansByNames).reduce(
      (sum, spans) =>
        sum +
        spans.reduce(
          (spansSum, span) => spansSum + spanIdExecTimeNs[span.spanId],
          0,
        ),
      0,
    );

    return {
      name: serviceName,
      averageDuration: getServiceAverageDuration(
        spansByNamesByServiceName[serviceName],
      ),
      isCalculatedAvg: true,
      execPercentage: serviceExecTimeNs / totalExecTime,
      execTime: serviceExecTimeNs,
      spansCount: Object.keys(spansByNames).reduce(
        (count, spanName) => count + spansByNames[spanName].length,
        0,
      ),
      spanNames: Object.keys(spansByNamesByServiceName[serviceName]).map(
        (spanName) => {
          const spans = spansByNamesByServiceName[serviceName][spanName];
          const spanNameExecTime = spans.reduce(
            (sum, span) => sum + spanIdExecTimeNs[span.spanId],
            0,
          );
          const totalDuration = getTotalDuraion(spans);
          const averageDuration = Math.round(totalDuration / spans.length);
          return {
            spansCount: spans.length,
            execTime: spanNameExecTime,
            execPercentage: spanNameExecTime / totalExecTime,
            name: spanName,
            spanType: spans.length ? spans[0].attributes['span_type'] : null,
            averageDuration,
            isCalculatedAvg: true,
            spans: spans.map((span) => {
              const spanExecTime = spanIdExecTimeNs[span.spanId];
              return {
                ...span,
                name: span.name,
                averageDuration: span.endTimeNs - span.startTimeNs,
                execTime: spanExecTime,
                execPercentage: spanExecTime / totalExecTime,
              };
            }),
          };
        },
      ),
    };
  });
};

const sortItems = (sortState: ReturnType<typeof useSortState>) => (a, b) => {
  const { isAsc, key } = sortState.state;
  const aVal = a[key];
  const bVal = b[key];

  if (key === 'name') {
    const aString = String(aVal);
    const bString = String(bVal);
    if (isAsc) {
      return aString.localeCompare(bString);
    }

    return bString.localeCompare(aString);
  }

  const aNum = typeof aVal === 'number' ? aVal : 0;
  const bNum = typeof bVal === 'number' ? bVal : 0;
  if (isAsc) {
    return aNum - bNum;
  }

  return bNum - aNum;
};

type Props = {
  clickedSpanId: string;
  colorsByServiceName: { [serviceName: string]: string };
  setClickedSpanId: (spanId: string) => void;
  spans: Span[];
  traceMetrics: TraceMetrics;
};

const OptionsToHideOnSpanList: {
  [key: string]: boolean;
} = {
  web: true,
  cache: true,
};

const TraceSidebarMainSpanList = ({
  clickedSpanId,
  colorsByServiceName,
  spans,
  setClickedSpanId,
  traceMetrics,
}: Props) => {
  const form = useForm({
    search: '',
    spanType: null,
  });

  const onClickHandler = (span: Span) => () => {
    setClickedSpanId(span.spanId);
  };

  const services = useMemo(() => {
    const filteredSpans = filterSpans({
      spans,
      ...form.values,
    });
    const spansByNamesByServiceName =
      getSpansByNamesByServiceName(filteredSpans);
    return getServices(spansByNamesByServiceName, traceMetrics);
  }, [form.values, spans, traceMetrics]);

  const sortState = useSortState({
    urlStateKey: 'name',
    shouldWriteToUrl: false,
    initalState: {
      key: TracesTableColumnKey.duration,
      isAsc: false,
    },
  });
  const sortByHandler = (key: string) => () => {
    const sortOrder =
      key === sortState.state.key
        ? sortState.state.isAsc
          ? 'desc'
          : 'asc'
        : 'asc';
    sortState.sortBy({ sortBy: key, sortOrder });
  };

  const sort = sortItems(sortState);

  const spanTypeProps = form.propsByKey('spanType');

  const onSpanTypeChange = (nextSpanType: string) => {
    if (nextSpanType === spanTypeProps.value) {
      spanTypeProps.onChange(null);
    } else {
      spanTypeProps.onChange(nextSpanType);
    }
  };

  const optionstoShow = Object.keys(iconsBySpanType).filter((spanType) => {
    return !OptionsToHideOnSpanList[spanType];
  });

  return (
    <div className="trace-sidebar__main__span-list">
      <div className="trace-sidebar__main__span-list__toolbar">
        <div className="trace-sidebar__main__span-list__toolbar__left">
          <RadioGroup
            {...spanTypeProps}
            className="radio-group--buttons"
            onChange={onSpanTypeChange}
            options={optionstoShow.map((spanType) => ({
              label: (
                <IconWithLabel
                  icon={iconsBySpanType[spanType]}
                  label={spanType}
                />
              ),
              value: spanType,
            }))}
          />
        </div>
        <div className="trace-sidebar__main__span-list__toolbar__right">
          <SearchInput
            size="2"
            placeholder="Search spans"
            type="text"
            {...form.propsByKey('search')}
          />
        </div>
      </div>
      <div className="trace-sidebar__main__span-list__header">
        {spanListColumns.map((column) => (
          <div
            className={classnames({
              'trace-sidebar__main__span-list__cell': true,
              [`trace-sidebar__main__span-list__cell--${column.key}`]: true,
            })}
            key={column.key}
            onClick={sortByHandler(column.key)}
          >
            {column.label}
            {sortState.state.key === column.key ? (
              <div className="trace-sidebar__main__span-list__cell__icon">
                {sortState.state.isAsc ? (
                  <Icon icon="chevron-up" size="xs" />
                ) : (
                  <Icon icon="chevron-down" size="xs" />
                )}
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <div className="trace-sidebar__main__span-list__body">
        {services.sort(sort).map((service) => (
          <Accordion
            className="trace-sidebar__main__span-list__service"
            key={service.name}
            renderContent={() =>
              service.spanNames.sort(sort).map((spanName) => (
                <Accordion
                  className="trace-sidebar__main__span-list__span-name"
                  key={spanName.name}
                  renderContent={() =>
                    spanName.spans.sort(sort).map((span, i) => (
                      <div
                        className={classnames({
                          'trace-sidebar__main__span-list__span': true,
                          'trace-sidebar__main__span-list__span--active':
                            span.spanId === clickedSpanId,
                        })}
                        key={i}
                        onClick={onClickHandler(span)}
                      >
                        <TraceSidebarMainSpanListRow
                          item={{ ...span, name: span.name }}
                        />
                      </div>
                    ))
                  }
                  renderTrigger={() => (
                    <TraceSidebarMainSpanListRow
                      item={{
                        ...spanName,
                        name: (
                          <IconWithLabel
                            icon={iconsBySpanType[spanName.spanType]}
                            label={spanName.name}
                          />
                        ),
                      }}
                    />
                  )}
                />
              ))
            }
            renderTrigger={() => (
              <TraceSidebarMainSpanListRow
                item={{
                  ...service,
                  name: (
                    <ChipWithLabel
                      color={colorsByServiceName[service.name]}
                      label={service.name}
                    />
                  ),
                }}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default TraceSidebarMainSpanList;
