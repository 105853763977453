import { ScrollView, Table, TableSearch, useTableSearch } from 'components';
import React, {
  MouseEvent,
  ReactElement,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { AlignedData } from 'uplot';

import { SeriesIcon } from '../components';
import { UPlotConfig } from '../types';
import { tooltipFormatter } from '../utils';

const columns = [
  { key: 'label', label: 'Label' },
  { key: 'value', label: 'Value' },
];

const LegendsRow = ({
  onFocusSeries,
  onRowClick,
  row,
  rowValue,
  width,
}: {
  onFocusSeries: () => void;
  onRowClick: (e: MouseEvent) => void;
  row: any;
  rowValue: number | string;
  width: number;
}) => {
  const maxWidth = width - 120;
  const maxCharLength = Math.floor(maxWidth / 8);
  return (
    <tr
      className="table__row table__row--body"
      onClick={onRowClick}
      style={{ opacity: row.show ? 1 : 0.5 }}
      onMouseEnter={onFocusSeries}
    >
      <th className="table__cell table__cell--body" style={{ maxWidth: width }}>
        <div
          className="uplot__value-legends__row__label"
          style={{ maxWidth: width - maxWidth }}
        >
          <SeriesIcon backgroundColor={row.stroke || row.fill} />
          <label style={{ whiteSpace: 'nowrap' }}>
            {row.label.length > maxCharLength
              ? row.label.slice(0, maxCharLength) + '...'
              : row.label}
          </label>
        </div>
      </th>
      <th className="table__cell table__cell--body">{rowValue}</th>
    </tr>
  );
};

const LegendsValue = ({
  config,
  data,
  onItemClick,
  onFocusSeries,
  unit,
}: {
  config: UPlotConfig;
  data: AlignedData;
  onItemClick: (e: MouseEvent<HTMLLIElement>, idx: number) => void;
  onFocusSeries: (idx: number) => void;
  unit?: string;
}): ReactElement => {
  const [focusedPointIdx, setFocusedPointIdx] = useState<number>(0);

  const valueSeries = useMemo(() => config.series.slice(1), [config]);
  const tableSearch = useTableSearch({
    rows: valueSeries,
    shouldWriteToUrl: false,
  });

  useLayoutEffect(() => {
    config.addHook('setLegend', (u: uPlot) => {
      if (u.cursor.idx && u.cursor.idx !== focusedPointIdx) {
        setFocusedPointIdx(u.cursor.idx);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <div onMouseLeave={() => onFocusSeries(null)}>
      {config.series.length > 8 && (
        <TableSearch
          className="uplot__value-legends__search-bar"
          placeholder="Search timeseries..."
          tableSearch={tableSearch}
        />
      )}
      <ScrollView
        height={'auto'}
        width={config.width - 16}
        scrollIndicator={false}
      >
        <Table
          className="uplot__aggregate-legends__table"
          columns={columns}
          rows={tableSearch.searchedRows || []}
          renderRow={({ row, rowIndex }) => {
            const rowValue = row.show
              ? data[rowIndex + 1][focusedPointIdx]
              : undefined;

            return (
              <LegendsRow
                key={rowIndex}
                onFocusSeries={() =>
                  !tableSearch.search && onFocusSeries(rowIndex + 1)
                }
                onRowClick={(e: any) => {
                  const clickedRow = tableSearch.searchedRows[rowIndex];
                  const seriesRowIdx = config.series.findIndex(
                    (d) => d.label === clickedRow.label,
                  );
                  onItemClick(e, seriesRowIdx);
                }}
                row={row}
                rowValue={
                  rowValue !== undefined
                    ? tooltipFormatter(rowValue, unit)
                    : '-'
                }
                width={config.width}
              />
            );
          }}
        />
      </ScrollView>
    </div>
  );
};

export default LegendsValue;
