import { getClientSelectedFacetValuesByName } from 'screens/Traces/utils';
import { SelectedFacetValuesByName } from 'types';
import {
  buildPromQLFilterFromSelectedFacetValuesByName,
  formatDurationNs,
  formatPercentage,
  getApmSumBy,
  getServiceNamePropertyToSumBy,
} from 'utils';

type ServiceQueryArgs = {
  isDownStream?: boolean;
  property: string;
  rateInterval: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceHash: string;
  stepInMs: number;
};

type ServicesQueryArgs = {
  rateInterval: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  stepInMs: number;
};

export enum ServiceTableKpiKeys {
  apdex = 'apdex',
  averageLatency = 'averageLatency',
  errorRate = 'errorRate',
  maxLatency = 'maxLatency',
  minLatency = 'minLatency',
  p50latency = 'p50latency',
  p75latency = 'p75latency',
  p90latency = 'p90latency',
  p95latency = 'p95latency',
  p99latency = 'p99latency',
  requests = 'requests',
  requestsPerSecond = 'requestsPerSecond',
  eventTime = 'eventTime',
}

const renderCellLatency = ({ value }) =>
  typeof value === 'number' ? formatDurationNs(value * 1000000, 1, 2) : '';

const renderCellPercent = ({ value }) =>
  typeof value === 'number' ? formatPercentage(value) : '';

const renderCellToFixed = ({ value }) =>
  typeof value === 'number' ? `${value.toFixed(2)}` : '';

export const serviceTableKpis = [
  {
    key: ServiceTableKpiKeys.requests,
    label: 'Requests',
    renderCell: ({ value }) => (value ? value.toLocaleString() : '0'),
    servicesQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: selectedFacetValuesByName,
        spanTypeFilter: {
          filter: 'db',
          operator: '!=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `round(sum by (${sumBy}) (increase(edge_latency_count${filter}[${stepInMs}ms])))`;
    },
    serviceQuery: ({
      isDownStream,
      property,
      serviceHash,
      selectedFacetValuesByName,
      stepInMs,
    }: ServiceQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        isDownStream,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          [isDownStream ? 'client_service_hash' : 'service_hash']: {
            [serviceHash]: 1,
          },
        },
      });
      const serviceNamePropertyToSumBy =
        getServiceNamePropertyToSumBy(property);
      const sumBy = getApmSumBy([
        property,
        ...(serviceNamePropertyToSumBy ? [serviceNamePropertyToSumBy] : []),
      ]);
      return `round(sum by (${sumBy}) (increase(edge_latency_count${filter}[${stepInMs}ms])))`;
    },
    servicesClientDBQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const clientSelectedFacetValuesByName =
        getClientSelectedFacetValuesByName(selectedFacetValuesByName);
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...clientSelectedFacetValuesByName,
          span_type: {},
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `round(sum by (${sumBy}) (increase(edge_latency_count${filter}[${stepInMs}ms])))`;
    },
  },
  {
    key: ServiceTableKpiKeys.requestsPerSecond,
    label: 'Requests/s',
    servicesQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: selectedFacetValuesByName,
        spanTypeFilter: {
          filter: 'db',
          operator: '!=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `sum by (${sumBy}) (rate(edge_latency_count${filter}[${stepInMs}ms]))`;
    },
    serviceQuery: ({
      isDownStream,
      property,
      selectedFacetValuesByName,
      serviceHash,
      stepInMs,
    }: ServiceQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        isDownStream,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          [isDownStream ? 'client_service_hash' : 'service_hash']: {
            [serviceHash]: 1,
          },
        },
      });
      const serviceNamePropertyToSumBy =
        getServiceNamePropertyToSumBy(property);
      const sumBy = getApmSumBy([
        property,
        ...(serviceNamePropertyToSumBy ? [serviceNamePropertyToSumBy] : []),
      ]);

      return `sum by (${sumBy}) (rate(edge_latency_count${filter}[${stepInMs}ms]))`;
    },
    servicesClientDBQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const clientSelectedFacetValuesByName =
        getClientSelectedFacetValuesByName(selectedFacetValuesByName);
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...clientSelectedFacetValuesByName,
          span_type: {},
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `sum by (${sumBy}) (rate(edge_latency_count${filter}[${stepInMs}ms]))`;
    },
    renderCell: ({ value }) =>
      typeof value === 'number' ? `${value.toFixed(2)}/s` : '',
  },
  {
    key: ServiceTableKpiKeys.p50latency,
    label: 'p50 Latency',
    renderCell: renderCellLatency,
    servicesQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName,
        spanTypeFilter: {
          filter: 'db',
          operator: '!=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `histogram_quantile(0.50, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
    serviceQuery: ({
      isDownStream,
      property,
      selectedFacetValuesByName,
      serviceHash,
      stepInMs,
    }: ServiceQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        isDownStream,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          [isDownStream ? 'client_service_hash' : 'service_hash']: {
            [serviceHash]: 1,
          },
        },
      });
      const serviceNamePropertyToSumBy =
        getServiceNamePropertyToSumBy(property);
      const sumBy = getApmSumBy([
        property,
        ...(serviceNamePropertyToSumBy ? [serviceNamePropertyToSumBy] : []),
      ]);
      return `histogram_quantile(0.50, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
    servicesClientDBQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const clientSelectedFacetValuesByName =
        getClientSelectedFacetValuesByName(selectedFacetValuesByName);
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...clientSelectedFacetValuesByName,
          span_type: {},
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `histogram_quantile(0.50, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
  },
  {
    key: ServiceTableKpiKeys.p75latency,
    label: 'p75 Latency',
    renderCell: renderCellLatency,
    servicesQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName,
        spanTypeFilter: {
          filter: 'db',
          operator: '!=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `histogram_quantile(0.75, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
    serviceQuery: ({
      isDownStream,
      property,
      selectedFacetValuesByName,
      serviceHash,
      stepInMs,
    }: ServiceQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        isDownStream,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          [isDownStream ? 'client_service_hash' : 'service_hash']: {
            [serviceHash]: 1,
          },
        },
      });

      const serviceNamePropertyToSumBy =
        getServiceNamePropertyToSumBy(property);
      const sumBy = getApmSumBy([
        property,
        ...(serviceNamePropertyToSumBy ? [serviceNamePropertyToSumBy] : []),
      ]);
      return `histogram_quantile(0.75, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
    servicesClientDBQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const clientSelectedFacetValuesByName =
        getClientSelectedFacetValuesByName(selectedFacetValuesByName);
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...clientSelectedFacetValuesByName,
          span_type: {},
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `histogram_quantile(0.75, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
  },
  {
    key: ServiceTableKpiKeys.p90latency,
    label: 'p90 Latency',
    renderCell: renderCellLatency,
    servicesQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName,
        spanTypeFilter: {
          filter: 'db',
          operator: '!=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `histogram_quantile(0.90, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
    serviceQuery: ({
      isDownStream,
      property,
      selectedFacetValuesByName,
      serviceHash,
      stepInMs,
    }: ServiceQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        isDownStream,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          [isDownStream ? 'client_service_hash' : 'service_hash']: {
            [serviceHash]: 1,
          },
        },
      });
      const serviceNamePropertyToSumBy =
        getServiceNamePropertyToSumBy(property);
      const sumBy = getApmSumBy([
        property,
        ...(serviceNamePropertyToSumBy ? [serviceNamePropertyToSumBy] : []),
      ]);
      return `histogram_quantile(0.90, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
    servicesClientDBQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const clientSelectedFacetValuesByName =
        getClientSelectedFacetValuesByName(selectedFacetValuesByName);
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...clientSelectedFacetValuesByName,
          span_type: {},
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `histogram_quantile(0.90, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
  },
  {
    key: ServiceTableKpiKeys.p95latency,
    label: 'p95 Latency',
    renderCell: renderCellLatency,
    servicesQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName,
        spanTypeFilter: {
          filter: 'db',
          operator: '!=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `histogram_quantile(0.95, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
    serviceQuery: ({
      isDownStream,
      property,
      selectedFacetValuesByName,
      serviceHash,
      stepInMs,
    }: ServiceQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        isDownStream,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          [isDownStream ? 'client_service_hash' : 'service_hash']: {
            [serviceHash]: 1,
          },
        },
      });
      const serviceNamePropertyToSumBy =
        getServiceNamePropertyToSumBy(property);
      const sumBy = getApmSumBy([
        property,
        ...(serviceNamePropertyToSumBy ? [serviceNamePropertyToSumBy] : []),
      ]);
      return `histogram_quantile(0.95, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
    servicesClientDBQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const clientSelectedFacetValuesByName =
        getClientSelectedFacetValuesByName(selectedFacetValuesByName);
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...clientSelectedFacetValuesByName,
          span_type: {},
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `histogram_quantile(0.95, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
  },
  {
    key: ServiceTableKpiKeys.p99latency,
    label: 'p99 Latency',
    renderCell: renderCellLatency,
    servicesQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName,
        spanTypeFilter: {
          filter: 'db',
          operator: '!=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `histogram_quantile(0.99, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
    serviceQuery: ({
      isDownStream,
      property,
      selectedFacetValuesByName,
      serviceHash,
      stepInMs,
    }: ServiceQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        isDownStream,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          [isDownStream ? 'client_service_hash' : 'service_hash']: {
            [serviceHash]: 1,
          },
        },
      });

      const serviceNamePropertyToSumBy =
        getServiceNamePropertyToSumBy(property);
      const sumBy = getApmSumBy([
        property,
        ...(serviceNamePropertyToSumBy ? [serviceNamePropertyToSumBy] : []),
      ]);
      return `histogram_quantile(0.99, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
    servicesClientDBQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const clientSelectedFacetValuesByName =
        getClientSelectedFacetValuesByName(selectedFacetValuesByName);
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...clientSelectedFacetValuesByName,
          span_type: {},
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `histogram_quantile(0.99, sum(rate(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
  },
  {
    key: ServiceTableKpiKeys.maxLatency,
    label: 'Max Latency',
    renderCell: renderCellLatency,
    servicesQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName,
        spanTypeFilter: {
          filter: 'db',
          operator: '!=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `max(max_over_time(edge_latency_max${filter}[${stepInMs}ms])) by (${sumBy})`;
    },
    serviceQuery: ({
      isDownStream,
      property,
      selectedFacetValuesByName,
      serviceHash,
      stepInMs,
    }: ServiceQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        isDownStream,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          [isDownStream ? 'client_service_hash' : 'service_hash']: {
            [serviceHash]: 1,
          },
        },
      });
      const serviceNamePropertyToSumBy =
        getServiceNamePropertyToSumBy(property);
      const sumBy = getApmSumBy([
        property,
        ...(serviceNamePropertyToSumBy ? [serviceNamePropertyToSumBy] : []),
      ]);
      return `max(max_over_time(edge_latency_max${filter}[${stepInMs}ms])) by (${sumBy})`;
    },
    servicesClientDBQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const clientSelectedFacetValuesByName =
        getClientSelectedFacetValuesByName(selectedFacetValuesByName);
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...clientSelectedFacetValuesByName,
          span_type: {},
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `max(max_over_time(edge_latency_max${filter}[${stepInMs}ms])) by (${sumBy})`;
    },
  },
  {
    key: ServiceTableKpiKeys.minLatency,
    label: 'Min Latency',
    renderCell: renderCellLatency,
    servicesQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName,
        spanTypeFilter: {
          filter: 'db',
          operator: '!=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `min(min_over_time(edge_latency_min${filter}[${stepInMs}ms])) by (${sumBy})`;
    },
    serviceQuery: ({
      isDownStream,
      property,
      selectedFacetValuesByName,
      serviceHash,
      stepInMs,
    }: ServiceQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        isDownStream,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          [isDownStream ? 'client_service_hash' : 'service_hash']: {
            [serviceHash]: 1,
          },
        },
      });
      const serviceNamePropertyToSumBy =
        getServiceNamePropertyToSumBy(property);
      const sumBy = getApmSumBy([
        property,
        ...(serviceNamePropertyToSumBy ? [serviceNamePropertyToSumBy] : []),
      ]);
      return `min(min_over_time(edge_latency_min${filter}[${stepInMs}ms])) by (${sumBy})`;
    },
    servicesClientDBQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const clientSelectedFacetValuesByName =
        getClientSelectedFacetValuesByName(selectedFacetValuesByName);
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...clientSelectedFacetValuesByName,
          span_type: {},
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `min(min_over_time(edge_latency_min${filter}[${stepInMs}ms])) by (${sumBy})`;
    },
  },
  {
    key: ServiceTableKpiKeys.averageLatency,
    label: 'Average Latency',
    renderCell: renderCellLatency,
    servicesQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName,
        spanTypeFilter: {
          filter: 'db',
          operator: '!=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `sum by (${sumBy}) (rate(edge_latency_sum${filter}[${stepInMs}ms])) / sum by (${sumBy}) (rate(edge_latency_count${filter}[${stepInMs}ms]))`;
    },
    serviceQuery: ({
      isDownStream,
      property,
      selectedFacetValuesByName,
      serviceHash,
      stepInMs,
    }: ServiceQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        isDownStream,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          [isDownStream ? 'client_service_hash' : 'service_hash']: {
            [serviceHash]: 1,
          },
        },
      });
      const serviceNamePropertyToSumBy =
        getServiceNamePropertyToSumBy(property);
      const sumBy = getApmSumBy([
        property,
        ...(serviceNamePropertyToSumBy ? [serviceNamePropertyToSumBy] : []),
      ]);
      return `sum by (${sumBy}) (rate(edge_latency_sum${filter}[${stepInMs}ms])) / sum by (${sumBy}) (rate(edge_latency_count${filter}[${stepInMs}ms]))`;
    },
    servicesClientDBQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const clientSelectedFacetValuesByName =
        getClientSelectedFacetValuesByName(selectedFacetValuesByName);
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...clientSelectedFacetValuesByName,
          span_type: {},
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `sum by (${sumBy}) (rate(edge_latency_sum${filter}[${stepInMs}ms])) / sum by (${sumBy}) (rate(edge_latency_count${filter}[${stepInMs}ms]))`;
    },
  },
  {
    key: ServiceTableKpiKeys.errorRate,
    label: 'Error Rate',
    renderCell: renderCellPercent,
    servicesQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName,
        spanTypeFilter: {
          filter: 'db',
          operator: '!=',
        },
      });

      const errorFilter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          error: {
            true: 1,
          },
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '!=',
        },
      });

      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `sum by (${sumBy}) (rate(edge_latency_count${errorFilter}[${stepInMs}ms])) / sum by (${sumBy}) (rate(edge_latency_count${filter}[${stepInMs}ms]))`;
    },
    serviceQuery: ({
      isDownStream,
      property,
      selectedFacetValuesByName,
      serviceHash,
      stepInMs,
    }: ServiceQueryArgs): string => {
      const errorFilter = buildPromQLFilterFromSelectedFacetValuesByName({
        isDownStream,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          error: {
            true: 1,
          },
          [isDownStream ? 'client_service_hash' : 'service_hash']: {
            [serviceHash]: 1,
          },
        },
      });

      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        isDownStream,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          [isDownStream ? 'client_service_hash' : 'service_hash']: {
            [serviceHash]: 1,
          },
        },
      });

      const serviceNamePropertyToSumBy =
        getServiceNamePropertyToSumBy(property);
      const sumBy = getApmSumBy([
        property,
        ...(serviceNamePropertyToSumBy ? [serviceNamePropertyToSumBy] : []),
      ]);
      return `sum by (${sumBy}) (rate(edge_latency_count${errorFilter}[${stepInMs}ms])) / sum by (${sumBy}) (rate(edge_latency_count${filter}[${stepInMs}ms]))`;
    },
    servicesClientDBQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const clientSelectedFacetValuesByName =
        getClientSelectedFacetValuesByName(selectedFacetValuesByName);
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...clientSelectedFacetValuesByName,
          span_type: {},
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '=',
        },
      });
      const errorFilter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...clientSelectedFacetValuesByName,
          span_type: {},
          error: {
            true: 1,
          },
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `sum by (${sumBy}) (rate(edge_latency_count${errorFilter}[${stepInMs}ms])) / sum by (${sumBy}) (rate(edge_latency_count${filter}[${stepInMs}ms]))`;
    },
  },
  {
    key: ServiceTableKpiKeys.apdex,
    label: 'APDEX',
    renderCell: renderCellToFixed,
    servicesQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName,
        spanTypeFilter: {
          filter: 'db',
          operator: '!=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `apdex(500, 2000, sum(increase(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
    serviceQuery: ({
      isDownStream,
      property,
      selectedFacetValuesByName,
      serviceHash,
      stepInMs,
    }: ServiceQueryArgs): string => {
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        isDownStream,
        selectedFacetValuesByName: {
          ...selectedFacetValuesByName,
          [isDownStream ? 'client_service_hash' : 'service_hash']: {
            [serviceHash]: 1,
          },
        },
      });
      const serviceNamePropertyToSumBy =
        getServiceNamePropertyToSumBy(property);
      const sumBy = getApmSumBy([
        property,
        ...(serviceNamePropertyToSumBy ? [serviceNamePropertyToSumBy] : []),
      ]);
      return `apdex(500, 2000, sum by(${sumBy},le) (increase(edge_latency_bucket${filter}[${stepInMs}ms])))`;
    },
    servicesClientDBQuery: ({
      selectedFacetValuesByName,
      stepInMs,
    }: ServicesQueryArgs): string => {
      const clientSelectedFacetValuesByName =
        getClientSelectedFacetValuesByName(selectedFacetValuesByName);
      const filter = buildPromQLFilterFromSelectedFacetValuesByName({
        selectedFacetValuesByName: {
          ...clientSelectedFacetValuesByName,
          span_type: {},
        },
        spanTypeFilter: {
          filter: 'db',
          operator: '=',
        },
      });
      const sumBy = getApmSumBy(['service_hash', 'service_name']);
      return `apdex(500, 2000, sum(increase(edge_latency_bucket${filter}[${stepInMs}ms])) by (${sumBy}, le))`;
    },
  },
];

export default serviceTableKpis;
