import {
  AutocompleteOption,
  Icon,
  IconWithLabel,
  SearchHelp,
  SearchTag,
} from 'components';
import { useRequest } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { DateSelection, FacetBase, LogsState } from 'types';
import {
  getLastParsedValue,
  logsQueryOperator,
  parsePartialSearchQuery,
} from 'utils';
import LogsSearchBarV2PanelNames from './LogsSearchBarV2PanelNames';
import LogsSearchBarV2PanelValues from './LogsSearchBarV2PanelValues';

type Props = {
  close: VoidFunction;
  error?: string;
  date: DateSelection;
  editIndex?: number;
  fetchFacetValuesByFacetName: (facetName: string) => Promise<any>;
  facetValueOptionsByFacetName: Record<string, any>;
  getFacetNamesRequest: ReturnType<typeof useRequest>;
  getLabelNamesRequest: ReturnType<typeof useRequest>;
  isLogsGrepSearchDisabled: boolean;
  onPanelOpen: VoidFunction;
  onToggleKeyExists: (facetKey: string) => void;
  onValueSelect: (value: string) => void;
  setMeta: (meta: Record<string, unknown>) => void;
  setTyped: (s: string) => void;
  tags: SearchTag[];
  typed: string;
};

const LogsSearchBarV2Panel = ({
  close,
  date,
  editIndex,
  error,
  fetchFacetValuesByFacetName,
  facetValueOptionsByFacetName,
  getFacetNamesRequest,
  getLabelNamesRequest,
  isLogsGrepSearchDisabled,
  onPanelOpen,
  onToggleKeyExists,
  onValueSelect,
  setMeta,
  setTyped,
  tags,
  typed,
}: Props) => {
  useEffect(() => {
    onPanelOpen();
    if (!getFacetNamesRequest.calledAtLeastOnce) {
      getFacetNamesRequest.call({ date, logsState: { date }, limit: '200' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { parsedFacetName, parsedOperator, parsedValue } = useMemo(() => {
    const parsed = parsePartialSearchQuery(typed, false);
    return {
      parsedFacetName: parsed.facetName,
      parsedOperator: parsed.operator,
      parsedValue: parsed.value,
    };
  }, [typed]);

  const lastParsedValue = useMemo(() => {
    const tag = tags[editIndex];
    if (typed === tag?.text) {
      return '';
    }
    return getLastParsedValue(parsedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editIndex, parsedValue, tags]);

  const onFacetValueAutocompleteOptionClick = ({
    option,
  }: {
    close: () => void;
    option: AutocompleteOption;
    type: 'mouse' | 'key';
  }) => {
    const parsedValues = parsedValue.split(' OR ');
    const nextValue = [
      ...parsedValues.slice(0, parsedValues.length - 1),
      option.value,
    ].join(' OR ');

    onValueSelect(nextValue);
    setTyped('');
    close();
  };

  useEffect(() => {
    setMeta(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {error ? (
        <div className="search__panel__error">
          <IconWithLabel icon={<Icon icon="alert-circle" />} label={error} />
        </div>
      ) : null}
      {!parsedValue && !parsedOperator ? (
        <LogsSearchBarV2PanelNames
          close={close}
          date={date}
          getFacetNamesRequest={getFacetNamesRequest}
          getLabelNamesRequest={getLabelNamesRequest}
          onToggleKeyExists={onToggleKeyExists}
          parsedOperator={parsedOperator}
          setMeta={setMeta}
          setTyped={setTyped}
          typed={typed}
        />
      ) : null}
      {parsedFacetName && parsedOperator ? (
        <LogsSearchBarV2PanelValues
          fetchFacetValuesByFacetName={fetchFacetValuesByFacetName}
          facetValueOptions={
            facetValueOptionsByFacetName[parsedFacetName] || []
          }
          key={parsedFacetName}
          onAutocompleteOptionClick={onFacetValueAutocompleteOptionClick}
          parsedFacetName={parsedFacetName}
          parsedValue={lastParsedValue}
        />
      ) : null}
      <SearchHelp operators={logsQueryOperator}>
        <div className="search__help__row">
          <div className="search__help__row__label">Message search:</div>
          <div className="search__help__operators">
            {!isLogsGrepSearchDisabled ? (
              <>
                <div className="search__help__operator__option">
                  <span className="font-bold">{'"example"'}</span>
                  <span>{' Full grep search'}</span>
                </div>
                <div className="search__help__operator__option">
                  <span className="font-bold">{'!"example"'}</span>
                  <span>{' Full grep search (exclude)'}</span>
                </div>
              </>
            ) : null}
            <div className="search__help__operator__option">
              <span className="font-bold">{'example'}</span>
              <span>{' Term search'}</span>
            </div>
            <div className="search__help__operator__option">
              <span className="font-bold">{'!example'}</span>
              <span>{' Term search (exclude)'}</span>
            </div>
          </div>
        </div>
        <div className="search__help__row">
          <div className="search__help__row__label">Logs with facet:</div>
          <div className="search__help__operators">
            <div className="search__help__operator__option">
              <div className="search__help__operator__option">
                <span>{'key exists'}</span>
                <span className="font-bold">=</span>
                <span>{'"A"'}</span>
              </div>
            </div>
          </div>
        </div>
      </SearchHelp>
    </div>
  );
};

export default LogsSearchBarV2Panel;
