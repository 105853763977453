import classnames from 'classnames';
import { Datepicker } from 'composite';
import { useHydrationPageStateContext } from 'context';
import React from 'react';
import { GrList } from 'react-icons/gr';
import { BiArchive } from 'react-icons/bi';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import LogsHydrationArchives from './LogsHydrationArchives';
import LogsHydrationJobsList from './LogsHydrationJobsList';

const getTabs = ({
  tab,
  navigate,
}: {
  tab: string;
  navigate: NavigateFunction;
}) => {
  return [
    {
      key: 'archives',
      label: 'Archives',
      isActive: tab === 'archives',
      icon: <BiArchive size={16} />,
      onClick: () => navigate(`/logs/hydration/archives`),
    },
    {
      key: 'jobs',
      icon: <GrList size={14} />,
      isActive: tab === 'jobs',
      label: 'Jobs',
      onClick: () => navigate(`/logs/hydration/jobs`),
    },
  ];
};

const LogsHydration = () => {
  const { dateState } = useHydrationPageStateContext();
  const [date, setDate] = dateState;
  const navigate = useNavigate();
  const { tab } = useParams();

  const tabs = getTabs({ tab, navigate });
  return (
    <div className="mx-auto max-w-[1200px] py-4">
      <div className="flex items-center justify-between pb-2">
        <h2 className="text-lg font-semibold">Logs Hydration</h2>
        {tab === 'jobs' && (
          <Datepicker
            className="logs__search__datepicker"
            hasStartedLiveTail={false}
            onChange={setDate}
            startLiveTail={undefined}
            value={date}
            quickRangeOptions={[]}
          />
        )}
      </div>
      <div className="mt-2 flex h-8 items-center">
        {tabs.map((item) => (
          <button
            className={classnames({
              logs__tabs__item: true,
              'logs__tabs__item--active': item.key === tab,
            })}
            key={item.key}
            onClick={item.onClick}
          >
            <div className="logs__tabs__item__icon">{item.icon}</div>
            {item.label}
          </button>
        ))}
      </div>
      {tab === 'archives' && <LogsHydrationArchives />}
      {tab === 'jobs' && <LogsHydrationJobsList date={date} />}
    </div>
  );
};

export default LogsHydration;
