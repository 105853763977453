import { AutocompleteOption } from 'components';
import {
  convertSecondToReadable,
  getForecastLookbackWindow,
  evaluateMathExpression,
  getRollupToSecond,
} from 'utils';

import {
  ConditionProps,
  ForecastConditionProps,
} from '../../AlertsCreateCondition';
import {
  validateForecastConditionLinear,
  validateForecastConditionSeasonal,
} from '../../AlertsCreateValidators';
import { alertQueryAndVariableParser } from '../../utils';

export const forecastAlgorithmType = [
  { label: 'Please select', value: '' },
  { label: 'Linear', value: 'linear' },
  { label: 'Seasonal', value: 'seasonal' },
];

export const forecastSeasonalityOptions = [
  { label: 'Hourly', value: '0' },
  { label: 'Daily', value: '1' },
  { label: 'Weekly', value: '2' },
];

export const forecastSeasonalityReverseMapping = {
  '0': 'Hourly',
  '1': 'Daily',
  '2': 'Weekly',
};

const forecastIntervalForHourSeasonal = [
  { label: '5m', value: 60 * 5 },
  { label: '10m', value: 60 * 10 },
];

const forecastIntervalForDaySeasonal = [
  { label: '10m', value: 60 * 10 },
  { label: '20m', value: 60 * 20 },
  { label: '30m', value: 60 * 30 },
];

const forecastIntervalForWeekSeasonal = [
  { label: '30m', value: 60 * 30 },
  { label: '60m', value: 60 * 60 },
  { label: '90m', value: 60 * 90 },
];

export const forecastLinearDurationOptions: AutocompleteOption[] = [
  { label: 'Select', value: '' },
  { label: '4h', value: '4h' },
  { label: '6h', value: '6h' },
  { label: '12h', value: '12h' },
  { label: '1d', value: '1d' },
  { label: '2d', value: '2d' },
  { label: '3d', value: '3d' },
  { label: '7d', value: '7d' },
];

export const forecastSeasonalityDurationOptions: AutocompleteOption[] = [
  { label: 'Select', value: '' },
  { label: '12h', value: '12h' },
  { label: '1d', value: '1d' },
  { label: '2d', value: '2d' },
  { label: '3d', value: '3d' },
  { label: '1w', value: '1w' },
  { label: '2w', value: '2w' },
  { label: '3w', value: '3w' },
];

export const forecastConditionByLabel: AutocompleteOption[] = [
  { label: 'above', value: 'gt' },
  { label: 'below', value: 'lt' },
];

export const getForecastIntervalBySeasonality = (seasonality: string) => {
  let seasonalityIntervalOptions: { label: string; value: number }[] = [];
  if (seasonality === '0') {
    seasonalityIntervalOptions = forecastIntervalForHourSeasonal;
  }
  if (seasonality === '1') {
    seasonalityIntervalOptions = forecastIntervalForDaySeasonal;
  }
  if (seasonality === '2') {
    seasonalityIntervalOptions = forecastIntervalForWeekSeasonal;
  }
  return seasonalityIntervalOptions.map((option) => ({
    label: option.label,
    value: option.value.toString(),
  }));
};

export const buildForecastLinearPromql = (
  promql: string,
  forecastCondition: ForecastConditionProps,
): string => {
  if (!promql) return '';
  const validateFactors = validateForecastConditionLinear(forecastCondition);
  if (Object.keys(validateFactors).length > 0) {
    return '';
  }

  const { forecastDuration } = forecastCondition;
  const forecastDurationIntoSeconds = getRollupToSecond(forecastDuration);
  const forecastLookbackWindow = getForecastLookbackWindow(forecastDuration);

  return `predict_linear(${promql} [${forecastLookbackWindow}], ${forecastDurationIntoSeconds})`;
};

const getForecastSeasonalBandCondition = (
  condition: ConditionProps,
): string => {
  const { of } = condition;
  if (of === 'gt') return '2';
  return '1';
};

const getForecastSeasonalConditionOf = (str: string): string => {
  if (str === '2') return 'gt';
  return 'lt';
};

export const buildForecastSeasonalPromql = ({
  condition,
  forecastCondition,
  promql,
  type,
}: {
  condition: ConditionProps;
  forecastCondition: ForecastConditionProps;
  promql: string;
  type: 'create' | 'load';
}): string => {
  if (!promql) return '';
  const { forecastDuration, seasonality } = forecastCondition;
  if (!forecastDuration) return '';

  const validateFactors = validateForecastConditionSeasonal(forecastCondition);
  if (Object.keys(validateFactors).length > 0) {
    return '';
  }
  const forecastDurationIntoSeconds = getRollupToSecond(forecastDuration);

  if (type === 'load') {
    return `holtwinters_seasonal(${promql}, ${seasonality}, 3, ${forecastDurationIntoSeconds})`;
  }

  return `holtwinters_seasonal(${promql}, ${seasonality}, ${getForecastSeasonalBandCondition(condition)}, ${forecastDurationIntoSeconds})`;
};

export const parseForecastPromql = (
  promql: string,
): {
  forecastCondition: ForecastConditionProps;
  promql: string;
  of?: string;
} => {
  const forecastCondition: ForecastConditionProps = {};
  let newPromql = '';
  let seasonalConditionOf = 'gt';

  if (promql.includes('predict_linear(')) {
    forecastCondition.forecastAlgorithm = 'linear';

    const { query, window, variables } = alertQueryAndVariableParser({
      algoKey: 'predict_linear',
      promql,
      numberOfVariable: 1,
      hasWindow: true,
    });

    if (!query) return { forecastCondition, promql };
    forecastCondition.forecastDuration = window;
    forecastCondition.forecastDuration = convertDurationToReadableFormat(
      variables[0],
    );
    newPromql = query;
  }

  if (promql.includes('holt_winters(')) {
    forecastCondition.forecastAlgorithm = 'seasonal';

    const { query, window } = alertQueryAndVariableParser({
      algoKey: 'holt_winters',
      promql,
      numberOfVariable: 2,
      hasWindow: true,
    });
    forecastCondition.forecastDuration = window;
    newPromql = query;
  }

  if (promql.includes('holtwinters_seasonal(')) {
    forecastCondition.forecastAlgorithm = 'seasonal';
    const { query, variables } = alertQueryAndVariableParser({
      algoKey: 'holtwinters_seasonal',
      promql,
      numberOfVariable: 3,
      hasWindow: false,
    });

    if (!query) return { forecastCondition, promql };

    const forecastDuration = variables[2];
    const forecastDurationInSeconds = Number(forecastDuration);
    forecastCondition.forecastDuration = convertDurationToReadableFormat(
      forecastDurationInSeconds.toString(),
    );
    forecastCondition.seasonality = variables[0];
    seasonalConditionOf = getForecastSeasonalConditionOf(variables[1]);
    newPromql = query;
  }

  return { forecastCondition, promql: newPromql, of: seasonalConditionOf };
};

const convertDurationToReadableFormat = (duration: string): string => {
  // if duration contain alphabets
  if (duration.match(/[a-z]/i)) {
    return duration;
  }

  const evaluatedDuration = evaluateMathExpression(duration);
  if (!evaluatedDuration) return '';

  const durationReadable = convertSecondToReadable(evaluatedDuration);
  if (!durationReadable) return '';

  return durationReadable;
};
