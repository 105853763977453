import { AutocompleteV2, AutocompleteOption } from 'components';
import React, { ReactElement } from 'react';
import { AnomalyAlgorithmType } from 'types';

import {
  AlertsCreateConditionFor,
  AlertsCreateConditionInfo,
  AlertsCreateConditionQueryKey,
  AlertsCreateConditionThreshold,
  useAlertsCreateCondition,
} from '../AlertsCreateCondition';
import AlertsCreateAnomalyConditionBasic from './AlertsCreateAnomalyConditionBasic';
import { anomalyAlertPopupMessage } from '../constants';
import { useAlertsCreate } from '../hooks';
import { anomalyAlgorithmTypeOptions } from './utils';

const AlertsCreateAnomalyCondition = ({
  alertsCreateState,
  conditionState,
  queryAndFormulaKeysLabel,
}: {
  alertsCreateState: ReturnType<typeof useAlertsCreate>;
  conditionState: ReturnType<typeof useAlertsCreateCondition>;
  queryAndFormulaKeysLabel: AutocompleteOption[];
}): ReactElement => {
  const { anomalyCondition, setAnomalyCondition } = conditionState;
  const { alertType, evaluate, setEvaluate } = alertsCreateState;
  const { anomalyAlgorithm } = anomalyCondition;
  const {
    BASIC_ALGORITHM_DESCRIPTION,
    AGILE_ALGORITHM_DESCRIPTION,
    ROBUST_ALGORITHM_DESCRIPTION,
  } = anomalyAlertPopupMessage;

  const getAlgorithmDescription = (algorithm: AnomalyAlgorithmType) => {
    if (algorithm === AnomalyAlgorithmType.BASIC)
      return BASIC_ALGORITHM_DESCRIPTION;
    if (algorithm === AnomalyAlgorithmType.AGILE)
      return AGILE_ALGORITHM_DESCRIPTION;
    if (algorithm === AnomalyAlgorithmType.ROBUST)
      return ROBUST_ALGORITHM_DESCRIPTION;
    return 'Please select an algorithm to see the description';
  };

  return (
    <div>
      <div className="alerts__create__section__item">
        <div className="alerts__create__conditions__item__text">
          Trigger when anomalies are detected on evaluated value of
        </div>
        <AlertsCreateConditionQueryKey
          conditionState={conditionState}
          queryAndFormulaKeysLabel={queryAndFormulaKeysLabel}
        />
        <div className="alerts__create__conditions__item__text">
          and bounds above or below
        </div>
        <AlertsCreateConditionThreshold
          conditionState={conditionState}
          placeholder="Threshold"
          alertType={alertType}
        />
        <div className="alerts__create__conditions__item__text">
          percentage of times over last
        </div>
        <AlertsCreateConditionFor
          evaluate={evaluate}
          ruleType={alertsCreateState.ruleType}
          setEvaluate={setEvaluate}
        />
      </div>
      <div className="alerts__create__section__header">
        Anomaly algorithm options
      </div>
      <div className="alerts__create__section__item">
        <div className="alerts__create__conditions__item__text">
          Using Algorithm
        </div>
        <div className="alert__create__anomaly__detaction_desciption">
          <AlertsCreateConditionInfo
            description={getAlgorithmDescription(anomalyAlgorithm)}
          />
        </div>
        <div className="alerts__create__conditions__algorithm">
          <AutocompleteV2
            onChange={(val: AnomalyAlgorithmType) =>
              setAnomalyCondition((prev) => ({
                ...prev,
                anomalyAlgorithm: val,
              }))
            }
            options={anomalyAlgorithmTypeOptions}
            value={anomalyAlgorithm}
            placeholder={'Select'}
          />
        </div>
        {[
          AnomalyAlgorithmType.BASIC,
          AnomalyAlgorithmType.AGILE,
          AnomalyAlgorithmType.ROBUST,
          AnomalyAlgorithmType.AGILE_ROBUST,
        ].includes(anomalyAlgorithm) && (
          <AlertsCreateAnomalyConditionBasic conditionState={conditionState} />
        )}
      </div>
    </div>
  );
};

export default AlertsCreateAnomalyCondition;
