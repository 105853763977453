import React from 'react';
import * as constants from './constants';
import { JsonOptions, JsonType } from './types';
import CopyButton from '../CopyButton';

type Props = {
  label: string;
  level: number;
  options?: JsonOptions;
  path: string;
  type: JsonType;
  value: any;
};

const JsonItemValue = ({ label, level, options, path, type, value }: Props) => {
  const renderValue = options?.renderValue;

  return (
    <div
      className="json__item"
      style={{ paddingLeft: `${level * constants.marginLeft}px` }}
    >
      <div className="json__label">{label}</div>
      {renderValue ? (
        renderValue({ label, path, type, value })
      ) : (
        <div className={`json__value text--${type}`}>{String(value)}</div>
      )}
      <div className="json__copy-button">
        <CopyButton text={String(value)} />
      </div>
    </div>
  );
};

export default JsonItemValue;
