import { useSelectedFacetValuesByNameState } from 'hooks';

export type FacetBase = {
  name: string;
  type?: string;
  component: string;
  fpHash?: string;
  count?: number;
};

export type FacetRange = {
  lower: number;
  upper: number;
};

export enum FacetType {
  duration = 'duration',
  number = 'number',
  size = 'size',
  string = 'string',
  url = 'url',
  uuid = 'uuid',
}

export type FacetValueInfo = {
  facetKey: string;
  facetValue: string;
  count: number;
};

export type FacetValueInfoByFacetKey = Record<
  FacetValueInfo['facetKey'],
  Array<Pick<FacetValueInfo, 'facetValue' | 'count'>>
>;

export type SelectedFacetValuesByNameState = ReturnType<
  typeof useSelectedFacetValuesByNameState
>;
