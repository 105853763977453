import dayjs from 'dayjs';
import { getSpanTypeFilterForServiceAPI } from 'utils';
import {
  DateSelection,
  SelectedFacetValuesByName,
  SelectedFacetValues,
  Service,
} from 'types';
import { onPromiseError } from 'utils';
import queryTraceService from './queryTraceService';

type Args = {
  date: DateSelection;
  selectedFacetValuesByName?: SelectedFacetValuesByName;
};

const getServices = async ({
  date,
  selectedFacetValuesByName = {},
}: Args): Promise<Service[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const foundKfSource = selectedFacetValuesByName['kf_source']
    ? Object.keys(selectedFacetValuesByName['kf_source']).find(
        (key) => selectedFacetValuesByName['kf_source'][key],
      )
    : null;

  const spanTypeServiceFilter = getSpanTypeFilterForServiceAPI(
    selectedFacetValuesByName['span_type'] || {},
  );

  const kfSource = foundKfSource || 'apm';

  return queryTraceService<Service[], 'services'>(`
    query GetServices {
      services (
        durationSecs: ${durationSecs}
        kfSource: "${kfSource}"
        ${
          spanTypeServiceFilter
            ? `service: {kfType: "${spanTypeServiceFilter}" }`
            : ''
        }
        timestamp: "${endTime.format()}",
      ) {
        name
        distinctLabels
        labels
        hash
        kfType
      }
    }
  `).then((data) => data.services || [], onPromiseError);
};

export default getServices;
